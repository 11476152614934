unit ChangePasswordUnit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, LxWebPasswordStrength,
  Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TChangePasswordForm = class(TForm)
    edRepeatPassword: TEdit;
    btnChangePassword: TButton;
    edNewPassword: TEdit;
    LxWebPasswordStrength1: TLxWebPasswordStrength;
    procedure edNewPasswordChange(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure btnChangePasswordClick(Sender: TObject);
  private
    { Private declarations }
    [async]
    procedure ValidateKey; async;
    [async]
    procedure ChangePassword; async;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  ChangePasswordForm: TChangePasswordForm;

implementation

{$R *.dfm}

Uses LxWeb.Tools, LxWeb.Router, MyPolyCAD.App, MyPolyCAD.COnfiguration, MessageLoginUnit;


procedure TChangePasswordForm.ValidateKey;
Var
   Location, Key : String;
   AMessage : String;
begin
     Location := window.location.href;
     if Not Location.Contains('?key=') Then Router.Route('/LOGIN');

     Key := StringBetween('key=', '&', Location);
     if Not await(boolean, appMyPolyCAD.ValidatePasswordKey(Key, AMessage)) Then
        Router.Route('/LOGIN');
end;

procedure TChangePasswordForm.ChangePassword;
Var
   Location, Key, ATips : String;
   AStrength : Integer;
   AMessage, APageMessage : String;
Begin
     AStrength := CheckPasswordStrength(edNewPassword.Text, ATips);
     If (AStrength >= 4) and (edNewPassword.Text = edRepeatPassword.Text) Then Begin

        Location := window.location.href;
        Key := StringBetween('key=', '&', Location);

        if await(boolean, appMyPolyCAD.ChangePassword(Key, edNewPassword.Text, AMessage)) Then
           TMessageLoginForm.ShowMessage('Password Changed', '<p>Request to change password was successful.  Press Login to continue</p>')
        Else Begin
           APageMessage := '<p>Request to change password was unsuccessful, please request the change again.</p>' +
                           '<p>Reason: ' + AMessage + '</p>' +
                           '<p>If you continue to experience issues changing you&apos;re password, please contact support at <a href="mailto:' + ConfigurationSupportEmail + '">' + ConfigurationSupportEmail + '</a></p>';

           TMessageLoginForm.ShowMessage('Password Change Failed', APageMessage);
           End;
        End;
End;

procedure TChangePasswordForm.WebFormCreate(Sender: TObject);
begin
     ValidateKey;
end;

procedure TChangePasswordForm.edNewPasswordChange(Sender: TObject);
Var
   AStrength : Integer;
   ATips : String;
begin
     AStrength := CheckPasswordStrength(edNewPassword.Text, ATips);
     btnChangePassword.Enabled := (AStrength >= 4) and (edNewPassword.Text = edRepeatPassword.Text);

     LxWebPasswordStrength1.Strength := AStrength;
     LxWebPasswordStrength1.Hint := ATips;

     edNewPassword.Hint := ATips;
end;

procedure TChangePasswordForm.btnChangePasswordClick(Sender: TObject);
begin
     ChangePassword;
end;

procedure TChangePasswordForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  edRepeatPassword := TEdit.Create('edRepeatPassword');
  btnChangePassword := TButton.Create('btnChangePassword');
  edNewPassword := TEdit.Create('edNewPassword');
  LxWebPasswordStrength1 := TLxWebPasswordStrength.Create('lbPasswordStrength');

  edRepeatPassword.BeforeLoadDFMValues;
  btnChangePassword.BeforeLoadDFMValues;
  edNewPassword.BeforeLoadDFMValues;
  LxWebPasswordStrength1.BeforeLoadDFMValues;
  try
    Name := 'ChangePasswordForm';
    Width := 640;
    Height := 480;
    ElementClassName := 'application';
    ElementFont := efCSS;
    ElementPosition := epRelative;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    edRepeatPassword.SetParentComponent(Self);
    edRepeatPassword.Name := 'edRepeatPassword';
    edRepeatPassword.Left := 8;
    edRepeatPassword.Top := 39;
    edRepeatPassword.Width := 141;
    edRepeatPassword.Height := 25;
    edRepeatPassword.ElementFont := efCSS;
    edRepeatPassword.ElementPosition := epIgnore;
    edRepeatPassword.HeightStyle := ssAuto;
    edRepeatPassword.HeightPercent := 100.000000000000000000;
    edRepeatPassword.WidthStyle := ssAuto;
    edRepeatPassword.WidthPercent := 100.000000000000000000;
    SetEvent(edRepeatPassword, Self, 'OnChange', 'edNewPasswordChange');
    btnChangePassword.SetParentComponent(Self);
    btnChangePassword.Name := 'btnChangePassword';
    btnChangePassword.Left := 8;
    btnChangePassword.Top := 70;
    btnChangePassword.Width := 141;
    btnChangePassword.Height := 25;
    btnChangePassword.Caption := 'btnChangePassword';
    btnChangePassword.ChildOrder := 1;
    btnChangePassword.ElementFont := efCSS;
    btnChangePassword.ElementPosition := epIgnore;
    btnChangePassword.Enabled := False;
    btnChangePassword.HeightStyle := ssAuto;
    btnChangePassword.HeightPercent := 100.000000000000000000;
    btnChangePassword.WidthStyle := ssAuto;
    btnChangePassword.WidthPercent := 100.000000000000000000;
    SetEvent(btnChangePassword, Self, 'OnClick', 'btnChangePasswordClick');
    edNewPassword.SetParentComponent(Self);
    edNewPassword.Name := 'edNewPassword';
    edNewPassword.Left := 8;
    edNewPassword.Top := 8;
    edNewPassword.Width := 141;
    edNewPassword.Height := 25;
    edNewPassword.ChildOrder := 2;
    edNewPassword.ElementFont := efCSS;
    edNewPassword.ElementPosition := epIgnore;
    edNewPassword.HeightStyle := ssAuto;
    edNewPassword.HeightPercent := 100.000000000000000000;
    edNewPassword.WidthStyle := ssAuto;
    edNewPassword.WidthPercent := 100.000000000000000000;
    SetEvent(edNewPassword, Self, 'OnChange', 'edNewPasswordChange');
    LxWebPasswordStrength1.SetParentComponent(Self);
    LxWebPasswordStrength1.Name := 'LxWebPasswordStrength1';
    LxWebPasswordStrength1.Left := 166;
    LxWebPasswordStrength1.Top := 8;
    LxWebPasswordStrength1.Width := 155;
    LxWebPasswordStrength1.Height := 25;
    LxWebPasswordStrength1.HeightStyle := ssAuto;
    LxWebPasswordStrength1.WidthStyle := ssAuto;
    LxWebPasswordStrength1.ShapeCount := 5;
    LxWebPasswordStrength1.ShapeWidth := 16;
    LxWebPasswordStrength1.ShapeHeight := 16;
    LxWebPasswordStrength1.ShapeRadius := 12;
    LxWebPasswordStrength1.ShapeThickness := 2;
    LxWebPasswordStrength1.Strength := 0;
    LxWebPasswordStrength1.AcceptableStrength := 4;
    LxWebPasswordStrength1.ShapeColor := clSilver;
    LxWebPasswordStrength1.PoorColor := clRed;
    LxWebPasswordStrength1.AcceptableColor := 42495;
    LxWebPasswordStrength1.GoodColor := clLime;
    LxWebPasswordStrength1.ShowHint := True;
    LxWebPasswordStrength1.BorderStyle := bsSingle;
    LxWebPasswordStrength1.BorderColor := clSilver;
    LxWebPasswordStrength1.Color := clWhite;
    LxWebPasswordStrength1.ElementFont := efCSS;
    LxWebPasswordStrength1.ElementPosition := epIgnore;
  finally
    edRepeatPassword.AfterLoadDFMValues;
    btnChangePassword.AfterLoadDFMValues;
    edNewPassword.AfterLoadDFMValues;
    LxWebPasswordStrength1.AfterLoadDFMValues;
  end;
end;

end.