unit MyPolyCAD.Model.Feedback;

interface

Uses Classes, SysUtils, WEBLib.JSON;

Type
          TMyUserDescription = (udStudent, udAmateur, udAcademic, udProfessional, udITAdministrator, udOther, udUnknown);

          TMySoftwareFeature = (sfHullSurfaceDesign, sfCurveDesign, sfParametricHullGeneration, sfXTopology, sfHullTransformation,
                                sfGeometricAnalysis, sfWeightEstimation, sfHydrostaticAnalysis, sfIntactStability, sfFormatExchange,
                                sfPointClouds, sfImages, sfCompartmentation, sfLinesPlanGeneration);
         TMySoftwareFeatures = set of TMySoftwareFeature;

    TMyUserDescriptionHelper = record helper for TMyUserDescription
                               Function ToString : String;
                               End;

   TMySoftwareFeaturesHelper = record helper for TMySoftwareFeatures
                               Function ToString : String;
                               End;

                 TMyFeedback = Class(TObject)
                               Private
                               FHearOfPolyCAD : String;
                               FUserDescription : TMyUserDescription;
                               FSoftwareFeatures : TMySoftwareFeatures;
                               Public
                               Constructor Create;
                               Procedure Clear;

                               Procedure ToJSON(AJSONObject : TJSONObject);


                               Property HearOfPolyCAD : String Read FHearOfPolyCAD Write FHearOfPolyCAD;
                               Property UserDescription : TMyUserDescription Read FUserDescription Write FUserDescription;
                               Property SoftwareFeatures : TMySoftwareFeatures Read FSoftwareFeatures Write FSoftwareFeatures;
                               End;


implementation

Uses TypInfo, Web, LxWeb.Tools, LxWeb.JSON;

{$region '************************************************* TMyUserDescriptionHelper **************************************'}

Function TMyUserDescriptionHelper.ToString : String;
Begin
     Result := EnumerationToString(TypeInfo(TMyUserDescription), Self);
End;

{$endregion}

{$region '************************************************* TMySoftwareFeaturesHelper *************************************'}

Function TMySoftwareFeaturesHelper.ToString : String;
Begin
     Result := SetToString(TypeInfo(TMySoftwareFeatures), Self);
End;

{$endregion}

{$region '************************************************* TMyFeedback ***************************************************'}

Constructor TMyFeedback.Create;
Begin
     Inherited Create;
     Clear;
End;

Procedure TMyFeedback.Clear;
Begin
     FHearOfPolyCAD := '';
     FUserDescription := udUnknown;
     FSoftwareFeatures := [];
End;

Procedure TMyFeedback.ToJSON(AJSONObject : TJSONObject);
Begin
     AJSONObject.AddPair('HearOfPolyCAD', FHearOfPolyCAD);
     AJSONObject.AddPair('UserDescription', FUserDescription.ToString);
     AJSONObject.AddPair('SoftwareFeatures', FSoftwareFeatures.ToString);
End;

{$endregion}



end.
