unit LxWeb.Router;

interface

Uses Classes, SysUtils, LxWeb.Tools, WEBLib.Forms, Generics.Collections, WEBLib.Controls, JS;

Type
  TLxRouteValidation = reference to function : boolean; async;

    TLxWebRoutedForm = Class(TObject)
                       Private
                       FName : String;
                       FFormClass : TFormClass;
                       FShowHash : Boolean;
                       FValidateRoute : TLxRouteValidation;
                       FInvalidRouting : String;
                       Public
                       Constructor Create(AName : String; AFormClass : TFormClass; AShowHash : Boolean = True); overload;
                       Constructor Create(AName : String; AFormClass : TFormClass; AValidation : TLxRouteValidation; AInvalidRouting : String; AShowHash : Boolean = True); overload;
                       Property Name : String Read FName;
                       Property FormClass : TFormClass Read FFormClass;
                       Property ValidateRoute : TLxRouteValidation Read FValidateRoute;
                       Property InvalidRouting : String Read FInvalidRouting;
                       Property ShowHash : Boolean Read FShowHash;
                       End;

        TLxWebRouter = Class(TObject)
                       Private
                       FMap : TDictionary<String, TLxWebRoutedForm>;
                       FDefaultFormClass : TFormClass;
                       FCurrentRoute : TLxWebRoutedForm;
                       FApplication : TApplication;
                       Procedure DoHashChange(Sender: TObject; AHash: string; var Handled: boolean);
                       Public
                       Constructor Create(AApplication : TApplication);
                       Procedure AddRoute(AName : String; AFormClass : TFormClass; AShowHash : Boolean = True); overload;
                       Procedure AddRoute(AName : String; AFormClass : TFormClass; AValidation : TLxRouteValidation; AInvalidRouting : String; AShowHash : Boolean = True); overload;
                       Procedure AddDefaultRoute(AFormClass : TFormClass);
                       Function Route(ARoute : String) : TForm; async;
                       Function DefaultRoute : TForm; async;
                       End;

Var
   Router : TLxWebRouter;

implementation

Uses Web;

{$region '************************************************* TLxWebRoutedForm **********************************************'}

Constructor TLxWebRoutedForm.Create(AName : String; AFormClass : TFormClass; AShowHash : Boolean = True);
Begin
     Inherited Create;
     FName := AName;
     FFormClass := AFormClass;
     FShowHash := AShowHash;
     FValidateRoute := nil;
     FInvalidRouting := '';
End;

Constructor TLxWebRoutedForm.Create(AName : String; AFormClass : TFormClass; AValidation : TLxRouteValidation; AInvalidRouting : String; AShowHash : Boolean = True);
Begin
     Create(AName, AFormClass, AShowHash);
     FValidateRoute := AValidation;
     FInvalidRouting := AInvalidRouting;
End;

{$endregion}

{$region '************************************************* TLxWebRouter **************************************************'}

Constructor TLxWebRouter.Create(AApplication : TApplication);
Begin
     Inherited Create;
     FDefaultFormClass := nil;
     FMap := TDictionary<String, TLxWebRoutedForm>.Create;

     FApplication := AApplication;
     FApplication.AutoFormRoute := true;
     FApplication.OnHashChange := DoHashChange;
     FCurrentRoute := nil;
End;

Procedure TLxWebRouter.AddRoute(AName : String; AFormClass : TFormClass; AShowHash : Boolean = True);
Begin
     FMap.Add(UpperCase(AName), TLxWebRoutedForm.Create(UpperCase(AName), AFormClass, AShowHash));
End;

Procedure TLxWebRouter.AddRoute(AName : String; AFormClass : TFormClass; AValidation : TLxRouteValidation; AInvalidRouting : String; AShowHash : Boolean = True); overload;
Begin
     FMap.Add(UpperCase(AName), TLxWebRoutedForm.Create(UpperCase(AName), AFormClass, AValidation, AInvalidRouting, AShowHash));
End;

Procedure TLxWebRouter.AddDefaultRoute(AFormClass : TFormClass);
Begin
     FDefaultFormClass := AFormClass;
End;

Function TLxWebRouter.Route(ARoute : String) : TForm;
Var
   ARoutedForm : TLxWebRoutedForm;
   QPos : Integer;
   Params : String;
   AResult : Boolean;
Begin
     Result := nil;
     Params := '';
     If (Pos('#', ARoute) = 1) Then ARoute := StringAfter('#', ARoute);

     QPos := Pos('?', ARoute);
     If QPos > 0 Then Begin
        Params := Copy(ARoute, QPos, Length(ARoute));
        ARoute := Copy(ARoute, 1, QPos - 1);
        End;

     If FMap.ContainsKey(UpperCase(ARoute)) Then Begin
        ARoutedForm := FMap[UpperCase(ARoute)];

        If Assigned(ARoutedForm.FValidateRoute) Then Begin
           asm
              AResult = await ARoutedForm.FValidateRoute.apply(null);
           end;

           If Not AResult Then ARoutedForm := FMap[UpperCase(ARoutedForm.FInvalidRouting)];
           End;

        If Not(ARoutedForm = FCurrentRoute) Then Begin
           FCurrentRoute := ARoutedForm;
           Application.CreateForm(ARoutedForm.FormClass, Result);


           If ARoutedForm.ShowHash Then
              window.location.hash := ARoute + Params
           else
              window.history.replaceState('', document.title, window.location.href.split('#')[0]);
              //window.location.hash := '';
           End;
        End
     Else If Assigned(FDefaultFormClass) Then Begin
        FCurrentRoute := nil;
        Application.CreateForm(FDefaultFormClass, Result);
        window.history.replaceState('', document.title, window.location.href.split('#')[0]);
        //window.location.hash := '';
        End;
End;

Function TLxWebRouter.DefaultRoute : TForm; async;
Begin
     If Assigned(FDefaultFormClass) Then Begin
        FCurrentRoute := nil;
        Application.CreateForm(FDefaultFormClass, Result);
        window.history.replaceState('', document.title, window.location.href.split('#')[0]);
        End;
End;

Procedure TLxWebRouter.DoHashChange(Sender: TObject; AHash: string; var Handled: boolean);
Begin
     Handled := True;
     Route(AHash);
End;

{$endregion}


Initialization
   Router := TLxWebRouter.Create(Application);
end.
