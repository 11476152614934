unit LxWeb.JSON;

interface

Uses WEBLIB.Json;

Type
           TJSONEnumerator = class;

     TJSONIteratorDataType = (jdValue, jdObject, jdArray, jdString, jdInteger, jdFloat, jdBoolean);

             TJSONIterator = Class(TObject)
                             Private
                             FKey : String;
                             FValue : TJSONValue;
                             FDataType : TJSONIteratorDataType;
                             FUKey : String;
                             FJSONObject : TJSONObject;
                             FJSONArray : TJSONArray;
                             Function GetKey : String;
                             Function GetValue : TJSONValue;
                             Public
                             Constructor Create;
                             Destructor Destroy; override;
                             Function KeyIs(AKey : String) : Boolean; overload;
                             Function KeyIs(AKey : String; ADataType : TJSONIteratorDataType) : Boolean; overload;
                             Function AsString : String;
                             Function AsInteger : Integer;
                             Function AsFloat : Double;
                             Function AsBoolean : Boolean;
                             Function AsObject : TJSONObject;
                             Function AsArray : TJSONArray;
                             Function AsValue : JSValue;
                             Property DataType : TJSONIteratorDataType Read FDataType;
                             Property Key : String Read GetKey;
                             Property Value : TJSONValue Read GetValue;
                             End;

           TJSONEnumerator = Class(TObject)
                             Private
                             FObject : TJSONObject;
                             FArray : TJSONArray;
                             FIterator : TJSONIterator;
                             FIndex : Integer;
                             Function GetCurrent : TJSONIterator;
                             Public
                             Constructor Create(AJSONValue : TJSONValue);
                             Destructor Destroy; override;
                             Function MoveNext : Boolean;
                             Property Current : TJSONIterator Read GetCurrent;
                             End;

         TJSONObjectHelper = Class Helper for TJSONObject
                             Class Function ToJSON(Data : array of const) : String; overload;
                             Function GetEnumerator : TJSONEnumerator;
                             Function AddPair(const Str: string; const Val: Boolean): TJSONObject; overload;
                             Function AddPair(const Str: string; const Val: JSValue): TJSONObject; overload;
                             Function Contains(AKey : String) : Boolean;
                             End;

          TJSONArrayHelper = Class Helper for TJSONArray
                             Class Function ToJson(Data : array of const) : String; overload;
                             Function GetEnumerator : TJSONEnumerator;
                             End;

           TJSONObjectProc = Procedure(AJSONObject : TJSONObject) of object;
            TJSONArrayProc = Procedure(AJSONArray : TJSONArray) of object;


Function StrToJSON(Const AJSON : String; AJSONProc : TJSONObjectProc) : Boolean; overload;
Function StrToJSON(Const AJSON : String; AJSONProc : TJSONArrayProc) : Boolean; overload;

Function JSONtoStr(AJSONProc : TJSONObjectProc) : String; overload;
Function JSONtoStr(AJSONProc : TJSONArrayProc) : String; overload;

implementation

Uses SysUtils, Js, Web;

Procedure UnescapeString(Var Str : String);
Var
   Index : Integer;
   NextChar : Char;
Begin
     Index := 1;
     While Index < Length(Str) Do Begin
           If Str[Index] = '\' Then Begin
              NextChar := Str[Index];
              Case NextChar of
                   '\', '"', '/' : Delete(Str, Index, 1);
                   End;
              End;
           Inc(Index);
           End;
End;

{$region '******************************* TJSONIterator *********************************'}

Constructor TJSONIterator.Create;
Begin
     Inherited Create;
     FJSONObject := nil;
     FJSONArray := nil;
     FKey := '';
     FUKey := '';
     FValue := nil;
End;

Destructor TJSONIterator.Destroy;
Begin
     If Assigned(FJSONObject) Then FJSONObject.Free;
     If Assigned(FJSONArray) Then FJSONArray.Free;
     Inherited Destroy;
End;


Function TJSONIterator.GetKey : String;
Begin
     Result := FKey;
End;

Function TJSONIterator.KeyIs(AKey : String) : Boolean;
Begin
     Result := FUKey = AKey.ToUpper;
End;

Function TJSONIterator.KeyIs(AKey : String; ADataType : TJSONIteratorDataType) : Boolean;
Begin
     Result := (FUKey = AKey.ToUpper) and ((ADataType = FDataType) or ((FDataType = jdInteger) and (ADataType = jdFloat)));
End;


Function TJSONIterator.GetValue : TJSONValue;
Begin
     Result := FValue;
End;

Function TJSONIterator.AsString : String;
Begin
     Result := FValue.ToString;

     If (FDataType = jdString) and (Length(Result) > 1) and (Result[1] = #34) and (Result[Length(Result)] = #34) Then
        Result := Copy(Result, 2, Length(Result) - 2);

     UnescapeString(Result);
End;

{$HINTS OFF}

Function TJSONIterator.AsInteger : Integer;
Var
   AValue : JSValue;
Begin
     If FDataType = jdInteger Then Begin
        asm
           Result = this.FValue.fjv;
        end;
        End
     Else
        Raise Exception.Create('TJSONIterator.AsInteger - Value has not been parsed as an Integer (' + AsString + ')');
End;

Function TJSONIterator.AsFloat : Double;
Begin
     If FDataType in [jdInteger, jdFloat] Then
        asm
           Result = this.FValue.fjv;
        end
     else
        Raise Exception.Create('TJSONIterator.AsFloat - Value has not been parsed as an Float (' + AsString + ')');
End;

Function TJSONIterator.AsBoolean : Boolean;
Begin
     If FDataType = jdBoolean Then
        asm
           Result = this.FValue.fjv;
        end
     else
        Raise Exception.Create('TJSONIterator.AsBoolean - Value has not been parsed as an Boolean (' + AsString + ')');
End;

{$HINTS ON}

Function TJSONIterator.AsObject : TJSONObject;
Var
   AJSValue : TJSObject;
Begin
     If FDataType = jdObject Then Begin
        If Assigned(FJSONObject) Then FreeAndNil(FJSONObject);
        If Assigned(FJSONArray) Then FreeAndNil(FJSONArray);

        asm
           AJSValue =  this.FValue.fjv;
        end;

        FJSONObject := TJSONObject.Create(AJSValue);
        Result := FJSONObject;
        End
     {Else If FDataType = jdArray Then
        Result := AsArray}
     Else
        Raise Exception.Create('TJSONIterator.AsObject - Value has not been parsed as an Object (' + AsString + ')');
End;

Function TJSONIterator.AsArray : TJSONArray;
Var
   AJSValue : TJSObject;
Begin
     If FDataType = jdArray Then Begin
        If Assigned(FJSONObject) Then FreeAndNil(FJSONObject);
        If Assigned(FJSONArray) Then FreeAndNil(FJSONArray);

        asm
           AJSValue =  this.FValue.fjv;
        end;

        FJSONArray := TJSONArray.Create(TJSArray(AJSValue));
        Result := FJSONArray;
        End
     Else
        Raise Exception.Create('TJSONIterator.AsArray - Value has not been parsed as an Array (' + AsString + ')');
End;

{$HINTS OFF}

Function TJSONIterator.AsValue : JSValue;
Begin
     asm
        Result = this.FValue.fjv;
     End;
End;

{$HINTS ON}

{$endregion}

{$region '******************************* TJSONEnumerator ********************************'}

Constructor TJSONEnumerator.Create(AJSONValue : TJSONValue);
Begin
     Inherited;
     FArray := nil;
     FObject := nil;


     If AJSONValue is TJSONArray Then
        FArray := AJSONValue as TJSONArray
     Else If AJSONValue is TJSONObject Then
        FObject := AJSONValue as TJSONObject;

     FIterator := TJSONIterator.Create;
     FIndex := -1;
End;

Destructor TJSONEnumerator.Destroy;
Begin
     FIterator.Free;
     Inherited Destroy;
End;

Function TJSONEnumerator.GetCurrent : TJSONIterator;
Begin
     If Assigned(FObject) Then Begin
        If (FIndex >=0) and (FIndex < FObject.Count) Then
           Result := FIterator
        Else
           Result := nil;
        end
     Else If Assigned(FArray) Then Begin
        If (FIndex >=0) and (FIndex < FArray.Count) Then
           Result := FIterator
        Else
           Result := nil;
        End
     Else
        Result := nil;
End;

Function TJSONEnumerator.MoveNext : Boolean;
Var
   APair : TJSONPair;
   AValue : TJSONValue;
   AKey : String;
Begin
     Inc(FIndex);
     if Assigned(FObject) Then
        Result := FIndex < FObject.Count
     Else If Assigned(FArray) Then
        Result := FIndex < FArray.Count
     Else
        Result := False;

     If Result Then Begin
        With FIterator Do Begin
             If Assigned(FObject) Then Begin
                APair := FObject.Pairs[FIndex];
                AKey := APair.JsonString.ToString;
                AValue := APair.JsonValue;

                // Process Key
                If (Length(AKey) > 1) and (AKey[1] = #34) and (AKey[Length(AKey)] = #34) Then
                   AKey := Copy(AKey, 2, Length(AKey) - 2);

                FIterator.FKey := AKey;
                FIterator.FUKey := UpperCase(AKey);

                FIterator.FValue := AValue;
                End
             Else If Assigned(FArray) Then Begin
                AValue := FArray[FIndex];

                FIterator.FKey := '';
                FIterator.FUKey := '';
                FIterator.FValue := AValue;
                End
             Else
                AValue := nil;
             End;

        If Assigned(AValue) Then Begin
           asm
              if (rtl.isArray(AValue.fjv))
              {
                 this.FIterator.FDataType = $mod.TJSONIteratorDataType.jdArray;
              }
              else if (rtl.isObject(AValue.fjv))
              {
                 this.FIterator.FDataType = $mod.TJSONIteratorDataType.jdObject;
              }
              else if (rtl.isString(AValue.fjv))
              {
                 this.FIterator.FDataType = $mod.TJSONIteratorDataType.jdString;
              }
              else if (rtl.isNumber(AValue.fjv))
              {
                 if (Number.isInteger(AValue.fjv))
                 {
                    this.FIterator.FDataType = $mod.TJSONIteratorDataType.jdInteger;
                 }
                 else
                 {
                    this.FIterator.FDataType = $mod.TJSONIteratorDataType.jdFloat;
                 }
              }
              else if (typeof(AValue.fjv) == 'boolean')
              {
                 this.FIterator.FDataType = $mod.TJSONIteratorDataType.jdBoolean;
              }
              else
              {
                 this.FIterator.FDataType = $mod.TJSONIteratorDataType.jdValue;
              }
           end;
           End;
        End;
End;

{$endregion}

{$region '******************************* TJSONObjectHelper ******************************'}

Class Function TJSONObjectHelper.ToJson(Data : array of const) : String;
Var
   I, Count : Integer;
   JObj : TJSONObject;
Begin
     Count := Length(Data) DIV 2;
     For I := 0 to Count - 1 Do
         If Not (Data[I*2].VType in [vtWideChar, vtUnicodeString]) Then
            Raise Exception.Create(IntToStr(I) + ' pair name is not a string');

     JObj := TJSONObject.Create;
     Try
        For I := 0 to Count - 1 Do
            JObj.AddPair(Data[I*2].VUnicodeString, Data[I*2+1].VJSValue);
        Result := JObj.ToJSON;
     Finally
        JObj.Free;
     End;
End;

Function TJSONObjectHelper.GetEnumerator : TJSONEnumerator;
Begin
     Result := TJSONEnumerator.Create(Self);
End;

Function TJSONObjectHelper.AddPair(const Str: string; const Val: JSValue): TJSONObject;
Begin
     AddPair(TJSONPair.Create(Str, TJSONValue.Create(Val)));
     Result := Self;
End;

Function TJSONObjectHelper.AddPair(const Str: string; const Val: Boolean): TJSONObject;
Begin
     If Val Then
        AddPair(TJSONPair.Create(Str, TJSONTrue.Create))
     Else
        AddPair(TJSONPair.Create(Str, TJSONFalse.Create));

     Result := Self;
End;

Function TJSONObjectHelper.Contains(AKey : String) : Boolean;
{var
   jv: JSValue;
   jsv: TJSONValue;
   i: integer;
   p: TJSONPair;}
Begin
     Result := Assigned(Get(AKey));
{     If Assigned(jo) Then Begin
        jv := jo.Properties[AKey];
        Result := jv <> Null;
        End
     Else Begin
        For i := 0 to FMembers.Count - 1 Do Begin
            p := TJSONPair(FMembers.Items[i]);
            If (p.fjs.ToString = '"'+AKey'"') then Begin
               Result := True;
               Break;
               End;
            End;
        End;}
End;

{$endregion}

{$region '******************************* TJSONArrayHelper *******************************'}

Class Function TJSONArrayHelper.ToJson(Data : array of const) : String;
Var
   JArr : TJSONArray;
Begin
     //Count := Length(Data);

     JArr := TJSONArray.Create;
     Try
        asm
           let Count = Data.length;
           JArr.fja = new Array();
           for(let i = 0; i < Count; i++)
           {
               JArr.fja.push(Data[i].VJSValue);
           }
        end;

        Result := JArr.ToJSON;
     Finally
        JArr.Free;
     End;
End;

Function TJSONArrayHelper.GetEnumerator : TJSONEnumerator;
Begin
     Result := TJSONEnumerator.Create(Self);
End;

{$endregion}

Function StrtoJSON(Const AJSON : String; AJSONProc : TJSONObjectProc) : Boolean; overload;
Var
   AJSONInput : TJSONValue;
   AJSONObject : TJSONObject;
Begin
     Result := False;

     If (Length(AJSON) > 0) Then Begin
        AJSONInput := TJSONObject.ParseJSONValue(AJSON);
        If Assigned(AJSONInput) and (AJSONInput is TJSONObject) Then Begin
           AJSONObject := AJSONInput as TJSONObject;
           Try
              AJSONProc(AJSONObject);
              Result := True;
           Finally
              AJSONInput.Free;
           End;
           End;
        End;
End;

Function StrtoJSON(Const AJSON : String; AJSONProc : TJSONArrayProc) : Boolean; overload;
Var
   AJSONInput : TJSONValue;
   AJSONArray : TJSONArray;
Begin
     Result := False;

     If (Length(AJSON) > 0) Then Begin
        AJSONInput := TJSONObject.ParseJSONValue(AJSON);
        If Assigned(AJSONInput) and (AJSONInput is TJSONArray) Then Begin
           AJSONArray := AJSONInput as TJSONArray;
           Try
              AJSONProc(AJSONArray);
              Result := True;
           Finally
              AJSONInput.Free;
           End;
           End;
        End;
End;

Function JSONtoStr(AJSONProc : TJSONObjectProc) : String; overload;
Var
   AJSONObject : TJSONObject;
Begin
     AJSONObject := TJSONObject.Create;
     Try
        AJSONProc(AJSONObject);
        Result := AJSONObject.ToJSON;
     Finally
        AJSONObject.Free;
     End;
End;

Function JSONtoStr(AJSONProc : TJSONArrayProc) : String; overload;
Var
   AJSONArray : TJSONArray;
Begin
     AJSONArray := TJSONArray.Create;
     Try
        AJSONProc(AJSONArray);
        Result := AJSONArray.ToJSON;
     Finally
        AJSONArray.Free;
     End;
End;


end.

