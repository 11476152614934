unit MyPolyCAD.Model.Systems;

interface

Uses Classes, SysUtils, Js, WEBLib.JSON, Model.Systems, EndPoints.Types;

Type
                  TMySystems = Class(TSystems)
                               Private
                               Public
                               Function Delete(Index : Integer; AConnection : IHTTPConnection) : Boolean; override; async;
                               Function Update(AConnection : IHTTPConnection) : Boolean; override; async;
                               End;

implementation

Uses LxWeb.JSON;

{$region '************************************************* TMySystems ****************************************************'}

Function TMySystems.Delete(Index : Integer; AConnection : IHTTPConnection) : Boolean;
Var
   ASystem : TSystem;
   AResponse : String;
Begin
     Result := False;

     ASystem := Systems[Index];
     If Assigned(ASystem) and await(boolean, AConnection.Delete('systems/' + ASystem.SystemId, AResponse)) Then Begin
        Delete(ASystem);
        Result := True;
        End;
End;

Function TMySystems.Update(AConnection : IHTTPConnection) : Boolean;
Var
   AResponse : String;
begin
     Result := False;

     If await(boolean, AConnection.Get('systems', AResponse)) Then
        Result := StrToJson(AResponse, @fromJSON);
End;

{$endregion}


end.
