unit Admin.Users;

interface

Uses Classes, Js, SysUtils, WEBLib.JSON, Model.Users, EndPoints.Types;

Type
         TAdminUser = Class(TUser)
                      Private
                      Procedure SetName(Val : String);
                      Procedure SetEmail(Val : String);
                      Procedure SetOrganisation(Val : String);
                      Procedure SetStatus(Val : Integer);
                      Procedure SetIsValidated(Val : Boolean);
                      Procedure SetIsUnvalidated(Val : Boolean);
                      Procedure SetIsAdministrator(Val : Boolean);
                      Procedure SetIsChangePassword(Val : Boolean);
                      Procedure SetIsDisabled(Val : Boolean);
                      Public
                      Constructor Create(AId : String); overload;

                      Function Submit(AConnection : IHttpConnection) : Boolean; async;
                      Function Update(AConnection : IHttpConnection) : Boolean; async;

                      Property Id : String Read FId;
                      Property Name : String Read FName Write SetName;
                      Property Email : String Read FEmail Write SetEmail;
                      Property Organisation : String Read FOrganisation Write SetOrganisation;
                      Property Status : Integer Read FStatus Write SetStatus;

                      Property IsValidated : Boolean Read GetIsValidated Write SetIsValidated;
                      Property IsUnvalidated : Boolean Read GetIsUnvalidated Write SetIsUnvalidated;
                      Property IsAdministrator : Boolean Read GetIsAdministrator Write SetIsAdministrator;
                      Property IsChangePassword : Boolean Read GetIsChangePassword Write SetIsChangePassword;
                      Property IsDisabled : Boolean Read GetIsDisabled Write SetIsDisabled;
                      End;

        TAdminUsers = Class(TUsers)
                      Private
                      Function GetUser(Index : Integer) : TAdminUser;
                      Function GetUserById(UserId : String) : TAdminUser;
                      Protected
                      Function CreateUser : TUser; override;
                      Public
                      Function Add(EMail, Password : String; AConnection : IHttpConnection) : Boolean; overload; async;
                      Function Delete(Index : Integer; AConnection : IHttpConnection) : Boolean; overload; async;
                      Function Delete(Const UserId : String; AConnection : IHTTPConnection) : Boolean; overload; async;
                      Function Delete(AUser : TUser; AConnection : IHTTPConnection) : Boolean; overload; async;
                      Function Update(AConnection : IHttpConnection) : Boolean; override; async;
                      Property Users[Index : Integer] : TAdminUser Read GetUser; default;
                      Property UsersById[UserId : String] : TAdminUser Read GetUserById;
                      End;

implementation

Uses Web, LxWeb.Json;

Const
     UserURI = 'debug/users';

{$region '************************************************* TAdminUser ****************************************************'}

Constructor TAdminUser.Create(AId : String);
Begin
     Create;
     FId := AId;
End;

Procedure TAdminUser.SetName(Val : String);
Begin
     If Not(FName = Val) Then Begin
        FName := Val;
        Invalidate;
        End;
End;

Procedure TAdminUser.SetEmail(Val : String);
Begin
     If Not(FEmail = Val) Then Begin
        FEMail := Val;
        Invalidate;
        End;
End;

Procedure TAdminUser.SetOrganisation(Val : String);
Begin
     If Not(FOrganisation = Val) Then Begin
        FOrganisation := Val;
        Invalidate;
        End;
End;

Procedure TAdminUser.SetStatus(Val : Integer);
Begin
     If Not(FStatus = Val) Then Begin
        FStatus := Val;
        Invalidate;
        End;
End;

Procedure TAdminUser.SetIsValidated(Val : Boolean);
Begin
     If Val Then
        Status := Status or statusValidated
     Else If Not Val and IsValidated Then
        Status := Status and not StatusValidated
End;

Procedure TAdminUser.SetIsUnvalidated(Val : Boolean);
Begin
     If Val Then
        Status := Status or statusUnvalidated
     Else If Not Val and IsUnvalidated Then
        Status := Status and not statusUnvalidated
End;

Procedure TAdminUser.SetIsAdministrator(Val : Boolean);
Begin
     If Val Then
        Status := Status or statusAdmin
     Else If Not Val and IsAdministrator Then
        Status := Status and not statusAdmin
End;

Procedure TAdminUser.SetIsChangePassword(Val : Boolean);
Begin
     If Val Then
        Status := Status or statusChangePassword
     Else If Not Val and IsChangePassword Then
        Status := Status and not statusChangePassword
End;

Procedure TAdminUser.SetIsDisabled(Val : Boolean);
Begin
     If Val Then
        Status := Status or statusDisabled
     Else If Not Val and IsDisabled Then
        Status := Status and not statusDisabled
End;

Function TAdminUser.Update(AConnection : IHttpConnection) : Boolean;
Var
   AResponse : String;
begin
     If await(boolean, AConnection.Request(cmdGET, UserURI + '/' + FId, '', AResponse)) Then
        Result := StrToJson(AResponse, @fromJSON)
     Else
        Result := false;
End;

Function TAdminUser.Submit(AConnection : IHttpConnection) : Boolean;
Var
   AJSONObject : TJSONObject;
   ARequest, AResponse : String;
Begin
     ARequest := JSONToStr(@toJSON);

     AJSONObject := TJSONObject.Create;
     Try
        toJSON(AJSONObject);
        ARequest := AJSONObject.ToString;
     Finally
        AJSONObject.Free;
     End;

     if await(boolean, AConnection.Request(cmdPUT, UserURI + '/' + FId, ARequest, AResponse)) Then Begin
        FInvalidated := False;
        Result := True;
        End
     Else
        Result := False;
End;

{$endregion}

{$region '************************************************* TAdminUsers ***************************************************'}

Function TAdminUsers.GetUser(Index : Integer) : TAdminUser;
Begin
     Result := TAdminUser(Inherited Users[Index]);
End;

Function TAdminUsers.GetUserById(UserId : String) : TAdminUser;
Var
   Index : Integer;
Begin
     Index := IndexOf(UserId);
     If Index > -1 Then
        Result := Users[Index]
     Else
        Result := nil
End;


Function TAdminUsers.CreateUser : TUser;
Begin
     Result := TAdminUser.Create;
End;

Function TAdminUsers.Add(EMail, Password : String; AConnection : IHttpConnection) : Boolean;
Var
   AJSONObject : TJSONObject;
   ARequest, AResponse : String;
   AUser : TAdminUser;
Begin
     Result := False;

     AJSONObject := TJSONObject.Create;
     Try
        AJSONObject.AddPair('EMail', EMail);
        AJSONObject.AddPair('Password', Password);
        ARequest := AJSONObject.ToString;
     Finally
        AJSONObject.Free;
     End;

     If await(boolean, AConnection.Request(cmdPOST, UserURI, ARequest, AResponse)) Then Begin
        AUser := TAdminUser.Create;
        If StrToJSON(AResponse, @AUser.fromJSON) Then Begin
           Add(AUser);
           Result := True;
           End
        Else
          AUser.Free;
        End;
End;

Function TAdminUsers.Delete(Index : Integer; AConnection : IHttpConnection) : Boolean;
Var
   AUser : TAdminUser;
   AResponse : String;
Begin
     Result := False;

     AUser := Users[Index];
     If Assigned(AUser) and await(boolean, AConnection.Request(cmdDELETE, UserURI + '/' + AUser.Id,  '', AResponse)) Then Begin
        Delete(AUser);
        Result := True;
        End;
End;

Function TAdminUsers.Delete(Const UserId : String; AConnection : IHTTPConnection) : Boolean;
Var
   Index : Integer;
Begin
     Index := IndexOf(UserId);
     If Index > -1 Then
        Result := await(boolean, Delete(Index, AConnection))
     Else
        Result := False;
End;

Function TAdminUsers.Delete(AUser : TUser; AConnection : IHTTPConnection) : Boolean;
Var
   Index : Integer;
Begin
     Index := IndexOf(AUser);
     If Index > -1 Then
        Result := await(boolean, Delete(Index, AConnection))
     Else
        Result := False;
End;

Function TAdminUsers.Update(AConnection : IHttpConnection) : Boolean;
Var
   AResponse : String;
begin
     If await(boolean, AConnection.Request(cmdGET, UserURI, '', AResponse)) Then
        Result := StrToJSon(AResponse, @fromJSON);
End;

{$endregion}

end.
