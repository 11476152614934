unit EndPoints.Types;

interface

Uses Classes, SysUtils, JS, Web, WEBLib.JSON;

Type
                THttpCommand = (cmdGET, cmdPOST, cmdPUT, cmdDELETE);

        THttpConnectionError = Class(TObject)
                               Private
                               FEndPoint : String;
                               FCommand : String;
                               FStatusCode : Integer;
                               FRequest : String;
                               FMessage : String;
                               Public
                               Constructor Create;
                               Procedure Clear;
                               Procedure Assign(Const AEndPoint : String; Const ACommand : THttpCommand; Const AStatusCode : Integer; Const ARequest, AMessage : String);
                               Procedure Log;
                               Property EndPoint : String Read FEndPoint;
                               Property Command : String Read FCommand;
                               Property StatusCode : Integer Read FStatusCode;
                               Property Request : String Read FRequest;
                               Property Message : String Read FMessage;
                               End;


             IHttpConnection = Interface
                               Public
                               Procedure BasicAuthentication(AUserName, APassword : String);
                               Procedure ClearAuthentication;
                               Function Request(ACommand : THttpCommand; Const AEndPoint, ARequest : String; Out AResponse : String) : Boolean; overload; async;
                               Function Request(ACommand : THttpCommand; Const AEndPoint : String; ARequest, AResponse : TJSONObject) : Boolean; overload; async;

                               Function Get(Const AEndPoint : String; Out AResponse : String) : Boolean; overload; async;
                               Function Put(Const AEndPoint, ARequest : String; Out AResponse : String) : Boolean; overload; async;
                               Function Post(Const AEndPoint, ARequest : String; Out AResponse : String) : Boolean; overload; async;
                               Function Delete(Const AEndPoint : String; Out AResponse : String) : Boolean; overload; async;
                               End;

{         TEndPointConnection = Class(TObject)
                               Public
                               Function Request(ACommand : TEndpointCommand; Const AEndPoint, ARequest : String; Out AResponse : String) : Boolean; overload; virtual; async;
                               Function Request(ACommand : TEndpointCommand; Const AEndPoint : String; ARequest, AResponse : TJSONObject) : Boolean; overload; virtual; async;
                               End;}


implementation

{$region '************************************************* THttpConnectionError ******************************************'}


Constructor THttpConnectionError.Create;
Begin
     Inherited Create;
     Clear;
End;

Procedure THttpConnectionError.Clear;
Begin
     FEndPoint := '';
     FCommand := '';
     FStatusCode := 0;
     FRequest := '';
     FMessage := '';
End;

Procedure THttpConnectionError.Assign(Const AEndPoint : String; Const ACommand : THttpCommand; Const AStatusCode : Integer; Const ARequest, AMessage : String);
Begin
     FEndPoint := AEndPoint;

     Case ACommand of
             cmdGET : FCommand := 'GET';
            cmdPOST : FCommand := 'POST';
             cmdPUT : FCommand := 'PUT';
          cmdDELETE : FCommand := 'DELETE';
                 Else FCommand := 'UNKNOWN';
          End;

     FStatusCode := AStatusCode;
     FRequest := ARequest;
     FMessage := AMessage;
End;

Procedure THttpConnectionError.Log;
Begin
     Console.log(IntToStr(FStatusCode) + ' : ' + FMessage);
End;

{$endregion}


{

Function TEndPointConnection.Request(ACommand : TEndpointCommand; Const AEndPoint, ARequest : String; Out AResponse : String) : Boolean;
Begin
     Result := False;
End;

Function TEndPointConnection.Request(ACommand : TEndpointCommand; Const AEndPoint : String; ARequest, AResponse : TJSONObject) : Boolean;
Begin
     Result := False;
End;}

end.
