unit MyPolyCAD.Model;

interface

Uses Classes, SysUtils, EndPoints.Types, MyPolyCAD.Model.User, MyPolyCAD.Model.Systems, MyPolyCAD.Model.Feedback,
     LxWebRecaptcha;

Type
                  TMyPolyCAD = Class(TObject)
                               Private
                               FConnection : IHttpConnection;
                               FRecaptcha : TLxWebRecaptcha;
                               FSignupEmail, FSignupPassword : String;
                               FUser : TMyUser;
                               FSystems : TMySystems;
                               FFeedback : TMyFeedback;
                               Public
                               Const
                                    UserId_MAXLENGTH = 36;
                                     EMail_MAXLENGTH = 256;
                                  Password_MAXLENGTH = 80;
                                  SystemId_MAXLENGTH = 15;
                                    DiskId_MAXLENGTH = 30;
                                   BoardId_MAXLENGTH = 50;
                              Organisation_MAXLENGTH = 80;
                                  UserName_MAXLENGTH = 80;
                                  Location_MAXLENGTH = 80;

                               Constructor Create(AConnection : IHttpConnection); overload;
                               Destructor Destroy; override;
                               Procedure Clear;
                               Function preSignup(Const AEmail, APassword : String; Var AMessage : String) : Boolean; async;
                               Function Signup(Var AMessage : String) : Boolean; async;
                               Function IsSigningUp : Boolean; async;
                               Function IsValidating : Boolean; async;



                               Function IsValidated(Out ResendValidationAvailable : Boolean) : Boolean; async;
                               Function RequestValidationEmail(Var AMessage : String) : Boolean; async;
                               Function ValidateKey(Const AKey : String; Var AMessage : String) : Boolean; async;
                               Function ValidateShortCode(Const AShortCode : String; Var AMessage : String) : Boolean; async;
                               Function Login(Const AEmail, APassword : String; Var AMessage : String) : Boolean; async;
                               Function Logout : Boolean; async;
                               Function IsLoggedIn : Boolean; async;


                               Function RequestNewPassword(Const AEmail : String; Var AMessage : String) : Boolean; async;
                               Function ValidatePasswordKey(Const AKey : String; Var AMessage : String) : Boolean; async;
                               Function ChangePassword(Const AKey, ANewPassword : String; Var AMessage : String) : Boolean; async;
                               Function IsChangingPassword : Boolean; async;

                               Function Update : Boolean; async;
                               Function Submit : Boolean; async;

                               Function DeleteSystem(Const ASystemId : String) : Boolean; async;
                               Property Connection : IHTTPConnection Read FConnection;
                               Property User : TMyUser Read FUser;
                               Property Systems : TMySystems Read FSystems;
                               Property Feedback : TMyFeedback Read FFeedback;
                               End;


implementation

Uses Web, JS, WEBLib.JSON, LxWeb.Tools, LxWeb.JSON, MyPolyCAD.Configuration;

{$region '************************************************* TMyPolyCAD ****************************************************'}

Constructor TMyPolyCAD.Create(AConnection : IHTTPConnection);
Begin
     Inherited Create;
     FConnection := AConnection;
     FUser := TMyUser.Create;
     FSystems := TMySystems.Create;
     FFeedback := TMyFeedback.Create;
     FSignupEmail := '';
     FSignupPassword := '';

     FRecaptcha := TLxWebRecaptcha.Create(nil);
     FRecaptcha.SiteKey := ConfigurationRecaptchaKey;
     FRecaptcha.AfterLoadDFMValues;
End;

Destructor TMyPolyCAD.Destroy;
Begin
     FRecaptcha.Free;
     FFeedback.Free;
     FSystems.Free;
     FUser.Free;
     Inherited Destroy;
End;

Procedure TMyPolyCAD.Clear;
Begin
     FUser.Clear;
     FSystems.Clear;
     FFeedback.Clear;
     FSignupEmail := '';
     FSignupPassword := '';
End;

Function TMyPolyCAD.preSignup(Const AEmail, APassword : String; Var AMessage : String) : Boolean;
Var
   AToken, ARequest : String;
Begin
     if await(boolean, FRecaptcha.Execute(AToken, 'preSignUp')) Then Begin
        Clear;

        FSignupEmail := AEmail;
        FSignupPassword := APassword;

        ARequest := TJSONObject.ToJSON(['EMail', FSignupEmail, 'Password', FSignupPassword, 'RecaptchaToken', AToken]);

        Result := await(Boolean, FConnection.Put('presignup', ARequest, AMessage));
        End
     Else
        AMessage := 'Failed reCaptcha';
End;


Function TMyPolyCAD.Signup(Var AMessage : String) : Boolean;
Var
   AToken, ARequest : String;
   AJSONObject, jUser, jFeedback : TJSONObject;
Begin
     if await(boolean, FRecaptcha.Execute(AToken, 'SignUp')) Then Begin
        AJSONObject := TJSONObject.Create;
        Try
           jUser := TJSONObject.Create;
           FUser.toJSON(jUser);
           AJSONObject.addPair('EMail', FSignupEmail);
           AJSONObject.addPair('Password', FSignupPassword);
           AJSONObject.addPair('User', jUser);

           jFeedback := TJSONObject.Create;
           FFeedback.toJSON(jFeedback);
           AJSONObject.addPair('Feedback', jFeedBack);

           AJSONObject.addPair('RecaptchaToken', AToken);

           ARequest := AJSONObject.toJSON;
        Finally
           AJSONObject.Free;
        End;

        Result := await(Boolean, FConnection.Put('signup', ARequest, AMessage));
        If Result Then Begin
           FSignupEmail := '';
           FSignupPassword := ''
           End;
        End
     Else
        AMessage := 'Failed reCaptcha';
End;

Function TMyPolyCAD.IsSigningUp : Boolean; async;
Var
   AMessage : String;
Begin
     If (Length(FSignupEmail) > 0) and (Length(FSignupPassword) > 0) Then
        Result := await(boolean, FConnection.Get('issigningup', AMessage))
     Else
        Result := False;
End;

Function TMyPolyCAD.IsValidating : Boolean; async;
Var
   AMessage : String;
Begin
     Result := await(boolean, FConnection.Get('isvalidating', AMessage));
End;

Function TMyPolyCAD.IsValidated(Out ResendValidationAvailable : Boolean) : Boolean;
Var
   Success : Boolean;
   AResponse : String;
   AJsonInput : TJSONValue;
   AJsonObject : TJSONObject;
Begin
     Success := await(Boolean, FConnection.Get('validatesignup', AResponse));
     Console.log('validatesignup: ' + AResponse);

     If Success Then Begin
        AJSONInput := TJSONObject.ParseJSONValue(AResponse);
        If Assigned(AJSONInput) Then Begin
           AJSONObject := AJSONInput as TJSONObject;
           Try
              ResendValidationAvailable := AJSONObject.Values['ResendValidationAvailable'] is TJSONTrue;
              Result := AJSONObject.Values['Validated'] is TJSONTrue;
           Finally
              AJSONInput.Free;
           End;
           End;
        End
     Else Begin
        ResendValidationAvailable := False;
        Result := False;
        End;
End;

Function TMyPolyCAD.RequestValidationEmail(Var AMessage : String) : Boolean;
Var
   ARequest : String;
Begin
     ARequest := TJSONObject.ToJSON(['ResendValidation', True]);
     Result := await(Boolean, FConnection.Put('validatesignup', ARequest, AMessage));
End;

Function TMyPolyCAD.ValidateKey(Const AKey : String; Var AMessage : String) : Boolean;
Var
   ARequest : String;
Begin
     ARequest := TJSONObject.ToJSON(['ValidationKey', AKey]);
     Result := await(Boolean, FConnection.Put('validatesignup', ARequest, AMessage));
End;

Function TMyPolyCAD.ValidateShortCode(Const AShortCode : String; Var AMessage : String) : Boolean; async;
Var
   ARequest : String;
Begin
     ARequest := TJSONObject.ToJSON(['ValidationShortCode', AShortCode]);

     Result := await(Boolean, FConnection.Put('validatesignup', ARequest, AMessage));
End;

Function TMyPolyCAD.Login(Const AEmail, APassword : String; Var AMessage : String) : Boolean;
Var
   AToken, ARequest : String;
Begin
     if await(boolean, FRecaptcha.Execute(AToken, 'Login')) Then Begin
        Clear;

        FConnection.BasicAuthentication(AEmail, APassword);
        ARequest := TJSONObject.ToJSON(['RecaptchaToken', AToken]);

        if await(Boolean, FConnection.Put('login', ARequest, AMessage)) Then Begin

           FConnection.ClearAuthentication;

           If await(boolean, Update) Then
              Result := True
           Else Begin
              Result := False;
              AMessage := 'login successful, but failed to read from server. It could be restarting, please try again in a moment.';
              End
           End
        Else
           Result := False;

        FConnection.ClearAuthentication;
        End
     Else
        AMessage := 'Failed reCaptcha';
End;

Function TMyPolyCAD.Logout : Boolean;
Var
   AMessage : String;
Begin
     Result := await(boolean, FConnection.Get('logout', AMessage));
End;

Function TMyPolyCAD.IsLoggedIn : Boolean;
Var
   AMessage : String;
Begin
     Result := await(boolean, FConnection.Get('isloggedin', AMessage));
End;

Function TMyPolyCAD.RequestNewPassword(Const AEmail : String; Var AMessage : String) : Boolean; async;
Var
   AToken, ARequest : String;
Begin
     if await(boolean, FRecaptcha.Execute(AToken, 'RequestNewPassword')) Then Begin
        ARequest := TJSONObject.ToJSON(['EMail', AEMail, 'RecaptchaToken', AToken]);

        Result := await(Boolean, FConnection.Put('RequestNewPassword', ARequest, AMessage));
        End
     Else
        AMessage := 'Failed reCaptcha';
End;

Function TMyPolyCAD.ValidatePasswordKey(Const AKey : String; Var AMessage : String) : Boolean; async;
Var
   ARequest : String;
Begin
     ARequest:= TJSONObject.ToJSON(['Key', AKey]);
     Result := await(Boolean, FConnection.Put('ValidatePasswordKey', ARequest, AMessage));
End;

Function TMyPolyCAD.ChangePassword(Const AKey, ANewPassword : String; Var AMessage : String) : Boolean; async;
Var
   AToken, ARequest : String;
Begin
     if await(boolean, FRecaptcha.Execute(AToken, 'PasswordChange')) Then Begin
        ARequest:= TJSONObject.ToJSON(['Key', AKey, 'NewPassword', ANewPassword, 'RecaptchaToken', AToken]);
        Result := await(Boolean, FConnection.Put('PasswordChange', ARequest, AMessage));
        End
     Else
        AMessage := 'Failed reCaptcha';
End;

Function TMyPolyCAD.IsChangingPassword : Boolean; async;
Var
   AMessage : String;
Begin
     Result := await(boolean, FConnection.Get('ischangingpassword', AMessage));
End;

Function TMyPolyCAD.Update : Boolean; async;
Var
   AResponse : String;
   AJSONInput : TJSONValue;
   jObject : TJSONObject;
   jIterator : TJSONIterator;
Begin
     if await(Boolean, FConnection.Get('usersystems', AResponse)) Then Begin
        If (Length(AResponse) > 0) Then Begin
           AJSONInput := TJSONObject.ParseJSONValue(AResponse);
           If (AJSONInput is TJSONObject) Then Begin
              jObject := AJSONInput as TJSONObject;
              for jIterator in jObject Do Begin
                  If jIterator.KeyIs('User', jdObject) Then
                     FUser.fromJSON(jIterator.asObject)
                  Else if jIterator.KeyIs('Systems', jdArray) Then
                     FSystems.fromJSON(jIterator.asArray);
                  End;
              End;
           End;
        Result := True;
        End
     Else
        Result := False;


//     Result := await(boolean, FUser.Update(FConnection));
//     Console.Log('TMyPolyCAD.Update1 - ' + BoolToStr(Result, True));
//     Result := Result and await(boolean, FSystems.Update(FConnection));
//     Console.Log('TMyPolyCAD.Update2 - ' + BoolToStr(Result, True));
End;

Function TMyPolyCAD.Submit : Boolean; async;
Begin
     Result := await(boolean, FUser.Submit(FConnection));
End;

Function TMyPolyCAD.DeleteSystem(Const ASystemId : String) : Boolean; async;
Begin
     Result := await(boolean, FSystems.Delete(ASystemId, FConnection));
End;


{$endregion}


Initialization

end.
