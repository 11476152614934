unit LxWeb.Maths;

interface

Uses Classes, SysUtils, Math, Web;

Const
               MMatrixsize = 4;
       ABigNumber : Double = MaxDouble;
     ASmallNumber : Double = 10e-10; // Often used to catch small determinates
       SameVectorTolerance = 0.0001;
      ExactVectorTolerance = 1e-10;
           Float_Tolerance = 0.0001;

Type
                         TOrthogonalPlane = (opXY, opYZ, opZX, opXn, opYn, opZn, opXYZ);

                                 TMVector = Record
                                            X, Y, Z : Double;
                                            Class Function Create : TMVector; static;
                                            Function Negative : TMVector;
                                            Function Length : Double;
                                            Function Normalised : TMVector;
                                            Function AsString : String;
                                            End;

                             TMVectorLine = Record
                                            Base, Direction : TMVector;
                                            End;

                                TMSegment = Record
                                            P1, P2 : TMVector;
                                            Function Length : Double;
                                            End;

                                  TMPlane = Record
                                            Normal : TMVector;
                                            Offset : double;
                                            End;

                            TArrayOfDouble = array of double;
                                 TMMatrix = record
                                            Private
                                            Function GetElement(Const I, J : Integer) : Double;
                                            Procedure SetElement(Const I, J : Integer; Const Val : Double);
                                            Public
                                            m11, m12, m13, m14, m21, m22, m23, m24, m31, m32, m33, m34, m41, m42, m43, m44 : double;
                                            Class Function Create(Const AMatrix : TMMatrix) : TMMatrix; static;
                                            Function Transpose : TMMatrix;
                                            Function Invert : TMMatrix;
                                            Function AsArray : TArrayOfDouble;
                                            Function AsString : String;
                                            Class Function IMatrix : TMMatrix; static;
                                            Property Elements[Const I, J : Integer] : Double Read GetElement Write SetElement; default;
                                            End;

                                    TMBox = Record
                                            MinX, MinY, MinZ, MaxX, MaxY, MaxZ : Double;
                                            Class Function Create : TMBox; overload; static;
                                            Class Function Create(Const AMinX, AMinY, AMinZ, AMaxX, AMaxY, AMaxZ : Double) : TMBox; overload; static;
                                            Procedure Clear;
                                            Procedure Include(Const AVector : TMVector); overload;
                                            Procedure Include(Const ASize : TMBox); overload;
                                            Function Centre : TMVector;
                                            Function MinPoint : TMVector;
                                            Function MaxPoint : TMVector;
                                            Function MaxDimension : Double;
                                            Function Length : Double;
                                            Function Width : Double;
                                            Function Height : Double;
                                            End;

                               TMTriangle = Record
                                            P1, P2, P3 : TMVector;
                                            End;

            TPlaneAndTriangleIntersection = (tpiNoIntersection, tpiPointIntersection, tpiLineIntersection, tpiEdgeIntersection, tpiPlaneIntersection);

                        TMVectorRectangle = Record
                                            LeftBottom, LeftTop, RightTop, RightBottom : TMVector;
                                            End;

                           TMPlaneExtents = Record
                                            MinU, MinV, MaxU, MaxV : Double;
                                            End;

                                 TMCircle = Record
                                            Centre, U, V, Normal : TMVector;
                                            Radius : Double;
                                            End;

                                    TMArc = Record
                                            Centre, U, V, Normal : TMVector;
                                            Radius, StartAngle, EndAngle : Double;
                                            End;

                   TLineIntersectionState = (isParallel, isIntersects, isApproach);
                  TLineIntersectionResult = Record
                                            Line1, Line2 : TMVectorLine;
                                            Line1T, Line2T : Double;
                                            Line1Point, Line2Point : TMVector;
                                            Line : TMVectorLine;
                                            Det : Double;
                                            State : TLineIntersectionState;
                                            End;

                     TLineProximatyResult = Record
                                            Point : TMVector;
                                            T : Double;
                                            LineAndPlaneParallel : Boolean;
                                            End;

              TArcPlaneIntersectionResult = Record
                                            Count : Integer;
                                            P1, P2 : TMVector;
                                            End;

                      TArcProximatyResult = Record
                                            Point : TMVector;
                                            ArcPlaneParallel : Boolean;
                                            End;

                             TEigenValues = array[1..3] of Double;

                       THermiteComponents = Record
                                            H0, H1, H2, H3 : Double;
                                            End;

                       THermiteComponentDerivatives = Record
                                            H0, H1, H2, H3 : Double;
                                            H0d, H1d, H2d, H3d : Double;
                                            H0dd, H1dd, H2dd, H3dd : Double;
                                            End;



// Maths Functions
Procedure Swap(Var A, B : Integer); overload;
Procedure Swap(Var A, B : Double); overload;
Function Sgn(X : Double) : Integer;
Function ArcTanExt(Opp, Adj : Double) : Double;
Function Cube(const X : Double): Double;
Function Quar(const X : Double): Double;
Function InInterval(XLow, XHigh, X : Double) : Boolean; overload;
Function InInterval(XLow, XHigh, X : Integer) : Boolean; overload;
Function LinearInterpolate(X, XMin, XMax, YMin, YMax : Double) : Double;

// Vector
Function MVector(Const X, Y : Double; Const Z : Double = 0) : TMVector;
Function SameVector(Const Vector1, Vector2 : TMVector) : Boolean; overload;
Function SameVector(Const Vector1, Vector2 : TMVector; ATolerance : Double) : Boolean; overload;
Function EqualVectors(Const Vector1, Vector2 : TMVector) : Boolean;
Function Distance(Const Point1, Point2 : TMVector) : Double;
Function VectorToStr(Const AVector : TMVector) : String;
Function StrToVector(S : String) : TMVector;
Function VectorLength(Const Vector : TMVector) : Double;
Function VectorAdd(Const Vector1, Vector2 : TMVector) : TMVector; overload;
Function VectorAdd(Const Vector1, Vector2, Vector3 : TMVector) : TMVector; overload;
Function VectorAdd(Const Vector1, Vector2, Vector3, Vector4 : TMVector) : TMVector; overload;
Function VectorAdd(Const Vector1, Vector2, Vector3, Vector4, Vector5 : TMVector) : TMVector; overload;
Function VectorAdd(Const Vector1, Vector2, Vector3, Vector4, Vector5, Vector6 : TMVector) : TMVector; overload;
Function VectorScale(Const Scalar : Double; Vector : TMVector) : TMVector;
Function VectorNegative(Const Vector : TMVector) : TMVector;
Function VectorSubtract(Const Vector1, Vector2 : TMVector) : TMVector;
Function VectorMidPoint(Const Vector1, Vector2 : TMVector) : TMVector; overload;
Function VectorMidPoint(Const Vector1, Vector2, Vector3 : TMVector) : TMVector; overload;
Function VectorDotProduct(Const Vector1, Vector2 : TMVector) : Double;
Function VectorNormalise(Const Vector : TMVector) : TMVector;
Function VectorCrossProduct(Const Vector1, Vector2 : TMVector) : TMVector;
Function VectorAngles(Const Vector1, Vector2 : TMVector) : Double;
Function VectorAnglesOnPlane(Const Vector1, Vector2 : TMVector; Const APlane : TMPlane) : Double;
Function VectorAnglesAboutNormal(Const Vector1, Vector2, Normal : TMVector) : Double;
Function VectorRatioTheorem(Const Vector1, Vector2 : TMVector; Ratio : Double) : TMVector;
Function VectorSLERP(Vector1, Vector2 : TMVector; Ratio : Double) : TMVector;
Function ProjectVectorOntoPlane(Const Plane : TMPlane; Vector : TMVector) : TMVector;
Function TriangleArea(P1, P2, P3 : TMVector) : Double;
Function QuadrilateralArea(P1, P2, P3, P4 : TMVector) : Double;

// Segment
Function MSegment(Const V1, V2 : TMVector) : TMSegment; overload;
Function MSegment(Const X1, Y1, Z1, X2, Y2, Z2 : Double) : TMSegment; overload;

// Line
Function MVectorLine(Const Direction, Position : TMVector) : TMVectorLine;
Function VectorLine(Const VLine : TMVectorLine; Parameter : Double) : TMVector;
Function LineThroughTwoPoints(Const Point1, Point2 : TMVector; NormaliseDirectionVector : Boolean = True) : TMVectorLine;
Function LineBetweenLineSegmentsInfo(Line1, Line2 : TMVectorLine) : TLineIntersectionResult;
Function LineBetweenLineSegments(Line1, Line2 : TMVectorLine) : TMVectorLine;
Function DistancefromLineToLineSegment(Const Line : TMVectorLine; Const Segment : TMSegment) : Double;
Function DistanceFromPointToLine(Const Point : TMVector; Line : TMVectorLine) : Double;
Function DistanceFromPointToLineSegment(Const Segment : TMSegment; Point : TMVector) : Double;
Function PointOnLineClosestToPoint(Const Line : TMVectorLine; Point : TMVector) : TMVector; overload;
Function PointOnLineClosestToPoint(Const Line : TMVectorLine; Point : TMVector; Out T : Double) : TMVector; overload;
Function PointOnLineClosestToOrigin(Const Line : TMVectorLine) : TMVector;
Function PointOnLineClosestToLine(Const PointLine, PassingLine : TMVectorLine) : TMVector; overload;
Function PointOnLineClosestToLine(Const PointLine, PassingLine : TMVectorLine; Out PointLineT : Double) : TMVector; overload;
Function PointOnLineClosestToLine(Const PointLine, PassingLine : TMVectorLine; Out APoint : TMVector) : Boolean; overload;
Function PointOnLineClosestToLineSegment(Const Line : TMVectorLine; Const Segment : TMSegment) : TMVector;
Function PointOnLineSegmentClosestToPointInfo(Const Segment : TMSegment; Point : TMVector) : TLineProximatyResult;
Function PointOnLineSegmentClosestToPoint(Const Segment : TMSegment; Point : TMVector) : TMVector;
Function PointOnLineSegmentClosestToLine(Const Line : TMVectorLine; Const Segment : TMSegment; Out T : Double) : TMVector; overload;
Function PointOnLineSegmentClosestToLine(Const Line : TMVectorLine; Const Segment : TMSegment) : TMVector; overload;
Function PointBetweenTwoLinesInfo(Const Line1, Line2 : TMVectorLine) : TLineIntersectionResult;
Function PointBetweenTwoLines(Const Line1, Line2 : TMVectorLine) : TMVector;

// Matrix
Function EqualMatrix(Const Matrix1, Matrix2 : TMMatrix) : Boolean;
Function MultiplyMatrix(Const S : Double; Const M : TMMatrix) : TMMatrix; overload;
Function MultiplyMatrix(Const M1, M2 : TMMatrix) : TMMatrix; overload;
Function MultiplyMatrix(Const M1, M2, M3 : TMMatrix) : TMMatrix; overload;
Function MultiplyMatrix(Const M1, M2, M3, M4 : TMMatrix) : TMMatrix; overload;
Function MultiplyMatrix(Const M1 : TMMatrix; M2 : TMVector) : TMVector; overload;
Function MultiplyMatrix(Const M : TMMatrix; Plane : TMPlane) : TMPlane; overload;
Function MultiplyMatrix(Const M : TMMatrix; ALine : TMVectorLine) : TMVectorLine; overload;
Function InvertMatrix(Const InMat : TMMatrix) : TMMatrix;
Function TransposeMatrix(Const OMat : TMMatrix) : TMMatrix;
Function AddMatrix(Const AMat, BMat : TMMatrix) : TMMatrix;
Function SubtractMatrix(Const AMat, BMat : TMMatrix) : TMMatrix;
Function ColumnsMatrix(Const C1, C2, C3, C4 : TMVector) : TMMatrix;
Function RotateMatrix(Ax, Ay, Az : Double) : TMMatrix; overload;
Function RotateMatrix(Angle : Double; Axis : TMVector) : TMMatrix; overload;
Function RotateMatrix(OldNormal, NewNormal : TMVector) : TMMatrix; overload;
Function MirrorMatrix(Plane : TOrthogonalPlane; PlaneBase : Double) : TMMatrix; overload;
Function MirrorMatrix(APlane : TMPlane) : TMMatrix; overload;
Function MoveMatrix(Tx, Ty, Tz : Double) : TMMatrix; overload;
Function MoveMatrix(Vector : TMVector) : TMMatrix; overload;
Function ScaleMatrix(Sx, Sy, Sz : Double) : TMMatrix; overload;
Function ScaleMatrix(ScaleFactor : Double) : TMMatrix; overload;
Function VectorFromMatrixColumn(Const M : TMMatrix; ColumnIndex : Integer) : TMVector;
Function VectorFromMatrixRow(Const M : TMMatrix; RowIndex : Integer) : TMVector;
Procedure Jacobi(VAR a: TMMatrix; n: integer;VAR d: TEigenValues; VAR v: TMMatrix; VAR nrot: integer);

// Plane
Function MPlane(Const PlaneType : TOrthogonalPlane; Const Offset : Double = 0) : TMPlane; overload;
Function MPlane(Const PlaneType : TOrthogonalPlane; Const Position : TMVector) : TMPlane; overload;
Function MPlane(Const Normal, Position : TMVector) : TMPlane; overload;
Function MPlane(Const Normal : TMVector; Offset : Double) : TMPlane; overload;
Function MPlane(Const Normal : TMVector) : TMPlane; overload;
Procedure StandardisePlaneOrientation(Var APlane : TMPlane);
Function PlaneThrough3Points(Const P1, P2, P3 : TMVector) : TMPlane;
Function PlaneFromTriangle(Const Triangle : TMTriangle) : TMPlane;
Function PlaneThrough2PointsAndVector(Const P1, P2, Axis : TMVector) : TMPlane;
Function OffsetPlane(APlane : TMPlane; Distance : Double) : TMPlane;
Function SamePlane(Const APlane1, APlane2 : TMPlane) : Boolean; overload;
Function SamePlane(Const APlane1, APlane2 : TMPlane; ATolerance : Double) : Boolean; overload;
Function EqualPlanes(Const APlane1, APlane2 : TMPlane) : Boolean; overload;
Function PointOnWhichPlaneSide(Const Plane : TMPlane; Point : TMVector; Tolerance : Double = 0) : Integer;
Function DistancefromPlaneToPoint(Const Plane : TMPlane; Point : TMVector) : Double;
Function NearestPointOnPlaneToPoint(Const Plane : TMPlane; Point : TMVector) : TMVector;
Function NearestPointOnPlaneToOrigin(Const Plane : TMPlane) : TMVector;
Function NearestPointOnTriangleToPoint(Const APoint, P1, P2, P3 : TMVector; Out s, t : Double) : TMVector; overload;
Function NearestPointOnTriangleToPoint(Const APoint, P1, P2, P3 : TMVector) : TMVector; overload;
Function IntersectionOfLineandPlaneInfo(Const Line : TMVectorLine; Const Plane : TMPlane) : TLineProximatyResult;
Function IntersectionOfLineandPlane(Const Line : TMVectorLine; Const Plane : TMPlane) : TMVector;
Function IntersectionOfPointsandPlane(Const Point1, Point2 : TMVector; Const Plane : TMPlane) : TMVector;
Function IntersectionOfTwoPlanes(Const Plane1, Plane2 : TMPlane) : TMVectorLine;
Function IntersectionOfThreePlanes(Const Plane1, Plane2, Plane3 : TMPlane) : TMVector;
Function IntersectionOfLineAndTriangle(ALine : TMVectorLine; P1, P2, P3 : TMVector) : Boolean; overload;
Function IntersectionOfLineAndTriangle(ALine : TMVectorLine; P1, P2, P3 : TMVector; Out Intersection : TMVector) : Boolean; overload;
Function IntersectionOfLineAndTriangle(ALine : TMVectorLine; P1, P2, P3 : TMVector; Out Intersection : TMVector; Out T : Double) : Boolean; overload;
Function IntersectionOfLineAndTriangle(ALine : TMVectorLine; P1, P2, P3 : TMVector; Out T : Double) : Boolean; overload;
Function IntersectionOfPlaneAndTriangle(Const APlane : TMPlane; Const P1, P2, P3 : TMVector) : Boolean; overload;
Function IntersectionOfPlaneAndTriangle(Const APlane : TMPlane; Const ATriangle : TMTriangle) : Boolean; overload;
Function IntersectionOfPlaneAndTriangle(Const APlane : TMPlane; Const P1, P2, P3 : TMVector; Out Line : TMSegment) : TPlaneAndTriangleIntersection; overload;
Function IntersectionOfPlaneAndTriangle(Const APlane : TMPlane; Const ATriangle : TMTriangle; Out Line : TMSegment) : TPlaneAndTriangleIntersection; overload;
Function IntersectionOfTwoTriangles(Const Triangle1, Triangle2 : TMTriangle; Out Segment : TMSegment) : Boolean;
Function ClosestAxisPlaneNormalToVector(Const AVector : TMVector) : TMVector;
Function ClosestOrthogonalPlaneToPlane(Const APlane : TMPlane) : TOrthogonalPlane;
Function ClosestOrthogonalPlaneToNormal(Const ANormal : TMVector) : TOrthogonalPlane;
Procedure UVAxesFromNormal(Const Normal : TMVector; Out UVector : TMVector; Out VVector : TMVector);
Function XYTransformationFromNormal(Const Normal : TMVector) : TMMatrix;
Function MPlaneExtents(AMinU, AMinV, AMaxU, AMaxV : Double) : TMPlaneExtents; overload;
Function MPlaneExtents(APlane : TMPlane; AExtents : TMBox) : TMPlaneExtents; overload;
Function PlaneExtentsToRectangle(APlane : TMPlane; Extents : TMPlaneExtents) : TMVectorRectangle; overload;
Function PlaneExtentsToRectangle(APlane : TMPlane; AExtents : TMBox) : TMVectorRectangle; overload;

// Arc and Circle

Function CircleThroughThreePoints(P1, P2, P3 : TMVector) : TMCircle;
Function CircleThroughTwoPointsCentre(PStart, PEnd, PCentre : TMVector) : TMCircle;
Function CircleThroughCentreRadiusNormal(Centre : TMVector; Radius : Double; Normal : TMVector) : TMCircle;
Function ArcThroughThreePoints(P1, P2, P3 : TMVector) : TMArc;
Function ArcThroughTwoPointsCentre(PStart, PEnd, PCentre : TMVector) : TMArc; overload;
Function ArcThroughTwoPointsCentre(PStart, PEnd, PCentre, Normal : TMVector) : TMArc; overload;
Function ArcCentre2Angles(Centre : TMVector; Radius, StartAngle, EndAngle : Double; Normal : TMVector) : TMArc;
Function VectorArc(AArc : TMArc; AParameter : Double) : TMVector;
Function PointOnCircleClosestToPoint(ACircle : TMCircle; APoint : TMVector) : TMVector;
Function PointOnCircleClosestToLine(ACircle : TMCircle; ALine : TMVectorLine) : TArcProximatyResult;
Function PointOnArcClosestToPoint(AArc : TMArc; APoint : TMVector) : TMVector;
Function PointOnArcClosestToLine(AArc : TMArc; ALine : TMVectorLine) : TArcProximatyResult;
Function IntersectionOfPlaneAndArc(Const APlane : TMPlane; Const AArc : TMArc) : TArcPlaneIntersectionResult;
Function IntersectionOfPlaneAndCircle(Const APlane : TMPlane; Const ACircle : TMCircle) : TArcPlaneIntersectionResult;

// Triangle
Function MTriangle(Const P1, P2, P3 : TMVector) : TMTriangle;
Function ReflectTriangle(Const ATriangle : TMTriangle) : TMTriangle;

// Solvers
Function Solve2DLinearEquation(Const V1, V2, C : TMVector; Out Lambda, Mu : Double; WantExceptions : Boolean = True) : Boolean;
Function Solve3DPlaneEquation(Const V1, V2, C : TMVector; Out Lambda, Mu : Double; WantExceptions : Boolean = True) : Boolean;
Function Solve3DLinearEquation(Const V1, V2, C : TMVector; Out Lambda, Mu : Double; WantExceptions : Boolean = True) : Boolean;
Function SolveQuadraticEquation(Const A, B, C : Double; Out Root1, Root2 : Double) : Boolean;

// Hermite
Procedure Hermite(Const T : Double; Out Components : THermiteComponents);
Procedure HermiteDerivatives(Const T : Double; Out ComponentDerivatives : THermiteComponentDerivatives);
Function HermiteCurve(Const P0, T0, P1, T1 : TMVector; Const T : Double) : TMVector;

Const
     MVectorOrigin : TMVector = (X:0;Y:0;Z:0);
     MVectorX : TMVector = (X:1;Y:0;Z:0);
     MVectorY : TMVector = (X:0;Y:1;Z:0);
     MVectorZ : TMVector = (X:0;Y:0;Z:1);
     IMatrix : TMMatrix = (m11: 1; m12 : 0; m13 :0; m14 : 0; m21 : 0; m22 : 1; m23 : 0; m24 : 0; m31: 0; m32 : 0; m33 :1; m34 : 0; m41 : 0; m42 : 0; m43 : 0; m44 : 1;);
     OMatrix : TMMatrix = (m11: 0; m12 : 0; m13 :0; m14 : 0; m21 : 0; m22 : 0; m23 : 0; m24 : 0; m31: 0; m32 : 0; m33 :0; m34 : 0; m41 : 0; m42 : 0; m43 : 0; m44 : 0;);

     ScaleMMtoMMatrix : TMMatrix = (m11: 0.001; m12 : 0; m13 :0; m14 : 0; m21 : 0; m22 : 0.001; m23 : 0; m24 : 0; m31: 0; m32 : 0; m33 :0.001; m34 : 0; m41 : 0; m42 : 0; m43 : 0; m44 : 1;);
     ScaleMtoMMMatrix : TMMatrix = (m11: 1000; m12 : 0; m13 :0; m14 : 0; m21 : 0; m22 : 1000; m23 : 0; m24 : 0; m31: 0; m32 : 0; m33 :1000; m34 : 0; m41 : 0; m42 : 0; m43 : 0; m44 : 1;);
     XMirrorMatrix : TMMatrix = (m11: -1; m12 : 0; m13 :0; m14 : 0; m21 : 0; m22 : 1; m23 : 0; m24 : 0; m31: 0; m32 : 0; m33 :1; m34 : 0; m41 : 0; m42 : 0; m43 : 0; m44 : 1;);
     YMirrorMatrix : TMMatrix = (m11: 1; m12 : 0; m13 :0; m14 : 0; m21 : 0; m22 : -1; m23 : 0; m24 : 0; m31: 0; m32 : 0; m33 :1; m34 : 0; m41 : 0; m42 : 0; m43 : 0; m44 : 1;);
     ZMirrorMatrix : TMMatrix = (m11: 1; m12 : 0; m13 :0; m14 : 0; m21 : 0; m22 : 1; m23 : 0; m24 : 0; m31: 0; m32 : 0; m33 :-1; m34 : 0; m41 : 0; m42 : 0; m43 : 0; m44 : 1;);
     ZXtoXYMatrix : TMMatrix = (m11: 1; m12 : 0; m13 :0; m14 : 0; m21 : 0; m22 : 0; m23 : 1; m24 : 0; m31: 0; m32 : 1; m33 :0; m34 : 0; m41 : 0; m42 : 0; m43 : 0; m44 : 1;);
     YZtoXYMatrix : TMMatrix = (m11: 0; m12 : 1; m13 :0; m14 : 0; m21 : 0; m22 : 0; m23 : 1; m24 : 0; m31: 1; m32 : 0; m33 :0; m34 : 0; m41 : 0; m42 : 0; m43 : 0; m44 : 1;);
     MLineX : TMVectorLine = (Base: (X:0;Y:0;Z:0); Direction: (X:1;Y:0;Z:0));
     MLineY : TMVectorLine = (Base: (X:0;Y:0;Z:0); Direction: (X:0;Y:1;Z:0));
     MLineZ : TMVectorLine = (Base: (X:0;Y:0;Z:0); Direction: (X:0;Y:0;Z:1));
     MPlaneX : TMPlane = (Normal:(X:1;Y:0;Z:0); Offset:0);
     MPlaneY : TMPlane = (Normal:(X:0;Y:1;Z:0); Offset:0);
     MPlaneZ : TMPlane = (Normal:(X:0;Y:0;Z:1); Offset:0);
     MPlaneNull : TMPlane = (Normal:(X:0;Y:0;Z:0); Offset:0);


implementation

Uses LxWeb.Tools;

{$region '********************************************* Maths Functions ********************************************'}

Procedure Swap(Var A, B : Integer); overload;
Var
   Temp : Integer;
Begin
     Temp := A;
     A := B;
     B := Temp;
End;

Procedure Swap(Var A, B : Double); overload;
Var
   Temp : Double;
Begin
     Temp := A;
     A := B;
     B := Temp;
End;

Function Sgn(X : Double) : Integer;
Begin
     If X > 0 Then Sgn := 1
     Else If X < 0 Then Sgn := -1
     Else Sgn := 0;
End;

Function ArcTanExt(Opp, Adj : Double) : Double;
Begin
     // Range -pi -> +pi
     If Abs(Adj) < 10e-10 Then
        Result := sgn(Opp) * Pi / 2
     Else If Adj < 0 Then Begin
          If Opp > 0 Then
             Result := ArcTan(Opp / Adj)+pi
          Else If Opp < 0 Then
             Result := ArcTan(Opp / Adj)-pi
          Else
             Result := pi; {ArcTan(-0) = 180}
          End
     Else
        Result := ArcTan(Opp / Adj);
End;

{$HINTS OFF}

Function Cube(const X : Double): Double;
Begin
     {$IFDEF PAS2JS}
     asm
        Result = X ** 3;
     end
     {$ELSE}
     Result := X * X * X;
     {$ENDIF}
End;

Function Quar(const X : Double): Double;
Var
   Sq : Double;
Begin
     {$IFDEF PAS2JS}
     asm
        Result = X ** 4;
     end
     {$ELSE}
     Sq := Sqr(X);
     Result := Sqr(Sq);
     {$ENDIF}
End;

{$HINTS ON}

Function InInterval(XLow, XHigh, X : Double) : Boolean; overload;
Var
   Temp : Double;
Begin
     If XHigh < XLow Then Begin
                        Temp := XHigh;
                        XHigh := XLow;
                        XLow := Temp;
                        End;
     Result := (XLow <= X) and (X <= XHigh);  {Low < X < High}
End;

Function InInterval(XLow, XHigh, X : Integer) : Boolean; overload;
Var
   Temp : Integer;
Begin
     If XHigh < XLow Then Begin
                        Temp := XHigh;
                        XHigh := XLow;
                        XLow := Temp;
                        End;
     Result := (XLow <= X) and (X <= XHigh);  {Low < X < High}
End;

Function LinearInterpolate(X, XMin, XMax, YMin, YMax : Double) : Double;
Begin
     If Not(XMin = XMax) Then
        Result := (X - XMin) / (XMax - XMin) * (YMax - YMin) + YMin
     Else
        Raise Exception.Create('LxWeb.Maths.Intepolation - XMin = XMax');
End;

{$endregion}

{$region '********************************************* TMVector ***************************************************'}

Function MVector(Const X, Y : Double; Const Z : Double = 0) : TMVector;
Begin
     Result.X := X;
     Result.Y := Y;
     Result.Z := Z;
End;

Function SameVector(Const Vector1, Vector2 : TMVector) : Boolean;
Begin
     Result := SameVector(Vector1, Vector2, SameVectorTolerance);
End;

Function SameVector(Const Vector1, Vector2 : TMVector; ATolerance : Double) : Boolean;
Begin
     Result := (Abs(Vector1.X - Vector2.X) < ATolerance) and
               (Abs(Vector1.Y - Vector2.Y) < ATolerance) and
               (Abs(Vector1.Z - Vector2.Z) < ATolerance);
End;

Function EqualVectors(Const Vector1, Vector2 : TMVector) : Boolean;
Begin
     Result := SameVector(Vector1, Vector2, ExactVectorTolerance);
End;

Function Distance(Const Point1, Point2 : TMVector) : Double;
Begin
     Result := Sqrt(Sqr(Point2.X - Point1.X) + Sqr(Point2.Y - Point1.Y) + Sqr(Point2.Z - Point1.Z));
End;

Function VectorToStr(Const AVector : TMVector) : String;
Begin
     With AVector Do
          Result := Format('[%g, %g, %g]', [X, Y, Z]);
End;

Function StrToVector(S : String) : TMVector;
Var
   LeftBracketC, RightBracketC, LeftBracketS, RightBracketS, CommaIndex : Integer;
   S1 : String;
Begin
     Result := MVectorOrigin;
     LeftBracketC := Pos('(', S);
     RightBracketC := Pos(')', S);
     LeftBracketS := Pos('[', S);
     RightBracketS := Pos(']', S);

     If (LeftBracketC > 0) and (RightBracketC > 0) Then
        S := Trim(Copy(S, LeftBracketC + 1, RightBracketC - LeftBracketC - 1))
     Else If (LeftBracketS > 0) and (RightBracketS > 0) Then
        S := Trim(Copy(S, LeftBracketS + 1, RightBracketS - LeftBracketS - 1));

     CommaIndex := Pos(',', S);
     If CommaIndex > 0 Then Begin
        S1 := Trim(Copy(S, 1, CommaIndex - 1));
        S := Trim(Copy(S, CommaIndex + 1, Length(S)));
        Result.X := StrToFloat(S1);

        CommaIndex := Pos(',', S);
        If CommaIndex > 0 Then Begin
           S1 := Trim(Copy(S, 1, CommaIndex - 1));
           S := Trim(Copy(S, CommaIndex + 1, Length(S)));
           Result.Y := StrToFloat(S1);
           Result.Z := StrToFloat(S);
           End;
        End;
End;

Function VectorLength(Const Vector : TMVector) : Double;
Begin
     Result := Sqrt(Sqr(Vector.X) + Sqr(Vector.Y) + Sqr(Vector.Z));
End;

Function VectorAdd(Const Vector1, Vector2 : TMVector) : TMVector; overload;
Begin
     Result.X := Vector2.X + Vector1.X;
     Result.Y := Vector2.Y + Vector1.Y;
     Result.Z := Vector2.Z + Vector1.Z;
End;

Function VectorAdd(Const Vector1, Vector2, Vector3 : TMVector) : TMVector; overload;
Begin
     Result.X := Vector1.X + Vector2.X + Vector3.X;
     Result.Y := Vector1.Y + Vector2.Y + Vector3.Y;
     Result.Z := Vector1.Z + Vector2.Z + Vector3.Z;
End;

Function VectorAdd(Const Vector1, Vector2, Vector3, Vector4 : TMVector) : TMVector; overload;
Begin
     Result.X := Vector1.X + Vector2.X + Vector3.X + Vector4.X;
     Result.Y := Vector1.Y + Vector2.Y + Vector3.Y + Vector4.Y;
     Result.Z := Vector1.Z + Vector2.Z + Vector3.Z + Vector4.Z;
End;

Function VectorAdd(Const Vector1, Vector2, Vector3, Vector4, Vector5 : TMVector) : TMVector; overload;
Begin
     Result.X := Vector1.X + Vector2.X + Vector3.X + Vector4.X + Vector5.X;
     Result.Y := Vector1.Y + Vector2.Y + Vector3.Y + Vector4.Y + Vector5.Y;
     Result.Z := Vector1.Z + Vector2.Z + Vector3.Z + Vector4.Z + Vector5.Z;
End;

Function VectorAdd(Const Vector1, Vector2, Vector3, Vector4, Vector5, Vector6 : TMVector) : TMVector; overload;
Begin
     Result.X := Vector1.X + Vector2.X + Vector3.X + Vector4.X + Vector5.X + Vector6.X;
     Result.Y := Vector1.Y + Vector2.Y + Vector3.Y + Vector4.Y + Vector5.Y + Vector6.X;
     Result.Z := Vector1.Z + Vector2.Z + Vector3.Z + Vector4.Z + Vector5.Z + Vector6.X;
End;

Function VectorScale(Const Scalar : Double; Vector : TMVector) : TMVector;
Begin
     Result.X := Scalar * Vector.X;
     Result.Y := Scalar * Vector.Y;
     Result.Z := Scalar * Vector.Z;
End;

Function VectorNegative(Const Vector : TMVector) : TMVector;
Begin
     Result.X := -Vector.X;
     Result.Y := -Vector.Y;
     Result.Z := -Vector.Z;
End;

Class Function TMVector.Create : TMVector;
Begin
     Result := MVectorOrigin;
End;

Function TMVector.Negative : TMVector;
Begin
     Result.X := -X;
     Result.Y := -Y;
     Result.Z := -Z;
End;

Function TMVector.Length : Double;
Begin
     Result := Sqrt(Sqr(X) + Sqr(Y) + Sqr(Z));
End;

Function TMVector.Normalised : TMVector;
Var
   L : Double;
Begin
     L := Length;
     If L > 0 Then Begin
        Result.X := X / L;
        Result.Y := Y / L;
        Result.Z := Z / L;
        End
     Else
        Result := Self;
End;

Function TMVector.AsString : String;
Begin
     Result := format('%7.4f', [X]) + CRLF + format('%7.4f', [Y]) + CRLF + format('%7.4f', [Z]) + CRLF;
End;

Function VectorSubtract(Const Vector1, Vector2 : TMVector) : TMVector;
Begin
     Result.X := Vector2.X - Vector1.X;
     Result.Y := Vector2.Y - Vector1.Y;
     Result.Z := Vector2.Z - Vector1.Z;
End;

Function VectorMidPoint(Const Vector1, Vector2 : TMVector) : TMVector; overload;
Begin
     Result.X := (Vector2.X + Vector1.X)/2;
     Result.Y := (Vector2.Y + Vector1.Y)/2;
     Result.Z := (Vector2.Z + Vector1.Z)/2;
End;

Function VectorMidPoint(Const Vector1, Vector2, Vector3 : TMVector) : TMVector; overload;
Begin
     Result.X := (Vector3.X+Vector2.X+ Vector1.X)/3;
     Result.Y := (Vector3.Y+Vector2.Y+ Vector1.Y)/3;
     Result.Z := (Vector3.Z+Vector2.Z+ Vector1.Z)/3;
End;

Function VectorDotProduct(Const Vector1, Vector2 : TMVector) : Double;
Begin
     Result := (Vector2.X * Vector1.X) + (Vector2.Y * Vector1.Y) + (Vector2.Z * Vector1.Z);
End;

Function VectorNormalise(Const Vector : TMVector) : TMVector;
Var
   L : Double;
Begin
     L := VectorLength(Vector);
     If L > 0 Then Begin
        Result.X := Vector.X / L;
        Result.Y := Vector.Y / L;
        Result.Z := Vector.Z / L;
        End
     Else
        Result := Vector;
End;

Function VectorCrossProduct(Const Vector1, Vector2 : TMVector) : TMVector;
Begin
     Result.X := Vector1.Z * Vector2.Y - Vector2.Z * Vector1.Y;
     Result.Y := Vector2.Z * Vector1.X - Vector2.X * Vector1.Z;
     Result.Z := Vector2.X * Vector1.Y - Vector2.Y * Vector1.X;
End;

Function VectorAngles(Const Vector1, Vector2 : TMVector) : Double;
// Angles between 0 and 180 degrees
Var
   V1, V2 : TMVector;
   Dp : Double;
Begin
     V1 := VectorNormalise(Vector1);
     V2 := VectorNormalise(Vector2);
     Dp := VectorDotProduct(V1, V2);
     If Dp > 1 Then
        Dp := 1
     Else If Dp < -1 Then
        Dp := -1;

     Result := ArcCos(Dp);
End;

Function VectorAnglesOnPlane(Const Vector1, Vector2 : TMVector; Const APlane : TMPlane) : Double;
Var
   V1, V2, Cross : TMVector;
   Dp : Double;
   Direction : Integer;
Begin
     V1 := VectorNormalise(ProjectVectorOntoPlane(APlane, Vector1));
     V2 := VectorNormalise(ProjectVectorOntoPlane(APlane, Vector2));
     Dp := VectorDotProduct(V1, V2);
     Cross := VectorNormalise(VectorCrossProduct(V2, V1));
     Direction := Sgn(VectorLength(VectorAdd(Cross, APlane.Normal)) - 1);

     If Dp > 1 Then
        Dp := 1
     Else If Dp < -1 Then
        Dp := -1;

     If Direction = 0 Then
        Result := ArcCos(Dp)
     Else
        Result := ArcCos(Dp) * Direction;
End;

Function VectorAnglesAboutNormal(Const Vector1, Vector2, Normal : TMVector) : Double;
Var
   V1, V2, Cross : TMVector;
   Dp, DotNormal : Double;
Begin
     V1 := VectorNormalise(Vector1);
     V2 := VectorNormalise(Vector2);
     Dp := VectorDotProduct(V1, V2);
     Cross := VectorCrossProduct(V2, V1);
     DotNormal := VectorDotProduct(Cross, Normal);

     Result := ArcTan2(VectorLength(Cross), Dp) * Sgn(DotNormal);
End;

Function VectorRatioTheorem(Const Vector1, Vector2 : TMVector; Ratio : Double) : TMVector;
Begin
     Result.X := Vector2.X * Ratio + Vector1.X * (1-Ratio);
     Result.Y := Vector2.Y * Ratio + Vector1.Y * (1-Ratio);
     Result.Z := Vector2.Z * Ratio + Vector1.Z * (1-Ratio);
End;

Function VectorSLERP(Vector1, Vector2 : TMVector; Ratio : Double) : TMVector;
Var
   Angle, SinAngle : Double;
Begin
     Angle := VectorAngles(Vector1, Vector2);
     SinAngle := Sin(Angle);

     If SinAngle > 0 Then
        Result := VectorAdd(VectorScale(Sin((1 - Ratio) * Angle) / SinAngle, Vector1), VectorScale(Sin(Ratio * Angle) / SinAngle, Vector2))
     Else
        Result := Vector1;
End;

Function ProjectVectorOntoPlane(Const Plane : TMPlane; Vector : TMVector) : TMVector;
// Graphics Gems - Andrew S. Glassner
Begin
     Result := VectorSubtract(VectorScale(VectorDotProduct(Vector, Plane.Normal), Plane.Normal), Vector);
End;

Function TriangleArea(P1, P2, P3 : TMVector) : Double;
// http://geometryalgorithms.com/Archive/algorithm_0101/
// Area = 1/2 |V x W|
// Coded inline for efficiency
Var
   V, W, CrossProduct : TMVector;
Begin
     V.X := P2.X - P1.X;
     V.Y := P2.Y - P1.Y;
     V.Z := P2.Z - P1.Z;

     W.X := P3.X - P1.X;
     W.Y := P3.Y - P1.Y;
     W.Z := P3.Z - P1.Z;

     CrossProduct.X := V.Z * W.Y - W.Z * V.Y;
     CrossProduct.Y := W.Z * V.X - W.X * V.Z;
     CrossProduct.Z := W.X * V.Y - W.Y * V.X;

     Result := 0.5 * Sqrt(Sqr(CrossProduct.X) + Sqr(CrossProduct.Y) + Sqr(CrossProduct.Z));
End;

Function QuadrilateralArea(P1, P2, P3, P4 : TMVector) : Double;
// http://geometryalgorithms.com/Archive/algorithm_0101/
// Area = 1/2 |(V2 - V0) x (V3 - V1)|
// Coded inline for efficiency
Var
   V, W, CrossProduct : TMVector;
Begin
     V.X := P3.X - P1.X;
     V.Y := P3.Y - P1.Y;
     V.Z := P3.Z - P1.Z;

     W.X := P4.X - P2.X;
     W.Y := P4.Y - P2.Y;
     W.Z := P4.Z - P2.Z;

     CrossProduct.X := V.Z * W.Y - W.Z * V.Y;
     CrossProduct.Y := W.Z * V.X - W.X * V.Z;
     CrossProduct.Z := W.X * V.Y - W.Y * V.X;

     Result := 0.5 * Sqrt(Sqr(CrossProduct.X) + Sqr(CrossProduct.Y) + Sqr(CrossProduct.Z));
End;

{$endregion}

{$region '********************************************* TMSegment **************************************************'}

Function MSegment(Const V1, V2 : TMVector) : TMSegment; overload;
Begin
     Result.P1 := V1;
     Result.P2 := V2;
End;

Function MSegment(Const X1, Y1, Z1, X2, Y2, Z2 : Double) : TMSegment; overload;
Begin
     Result.P1 := MVector(X1, Y1, Z1);
     Result.P2 := MVector(X2, Y2, Z2);
End;

Function TMSegment.Length : Double;
Begin
     Result := Distance(P1, P2);
End;

{$endregion}

{$region '********************************************* TMVectorLine ***********************************************'}

Function MVectorLine(Const Direction, Position : TMVector) : TMVectorLine;
Begin
     Result.Base := Position;
     Result.Direction := Direction;
End;

Function VectorLine(Const VLine : TMVectorLine; Parameter : Double) : TMVector;
Begin
     Result := VectorAdd(VLine.Base, VectorScale(Parameter, VLine.Direction));
End;

Function LineThroughTwoPoints(Const Point1, Point2 : TMVector; NormaliseDirectionVector : Boolean = True) : TMVectorLine;
// Graphics Gems - Andrew S. Glassner
Begin
     Result.Base := Point1;

     If NormaliseDirectionVector Then
        Result.Direction := VectorNormalise(VectorSubtract(Point2, Point1))
     Else
        Result.Direction := VectorSubtract(Point2, Point1);
End;

Function LineBetweenLineSegmentsInfo(Line1, Line2 : TMVectorLine) : TLineIntersectionResult;
Var
   U, V, W, W1, W2 : TMVector;
   A, B, C, D, E : Double;
   Det : Double;
   sc, sN, sD : Double;
   tc, tN, tD : Double;
Begin
     U := Line1.Direction;
     V := Line2.Direction;
     W := VectorSubtract(Line2.Base, Line1.Base);

     A := VectorDotProduct(U, U);    // always >= 0
     B := VectorDotProduct(U, V);
     C := VectorDotProduct(V, V);    // always >= 0
     D := VectorDotProduct(U, W);
     E := VectorDotProduct(V, W);
     Det := a * c - b * b;           // always >= 0
     sD := Det;                      // sc = sN / sD, default sD = D >= 0
     tD := Det;                      // tc = tN / tD, default tD = D >= 0

    // compute the line parameters of the two closest points
    If (Det < ASmallNumber) Then Begin // the lines are almost parallel
        sN := 0.0;                   // force using point P0 on segment S1
        sD := 1.0;                   // to prevent possible division by 0.0 later
        tN := e;
        tD := c;
        End
    Else Begin                       // get the closest points on the infinite lines
        sN := (b * e - c * d);
        tN := (a * e - b * d);
        If (sN < 0.0) Then Begin     // sc < 0 => the s=0 edge is visible
            sN := 0.0;
            tN := e;
            tD := c;
            End
        Else if (sN > sD) Then Begin // sc > 1 => the s=1 edge is visible
            sN := sD;
            tN := e + b;
            tD := c;
            End;
        End;

    If (tN < 0.0) Then Begin         // tc < 0 => the t=0 edge is visible
        tN := 0.0;
        // recompute sc for this edge
        If (-d < 0.0) Then
            sN := 0.0
        Else If (-d > a) Then
            sN := sD
        Else Begin
            sN := -d;
            sD := a;
            End;
        End
    Else if (tN > tD) Then Begin     // tc > 1 => the t=1 edge is visible
        tN := tD;
        // recompute sc for this edge
        If ((-d + b) < 0.0) Then
            sN := 0
        Else If ((-d + b) > a) Then
            sN := sD
        Else Begin
            sN := (-d + b);
            sD := a;
            End;
        End;

    If abs(sN) < ASmallNumber Then Sc := 0.0 Else Sc := sN / sD;
    If abs(tN) < ASmallNumber Then tc := 0.0 Else tc := tN / tD;

    W1 := VectorLine(Line1, sc);
    W2 := VectorLine(Line2, tc);

    Result.Line1 := Line1;
    Result.Line2 := Line2;
    Result.Line1T := Sc;
    Result.Line2T := Tc;
    Result.Line1Point := W1;
    Result.Line2Point := W2;
    Result.Line.Base := W1;
    Result.Line.Direction := VectorSubtract(W1, W2);
    Result.Det := Det;
End;

Function LineBetweenLineSegments(Line1, Line2 : TMVectorLine) : TMVectorLine;
Begin
     Result := LineBetweenLineSegmentsInfo(Line1, Line2).Line;
End;

Function DistanceFromPointToLine(Const Point : TMVector; Line : TMVectorLine) : Double;
// Graphics Gems - Andrew S. Glassner
Begin
     If VectorLength(Line.Direction) > 0 Then
        Result := Distance(Point, PointOnLineClosestToPoint(Line, Point))
     Else
        Raise Exception.Create('LxWeb.Maths.DistanceFromPointToLine - Line has zero magnitude direction vector');
End;

Function DistanceFromPointToLineSegment(Const Segment : TMSegment; Point : TMVector) : Double;
Var
   Denom, T : Extended;
   Line : TMVectorLine;
   Plane : TMPlane;
   SegmentPoint : TMVector;
Begin
     Line.Direction := VectorSubtract(Segment.P1, Segment.P2);
     Line.Base := Segment.P1;
     // 1. Point lies on a plane with normal Line.Direction: Point . Line.Direction + d = 0
     // 2. Find the plane and intersect it with the line
     If VectorLength(Line.Direction) > 0 Then Begin
        Plane.Normal := Line.Direction;
        Plane.Offset := VectorDotProduct(Point, Line.Direction);

        Denom := VectorDotProduct(Line.Direction, Plane.Normal);
        T := (Plane.Offset - VectorDotProduct(Line.Base, Plane.Normal)) / Denom;
        SegmentPoint := VectorAdd(Line.Base, VectorScale(T, Line.Direction));

        If Abs(VectorDotProduct(Plane.Normal, SegmentPoint) - Plane.Offset) > ASmallNumber Then Begin
           // Result was inaccurate, probably due to large and small number mismatch, recalculate
           // with result as base
           T := (Plane.Offset - VectorDotProduct(SegmentPoint, Plane.Normal)) / Denom;
           SegmentPoint := VectorAdd(SegmentPoint, VectorScale(T, Line.Direction));
           End;

        If T < 0 Then
           Result := Distance(Segment.P1, Point)
        Else If T > 1 Then
           Result := Distance(Segment.P2, Point)
        Else
           Result := Distance(SegmentPoint, Point);
        End
     Else
        Raise Exception.Create('LxWeb.Maths.DistanceFromPointToLineSegment - Line has zero magnitude direction vector');
End;

Function DistancefromLineToLineSegment(Const Line : TMVectorLine; Const Segment : TMSegment) : Double;
Var
   U, V, W : TMVector;
   A, B, C, D, E : Double;
   Det : Double;
   sN, tN : Double;
   PLine, PSegment : TMVector;
Begin
     U := Line.Direction;
     V := VectorSubtract(Segment.P1, Segment.P2);
     W := VectorSubtract(Segment.P1, Line.Base);

     A := VectorDotProduct(U, U);    // always >= 0
     B := VectorDotProduct(U, V);
     C := VectorDotProduct(V, V);    // always >= 0
     D := VectorDotProduct(U, W);
     E := VectorDotProduct(V, W);
     Det := a * c - b * b;           // always >= 0

     // Segment and Line Parallel
     If Abs(Det) < ASmallNumber Then Begin
        PSegment := VectorMidPoint(Segment.P1, Segment.P2);
        PLine := PointOnLineClosestToPoint(Line, PSegment);
        Result := Distance(PSegment, PLine);
        End
     Else Begin
        sN := (b * e - c * d) / Det;
        tN := (a * e - b * d) / Det;

        If tN > 1 Then Begin
           PLine := PointOnLineClosestToPoint(Line, Segment.P2);
           Result := Distance(Segment.P2, PLine);
           End
        Else If tN < 0 Then Begin
           PLine := PointOnLineClosestToPoint(Line, Segment.P1);
           Result := Distance(Segment.P1, PLine);
           End
        Else Begin
           PSegment := VectorRatioTheorem(Segment.P1, Segment.P2, tN);
           PLine := VectorLine(Line, sN);
           Result := Distance(PSegment, PLine);
           End;
        End;
End;

Function PointOnLineClosestToPoint(Const Line : TMVectorLine; Point : TMVector) : TMVector;
// Graphics Gems - Andrew S. Glassner
Var
   Plane : TMPlane;
Begin
     // 1. Point lies on a plane with normal Line.Direction: Point . Line.Direction + d = 0
     // 2. Find the plane and intersect it with the line
     If VectorLength(Line.Direction) > 0 Then Begin
        Plane.Normal := Line.Direction;
        Plane.Offset := VectorDotProduct(Point, Line.Direction);
        Result := IntersectionOfLineandPlane(Line, Plane);
        End
     Else
        Raise Exception.Create('LxWeb.Maths.PointOnLineClosestToPoint - Line has zero magnitude direction vector');
End;

Function PointOnLineClosestToPoint(Const Line : TMVectorLine; Point : TMVector; Out T : Double) : TMVector;
// Graphics Gems - Andrew S. Glassner
Var
   Plane : TMPlane;
   Info : TLineProximatyResult;
Begin
     // 1. Point lies on a plane with normal Line.Direction: Point . Line.Direction + d = 0
     // 2. Find the plane and intersect it with the line
     If VectorLength(Line.Direction) > 0 Then Begin
        Plane.Normal := Line.Direction;
        Plane.Offset := VectorDotProduct(Point, Line.Direction);

        Info := IntersectionOfLineandPlaneInfo(Line, Plane);
        T := Info.T;

        Result := Info.Point;
        End
     Else
        Raise Exception.Create('LxWeb.Maths.PointOnLineClosestToPoint - Line has zero magnitude direction vector');
End;


Function PointOnLineClosestToOrigin(Const Line : TMVectorLine) : TMVector;
// Graphics Gems - Andrew S. Glassner
Begin
     Result := PointOnLineClosestToPoint(Line, MVectorOrigin);
End;

Function PointOnLineClosestToLine(Const PointLine, PassingLine : TMVectorLine) : TMVector; overload;
Var
   Temp : Double;
Begin
     Result := PointOnLineClosestToLine(PointLine, PassingLine, Temp);
End;

Function PointOnLineClosestToLine(Const PointLine, PassingLine : TMVectorLine; Out PointLineT : Double) : TMVector; overload;
Var
   U, V, W : TMVector;
   A, B, C, D, E : Double;
   Det : Double;
Begin
     U := PointLine.Direction;
     V := PassingLine.Direction;
     W := VectorSubtract(PassingLine.Base, PointLine.Base);

     A := VectorDotProduct(U, U);    // always >= 0
     B := VectorDotProduct(U, V);
     C := VectorDotProduct(V, V);    // always >= 0
     D := VectorDotProduct(U, W);
     E := VectorDotProduct(V, W);
     Det := a * c - b * b;           // always >= 0

     If Abs(Det) < ASmallNumber Then
        Raise Exception.Create('LxWeb.Maths - PointOnLineClosestToLine: attempted with parallel lines')
     Else
        PointLineT := (b * e - c * d) / Det;

     Result := VectorLine(PointLine, PointLineT);
End;

Function PointOnLineClosestToLine(Const PointLine, PassingLine : TMVectorLine; Out APoint : TMVector) : Boolean; overload;
Var
   U, V, W : TMVector;
   A, B, C, D, E : Double;
   Det : Double;
   sN : Double;
Begin
     U := PointLine.Direction;
     V := PassingLine.Direction;
     W := VectorSubtract(PassingLine.Base, PointLine.Base);

     A := VectorDotProduct(U, U);    // always >= 0
     B := VectorDotProduct(U, V);
     C := VectorDotProduct(V, V);    // always >= 0
     D := VectorDotProduct(U, W);
     E := VectorDotProduct(V, W);
     Det := a * c - b * b;           // always >= 0

     If Abs(Det) < ASmallNumber Then Begin
        Result := False;
        APoint := MVectorOrigin;
        End
     Else Begin
        sN := (b * e - c * d) / Det;
        APoint := VectorLine(PointLine, sN);
        Result := True;
        End;
End;

Function PointOnLineClosestToLineSegment(Const Line : TMVectorLine; Const Segment : TMSegment) : TMVector;
Var
   U, V, W : TMVector;
   A, B, C, D, E : Double;
   Det : Double;
   sN, tN : Double;
   Plane : TMPlane;
Begin
     U := Line.Direction;
     V := VectorSubtract(Segment.P1, Segment.P2);
     W := VectorSubtract(Segment.P1, Line.Base);

     A := VectorDotProduct(U, U);    // always >= 0
     B := VectorDotProduct(U, V);
     C := VectorDotProduct(V, V);    // always >= 0
     D := VectorDotProduct(U, W);
     E := VectorDotProduct(V, W);
     Det := a * c - b * b;           // always >= 0

     If Abs(Det) < ASmallNumber Then Begin
        Plane := MPlane(VectorNormalise(V), VectorMidPoint(Segment.P1, Segment.P2));
        Result := IntersectionOfLineandPlane(Line, Plane);
        End
     Else Begin
        sN := (b * e - c * d) / Det;
        tN := (a * e - b * d) / Det;

        If tN > 1 Then
           Result := PointOnLineClosestToPoint(Line, Segment.P2)
        Else If tN < 0 Then
           Result := PointOnLineClosestToPoint(Line, Segment.P1)
        Else
           Result := VectorLine(Line, sN);
        End;
End;

Function PointOnLineSegmentClosestToPointInfo(Const Segment : TMSegment; Point : TMVector) : TLineProximatyResult;
Var
   Line : TMVectorLine;
   Plane : TMPlane;
Begin
     Line.Direction := VectorSubtract(Segment.P1, Segment.P2);
     Line.Base := Segment.P1;
     // 1. Point lies on a plane with normal Line.Direction: Point . Line.Direction + d = 0
     // 2. Find the plane and intersect it with the line
     If VectorLength(Line.Direction) > 0 Then Begin
        Plane.Normal := Line.Direction;
        Plane.Offset := VectorDotProduct(Point, Line.Direction);
        Result := IntersectionOfLineandPlaneInfo(Line, Plane);

        If Result.T < 0 Then Begin
           Result.T := 0;
           Result.Point := VectorLine(Line, 0);
           End
        Else If Result.T > 1 Then Begin
           Result.T := 1;
           Result.Point := VectorLine(Line, 1);
           End;
        End
     Else
        Raise Exception.Create('LxWeb.Maths.PointOnLineSegmentClosestToPointInfo - Line has zero magnitude direction vector');
End;

Function PointOnLineSegmentClosestToPoint(Const Segment : TMSegment; Point : TMVector) : TMVector;
Begin
     Result := PointOnLineSegmentClosestToPointInfo(Segment, Point).Point;
End;

Function PointOnLineSegmentClosestToLine(Const Line : TMVectorLine; Const Segment : TMSegment; Out T : Double) : TMVector; overload;
Var
   U, V, W : TMVector;
   A, B, C, D, E : Double;
   Det : Double;
Begin
     U := Line.Direction;
     V := VectorSubtract(Segment.P1, Segment.P2);
     W := VectorSubtract(Segment.P1, Line.Base);

     A := VectorDotProduct(U, U);    // always >= 0
     B := VectorDotProduct(U, V);
     C := VectorDotProduct(V, V);    // always >= 0
     D := VectorDotProduct(U, W);
     E := VectorDotProduct(V, W);
     Det := a * c - b * b;           // always >= 0

     // Segment and Line Parallel
     If Abs(Det) < ASmallNumber Then
        Result := VectorMidPoint(Segment.P1, Segment.P2)
     Else Begin
        T := (a * e - b * d) / Det;

        If T > 1 Then
           Result := Segment.P2
        Else If T < 0 Then
           Result := Segment.P1
        Else
           Result := VectorRatioTheorem(Segment.P1, Segment.P2, T);
        End;
End;

Function PointOnLineSegmentClosestToLine(Const Line : TMVectorLine; Const Segment : TMSegment) : TMVector; overload;
Var
   T : Double;
Begin
     Result := PointOnLineSegmentClosestToLine(Line, Segment, T);
End;

Function PointBetweenTwoLinesInfo(Const Line1, Line2 : TMVectorLine) : TLineIntersectionResult;
Var
   U, V, W : TMVector;
   A, B, C, D, E : Double;
   Det : Double;
   sN, tN : Double;
   Point1, Point2 : TMVector;
Begin
     U := Line1.Direction;
     V := Line2.Direction;
     W := VectorSubtract(Line2.Base, Line1.Base);

     A := VectorDotProduct(U, U);    // always >= 0
     B := VectorDotProduct(U, V);
     C := VectorDotProduct(V, V);    // always >= 0
     D := VectorDotProduct(U, W);
     E := VectorDotProduct(V, W);
     Det := a * c - b * b;           // always >= 0

     If Abs(Det) < ASmallNumber Then Begin
        Result.Det := Det;
        Result.State := isParallel;
        Exit;
        End
     Else Begin
        sN := (b * e - c * d) / Det;
        tN := (a * e - b * d) / Det;
        End;

     Point1 := VectorLine(Line1, sN);
     Point2 := VectorLine(Line2, tN);

     Result.Line1 := Line1;
     Result.Line2 := Line2;
     Result.Line1T := sN;
     Result.Line2T := tN;
     Result.Line1Point := Point1;
     Result.Line2Point := Point2;
     Result.Line := LineThroughTwoPoints(Point1, Point2);
     Result.Det := Det;
     If Distance(Point1, Point2) < ASmallNumber Then
        Result.State := isIntersects
     Else
        Result.State := isApproach;
End;

Function PointBetweenTwoLines(Const Line1, Line2 : TMVectorLine) : TMVector;
Var
   R : TLineIntersectionResult;
Begin
     R := PointBetweenTwoLinesInfo(Line1, Line2);
     If Abs(R.Det) < ASmallNumber Then Raise Exception.Create('LxWeb.Maths - PointBetweenTwoLines: attempted with parallel lines');
     Result := VectorMidPoint(R.Line1Point, R.Line2Point);
End;

{$endregion}

{$region '********************************************* TMMatrix ***************************************************'}

Class Function TMMatrix.Create(Const AMatrix : TMMatrix) : TMMatrix;
Var
   I, J : Integer;
Begin
     For J := 1 to MMatrixsize Do
         For I := 1 to MMatrixsize Do
             Result[I, J] := AMatrix[I, J];
End;

Function TMMatrix.GetElement(Const I, J : Integer) : Double;
Begin
     Result := 0;
     Case I of
          1 : Case J of
                   1 : Result := m11;
                   2 : Result := m12;
                   3 : Result := m13;
                   4 : Result := m14;
                  Else Raise Exception.Create('TMMatrix.GetElement - Matrix Element Out of Bounds');
                   End;
          2 : Case J of
                   1 : Result := m21;
                   2 : Result := m22;
                   3 : Result := m23;
                   4 : Result := m24;
                  Else Raise Exception.Create('TMMatrix.GetElement - Matrix Element Out of Bounds');
                   End;
          3 : Case J of
                   1 : Result := m31;
                   2 : Result := m32;
                   3 : Result := m33;
                   4 : Result := m34;
                  Else Raise Exception.Create('TMMatrix.GetElement - Matrix Element Out of Bounds');
                   End;
          4 : Case J of
                   1 : Result := m41;
                   2 : Result := m42;
                   3 : Result := m43;
                   4 : Result := m44;
                   End;
         Else Raise Exception.Create('TMMatrix.GetElement - Matrix Element Out of Bounds');
          End;
End;


Procedure TMMatrix.SetElement(Const I, J : Integer; Const Val : Double);
Begin
     Case I of
          1 : Case J of
                   1 : m11 := Val;
                   2 : m12 := Val;
                   3 : m13 := Val;
                   4 : m14 := Val;
                  Else Raise Exception.Create('TMMatrix.SetElement - Matrix Element Out of Bounds');
                   End;
          2 : Case J of
                   1 : m21 := Val;
                   2 : m22 := Val;
                   3 : m23 := Val;
                   4 : m24 := Val;
                  Else Raise Exception.Create('TMMatrix.SetElement - Matrix Element Out of Bounds');
                   End;
          3 : Case J of
                   1 : m31 := Val;
                   2 : m32 := Val;
                   3 : m33 := Val;
                   4 : m34 := Val;
                  Else Raise Exception.Create('TMMatrix.SetElement - Matrix Element Out of Bounds');
                   End;
          4 : Case J of
                   1 : m41 := Val;
                   2 : m42 := Val;
                   3 : m43 := Val;
                   4 : m44 := Val;
                   End;
         Else Raise Exception.Create('TMMatrix.SetElement - Matrix Element Out of Bounds');
          End;
End;

Function TMMatrix.Transpose : TMMatrix;
Begin
     Result := TransposeMatrix(Self);
End;

Function TMMatrix.Invert : TMMatrix;
Begin
     Result := InvertMatrix(Self);
End;


Function TMMatrix.AsArray : TArrayOfDouble;
Var
   I, J, Index : Integer;
Begin
     // AsArray to supply in Column Major Order
     //   0 -> A[1, 1]
     //   1 -> A[2, 1]
     //   2 -> A[3, 1]
     //

     SetLength(Result, 16);
     Index := 0;
     For J := 1 to MMatrixsize Do
         For I := 1 to MMatrixsize Do Begin
             Result[Index] := Self[I, J];
             Inc(Index);
             End;
End;

Function TMMatrix.AsString : string;
Var
   I : Integer;
Begin
     Result := '';
     For I := 1 to 4 Do
         Result := Result + format('%7.4f %7.4f %7.4f %7.4f', [Self[I, 1], Self[I, 2], Self[I, 3], Self[I, 4]]) + CRLF;
End;

Class Function TMMatrix.IMatrix : TMMatrix;
Begin
     Result[1, 1] := 1;
     Result[1, 2] := 0;
     Result[1, 3] := 0;
     Result[1, 4] := 0;

     Result[2, 1] := 0;
     Result[2, 2] := 1;
     Result[2, 3] := 0;
     Result[2, 4] := 0;

     Result[3, 1] := 0;
     Result[3, 2] := 0;
     Result[3, 3] := 1;
     Result[3, 4] := 0;

     Result[4, 1] := 0;
     Result[4, 2] := 0;
     Result[4, 3] := 0;
     Result[4, 4] := 1;
End;

Function EqualMatrix(Const Matrix1, Matrix2 : TMMatrix) : Boolean;
Var
   I, J : Integer;
Begin
     Result := True;

     For I := 1 to MMatrixsize Do
         For J := 1 to MMatrixsize Do
             Result := Result and (Matrix1[I, J] = Matrix2[I, J]);
End;

Function MultiplyMatrix(Const S : Double; Const M : TMMatrix) : TMMatrix; overload;
Var
   I, J : Integer;
Begin
     For I := 1 to MMatrixsize Do
         For J := 1 to MMatrixsize Do
             Result[I, J] := S * M[I, J];
End;

Function MultiplyMatrix(Const M1, M2 : TMMatrix) : TMMatrix; overload;
Var
   I, J, K : Integer;
   TempSum : Double;
   Mat : TMMatrix;
Begin
     For I := 1 to MMatrixsize Do
         For J := 1 to MMatrixsize Do Begin
             TempSum := 0;
             For K := 1 to MMatrixsize Do
                 TempSum := TempSum + M1[I, K] * M2[K, J];
             Mat[I, J] := TempSum;
             End;
     Result := Mat;
End;

Function MultiplyMatrix(Const M1, M2, M3 : TMMatrix) : TMMatrix; overload;
Begin
     Result := MultiplyMatrix(M1, MultiplyMatrix(M2, M3));
End;

Function MultiplyMatrix(Const M1, M2, M3, M4 : TMMatrix) : TMMatrix; overload;
Begin
     Result := MultiplyMatrix(MultiplyMatrix(M1, M2), MultiplyMatrix(M3, M4));
End;

Function MultiplyMatrix(Const M1 : TMMatrix; M2 : TMVector) : TMVector; overload;
Var
   Mat : TMVector;
Begin
     Mat.X := M1[1, 1] * M2.X + M1[1, 2] * M2.Y + M1[1, 3] * M2.Z + M1[1, 4];
     Mat.Y := M1[2, 1] * M2.X + M1[2, 2] * M2.Y + M1[2, 3] * M2.Z + M1[2, 4];
     Mat.Z := M1[3, 1] * M2.X + M1[3, 2] * M2.Y + M1[3, 3] * M2.Z + M1[3, 4];
     Result := Mat;
End;

Function MultiplyMatrix(Const M : TMMatrix; Plane : TMPlane) : TMPlane; overload;
Var
   Location, Normal : TMVector;
   Location1, Normal1 : TMVector;
Begin
     Location := VectorScale(Plane.Offset, Plane.Normal);
     Normal := VectorAdd(Location, Plane.Normal);

     Location1 := MultiplyMatrix(M, Location);
     Normal1 := MultiplyMatrix(M, Normal);

     Result.Normal := VectorNormalise(VectorSubtract(Location1, Normal1));
     Result.Offset := VectorDotProduct(Result.Normal, Location1);
End;

Function MultiplyMatrix(Const M : TMMatrix; ALine : TMVectorLine) : TMVectorLine; overload;
Var
   TransformedDirection : TMVector;
Begin
     Result.Base := MultiplyMatrix(M, ALine.Base);
     TransformedDirection := MultiplyMatrix(M, VectorAdd(ALine.Base, ALine.Direction));

     Result.Direction := VectorNormalise(VectorSubtract(Result.Base, TransformedDirection));
End;

Function InvertMatrix(Const InMat : TMMatrix) : TMMatrix;
Const
     ErrorBound = 1.0E-30;

Var
   OMat, IMat : TMMatrix;
   I, J, K, M : Integer;
   Factor, Temp : Double;

Begin
     OMat := TMMatrix.Create(InMat);

     { Create Identity Matrix }
     IMat := TMMatrix.IMatrix;


     For J := 1 to MMatrixSize Do Begin
         I := J;

         While Abs(OMat[I, J]) < ErrorBound do Begin
               If I = MMatrixSize Then Begin           { Matrix is Singular }
                                      Result := OMatrix;
                                      Exit;
                                      End;
               I := I + 1;
               End;

         For K := 1 to MMatrixSize Do Begin
             Temp       := OMat[I, K];
             OMat[I, K] := OMat[J, K];
             OMat[J, K] := Temp;
             Temp       := IMat[I, K];
             IMat[I, K] := IMat[J, K];
             IMat[J, K] := Temp;
             End;

         Factor := 1 / OMat[J, J];

         For K := 1 to MMatrixSize do Begin
             OMat[J, K] := Factor * OMat[J, K];
             IMat[J, K] := Factor * IMat[J, K];
             End;

         For M := 1 to MMatrixSize Do
             If M <> J Then Begin
                Factor := -OMat[M, J];

                For K := 1 to MMatrixSize Do Begin
                    OMat[M, K] := OMat[M, K] + Factor * OMat[J, K];
                    IMat[M, K] := IMat[M, K] + Factor * IMat[J, K];
                    End;
             End;
         End;

     Result := IMat;
End;

Function TransposeMatrix(Const OMat : TMMatrix) : TMMatrix;
Var
   TMat : TMMatrix;
   I, J : Integer;

Begin
     For I := 1 to MMatrixsize do
         For J := 1 to MMatrixsize do
             TMat[J, I] := OMat[I, J];
     Result := TMat;
End;

Function AddMatrix(Const AMat, BMat : TMMatrix) : TMMatrix;
Var
   TMat : TMMatrix;
   I, J : Integer;
Begin
     For I := 1 to MMatrixsize do
         For J := 1 to MMatrixsize do
             TMat[I, J] := AMat[I, J] + BMat[I,J];
     Result := TMat;
End;

Function SubtractMatrix(Const AMat, BMat : TMMatrix) : TMMatrix;
Var
   TMat : TMMatrix;
   I, J : Integer;
Begin
     For I := 1 to MMatrixsize do
         For J := 1 to MMatrixsize do
             TMat[I, J] := AMat[I, J] - BMat[I,J];

     Result := TMat;
End;

Function ColumnsMatrix(Const C1, C2, C3, C4 : TMVector) : TMMatrix;
Var
   M : TMMatrix;
Begin
     M[1, 1] := C1.X;
     M[2, 1] := C1.Y;
     M[3, 1] := C1.Z;
     M[4, 1] := 0;

     M[1, 2] := C2.X;
     M[2, 2] := C2.Y;
     M[3, 2] := C2.Z;
     M[4, 2] := 0;

     M[1, 3] := C3.X;
     M[2, 3] := C3.Y;
     M[3, 3] := C3.Z;
     M[4, 3] := 0;

     M[1, 4] := C4.X;
     M[2, 4] := C4.Y;
     M[3, 4] := C4.Z;
     M[4, 4] := 1;
     Result := M;
End;

Function RotateMatrix(Ax, Ay, Az : Double) : TMMatrix; overload;
Var
   Temp1, Temp2, Temp3 : TMMatrix;
Begin
     Temp1 := TMMatrix.IMatrix;

     If Not(Ax = 0) Then Begin
        Temp1[2, 2] := Cos(Ax);
        Temp1[2, 3] := Sin(Ax);
        Temp1[3, 2] := -Sin(Ax);
        Temp1[3, 3] := Cos(Ax);
        End;

     If Not(Ay = 0) Then Begin
        Temp3 := TMMatrix.IMatrix;
        Temp3[1, 1] := Cos(Ay);
        Temp3[1, 3] := -Sin(Ay);
        Temp3[3, 1] := Sin(Ay);
        Temp3[3, 3] := Cos(Ay);
        Temp2 := MultiplyMatrix(Temp3, Temp1);
        Temp1 := Temp2;
        End;

     If Not(Az = 0) Then Begin
        Temp3 := TMMatrix.IMatrix;
        Temp3[1, 1] := Cos(Az);
        Temp3[1, 2] := Sin(Az);
        Temp3[2, 1] := -Sin(Az);
        Temp3[2, 2] := Cos(Az);
        Temp2 := MultiplyMatrix(Temp3, Temp1);
        Temp1 := Temp2;
        End;

     Result := Temp1;
End;

Function RotateMatrix(Angle : Double; Axis : TMVector) : TMMatrix; overload;
Var
   C, S : Double;
Begin
     Axis := VectorNormalise(Axis);
     C := Cos(Angle);
     S := Sin(Angle);

     Result[1, 1] := Axis.X * Axis.X * (1 - C) + C;
     Result[1, 2] := Axis.X * Axis.Y * (1 - C) - Axis.Z * S;
     Result[1, 3] := Axis.X * Axis.Z * (1 - C) + Axis.Y * S;
     Result[1, 4] := 0;

     Result[2, 1] := Axis.Y * Axis.X * (1 - C) + Axis.Z * S;
     Result[2, 2] := Axis.Y * Axis.Y * (1 - C) + C;
     Result[2, 3] := Axis.Y * Axis.Z * (1 - C) - Axis.X * S;
     Result[2, 4] := 0;

     Result[3, 1] := Axis.Z * Axis.X * (1 - C) - Axis.Y * S;
     Result[3, 2] := Axis.Z * Axis.Y * (1 - C) + Axis.X * S;
     Result[3, 3] := Axis.Z * Axis.Z * (1 - C) + C;
     Result[3, 4] := 0;

     Result[4, 1] := 0;
     Result[4, 2] := 0;
     Result[4, 3] := 0;
     Result[4, 4] := 1;
End;

Function RotateMatrix(OldNormal, NewNormal : TMVector) : TMMatrix; overload;
Var
   V1, V2, Axis : TMVector;
   C, S : Double;
Begin
     // Calculation is expanded to avoid floating point issues with
     // a mixture of large and small numbers preventing inversion

     V1 := VectorNormalise(OldNormal);
     V2 := VectorNormalise(NewNormal);
     Axis := VectorNormalise(VectorCrossProduct(V2, V1));
     C := VectorDotProduct(V1, V2);
     If C > 1 Then
        C := 1
     Else If C < -1 Then
        C := -1;

     S := Sqrt(1 - Sqr(C));

     Result[1, 1] := Axis.X * Axis.X * (1 - C) + C;
     Result[1, 2] := Axis.X * Axis.Y * (1 - C) - Axis.Z * S;
     Result[1, 3] := Axis.X * Axis.Z * (1 - C) + Axis.Y * S;
     Result[1, 4] := 0;

     Result[2, 1] := Axis.Y * Axis.X * (1 - C) + Axis.Z * S;
     Result[2, 2] := Axis.Y * Axis.Y * (1 - C) + C;
     Result[2, 3] := Axis.Y * Axis.Z * (1 - C) - Axis.X * S;
     Result[2, 4] := 0;

     Result[3, 1] := Axis.Z * Axis.X * (1 - C) - Axis.Y * S;
     Result[3, 2] := Axis.Z * Axis.Y * (1 - C) + Axis.X * S;
     Result[3, 3] := Axis.Z * Axis.Z * (1 - C) + C;
     Result[3, 4] := 0;

     Result[4, 1] := 0;
     Result[4, 2] := 0;
     Result[4, 3] := 0;
     Result[4, 4] := 1;
End;

Function MirrorMatrix(Plane : TOrthogonalPlane; PlaneBase : Double) : TMMatrix; overload;
Var
   Temp1, Temp2, Temp3 : TMMatrix;
Begin
     If PlaneBase = 0 Then Begin
        Temp1 := TMMatrix.IMatrix;
        Case Plane of
             opYZ : Temp1[1, 1] := -1;
             opZX : Temp1[2, 2] := -1;
             opXY : Temp1[3, 3] := -1;
             End;
        Result := Temp1;
        End
     Else Begin
          Temp3 := TMMatrix.IMatrix;
          Case Plane of
               opYZ : Temp3[1, 4] := -PlaneBase;
               opZX : Temp3[2, 4] := -PlaneBase;
               opXY : Temp3[3, 4] := -PlaneBase;
               End;

          Temp2 := TMMatrix.IMatrix;
          Case Plane of
               opYZ : Temp2[1, 1] := -1;
               opZX : Temp2[2, 2] := -1;
               opXY : Temp2[3, 3] := -1;
               End;

          Temp1 := MultiplyMatrix(Temp2, Temp3);

          Temp3 := TMMatrix.IMatrix;
          Case Plane of
               opYZ : Temp3[1, 4] := PlaneBase;
               opZX : Temp3[2, 4] := PlaneBase;
               opXY : Temp3[3, 4] := PlaneBase;
               End;

          Temp2 := MultiplyMatrix(Temp3, Temp1);
          Result := Temp2;
          End;
End;

Function MirrorMatrix(APlane : TMPlane) : TMMatrix; overload;
Var
   P, Normal : TMVector;
   T, TInv, Rot, RotInv : TMMatrix;
begin
     If APlane.Offset = 0 Then Begin // Plane Intersects with the origin, no translation necessary
        Normal := VectorNormalise(APlane.Normal);

        If VectorDotProduct(Normal, MVectorX) = 1 Then // Pure reflextion in X Plane
           Result := XMirrorMatrix
        Else If VectorDotProduct(Normal, MVectorY) = 1 Then // Pure reflextion in Y Plane
           Result := YMirrorMatrix
        Else If VectorDotProduct(Normal, MVectorZ) = 1 Then // Pure reflextion in Z Plane
           Result := ZMirrorMatrix
        Else Begin // Arbitrary Plane
           Rot := RotateMatrix(Normal, MVectorZ);
           RotInv := RotateMatrix(MVectorZ, Normal);
           Result := MultiplyMatrix(RotInv, MultiplyMatrix(ZMirrorMatrix, Rot));
           End;
        End
     Else Begin  // Translation Necessary
        Normal := VectorNormalise(APlane.Normal);
        P := VectorScale(APlane.Offset, APlane.Normal);
        T := MoveMatrix(VectorNegative(P));
        TInv := MoveMatrix(P);

        If VectorDotProduct(Normal, MVectorX) = 1 Then // Reflextion in X Plane
           Result := MultiplyMatrix(TInv, MultiplyMatrix(XMirrorMatrix, T))
        Else If VectorDotProduct(Normal, MVectorY) = 1 Then // Reflextion in Y Plane
           Result := MultiplyMatrix(TInv, MultiplyMatrix(YMirrorMatrix, T))
        Else If VectorDotProduct(Normal, MVectorZ) = 1 Then // Reflextion in Z Plane
           Result := MultiplyMatrix(TInv, MultiplyMatrix(ZMirrorMatrix, T))
        Else Begin // Arbitrary Plane
           Rot := RotateMatrix(Normal, MVectorZ);
           RotInv := RotateMatrix(MVectorZ, Normal);
           Result := MultiplyMatrix(TInv, MultiplyMatrix(RotInv, MultiplyMatrix(ZMirrorMatrix, MultiplyMatrix(Rot, T))));
           End;
        End;
End;

Function MoveMatrix(Tx, Ty, Tz : Double) : TMMatrix; overload;
Begin
     Result := TMMatrix.IMatrix;
     Result[1, 4] := Tx;
     Result[2, 4] := Ty;
     Result[3, 4] := Tz;
End;

Function MoveMatrix(Vector : TMVector) : TMMatrix; overload;
Begin
     Result := TMMatrix.IMatrix;
     Result[1, 4] := Vector.X;
     Result[2, 4] := Vector.Y;
     Result[3, 4] := Vector.Z;
End;

Function ScaleMatrix(Sx, Sy, Sz : Double) : TMMatrix; overload;
Begin
     Result := TMMatrix.IMatrix;
     Result[1, 1] := Sx;
     Result[2, 2] := Sy;
     Result[3, 3] := Sz;
End;

Function ScaleMatrix(ScaleFactor : Double) : TMMatrix; overload;
Begin
     Result := TMMatrix.IMatrix;
     Result[1, 1] := ScaleFactor;
     Result[2, 2] := ScaleFactor;
     Result[3, 3] := ScaleFactor;
End;

Function VectorFromMatrixColumn(Const M : TMMatrix; ColumnIndex : Integer) : TMVector;
Begin
     If (ColumnIndex > 0) and (ColumnIndex < 5) Then
        Result := MVector(M[1, ColumnIndex], M[2, ColumnIndex], M[3, ColumnIndex])
     Else
        Raise Exception.Create('LxWeb.Maths.VectorFromMatrixColumn - Column Index out of bounds');
End;

Function VectorFromMatrixRow(Const M : TMMatrix; RowIndex : Integer) : TMVector;
Begin
     If (RowIndex > 0) and (RowIndex < 5) Then
        Result := MVector(M[RowIndex, 1], M[RowIndex, 2], M[RowIndex, 3])
     Else
        Raise Exception.Create('LxWeb.Maths.VectorFromMatrixRow - Row Index out of bounds');
End;

Procedure Jacobi(VAR a: TMMatrix; n: integer;VAR d: TEigenValues; VAR v: TMMatrix; VAR nrot: integer);
(* Programs using routine JACOBI must define the types
TYPE
   glnpnp = ARRAY [1..np,1..np] OF real;
   glnp = ARRAY [1..np] OF real;
where 'np by np' is the physical dimension of the array
a into which all arrays are loaded for analysis. *)
CONST
   nmax=4;
VAR
   j,iq,ip,i: integer;
   tresh,theta,tau,t,sm,s,h,g,c: double;
   b,z: ARRAY [1..nmax] OF double;
BEGIN
   v := OMatrix;
   FOR ip := 1 TO n DO BEGIN
      FOR iq := 1 TO n DO BEGIN
         v[ip,iq] := 0.0
      END;
      v[ip,ip] := 1.0
   END;
   FOR ip := 1 TO n DO BEGIN
      b[ip] := a[ip,ip];
      d[ip] := b[ip];
      z[ip] := 0.0
   END;
   nrot := 0;
   FOR i := 1 TO 50 DO BEGIN
      sm := 0.0;
      FOR ip := 1 TO n-1 DO BEGIN
         FOR iq := ip+1 TO n DO BEGIN
            sm := sm+abs(a[ip,iq])
         END
      END;
      IF (sm = 0.0) THEN exit;
      IF (i < 4) THEN tresh := 0.2*sm/sqr(n)
      ELSE tresh := 0.0;
      FOR ip := 1 TO n-1 DO BEGIN
         FOR iq := ip+1 TO n DO BEGIN
            g := 100.0*abs(a[ip,iq]);
            IF ((i > 4) AND ((abs(d[ip])+g) = abs(d[ip]))
               AND ((abs(d[iq])+g) = abs(d[iq]))) THEN
               a[ip,iq] := 0.0
            ELSE IF (abs(a[ip,iq]) > tresh) THEN BEGIN
               h := d[iq]-d[ip];
               IF ((abs(h)+g) = abs(h)) THEN BEGIN
                  t := a[ip,iq]/h
               END ELSE BEGIN
                  theta := 0.5*h/a[ip,iq];
                  t := 1.0/(abs(theta)+sqrt(1.0+sqr(theta)));
                  IF (theta < 0.0) THEN t := -t
               END;
               c := 1.0/sqrt(1+sqr(t));
               s := t*c;
               tau := s/(1.0+c);
               h := t*a[ip,iq];
               z[ip] := z[ip]-h;
               z[iq] := z[iq]+h;
               d[ip] := d[ip]-h;
               d[iq] := d[iq]+h;
               a[ip,iq] := 0.0;
               FOR j := 1 TO ip-1 DO BEGIN
                  g := a[j,ip];
                  h := a[j,iq];
                  a[j,ip] := g-s*(h+g*tau);
                  a[j,iq] := h+s*(g-h*tau)
               END;
               FOR j := ip+1 TO iq-1 DO BEGIN
                  g := a[ip,j];
                  h := a[j,iq];
                  a[ip,j] := g-s*(h+g*tau);
                  a[j,iq] := h+s*(g-h*tau)
               END;
               FOR j := iq+1 TO n DO BEGIN
                  g := a[ip,j];
                  h := a[iq,j];
                  a[ip,j] := g-s*(h+g*tau);
                  a[iq,j] := h+s*(g-h*tau)
               END;
               FOR j := 1 TO n DO BEGIN
                  g := v[j,ip];
                  h := v[j,iq];
                  v[j,ip] := g-s*(h+g*tau);
                  v[j,iq] := h+s*(g-h*tau)
               END;
               nrot := nrot+1
            END
         END
      END
   END;
   FOR ip := 1 TO n DO BEGIN
      b[ip] := b[ip]+z[ip];
      d[ip] := b[ip];
      z[ip] := 0.0
   END;

   Raise Exception.Create('Geom.Jacobi - Iterations have exceeded 50, this should not happen');
END;

{$endregion}

{$region '********************************************* TMSize ***************************************************'}

Class Function TMBox.Create : TMBox;
Begin
     Result.MinX := MaxDouble;
     Result.MinY := MaxDouble;
     Result.MinZ := MaxDouble;
     Result.MaxX := -MaxDouble;
     Result.MaxY := -MaxDouble;
     Result.MaxZ := -MaxDouble;
End;

Class Function TMBox.Create(Const AMinX, AMinY, AMinZ, AMaxX, AMaxY, AMaxZ : Double) : TMBox;
Begin
     Result.MinX := AMinX;
     Result.MinY := AMinY;
     Result.MinZ := AMinZ;
     Result.MaxX := AMaxX;
     Result.MaxY := AMaxY;
     Result.MaxZ := AMaxZ;
End;

Procedure TMBox.Clear;
Begin
     MinX := MaxDouble;
     MinY := MaxDouble;
     MinZ := MaxDouble;
     MaxX := -MaxDouble;
     MaxY := -MaxDouble;
     MaxZ := -MaxDouble;
End;

Procedure TMBox.Include(Const AVector : TMVector);
Begin
     If AVector.X < MinX Then MinX := AVector.X;
     If AVector.Y < MinY Then MinY := AVector.Y;
     If AVector.Z < MinZ Then MinZ := AVector.Z;
     If AVector.X > MaxX Then MaxX := AVector.X;
     If AVector.Y > MaxY Then MaxY := AVector.Y;
     If AVector.Z > MaxZ Then MaxZ := AVector.Z;
End;

Procedure TMBox.Include(Const ASize : TMBox);
Begin
     If ASize.MinX < MinX Then MinX := ASize.MinX;
     If ASize.MinY < MinY Then MinY := ASize.MinY;
     If ASize.MinZ < MinZ Then MinZ := ASize.MinZ;
     If ASize.MaxX > MaxX Then MaxX := ASize.MaxX;
     If ASize.MaxY > MaxY Then MaxY := ASize.MaxY;
     If ASize.MaxZ > MaxZ Then MaxZ := ASize.MaxZ;
End;

Function TMBox.Centre : TMVector;
Begin
     Result.X := (MinX + MaxX) / 2;
     Result.Y := (MinY + MaxY) / 2;
     Result.Z := (MinZ + MaxZ) / 2;
End;

Function TMBox.MinPoint : TMVector;
Begin
     Result.X := MinX;
     Result.Y := MinY;
     Result.Z := MinZ;
End;

Function TMBox.MaxPoint : TMVector;
Begin
     Result.X := MaxX;
     Result.Y := MaxY;
     Result.Z := MaxZ;
End;

Function TMBox.MaxDimension : Double;
Var
   ALength, AWidth, AHeight : Double;
Begin
     ALength := Abs(MaxX - MinX);
     AWidth := Abs(MaxY - MinY);
     AHeight := Abs(MaxZ - MinZ);

     If (ALength > AWidth) and (ALength > AHeight) Then
        Result := ALength
     Else If AWidth > AHeight Then
        Result := AWidth
     Else
        Result := AHeight;
End;


Function TMBox.Length : Double;
Begin
     Result := Abs(MaxX - MinX);
End;

Function TMBox.Width : Double;
Begin
     Result := Abs(MaxY - MinY);
End;

Function TMBox.Height : Double;
Begin
     Result := Abs(MaxZ - MinZ);
End;

{$endregion}

{$region '********************************************* TMPlane ***************************************************'}

Function MPlane(Const PlaneType : TOrthogonalPlane; Const Offset : Double = 0) : TMPlane;
Begin
     Case PlaneType of
          opXY, opZn : Result := MPlaneZ;
          opYZ, opXn : Result := MPlaneX;
          opZX, opYn : Result := MPlaneY;
          Else Raise Exception.Create('MPlane: Bad Plane Direction Requested (opXYZ)');
          End;
     Result.Offset := Offset;
End;

Function MPlane(Const PlaneType : TOrthogonalPlane; Const Position : TMVector) : TMPlane;
Begin
     Case PlaneType of
          opXY, opZn : Result := MPlaneZ;
          opYZ, opXn : Result := MPlaneX;
          opZX, opYn : Result := MPlaneY;
          Else Raise Exception.Create('MPlane: Bad Plane Direction Requested (opXYZ)');
          End;
     Result.Offset := VectorDotProduct(Result.Normal, Position);
End;

Function MPlane(Const Normal, Position : TMVector) : TMPlane;
Begin
     Result.Normal := VectorNormalise(Normal);
     Result.Offset := VectorDotProduct(Result.Normal, Position);
End;

Function MPlane(Const Normal : TMVector; Offset : Double) : TMPlane; overload;
Begin
     Result.Normal := VectorNormalise(Normal);
     Result.Offset := Offset;
End;

Function MPlane(Const Normal : TMVector) : TMPlane;
Begin
     Result.Normal := VectorNormalise(Normal);
     Result.Offset := 0
End;

Procedure StandardisePlaneOrientation(Var APlane : TMPlane);
Begin
     If (APlane.Normal.Z < 0) or ((APlane.Normal.Z = 0) and (APlane.Normal.Y < 0)) Then Begin
        APlane.Normal := VectorNegative(APlane.Normal);
        APlane.Offset := -APlane.Offset;
        End;
End;

Function PlaneThrough3Points(Const P1, P2, P3 : TMVector) : TMPlane;
Var
   V1, V2 : TMVector;
Begin
     V1 := VectorSubtract(P1, P2);
     V2 := VectorSubtract(P1, P3);
     Result.Normal := VectorNormalise(VectorCrossProduct(V1, V2));
     Result.Offset := VectorDotProduct(Result.Normal, P1);
End;

Function PlaneFromTriangle(Const Triangle : TMTriangle) : TMPlane;
Begin
     With Triangle Do
          Result := PlaneThrough3Points(P1, P2, P3);
End;

Function PlaneThrough2PointsAndVector(Const P1, P2, Axis : TMVector) : TMPlane;
Begin
     Result := PlaneThrough3Points(P1, VectorAdd(P1, Axis), P2);
End;

Function OffsetPlane(APlane : TMPlane; Distance : Double) : TMPlane;
Begin
     Result.Normal := APlane.Normal;
     Result.Offset := APlane.Offset + Distance / VectorLength(Result.Normal);
End;

Function SamePlane(Const APlane1, APlane2 : TMPlane) : Boolean; overload;
Begin
     Result := SamePlane(APlane1, APlane2, SameVectorTolerance);
End;

Function SamePlane(Const APlane1, APlane2 : TMPlane; ATolerance : Double) : Boolean; overload;
Var
   TempPlane : TMPlane;
Begin
     If SameVector(APlane1.Normal, APlane2.Normal, ATolerance) and (Abs(APlane1.Offset - APlane2.Offset) < ATolerance) Then
        Result := True
     Else Begin
        // Check the negative version of the plane
        TempPlane.Normal := VectorNegative(APlane2.Normal);
        TempPlane.Offset := -APlane2.Offset;

        Result := SameVector(APlane1.Normal, TempPlane.Normal, ATolerance) and (Abs(APlane1.Offset - TempPlane.Offset) < ATolerance);
        End;
End;

Function EqualPlanes(Const APlane1, APlane2 : TMPlane) : Boolean;
Begin
     Result := (APlane1.Offset = APlane2.Offset) and EqualVectors(APlane1.Normal, APlane2.Normal);
End;

Function PointOnWhichPlaneSide(Const Plane : TMPlane; Point : TMVector; Tolerance : Double = 0) : Integer;
Var
   Dot : Double;
Begin
     Dot := VectorDotProduct(Plane.Normal, Point) - Plane.Offset;
     If (Dot = 0) or (Abs(Dot) < Tolerance) Then
        // When Tolerance = 0 and Dot = 0, Dot < Tolerance never happens!
        Result := 0
     Else If Dot > Tolerance Then
        Result := 1
     Else
        Result := -1;
End;

Function DistancefromPlaneToPoint(Const Plane : TMPlane; Point : TMVector) : Double;
Begin
     Result := Abs(Plane.Offset - VectorDotProduct(Plane.Normal, Point)) / VectorLength(Plane.Normal);
End;

Function NearestPointOnPlaneToPoint(Const Plane : TMPlane; Point : TMVector) : TMVector;
// Graphics Gems - Andrew S. Glassner
Begin
     //Result := P - ((Joffset + Jn . P) / (Jn . Jn)) * Jn;
     // Remember that the order of VectorSubtract is reversed
//     Result := VectorSubtract(VectorScale( (Plane.Offset + VectorDotProduct(Plane.Normal, Point)) / VectorDotProduct(Plane.Normal, Plane.Normal), Plane.Normal), Point);
     Result := VectorAdd(Point, VectorScale( (Plane.Offset - VectorDotProduct(Plane.Normal, Point)) / VectorDotProduct(Plane.Normal, Plane.Normal), Plane.Normal));
End;

Function NearestPointOnPlaneToOrigin(Const Plane : TMPlane) : TMVector;
// Graphics Gems - Andrew S. Glassner
Begin
     Result := NearestPointOnPlaneToPoint(Plane, MVectorOrigin);
End;

Function NearestPointOnTriangleToPoint(Const APoint, P1, P2, P3 : TMVector; Out s, t : Double) : TMVector; overload;
// Geometric Tools for Computer Graphics, Schneider and Eberly 2003, Page 380
Var
   BP, e0, e1 : TMVector;
   a, b, c, d, e, det, numer, denom, tmp0, tmp1 : Double;
Begin
     BP := VectorSubtract(APoint, P1);
     e0 := VectorSubtract(P1, P2);
     e1 := VectorSubtract(P1, P3);

     a := VectorDotProduct(e0, e0);
     b := VectorDotProduct(e0, e1);
     c := VectorDotProduct(e1, e1);
     d := VectorDotProduct(e0, BP);
     e := VectorDotProduct(e1, BP);

     det := a * c - b * b;
       s := b * e - c * d;
       t := b * d - a * e;

     If s + t <= det Then Begin
        If s < 0 Then Begin
           If t < 0 Then Begin // Region 4
              if d < 0 Then Begin
                 t := 0;
                 if d >= a Then
                    s := 0
                 else
                    s := -d / a;
                 End
              Else Begin
                 s := 0;
                 if e >= 0 then
                    t := 0
                 else if -e >= c Then
                    t := 1
                 else
                    t := -e / c;
                 End;
              End
           Else Begin // region 3
              // F(t) = Q(0, t) = ct^2 + et + f
              // F'(t)/2 = ct + e
              // F'(t) = 0 when t = -e/c
              s := 0;
              If e >= 0 Then
                 t := 0
              Else Begin
                 If -e >= c Then
                    t := 1
                 Else
                    t := -e / c;
                 End;
              End;
           End
        Else Begin
           If t < 0 Then Begin // Region 5
              t := 0;
              If d >= 0 Then
                 s := 0
              Else Begin
                 If -d >= a Then
                    s := 1
                 Else
                    s := -d / a;
                 End;
              End
           Else Begin // Region 0
              s := s / det;
              t := t / det;
              End;
           End;
        End
     Else Begin
        If s < 0 Then Begin // region 2
           // Grad(Q) = 2(as+bt+d,bs+ct+e)
           // (0,-1)*Grad(Q(0,1)) = (0,-1)*(b+d,c+e) = -(c+e)
           // (1,-1)*Grad(Q(0,1)) = (1,-1)*(b+d,c+e) = (b+d)-(c+e)
           // min on edge s+t=1 if (1,-1)*Grad(Q(0,1)) > 0 )
           // min on edge s=0 otherwise

           tmp0 := b + d;
           tmp1 := c + e;
           If tmp1 > tmp0 Then Begin // minimum on edge s+t=1
              numer := tmp1 - tmp0;
              denom := a - 2 * b + c;
              if numer >= denom Then s := 1 else s := numer / denom;
              t := 1 - s;
              End
           Else Begin
              s := 0;
              if tmp1 <= 0 Then
                 t := 1
              Else Begin
                 if e >= 0 Then
                    t := 0
                 else
                    t := -e / c;
                 End;
              End;
           End
        else If t < 0 Then Begin // Region 6
           tmp0 := b + e;
           tmp1 := a + d;

           If tmp1 > tmp0 Then Begin // minimum on edge s+t=1
              numer := tmp1 - tmp0;
              denom := a - 2 * b + c;
              if numer >= denom Then t := 0 else t := numer / denom;
              s := 1 - s;
              End
           Else Begin
              t := 0;
              if tmp1 <= 0 Then
                 s := 1
              Else Begin
                 if e >= 0 Then
                    s := 0
                 else
                    s := -d / a;
                 End;
              End;
           End
        Else Begin // region 1
           // F(s) = Q(s, 1-s) = (a - 2b + c)s^2 + 2(2(b - c + d - e)s + (c + 2e + f)
           // F'(s)/2 = (a - 2b + c)s + (b - c + d - e)
           // F'(s) = 0 when s = (c + e - b - d)/(a - 2b + c)
           // a - 2b + c = |e0 - e1|^2 > 0,
           // so only the sign of c + e - b - d need be considered

           numer := c + e - b - d;
           if numer <= 0 Then
              s := 0
           Else Begin
              denom := a - 2 * b + c;
              If numer >= denom Then s := 1 else s := numer / denom;
              End;

           t := 1 - s;
           End
        End;

     Result := VectorAdd(P1, VectorAdd(VectorScale(s, e0), VectorScale(t, e1)));
End;

Function NearestPointOnTriangleToPoint(Const APoint, P1, P2, P3 : TMVector) : TMVector; overload;
Var
   S, T : Double;
Begin
     Result := NearestPointOnTriangleToPoint(APoint, P1, P2, P3, s, t);
End;

Function IntersectionOfLineandPlaneInfo(Const Line : TMVectorLine; Const Plane : TMPlane) : TLineProximatyResult;
// Graphics Gems - Andrew S. Glassner
Var
   Denom : Extended;
Begin
//     t := -(Plane.Offset + VectorDotProduct(Line.Base, Plane.Normal)) / VectorDotProduct(Line.Direction, Plane.Normal);
     Denom := VectorDotProduct(Line.Direction, Plane.Normal);
     If Abs(Denom) < ASmallNumber Then Begin
        Result.LineAndPlaneParallel := True;
        Exit;
        End
     Else
        Result.T := (Plane.Offset - VectorDotProduct(Line.Base, Plane.Normal)) / Denom;
     Result.Point := VectorAdd(Line.Base, VectorScale(Result.t, Line.Direction));

     If Abs(VectorDotProduct(Plane.Normal, Result.Point) - Plane.Offset) > ASmallNumber Then Begin
        // Result was inaccurate, probably due to large and small number mismatch, recalculate
        // with result as base
        Result.T := (Plane.Offset - VectorDotProduct(Result.Point, Plane.Normal)) / Denom;
        Result.Point := VectorAdd(Result.Point, VectorScale(Result.T, Line.Direction));
        End;

     Result.LineAndPlaneParallel := False;
End;

Function IntersectionOfLineandPlane(Const Line : TMVectorLine; Const Plane : TMPlane) : TMVector;
Var
   Info : TLineProximatyResult;
Begin
     Info := IntersectionOfLineandPlaneInfo(Line, Plane);

     If Info.LineAndPlaneParallel Then
        Raise Exception.Create('LxWeb.Maths - IntersectionOfLineAndPlane: Intersection attempted with a line parallel to plane');

     Result := Info.Point;
End;

Function IntersectionOfPointsandPlane(Const Point1, Point2 : TMVector; Const Plane : TMPlane) : TMVector;
Var
   LBase, LDirection : TMVector;
   t, Denom : Extended;
Begin
     LBase := Point1;
     LDirection := VectorNormalise(VectorSubtract(Point1, Point2));

     Denom := VectorDotProduct(LDirection, Plane.Normal);
     If Abs(Denom) < ASmallNumber Then
        Raise Exception.Create('LxWeb.Maths - IntersectionOfPointsAndPlane: Intersection attempted with a line parallel to plane')
     Else
        t := (Plane.Offset - VectorDotProduct(LBase, Plane.Normal)) / Denom;
     Result := VectorAdd(LBase, VectorScale(t, LDirection));

     If Abs(VectorDotProduct(Plane.Normal, Result) - Plane.Offset) > ASmallNumber Then Begin
        // Result was inaccurate, probably due to large and small number mismatch, recalculate
        // with result as base
        t := (Plane.Offset - VectorDotProduct(Result, Plane.Normal)) / Denom;
        Result := VectorAdd(Result, VectorScale(t, LDirection));
        End;
End;

Function IntersectionOfTwoPlanes(Const Plane1, Plane2 : TMPlane) : TMVectorLine;
// Geometric Tools for Computer Graphics, Page 531
Var
   s1, s2, a, b, n1n2dot, n1normsqr, n2normsqr : Double;
Begin
     Result.Direction := VectorNormalise(VectorCrossProduct(Plane1.Normal, Plane2.Normal));
     If VectorLength(Result.Direction) > 0 Then Begin
        s1 := Plane1.Offset;
        s2 := Plane2.Offset;

        n1n2dot := VectorDotProduct(Plane1.Normal, Plane2.Normal);
        n1normsqr := VectorDotProduct(Plane1.Normal, Plane1.Normal);
        n2normsqr := VectorDotProduct(Plane2.Normal, Plane2.Normal);
        a := (s2 * n1n2dot - s1 * n2normsqr) / (sqr(n1n2dot) - n1normsqr * n2normsqr);
        b := (s1 * n1n2dot - s2 * n2normsqr) / (sqr(n1n2dot) - n1normsqr * n2normsqr);
        Result.Base := VectorAdd(VectorScale(a, Plane1.Normal), VectorScale(b, Plane2.Normal));
        End;
End;

Function IntersectionOfThreePlanes(Const Plane1, Plane2, Plane3 : TMPlane) : TMVector;
Var
   M : TMMatrix;
   Det : Double;
   V1, V2, V3 : TMVector;
Begin
     M := ColumnsMatrix(Plane1.Normal, Plane2.Normal, Plane3.Normal, MVectorOrigin);
     Det := M[1, 1] * (M[2, 2] * M[3, 3] - M[3, 2] * M[2, 3]) - M[1, 2] * (M[2, 1] * M[3, 3] - M[3, 1] * M[2, 3]) + M[1, 3] * (M[2, 1] * M[3, 2] - M[3, 1] * M[2, 2]);

     If Abs(Det) > ASmallNumber Then Begin
        V1 := VectorScale(Plane1.Offset, VectorCrossProduct(Plane3.Normal, Plane2.Normal));
        V2 := VectorScale(Plane2.Offset, VectorCrossProduct(Plane1.Normal, Plane3.Normal));
        V3 := VectorScale(Plane3.Offset, VectorCrossProduct(Plane2.Normal, Plane1.Normal));

        Result := VectorScale(1 / Det, VectorAdd(V1, VectorAdd(V2, V3)));
        End
     Else
     Raise Exception.Create('LxWeb.Maths.IntersectionOfThreePlanes - Two/Three planes are parallel');
End;

Function IntersectionOfLineAndTriangle(ALine : TMVectorLine; P1, P2, P3 : TMVector) : Boolean; overload;
// LineTriangleIntersection, Taken from Geometric Tools for Computer Graphics, Schneider and Eberly 2003, Page 487
Var
   e1, e2, p, s, q : TMVector;
   tmp, u, v : Double;
Begin
     // Does not cull backfacing triangles
     e1 := VectorSubtract(P1, P2);
     e2 := VectorSubtract(P1, P3);
     p := VectorCrossProduct(ALine.Direction, e2); //!!
     tmp := VectorDotProduct(p, e1);

     If (tmp > -ASmallNumber) and (tmp < ASmallNumber) Then Begin
        Result := False;
        Exit;
        End;

     tmp := 1 / tmp;
     s := VectorSubtract(p1, Aline.Base);
     u := tmp * VectorDotProduct(s, p);
     if (u < 0) or (u > 1) Then Begin
        Result := False;
        Exit;
        End;

     q := VectorCrossProduct(s, e1);
     v := tmp * VectorDotProduct(ALine.Direction, q);
     if (v < 0) or (v > 1) Then Begin
        Result := False;
        Exit;
        End;

     if (u + v > 1) Then Begin
        Result := False;
        Exit;
        End;

     Result := True;
End;

Function IntersectionOfLineAndTriangle(ALine : TMVectorLine; P1, P2, P3 : TMVector; Out Intersection : TMVector) : Boolean; overload;
// LineTriangleIntersection, Taken from Geometric Tools for Computer Graphics, Schneider and Eberly 2003, Page 487
Var
   e1, e2, p, s, q : TMVector;
   tmp, u, v, t : Double;
Begin
     // Does not cull backfacing triangles
     e1 := VectorSubtract(P1, P2);
     e2 := VectorSubtract(P1, P3);
     p := VectorCrossProduct(ALine.Direction, e2); //!!
     tmp := VectorDotProduct(p, e1);

     If (tmp > -ASmallNumber) and (tmp < ASmallNumber) Then Begin
        Result := False;
        Exit;
        End;

     tmp := 1 / tmp;
     s := VectorSubtract(p1, Aline.Base);
     u := tmp * VectorDotProduct(s, p);
     if (u < 0) or (u > 1) Then Begin
        Result := False;
        Exit;
        End;

     q := VectorCrossProduct(s, e1);
     v := tmp * VectorDotProduct(ALine.Direction, q);
     if (v < 0) or (v > 1) Then Begin
        Result := False;
        Intersection := MVectorOrigin;
        Exit;
        End;

     if (u + v > 1) Then Begin
        Result := False;
        Intersection := MVectorOrigin;
        Exit;
        End;

     t := tmp * VectorDotProduct(e2, q);
     Intersection := VectorLine(Aline, t);

     Result := True;
End;

Function IntersectionOfLineAndTriangle(ALine : TMVectorLine; P1, P2, P3 : TMVector; Out T : Double) : Boolean; overload;
// LineTriangleIntersection, Taken from Geometric Tools for Computer Graphics, Schneider and Eberly 2003, Page 487
Var
   e1, e2, p, s, q : TMVector;
   tmp, u, v : Double;
Begin
     // Does not cull backfacing triangles
     e1 := VectorSubtract(P1, P2);
     e2 := VectorSubtract(P1, P3);
     p := VectorCrossProduct(ALine.Direction, e2); //!!
     tmp := VectorDotProduct(p, e1);

     If (tmp > -ASmallNumber) and (tmp < ASmallNumber) Then Begin
        Result := False;
        Exit;
        End;

     tmp := 1 / tmp;
     s := VectorSubtract(p1, Aline.Base);
     u := tmp * VectorDotProduct(s, p);
     if (u < 0) or (u > 1) Then Begin
        Result := False;
        Exit;
        End;

     q := VectorCrossProduct(s, e1);
     v := tmp * VectorDotProduct(ALine.Direction, q);
     if (v < 0) or (v > 1) Then Begin
        Result := False;
        T := 0;
        Exit;
        End;

     if (u + v > 1) Then Begin
        Result := False;
        T := 0;
        Exit;
        End;

     T := tmp * VectorDotProduct(e2, q);
     Result := True;
End;

Function IntersectionOfLineAndTriangle(ALine : TMVectorLine; P1, P2, P3 : TMVector; Out Intersection : TMVector; Out T : Double) : Boolean; overload;
// LineTriangleIntersection, Taken from Geometric Tools for Computer Graphics, Schneider and Eberly 2003, Page 487
Var
   e1, e2, p, s, q : TMVector;
   tmp, u, v : Double;
Begin
     // Does not cull backfacing triangles
     e1 := VectorSubtract(P1, P2);
     e2 := VectorSubtract(P1, P3);
     p := VectorCrossProduct(ALine.Direction, e2); //!!
     tmp := VectorDotProduct(p, e1);

     If (tmp > -ASmallNumber) and (tmp < ASmallNumber) Then Begin
        Result := False;
        Exit;
        End;

     tmp := 1 / tmp;
     s := VectorSubtract(p1, Aline.Base);
     u := tmp * VectorDotProduct(s, p);
     if (u < 0) or (u > 1) Then Begin
        Result := False;
        Exit;
        End;

     q := VectorCrossProduct(s, e1);
     v := tmp * VectorDotProduct(ALine.Direction, q);
     if (v < 0) or (v > 1) Then Begin
        Result := False;
        Intersection := MVectorOrigin;
        Exit;
        End;

     if (u + v > 1) Then Begin
        Result := False;
        Intersection := MVectorOrigin;
        Exit;
        End;

     T := tmp * VectorDotProduct(e2, q);
     Intersection := VectorLine(Aline, t);

     Result := True;
End;


Function IntersectionOfPlaneAndTriangle(Const APlane : TMPlane; Const P1, P2, P3 : TMVector) : Boolean; overload;
Begin
     Result := Abs(PointOnWhichPlaneSide(APlane, P1) + PointOnWhichPlaneSide(APlane, P2) + PointOnWhichPlaneSide(APlane, P3)) < 3;
End;

Function IntersectionOfPlaneAndTriangle(Const APlane : TMPlane; Const ATriangle : TMTriangle) : Boolean; overload;
Begin
     With ATriangle Do
          Result := IntersectionOfPlaneAndTriangle(APlane, P1, P2, P3);
End;

Function IntersectionOfPlaneAndTriangle(Const APlane : TMPlane; Const P1, P2, P3 : TMVector; Out Line : TMSegment) : TPlaneAndTriangleIntersection; overload;
Var
   T, Coord : Double;
   Side1, Side2, Side3, EndPoints : Integer;
   V : TMVector;
Begin
     Result := tpiNoIntersection;

// ***** Intersection in the X Plane **********************************************************************************

     If (APlane.Normal.Y = 0) and (APlane.Normal.Z = 0) Then Begin // Intersect triangle using optimised approach
        EndPoints := 0;
        Coord := APlane.Normal.X * APlane.Offset;

        // Check for coplaner polygon
        If (P1.X = P2.X) and (P1.X = P3.X) Then Begin
           If P1.X = Coord Then Result := tpiPlaneIntersection Else Result := tpiNoIntersection;
           Line.P1 := MVectorOrigin;
           Line.P2 := MVectorOrigin;
           Exit;
           End;

        // Check the intersection of each vertex
        If P1.X = Coord Then Begin
           Line.P1 := P1;
           Inc(EndPoints);
           End;

        If P2.X = Coord Then Begin
           If EndPoints = 1 Then Begin
              Line.P2 := P2;
              Result := tpiEdgeIntersection;
              Exit;
              End
           Else Begin
              Line.P1 := P2;
              Inc(EndPoints);
              End;
           End;

        If P3.X = Coord Then Begin
           If EndPoints = 1 Then Begin
              Line.P2 := P3;
              Result := tpiEdgeIntersection;
              Exit;
              End
           Else Begin
              Line.P1 := P3;
              Inc(EndPoints);
              End;
           End;

        // Go through each of the lines, disregarding any which have a vertex on the plane
        If InInterval(P1.X, P2.X, Coord) and Not((P1.X = Coord) or (P2.X = Coord)) Then Begin
           T := (Coord - P1.X) / (P2.X - P1.X);
           V.X := Coord;
           V.Y := T * (P2.Y - P1.Y) + P1.Y;
           V.Z := T * (P2.Z - P1.Z) + P1.Z;

           If EndPoints = 1 Then Begin
              Line.P2 := V;
              Result := tpiEdgeIntersection;
              Exit;
              End
           Else Begin
              Line.P1 := V;
              Inc(EndPoints);
              End;
           End;


        If InInterval(P2.X, P3.X, Coord) and Not((P2.X = Coord) and (P3.X = Coord)) Then Begin
           T := (Coord - P2.X) / (P3.X - P2.X);
           V.X := Coord;
           V.Y := T * (P3.Y - P2.Y) + P2.Y;
           V.Z := T * (P3.Z - P2.Z) + P2.Z;

           If EndPoints = 1 Then Begin
              Line.P2 := V;
              Result := tpiEdgeIntersection;
              Exit;
              End
           Else Begin
              Line.P1 := V;
              Inc(EndPoints);
              End;
           End;

        If InInterval(P3.X, P1.X, Coord) and Not((P3.X = Coord) and (P1.X = Coord))Then Begin
           T := (Coord - P3.X) / (P1.X - P3.X);
           V.X := Coord;
           V.Y := T * (P1.Y - P3.Y) + P3.Y;
           V.Z := T * (P1.Z - P3.Z) + P3.Z;

           If EndPoints = 1 Then Begin
              Line.P2 := V;
              Result := tpiEdgeIntersection;
              Exit;
              End
           Else Begin
              Line.P1 := V;
              Inc(EndPoints);
              End;
           End;

        If EndPoints = 1 Then Begin
           Result := tpiPointIntersection;
           Line.P2 := Line.P1;
           End
        Else If EndPoints = 0 Then Begin
           Result := tpiNoIntersection;
           Line.P1 := MVectorOrigin;
           Line.P2 := MVectorOrigin;
           End;
        End

// ***** Intersection in the Y Plane **********************************************************************************

     Else If (APlane.Normal.X = 0) and (APlane.Normal.Z = 0) Then Begin // Intersect triangle using optimised approach
        EndPoints := 0;
        Coord := APlane.Normal.Y * APlane.Offset;

        // Check for coplaner polygon
        If (P1.Y = P2.Y) and (P1.Y = P3.Y) Then Begin
           If P1.X = Coord Then Result := tpiPlaneIntersection Else Result := tpiNoIntersection;
           Line.P1 := MVectorOrigin;
           Line.P2 := MVectorOrigin;
           Exit;
           End;

        // Check the intersection of each vertex
        If P1.Y = Coord Then Begin
           Line.P1 := P1;
           Inc(EndPoints);
           End;

        If P2.Y = Coord Then Begin
           If EndPoints = 1 Then Begin
              Line.P2 := P2;
              Result := tpiEdgeIntersection;
              Exit;
              End
           Else Begin
              Line.P1 := P2;
              Inc(EndPoints);
              End;
           End;

        If P3.Y = Coord Then Begin
           If EndPoints = 1 Then Begin
              Line.P2 := P3;
              Result := tpiEdgeIntersection;
              Exit;
              End
           Else Begin
              Line.P1 := P3;
              Inc(EndPoints);
              End;
           End;

        // Go through each of the lines, disregarding any which have a vertex on the plane
        If InInterval(P1.Y, P2.Y, Coord) and Not((P1.Y = Coord) or (P2.Y = Coord)) Then Begin
           T := (Coord - P1.Y) / (P2.Y - P1.Y);
           V.X := T * (P2.X - P1.X) + P1.X;
           V.Y := Coord;
           V.Z := T * (P2.Z - P1.Z) + P1.Z;

           If EndPoints = 1 Then Begin
              Line.P2 := V;
              Result := tpiEdgeIntersection;
              Exit;
              End
           Else Begin
              Line.P1 := V;
              Inc(EndPoints);
              End;
           End;


        If InInterval(P2.Y, P3.Y, Coord) and Not((P2.Y = Coord) and (P3.Y = Coord)) Then Begin
           T := (Coord - P2.Y) / (P3.Y - P2.Y);
           V.X := T * (P3.X - P2.X) + P2.X;
           V.Y := Coord;
           V.Z := T * (P3.Z - P2.Z) + P2.Z;

           If EndPoints = 1 Then Begin
              Line.P2 := V;
              Result := tpiEdgeIntersection;
              Exit;
              End
           Else Begin
              Line.P1 := V;
              Inc(EndPoints);
              End;
           End;

        If InInterval(P3.Y, P1.Y, Coord) and Not((P3.Y = Coord) and (P1.Y = Coord))Then Begin
           T := (Coord - P3.Y) / (P1.Y - P3.Y);
           V.X := T * (P1.X - P3.X) + P3.X;
           V.Y := Coord;
           V.Z := T * (P1.Z - P3.Z) + P3.Z;

           If EndPoints = 1 Then Begin
              Line.P2 := V;
              Result := tpiEdgeIntersection;
              Exit;
              End
           Else Begin
              Line.P1 := V;
              Inc(EndPoints);
              End;
           End;

        If EndPoints = 1 Then Begin
           Result := tpiPointIntersection;
           Line.P2 := Line.P1;
           End
        Else If EndPoints = 0 Then Begin
           Result := tpiNoIntersection;
           Line.P1 := MVectorOrigin;
           Line.P2 := MVectorOrigin;
           End;
        End

// ***** Intersection in the Z Plane **********************************************************************************

     Else If (APlane.Normal.X = 0) and (APlane.Normal.Y = 0) Then Begin // Intersect triangle using optimised approach
        EndPoints := 0;
        Coord := APlane.Normal.Z * APlane.Offset;

        // Check for coplaner polygon
        If (P1.Z = P2.Z) and (P1.Z = P3.Z) Then Begin
           If P1.X = Coord Then Result := tpiPlaneIntersection Else Result := tpiNoIntersection;
           Line.P1 := MVectorOrigin;
           Line.P2 := MVectorOrigin;
           Exit;
           End;

        // Check the intersection of each vertex
        If P1.Z = Coord Then Begin
           Line.P1 := P1;
           Inc(EndPoints);
           End;

        If P2.Z = Coord Then Begin
           If EndPoints = 1 Then Begin
              Line.P2 := P2;
              Result := tpiEdgeIntersection;
              Exit;
              End
           Else Begin
              Line.P1 := P2;
              Inc(EndPoints);
              End;
           End;

        If P3.Z = Coord Then Begin
           If EndPoints = 1 Then Begin
              Line.P2 := P3;
              Result := tpiEdgeIntersection;
              Exit;
              End
           Else Begin
              Line.P1 := P3;
              Inc(EndPoints);
              End;
           End;

        // Go through each of the lines, disregarding any which have a vertex on the plane
        If InInterval(P1.Z, P2.Z, Coord) and Not((P1.Z = Coord) or (P2.Z = Coord)) Then Begin
           T := (Coord - P1.Z) / (P2.Z - P1.Z);
           V.X := T * (P2.X - P1.X) + P1.X;
           V.Y := T * (P2.Y - P1.Y) + P1.Y;
           V.Z := Coord;

           If EndPoints = 1 Then Begin
              Line.P2 := V;
              Result := tpiEdgeIntersection;
              Exit;
              End
           Else Begin
              Line.P1 := V;
              Inc(EndPoints);
              End;
           End;


        If InInterval(P2.Z, P3.Z, Coord) and Not((P2.Z = Coord) and (P3.Z = Coord)) Then Begin
           T := (Coord - P2.Z) / (P3.Z - P2.Z);
           V.X := T * (P3.X - P2.X) + P2.X;
           V.Y := T * (P3.Y - P2.Y) + P2.Y;
           V.Z := Coord;

           If EndPoints = 1 Then Begin
              Line.P2 := V;
              Result := tpiEdgeIntersection;
              Exit;
              End
           Else Begin
              Line.P1 := V;
              Inc(EndPoints);
              End;
           End;

        If InInterval(P3.Z, P1.Z, Coord) and Not((P3.Z = Coord) and (P1.Z = Coord))Then Begin
           T := (Coord - P3.Z) / (P1.Z - P3.Z);
           V.X := T * (P1.X - P3.X) + P3.X;
           V.Y := T * (P1.Y - P3.Y) + P3.Y;
           V.Z := Coord;

           If EndPoints = 1 Then Begin
              Line.P2 := V;
              Result := tpiEdgeIntersection;
              Exit;
              End
           Else Begin
              Line.P1 := V;
              Inc(EndPoints);
              End;
           End;

        If EndPoints = 1 Then Begin
           Result := tpiPointIntersection;
           Line.P2 := Line.P1;
           End
        Else If EndPoints = 0 Then Begin
           Result := tpiNoIntersection;
           Line.P1 := MVectorOrigin;
           Line.P2 := MVectorOrigin;
           End;
        End

// ***** Arbitrary in Plane **********************************************************************************

     Else Begin
          Side1 := PointOnWhichPlaneSide(APlane, P1, ExactVectorTolerance);
          Side2 := PointOnWhichPlaneSide(APlane, P2, ExactVectorTolerance);
          Side3 := PointOnWhichPlaneSide(APlane, P3, ExactVectorTolerance);

          // No Intersection
          If Abs(Side1 + Side2 + Side3) = 3 Then Begin
             Result := tpiNoIntersection;
             Line.P1 := MVectorOrigin;
             Line.P2 := MVectorOrigin;
             End

          // Plane Intersection - all points on the plane
          Else if (Side1 = 0) and (Side2 = 0) and (Side3 = 0) Then Begin
             Result := tpiPlaneIntersection;
             Line.P1 := MVectorOrigin;
             Line.P2 := MVectorOrigin;
             End

          // Common Intersection - Plane intersects with two of the edges
          Else If ((Side1 = 1) and (Side2 = 1) and (Side3 = -1)) or ((Side1 = -1) and (Side2 = -1) and (Side3 = 1)) Then Begin
             Result := tpiLineIntersection;
             Line.P1 := IntersectionOfPointsandPlane(P1, P3, APlane);
             Line.P2 := IntersectionOfPointsandPlane(P2, P3, APlane);
             End
          Else If ((Side2 = 1) and (Side3 = 1) and (Side1 = -1)) or ((Side2 = -1) and (Side3 = -1) and (Side1 = 1)) Then Begin
             Result := tpiLineIntersection;
             Line.P1 := IntersectionOfPointsandPlane(P2, P1, APlane);
             Line.P2 := IntersectionOfPointsandPlane(P3, P1, APlane);
             End
          Else If ((Side3 = 1) and (Side1 = 1) and (Side2 = -1)) or ((Side3 = -1) and (Side1 = -1) and (Side2 = 1)) Then Begin
             Result := tpiLineIntersection;
             Line.P1 := IntersectionOfPointsandPlane(P1, P2, APlane);
             Line.P2 := IntersectionOfPointsandPlane(P3, P2, APlane);
             End

          // One Point on the plane
          Else If (Side1 = 0) Then Begin
             If (Side2 = 0) Then Begin
                // Two Points on the plane
                Result := tpiEdgeIntersection;
                Line.P1 := P1;
                Line.P2 := P2;
                End
             Else If (Side3 = 0) Then Begin
                // Two Points on the plane
                Result := tpiEdgeIntersection;
                Line.P1 := P1;
                Line.P2 := P3;
                End
             Else If (((Side2 = 1) and (Side3 = 1)) or ((Side2 = -1) and (Side3 = -1))) Then Begin
                // One Points on the plane
                Result := tpiPointIntersection;
                Line.P1 := P1;
                Line.P2 := P1;
                End
             Else Begin
                // fully intersecting
                Result := tpiLineIntersection;
                Line.P1 := P1;
                Line.P2 := IntersectionOfPointsandPlane(P2, P3, APlane);
                End;
             End
          Else If (Side2 = 0) Then Begin
             // Aready done side 2
             If (Side3 = 0) Then Begin
                // Two Points on the plane
                Result := tpiEdgeIntersection;
                Line.P1 := P2;
                Line.P2 := P3;
                End
             Else If (((Side1 = 1) and (Side3 = 1)) or ((Side1 = -1) and (Side3 = -1))) Then Begin
                // One Points on the plane
                Result := tpiPointIntersection;
                Line.P1 := P2;
                Line.P2 := P2;
                End
             Else Begin
                // fully intersecting
                Result := tpiLineIntersection;
                Line.P1 := P2;
                Line.P2 := IntersectionOfPointsandPlane(P1, P3, APlane);
                End;
             End
          Else If (Side3 = 0) Then Begin
             // Aready done side 1 and 2
             If (((Side1 = 1) and (Side2 = 1)) or ((Side1 = -1) and (Side2 = -1))) Then Begin
                // One Points on the plane
                Result := tpiPointIntersection;
                Line.P1 := P3;
                Line.P2 := P3;
                End
             Else Begin
                // fully intersecting
                Result := tpiLineIntersection;
                Line.P1 := P3;
                Line.P2 := IntersectionOfPointsandPlane(P1, P2, APlane);
                End;
             End
          Else Begin
             Result := tpiNoIntersection;
             Line.P1 := MVectorOrigin;
             Line.P2 := MVectorOrigin;
             End;
          End;
End;

Function IntersectionOfPlaneAndTriangle(Const APlane : TMPlane; Const ATriangle : TMTriangle; Out Line : TMSegment) : TPlaneAndTriangleIntersection; overload;
Begin
     With ATriangle Do
          Result := IntersectionOfPlaneAndTriangle(APlane, P1, P2, P3, Line);
End;

Function IntersectionOfTwoTriangles(Const Triangle1, Triangle2 : TMTriangle; Out Segment : TMSegment) : Boolean;
Var
   Plane1, Plane2 : TMPlane;
   Segment1, Segment2 : TMSegment;
   IntersectionResult1, IntersectionResult2 : TPlaneAndTriangleIntersection;
   Line : TMVectorLine;
   Dot : Double;
   SwapV : TMVector;

   T1T1, T1T2, T2T1, T2T2 : Double;
Begin
     Plane1 := PlaneFromTriangle(Triangle1);
     Plane2 := PlaneFromTriangle(Triangle2);

     // Filter out coplaner polygons
     Dot := VectorDotProduct(Plane1.Normal, Plane2.Normal);

     If (Dot < 1) and IntersectionOfPlaneAndTriangle(Plane1, Triangle2) and IntersectionOfPlaneAndTriangle(Plane2, Triangle1) Then Begin
        IntersectionResult1 := IntersectionOfPlaneAndTriangle(Plane1, Triangle2, Segment1);
        IntersectionResult2 := IntersectionOfPlaneAndTriangle(Plane2, Triangle1, Segment2);

        If (IntersectionResult1 in [tpiEdgeIntersection, tpiLineIntersection]) and (IntersectionResult2 in [tpiEdgeIntersection, tpiLineIntersection]) Then Begin
           Line := IntersectionOfTwoPlanes(Plane1, Plane2);

           // Get and sort the T values of the line segments
           T1T1 := VectorDotProduct(Segment1.P1, Line.Direction);
           T1T2 := VectorDotProduct(Segment1.P2, Line.Direction);
           If T1T1 > T1T2 Then Begin // Swap using Dot at the temp variable
              Dot := T1T1;
              T1T1 := T1T2;
              T1T2 := Dot;

              SwapV := Segment1.P1;
              Segment1.P1 := Segment1.P2;
              Segment1.P2 := SwapV;
              End;

           T2T1 := VectorDotProduct(Segment2.P1, Line.Direction);
           T2T2 := VectorDotProduct(Segment2.P2, Line.Direction);
           If T2T1 > T2T2 Then Begin // Swap using Dot at the temp variable
              Dot := T2T1;
              T2T1 := T2T2;
              T2T2 := Dot;

              SwapV := Segment2.P1;
              Segment2.P1 := Segment2.P2;
              Segment2.P2 := SwapV;
              End;

           If Not((T1T2 < T2T1) or (T2T2 < T1T1)) Then Begin
              If T1T1 > T2T1 Then Segment.P1 := Segment1.P1 Else Segment.P1 := Segment2.P1;
              If T1T2 < T2T2 Then Segment.P2 := Segment1.P2 Else Segment.P2 := Segment2.P2;
              Result := True;
              End
           Else Begin
              Result := False;
              Segment.P1 := MVectorOrigin;
              Segment.P2 := MVectorOrigin;
              End;
           End
        Else Begin
           Result := False;
           Segment.P1 := MVectorOrigin;
           Segment.P2 := MVectorOrigin;
           End;
        End
     Else Begin
        Result := False;
        Segment.P1 := MVectorOrigin;
        Segment.P2 := MVectorOrigin;
        End;
End;

Function ClosestAxisPlaneNormalToVector(Const AVector : TMVector) : TMVector;
Var
   XPlaneDP, YPlaneDP, ZPlaneDP : Double;
Begin
     Result := MVectorZ;

     XPlaneDP := Abs(VectorDotProduct(AVector, MVectorX));
     YPlaneDP := Abs(VectorDotProduct(AVector, MVectorY));
     ZPlaneDP := Abs(VectorDotProduct(AVector, MVectorZ));

     If (XPlaneDP >= YPlaneDP) and (XPlaneDP >= ZPlaneDP) Then
        Result := MVectorX
     Else If (YPlaneDP >= XPlaneDP) and (YPlaneDP >= ZPlaneDP) Then
        Result := MVectorY
     Else If (ZPlaneDP >= XPlaneDP) and (ZPlaneDP >= YPlaneDP) Then
        Result := MVectorZ;
End;

Function ClosestOrthogonalPlaneToPlane(Const APlane : TMPlane) : TOrthogonalPlane;
Var
   XPlaneDP, YPlaneDP, ZPlaneDP : Double;
Begin
     Result := opXYZ;

     XPlaneDP := Abs(VectorDotProduct(VectorNormalise(APlane.Normal), MVectorX));
     YPlaneDP := Abs(VectorDotProduct(VectorNormalise(APlane.Normal), MVectorY));
     ZPlaneDP := Abs(VectorDotProduct(VectorNormalise(APlane.Normal), MVectorZ));

     If (XPlaneDP >= YPlaneDP) and (XPlaneDP >= ZPlaneDP) Then
        Result := opYZ
     Else If (YPlaneDP >= XPlaneDP) and (YPlaneDP >= ZPlaneDP) Then
        Result := opZX
     Else If (ZPlaneDP >= XPlaneDP) and (ZPlaneDP >= YPlaneDP) Then
        Result := opXY;
End;

Function ClosestOrthogonalPlaneToNormal(Const ANormal : TMVector) : TOrthogonalPlane;
Var
   XPlaneDP, YPlaneDP, ZPlaneDP : Double;
Begin
     Result := opXYZ;

     XPlaneDP := Abs(VectorDotProduct(VectorNormalise(ANormal), MVectorX));
     YPlaneDP := Abs(VectorDotProduct(VectorNormalise(ANormal), MVectorY));
     ZPlaneDP := Abs(VectorDotProduct(VectorNormalise(ANormal), MVectorZ));

     If (XPlaneDP >= YPlaneDP) and (XPlaneDP >= ZPlaneDP) Then
        Result := opYZ
     Else If (YPlaneDP >= XPlaneDP) and (YPlaneDP >= ZPlaneDP) Then
        Result := opZX
     Else If (ZPlaneDP >= XPlaneDP) and (ZPlaneDP >= YPlaneDP) Then
        Result := opXY;
End;


Procedure UVAxesFromNormal(Const Normal : TMVector; Out UVector : TMVector; Out VVector : TMVector);
Var
   XPlaneDP, YPlaneDP, ZPlaneDP : Double;
Begin
     UVector := MVectorX;
     VVector := MVectorY;

     XPlaneDP := Abs(VectorDotProduct(Normal, MVectorX));
     YPlaneDP := Abs(VectorDotProduct(Normal, MVectorY));
     ZPlaneDP := Abs(VectorDotProduct(Normal, MVectorZ));

     If (XPlaneDP >= YPlaneDP) and (XPlaneDP >= ZPlaneDP) Then Begin
        UVector := MVectorY;
        VVector := VectorNormalise(VectorCrossProduct(UVector, Normal));
        UVector := VectorNormalise(VectorCrossProduct(Normal, VVector));
        End
     Else If (YPlaneDP >= XPlaneDP) and (YPlaneDP >= ZPlaneDP) Then Begin
        UVector := MVectorX;

        // Original definition
        //VVector := VectorNormalise(VectorCrossProduct(Normal, UVector));
        //UVector := VectorNormalise(VectorCrossProduct(VVector, Normal));

        // Changed 18-01-17 because it flipped sides in the stability display
        //VVector := VectorNormalise(VectorCrossProduct(Normal, UVector));
        //UVector := VectorNormalise(VectorCrossProduct(VVector, Normal));

        VVector := VectorNormalise(VectorCrossProduct(UVector, Normal));
        UVector := VectorNormalise(VectorCrossProduct(Normal, VVector));
        End
     Else If (ZPlaneDP >= XPlaneDP) and (ZPlaneDP >= YPlaneDP) Then Begin
        UVector := MVectorX;
        VVector := VectorNormalise(VectorCrossProduct(UVector, Normal));
        UVector := VectorNormalise(VectorCrossProduct(Normal, VVector));
        End;
End;

Function XYTransformationFromNormal(Const Normal : TMVector) : TMMatrix;
Var
   UVector, VVector : TMVector;
   XPlaneDP, YPlaneDP, ZPlaneDP : Double;
   AMatrix : TMMatrix;
Begin
     UVector := MVectorX;
     VVector := MVectorY;

     XPlaneDP := Abs(VectorDotProduct(Normal, MVectorX));
     YPlaneDP := Abs(VectorDotProduct(Normal, MVectorY));
     ZPlaneDP := Abs(VectorDotProduct(Normal, MVectorZ));

     If (XPlaneDP >= YPlaneDP) and (XPlaneDP >= ZPlaneDP) Then Begin
        UVector := MVectorY;
        VVector := VectorNormalise(VectorCrossProduct(UVector, Normal));
        UVector := VectorNormalise(VectorCrossProduct(Normal, VVector));
        End
     Else If (YPlaneDP >= XPlaneDP) and (YPlaneDP >= ZPlaneDP) Then Begin
        UVector := MVectorX;

        // Maintains X -> X, Y -> Y

        VVector := VectorNormalise(VectorCrossProduct(Normal, UVector));
        UVector := VectorNormalise(VectorCrossProduct(VVector, Normal));
        End
     Else If (ZPlaneDP >= XPlaneDP) and (ZPlaneDP >= YPlaneDP) Then Begin
        UVector := MVectorX;
        VVector := VectorNormalise(VectorCrossProduct(UVector, Normal));
        UVector := VectorNormalise(VectorCrossProduct(Normal, VVector));
        End;


     AMatrix := ColumnsMatrix(UVector, VVector, Normal, MVectorOrigin);
     Result := InvertMatrix(AMatrix);
End;

Function MPlaneExtents(AMinU, AMinV, AMaxU, AMaxV : Double) : TMPlaneExtents; overload;
Begin
     Result.MinU := AMinU;
     Result.MinV := AMinV;
     Result.MaxU := AMaxU;
     Result.MaxV := AMaxV;
End;

Function MPlaneExtents(APlane : TMPlane; AExtents : TMBox) : TMPlaneExtents; overload;
Var
   UVector, VVector, PlaneOrigin : TMVector;
   ULine, VLine : TMVectorLine;
   PlaneExtents : TMPlaneExtents;

   Function LineT(ALine : TMVectorLine; APoint : TMVector) : Double;
   Var
      Denom, Offset : Double;
   Begin
        If VectorLength(ALine.Direction) > 0 Then Begin
           Denom := VectorDotProduct(ALine.Direction, ALine.Direction);
           Offset := VectorDotProduct(APoint, ALine.Direction);
           Result := (Offset - VectorDotProduct(ALine.Base, ALine.Direction)) / Denom;
           End
        Else
           Raise Exception.Create('LxWeb.Maths.MPlaneExtents.LineT - Line has zero magnitude direction vector');
   End;

   Procedure ProcessCorner(APoint : TMVector);
   Var
      U, V : Double;
   Begin
     U := LineT(ULine, APoint);
     V := LineT(VLine, APoint);
     If U < PlaneExtents.MinU Then PlaneExtents.MinU := U;
     If U > PlaneExtents.MaxU Then PlaneExtents.MaxU := U;
     If V < PlaneExtents.MinV Then PlaneExtents.MinV := V;
     If V > PlaneExtents.MaxV Then PlaneExtents.MaxV := V;
   End;

Begin
     PlaneExtents := MPlaneExtents(ABigNumber, ABigNumber, -ABigNumber, -ABigNumber);

     PlaneOrigin := VectorScale(APlane.Offset, APlane.Normal);
     UVAxesFromNormal(APlane.Normal, UVector, VVector);

     ULine := MVectorLine(UVector, PlaneOrigin);
     VLine := MVectorLine(VVector, PlaneOrigin);

     With AExtents Do Begin
          ProcessCorner(MVector(MinX, MinY, MinZ));
          ProcessCorner(MVector(MaxX, MinY, MinZ));
          ProcessCorner(MVector(MaxX, MaxY, MinZ));
          ProcessCorner(MVector(MinX, MaxY, MinZ));

          ProcessCorner(MVector(MinX, MinY, MaxZ));
          ProcessCorner(MVector(MaxX, MinY, MaxZ));
          ProcessCorner(MVector(MaxX, MaxY, MaxZ));
          ProcessCorner(MVector(MinX, MaxY, MaxZ));
          End;

     Result := PlaneExtents;
End;


Function PlaneExtentsToRectangle(APlane : TMPlane; Extents : TMPlaneExtents) : TMVectorRectangle; overload;
Var
   Origin, UVector, VVector : TMVector;
Begin
     Origin := VectorScale(APlane.Offset, APlane.Normal);
     {If Abs(VectorDotProduct(Origin, APlane.Normal) - APlane.Offset) > 0.1 Then
        Raise Exception.Create('LxWeb.Maths.PlaneExtentsToRectangle Failed');}

     UVAxesFromNormal(APlane.Normal, UVector, VVector);

     Result.LeftBottom := VectorAdd(Origin, VectorAdd(VectorScale(Extents.MinU, UVector), VectorScale(Extents.MinV, VVector)));
     Result.LeftTop := VectorAdd(Origin, VectorAdd(VectorScale(Extents.MinU, UVector), VectorScale(Extents.MaxV, VVector)));
     Result.RightBottom := VectorAdd(Origin, VectorAdd(VectorScale(Extents.MaxU, UVector), VectorScale(Extents.MinV, VVector)));
     Result.RightTop := VectorAdd(Origin, VectorAdd(VectorScale(Extents.MaxU, UVector), VectorScale(Extents.MaxV, VVector)));
End;

Function PlaneExtentsToRectangle(APlane : TMPlane; AExtents : TMBox) : TMVectorRectangle; overload;
Begin
     Result := PlaneExtentsToRectangle(APlane, MPlaneExtents(APlane, AExtents));
End;

{$endregion}

{$region '*************************************** TMCircle and TMArc ***************************************'}

Function CircleThroughThreePoints(P1, P2, P3 : TMVector) : TMCircle;
Var
   Radius : Double;
   Normal, Seg1, Seg2, Seg1Mid, Seg2Mid, VP1{, VP3}, Centre : TMVector;
   NormalPlane, Seg1Plane, Seg2Plane : TMPlane;
Begin
     If TriangleArea(P1, P2, P3) > 0 Then Begin
        Seg1Mid := VectorMidPoint(P1, P2);
        Seg2Mid := VectorMidPoint(P2, P3);
        Seg1 := VectorNormalise(VectorSubtract(P1, P2));
        Seg2 := VectorNormalise(VectorSubtract(P2, P3));

        Normal := VectorNormalise(VectorCrossProduct(Seg1, Seg2));

        NormalPlane := MPlane(Normal, P1);
        Seg1Plane := MPlane(Seg1, Seg1Mid);
        Seg2Plane := MPlane(Seg2, Seg2Mid);

        Centre := IntersectionOfThreePlanes(NormalPlane, Seg1Plane, Seg2Plane);

        VP1 := VectorSubtract(Centre, P1);
        {VP3 := VectorSubtract(Centre, P3);}

        Radius := VectorLength(VP1);
        Result := CircleThroughCentreRadiusNormal(Centre, Radius, Normal);
        End
     Else Begin
        Result.Centre := P1;
        Result.U := MVectorOrigin;
        Result.V := MVectorOrigin;
        Result.Radius := 0;
        Result.Normal := MVectorX;
        End;
End;

Function CircleThroughTwoPointsCentre(PStart, PEnd, PCentre : TMVector) : TMCircle;
Var
   Radius : Double;
   Normal, VP1, VP3 : TMVector;
Begin
     VP1 := VectorSubtract(PCentre, PStart);
     VP3 := VectorSubtract(PCentre, PEnd);

     Radius := (VectorLength(VP1) + VectorLength(VP3)) / 2;
     Normal := VectorNormalise(VectorCrossProduct(VectorNormalise(VP1), VectorNormalise(VP3)));

     Result := CircleThroughCentreRadiusNormal(PCentre, Radius, Normal);
End;

Function CircleThroughCentreRadiusNormal(Centre : TMVector; Radius : Double; Normal : TMVector) : TMCircle;
Var
   XPlaneDP, YPlaneDP, ZPlaneDP : Double;
Begin
     Result.Centre := Centre;
     Result.Radius := Radius;
     If VectorLength(Normal) = 0 Then
        Result.Normal := MVectorZ
     Else
        Result.Normal := VectorNormalise(Normal);

     XPlaneDP := Abs(VectorDotProduct(Result.Normal, MVectorX));
     YPlaneDP := Abs(VectorDotProduct(Result.Normal, MVectorY));
     ZPlaneDP := Abs(VectorDotProduct(Result.Normal, MVectorZ));

     If (XPlaneDP >= YPlaneDP) and (XPlaneDP >= ZPlaneDP) Then Begin
        Result.U := MVectorY;
        Result.V := VectorNormalise(VectorCrossProduct(Result.Normal, Result.U));
        Result.U := VectorNormalise(VectorCrossProduct(Result.Normal, Result.V));
        End
     Else If (YPlaneDP >= XPlaneDP) and (YPlaneDP >= ZPlaneDP) Then Begin
        Result.U := MVectorX;
        Result.V := VectorNormalise(VectorCrossProduct(Result.Normal, Result.U));
        Result.U := VectorNormalise(VectorCrossProduct(Result.Normal, Result.V));
        End
     Else If (ZPlaneDP >= XPlaneDP) and (ZPlaneDP >= YPlaneDP) Then Begin
        Result.U := MVectorX;
        Result.V := VectorNormalise(VectorCrossProduct(Result.Normal, Result.U));
        Result.U := VectorNormalise(VectorCrossProduct(Result.Normal, Result.V));
        End
End;

Function ArcThroughThreePoints(P1, P2, P3 : TMVector) : TMArc;
Var
   D : Double;
   Normal, Seg1, Seg2, Seg1Mid, Seg2Mid, AngleNormal, VP1, VP3 : TMVector;
   NormalPlane, Seg1Plane, Seg2Plane : TMPlane;
Begin
     If TriangleArea(P1, P2, P3) > 0 Then Begin
        Seg1Mid := VectorMidPoint(P1, P2);
        Seg2Mid := VectorMidPoint(P2, P3);
        Seg1 := VectorNormalise(VectorSubtract(P1, P2));
        Seg2 := VectorNormalise(VectorSubtract(P2, P3));

        Normal := VectorNormalise(VectorCrossProduct(Seg1, Seg2));

        NormalPlane := MPlane(Normal, P1);
        Seg1Plane := MPlane(Seg1, Seg1Mid);
        Seg2Plane := MPlane(Seg2, Seg2Mid);

        Result.Normal := Normal;
        Result.Centre := IntersectionOfThreePlanes(NormalPlane, Seg1Plane, Seg2Plane);

        VP1 := VectorSubtract(Result.Centre, P1);
        VP3 := VectorSubtract(Result.Centre, P3);

        Result.Radius := VectorLength(VP1);
        Result.U := VectorNormalise(VP1);
        Result.V := VectorNormalise(VectorCrossProduct(Result.Normal, Result.U));
        Result.StartAngle := 0;

        AngleNormal := VectorNormalise(VectorCrossProduct(VectorNormalise(VP1), VectorNormalise(VP3)));
        If VectorLength(AngleNormal) = 0  Then Begin
           If Abs(VectorLength(VectorAdd(VP1, VP3))) < ASmallNumber Then
              Result.EndAngle := Pi
           Else
              Result.EndAngle := 0;
           End
        Else Begin
           D := VectorLength(VectorAdd(AngleNormal, Normal));
           If D < 0.5 Then
              Result.EndAngle := 2 * Pi - VectorAngles(Result.U, VP3)
           Else
              Result.EndAngle := VectorAngles(Result.U, VP3);
           End;
        End
     Else Begin
        Result.Centre := P1;
        Result.U := MVectorOrigin;
        Result.V := MVectorOrigin;
        Result.StartAngle := 0;
        Result.EndAngle := 0;
        Result.Radius := 0;
        End;
End;

Function ArcThroughTwoPointsCentre(PStart, PEnd, PCentre : TMVector) : TMArc; overload;
Var
   VP1, VP3 : TMVector;
Begin
     VP1 := VectorSubtract(PCentre, PStart);
     VP3 := VectorSubtract(PCentre, PEnd);

     Result.Radius := (VectorLength(VP1) + VectorLength(VP3)) / 2;
     Result.Centre := PCentre;
     Result.U := VectorNormalise(VP1);
     Result.Normal := VectorNormalise(VectorCrossProduct(VectorNormalise(VP1), VectorNormalise(VP3)));
     Result.V := VectorNormalise(VectorCrossProduct(Result.Normal, Result.U));
     Result.StartAngle := 0;
     Result.EndAngle := VectorAngles(Result.U, VP3);
End;

Function ArcThroughTwoPointsCentre(PStart, PEnd, PCentre, Normal : TMVector) : TMArc; overload;
Var
   VP1, VP3, AngleNormal : TMVector;
   D : Double;
Begin
     VP1 := VectorSubtract(PCentre, PStart);
     VP3 := VectorSubtract(PCentre, PEnd);

     Result.Radius := (VectorLength(VP1) + VectorLength(VP3)) / 2;
     Result.Centre := PCentre;
     Result.U := VectorNormalise(VP1);
     Result.Normal := VectorNormalise(VectorCrossProduct(VectorNormalise(VP1), VectorNormalise(VP3)));
     If VectorLength(Result.Normal) < 0.0001 Then
        Result.Normal := Normal
     Else If VectorLength(VectorAdd(Result.Normal, Normal)) < 0.9 Then
        Result.Normal := VectorNegative(Result.Normal);

     Result.V := VectorNormalise(VectorCrossProduct(Result.Normal, Result.U));
     Result.StartAngle := 0;

     AngleNormal := VectorNormalise(VectorCrossProduct(VectorNormalise(VP1), VectorNormalise(VP3)));
     D := VectorLength(VectorAdd(AngleNormal, Normal));
     If D < 0.5 Then
        Result.EndAngle := 2 * Pi - VectorAngles(Result.U, VP3)
     Else
        Result.EndAngle := VectorAngles(Result.U, VP3);
End;

Function ArcCentre2Angles(Centre : TMVector; Radius, StartAngle, EndAngle : Double; Normal : TMVector) : TMArc;
Begin
     Result.Centre := Centre;
     Result.Normal := Normal;
     Result.Radius := Radius;
     Result.U := MVector(Cos(StartAngle), Sin(StartAngle));
     Result.V := VectorNormalise(VectorCrossProduct(Result.Normal, Result.U));
     Result.StartAngle := 0;
     Result.EndAngle := EndAngle - StartAngle;
End;

Function VectorArc(AArc : TMArc; AParameter : Double) : TMVector;
Var
   Theta : Double;
Begin
     If (VectorLength(AArc.U) > ASmallNumber) and (VectorLength(AArc.V) > ASmallNumber) Then Begin
        Theta := AArc.EndAngle * AParameter;
        Result := VectorAdd(AArc.Centre, VectorAdd(VectorScale(Cos(Theta) * AArc.Radius, AArc.U), VectorScale(Sin(Theta) * AArc.Radius, AArc.V)));
        End
     Else
        Result := AArc.Centre;
End;

Function PointOnCircleClosestToPoint(ACircle : TMCircle; APoint : TMVector) : TMVector;
// Taken from Geometric Tools for Computer Graphics, Schneider and Eberly 2003, Page 388
Var
   P, dQ : TMVector;
Begin
     P := NearestPointOnPlaneToPoint(MPlane(ACircle.Normal, ACircle.Centre), APoint);
     dQ := VectorNormalise(VectorSubtract(ACircle.Centre, P));
     Result := VectorAdd(ACircle.Centre, VectorScale(ACircle.Radius, dQ));
End;

Function PointOnCircleClosestToLine(ACircle : TMCircle; ALine : TMVectorLine) : TArcProximatyResult;
// Taken from Geometric Tools for Computer Graphics, Schneider and Eberly 2003, Page 388
Var
   Rec : TLineProximatyResult;
   dQ : TMVector;
Begin
     Rec := IntersectionOfLineandPlaneInfo(ALine, MPlane(ACircle.Normal, ACircle.Centre));
     If Rec.LineAndPlaneParallel Then
        Result.ArcPlaneParallel := True
     Else Begin
        Result.ArcPlaneParallel := False;
        dQ := VectorNormalise(VectorSubtract(ACircle.Centre, Rec.Point));
        Result.Point := VectorAdd(ACircle.Centre, VectorScale(ACircle.Radius, dQ));
        End;
End;

Function PointOnArcClosestToPoint(AArc : TMArc; APoint : TMVector) : TMVector;
// Taken from Geometric Tools for Computer Graphics, Schneider and Eberly 2003, Page 388

     Function Point(Theta : Double) : TMVector;
     Begin
          Result := VectorAdd(AArc.Centre, VectorAdd(VectorScale(Cos(Theta) * AArc.Radius, AArc.U), VectorScale(Sin(Theta) * AArc.Radius, AArc.V)));
     End;

Var
   P, dQ : TMVector;
   LinePlane : TMPlane;
   StartPoint, EndPoint, PointDirection : TMVector;
Begin
     P := NearestPointOnPlaneToPoint(MPlane(AArc.Normal, AArc.Centre), APoint);
     dQ := VectorNormalise(VectorSubtract(AArc.Centre, P));
     Result := VectorAdd(AArc.Centre, VectorScale(AArc.Radius, dQ));

     StartPoint := Point(AArc.StartAngle);
     EndPoint := Point(AArc.EndAngle);
     PointDirection := VectorNormalise(VectorSubtract(StartPoint, EndPoint));
     LinePlane.Normal := VectorNormalise(VectorCrossProduct(PointDirection, AArc.Normal));
     LinePlane.Offset := VectorDotProduct(StartPoint, LinePlane.Normal);

     If PointOnWhichPlaneSide(LinePlane, Result) < 0 Then Begin
        If Distance(StartPoint, Result) < Distance(EndPoint, Result) Then
           Result := StartPoint
        Else
           Result := EndPoint;
        End;
End;

Function PointOnArcClosestToLine(AArc : TMArc; ALine : TMVectorLine) : TArcProximatyResult;
// Taken from Geometric Tools for Computer Graphics, Schneider and Eberly 2003, Page 388

     Function Point(Theta : Double) : TMVector;
     Begin
          Result := VectorAdd(AArc.Centre, VectorAdd(VectorScale(Cos(Theta) * AArc.Radius, AArc.U), VectorScale(Sin(Theta) * AArc.Radius, AArc.V)));
     End;

Var
   Rec : TLineProximatyResult;
   dQ : TMVector;
   LinePlane : TMPlane;
   StartPoint, EndPoint, PointDirection : TMVector;
Begin
     Rec := IntersectionOfLineandPlaneInfo(ALine, MPlane(AArc.Normal, AArc.Centre));
     If Rec.LineAndPlaneParallel Then
        Result.ArcPlaneParallel := True
     Else Begin
        Result.ArcPlaneParallel := False;

        dQ := VectorNormalise(VectorSubtract(AArc.Centre, Rec.Point));
        Result.Point := VectorAdd(AArc.Centre, VectorScale(AArc.Radius, dQ));

        StartPoint := Point(AArc.StartAngle);
        EndPoint := Point(AArc.EndAngle);
        PointDirection := VectorNormalise(VectorSubtract(StartPoint, EndPoint));
        LinePlane.Normal := VectorNormalise(VectorCrossProduct(PointDirection, AArc.Normal));
        LinePlane.Offset := VectorDotProduct(StartPoint, LinePlane.Normal);

        If PointOnWhichPlaneSide(LinePlane, Result.Point) < 0 Then Begin
           If Distance(StartPoint, Result.Point) < Distance(EndPoint, Result.Point) Then
              Result.Point := StartPoint
           Else
              Result.Point := EndPoint;
           End;
        End;
End;

Function IntersectionOfPlaneAndCircle(Const APlane : TMPlane; Const ACircle : TMCircle) : TArcPlaneIntersectionResult;
// Taken from Geometric Tools for Computer Graphics, Schneider and Eberly 2003, Page 248
Var
   ArcPlane : TMPlane;
   Line : TMVectorLine;
   Delta : TMVector;
   dd, dlta, md, mdelta, T1, T2  : Double;
Begin
     If Abs(VectorDotProduct(APlane.Normal, ACircle.Normal)) < (1 - ASmallNumber) Then Begin
        ArcPlane := MPlane(ACircle.Normal, ACircle.Centre);
        Line := IntersectionOfTwoPlanes(APlane, ArcPlane);
        Delta := VectorSubtract(ACircle.Centre, Line.Base);

        dd := VectorDotProduct(Line.Direction, Delta);
        md := VectorDotProduct(Line.Direction, Line.Direction);
        mdelta := VectorDotProduct(Delta, Delta);

        dlta := Sqr(dd) - md * (mdelta - Sqr(ACircle.Radius));
        If dlta < 0 Then
           Result.Count := 0
        Else If dlta > ASmallNumber Then Begin
           T1 := (-dd + Sqrt(dlta)) / md;
           T2 := (-dd - Sqrt(dlta)) / md;
           Result.P1 := VectorLine(Line, T1);
           Result.P2 := VectorLine(Line, T2);
           If SameVector(Result.P1, Result.P2) Then
              Result.Count := 1
           Else
              Result.Count := 2;
           End
        Else Begin
           T1 := -dd/ md;
           Result.P1 := VectorLine(Line, T1);
           Result.Count := 1;
           End;
        End
     Else
        Result.Count := 0;
End;

Function IntersectionOfPlaneAndArc(Const APlane : TMPlane; Const AArc : TMArc) : TArcPlaneIntersectionResult;
// Taken from Geometric Tools for Computer Graphics, Schneider and Eberly 2003, Page 248

     Function Point(Theta : Double) : TMVector;
     Begin
          Result := VectorAdd(AArc.Centre, VectorAdd(VectorScale(Cos(Theta) * AArc.Radius, AArc.U), VectorScale(Sin(Theta) * AArc.Radius, AArc.V)));
     End;

Var
   ArcPlane, LinePlane : TMPlane;
   Line : TMVectorLine;
   Delta, PointDirection : TMVector;
   dd, dlta, md, mdelta, T1, T2  : Double;
   StartPoint, EndPoint : TMVector;
Begin
     If Abs(VectorDotProduct(APlane.Normal, AArc.Normal)) < (1 - ASmallNumber) Then Begin
        ArcPlane := MPlane(AArc.Normal, AArc.Centre);
        Line := IntersectionOfTwoPlanes(APlane, ArcPlane);
        Delta := VectorSubtract(AArc.Centre, Line.Base);

        dd := VectorDotProduct(Line.Direction, Delta);
        md := VectorDotProduct(Line.Direction, Line.Direction);
        mdelta := VectorDotProduct(Delta, Delta);

        StartPoint := Point(AArc.StartAngle);
        EndPoint := Point(AArc.EndAngle);
        PointDirection := VectorNormalise(VectorSubtract(StartPoint, EndPoint));
        LinePlane.Normal := VectorNormalise(VectorCrossProduct(PointDirection, AArc.Normal));
        LinePlane.Offset := VectorDotProduct(StartPoint, LinePlane.Normal);

        dlta := Sqr(dd) - md * (mdelta - Sqr(AArc.Radius));
        If dlta < 0 Then
           Result.Count := 0
        Else If dlta > ASmallNumber Then Begin
           T1 := (-dd + Sqrt(dlta)) / md;
           T2 := (-dd - Sqrt(dlta)) / md;
           Result.P1 := VectorLine(Line, T1);
           Result.P2 := VectorLine(Line, T2);
           If SameVector(Result.P1, Result.P2) Then
              Result.Count := 1
           Else
              Result.Count := 2;

           If (Result.Count > 1) and (PointOnWhichPlaneSide(LinePlane, Result.P2) < 0) Then
              Dec(Result.Count);

           If PointOnWhichPlaneSide(LinePlane, Result.P1) < 0 Then Begin
              If Result.Count = 2 Then Result.P1 := Result.P2;
              Dec(Result.Count);
              End;

           End
        Else Begin
           T1 := -dd/ md;
           Result.P1 := VectorLine(Line, T1);
           Result.Count := 1;

           If PointOnWhichPlaneSide(LinePlane, Result.P1) < 0 Then Begin
              Result.P1 := Result.P2;
              Dec(Result.Count);
              End;
           End;
        End
     Else
        Result.Count := 0;
End;


{$endregion}

{$region '*************************************** TMTriangle ***************************************'}

Function MTriangle(Const P1, P2, P3 : TMVector) : TMTriangle;
Begin
     Result.P1 := P1;
     Result.P2 := P2;
     Result.P3 := P3;
End;

Function ReflectTriangle(Const ATriangle : TMTriangle) : TMTriangle;
Begin
     // Orientation is flipped
     Result.P1 := ATriangle.P1;
     Result.P2 := ATriangle.P3;
     Result.P3 := ATriangle.P2;

     Result.P1.Y := -Result.P1.Y;
     Result.P2.Y := -Result.P2.Y;
     Result.P3.Y := -Result.P3.Y;
End;

{$endregion}

{$region '*************************************** Equation Solvers ***************************************'}

Function Solve2DLinearEquation(Const V1, V2, C : TMVector; Out Lambda, Mu : Double; WantExceptions : Boolean = True) : Boolean;
{*************************************************************}
{*             Solution to 2 Linear Equations
{*
{*             [C1] = [A  B] [V1]
{*             [C2]   [C  D] [V2]
{*
{*             Det = A * D - B * C
{*
{*             [V1] = [D -B] [C1] / DET
{*             [V2] = [-C A] [C2] / DET
{*
{*************************************************************}
Const
     Eps = 1e-5;
Var
   Det : Double;
   Poor : Boolean;
Begin
     Det := V1.X * V2.Y - V2.X * V1.Y;

     Poor := (Abs(Det) < Eps) or ((Abs(C.X) < Eps) and (Abs(C.Y) < Eps));

     If Not(Poor) Then Begin
        Lambda := (V2.Y * C.X - V2.X * C.Y) / Det;
        Mu := (-V1.Y * C.X + V1.X * C.Y) / Det;
        Result := False;
        End
     Else If WantExceptions Then
        Raise Exception.Create('Solve2DLinearEquation')
     Else Begin
        console.Log('Solve2DLinearEquation failed');
        Result := True;
        End;
End;

Function Solve3DPlaneEquation(Const V1, V2, C : TMVector; Out Lambda, Mu : Double; WantExceptions : Boolean = True) : Boolean;
Const
     Eps = 1e-5;

Var
   Answer : TMVector;

Begin
     If VectorLength(V1) < Eps Then Begin
        Lambda := 0;
        If (Abs(V2.X) > Eps) and (Abs(C.X) > Eps) Then
           Mu := C.X / V2.X
        Else If (Abs(V2.Y) > Eps) and (Abs(C.Y) > Eps) Then
           Mu := C.Y / V2.Y
        Else If (Abs(V2.Z) > Eps) and (Abs(C.Z) > Eps) Then
           Mu := C.Z / V2.Z;

        // Check
        Answer := VectorScale(Mu, V2);
        If Not EqualVectors(Answer, C) Then Begin
           If WantExceptions Then
              Raise Exception.Create('Solve3DPlaneEquation - Vectors not planar')
           Else Begin
              console.Log('Solve3DPlaneEquation failed - Vectors not planar');
              Result := True;
              End;
           End
        Else
           Result := False;
        End
     Else If VectorLength(V2) < Eps Then Begin
        Mu := 0;

        If (Abs(V1.X) > Eps) and (Abs(C.X) > Eps) Then
           Mu := C.X / V1.X
        Else If (Abs(V1.Y) > Eps) and (Abs(C.Y) > Eps) Then
           Mu := C.Y / V1.Y
        Else If (Abs(V1.Z) > Eps) and (Abs(C.Z) > Eps) Then
           Mu := C.Z / V1.Z;

        // Check
        Answer := VectorScale(Mu, V1);
        If Not EqualVectors(Answer, C) Then Begin
           If WantExceptions Then
              Raise Exception.Create('Solve3DPlaneEquation - Vectors not planar')
           Else Begin
              Console.Log('Solve3DPlaneEquation failed - Vectors not planar');
              Result := True;
              End;
           End
        Else
           Result := False;
        End
     Else
        Result := Solve3DLinearEquation(V1, V2, C, Lambda, Mu, WantExceptions);
End;


Function Solve3DLinearEquation(Const V1, V2, C : TMVector; Out Lambda, Mu : Double; WantExceptions : Boolean = True) : Boolean;
{*************************************************************}
{*             Solution to 2 Linear Equations
{*
{*             [C1] = [A  B] [V1]
{*             [C2]   [C  D] [V2]
{*
{*             Det = A * D - B * C
{*
{*             [V1] = [D -B] [C1] / DET
{*             [V2] = [-C A] [C2] / DET
{*
{*************************************************************}
Const
     Eps = 1e-5;

Var
   Selection : Integer;
   DetXY, DetYZ, DetXZ, Found : Double;
   PoorXY, PoorYZ, PoorXZ : Boolean;
Begin
     DetXY := V1.X * V2.Y - V2.X * V1.Y;
     DetXZ := V1.X * V2.Z - V2.X * V1.Z;
     DetYZ := V1.Y * V2.Z - V2.Y * V1.Z;

     PoorXY := (Abs(DetXY) < Eps) or ((Abs(C.X) < Eps) and (Abs(C.Y) < Eps) and (Abs(C.Z) > 1 - Eps));
     PoorXZ := (Abs(DetXZ) < Eps) or ((Abs(C.X) < Eps) and (Abs(C.Y) > 1 - Eps) and (Abs(C.Z) < Eps));
     PoorYZ := (Abs(DetYZ) < Eps) or ((Abs(C.X) > 1 - Eps) and (Abs(C.Y) > Eps) and (Abs(C.Z) < Eps));

     // Try to select the combination with the best (largest magnitude) determinate
     Selection := 0;
     Found := 0;
     If Not(PoorXY) Then Begin
        If Abs(DetXY) > Found Then Begin
           Found := Abs(DetXY);
           Selection := 1;
           End;
        End;

     If Not(PoorXZ) Then Begin
        If Abs(DetXZ) > Found Then Begin
           Found := Abs(DetXZ);
           Selection := 2;
           End;
        End;

     If Not(PoorYZ) Then Begin
        If Abs(DetYZ) > Found Then Begin
           Selection := 3;
           End;
        End;

     If Selection = 1 Then Begin
        Lambda := (V2.Y * C.X - V2.X * C.Y) / DetXY;
        Mu := (-V1.Y * C.X + V1.X * C.Y) / DetXY;
        Result := False;
        End
     Else If Selection = 2 Then Begin
        Lambda := (V2.Z * C.X - V2.X * C.Z) / DetXZ;
        Mu := (-V1.Z * C.X + V1.X * C.Z) / DetXZ;
        Result := False;
        End
     Else If Selection = 3 Then Begin
        Lambda := (V2.Z * C.Y - V2.Y * C.Z) / DetYZ;
        Mu := (-V1.Z * C.Y + V1.Y * C.Z) / DetYZ;
        Result := False;
        End
     Else If WantExceptions Then
        Raise Exception.Create('Solve3DLinearEquation')
     Else Begin
        console.log('Solve3DLinearEquation failed');
        Result := True;
        End;
End;


Function SolveQuadraticEquation(Const A, B, C : Double; Out Root1, Root2 : Double) : Boolean;
Var
   Discriminant : Double;
Begin
     Discriminant := Sqr(B) - 4 * A * C;
     if Discriminant >= 0 Then Begin
        Root1 := (-B + Sqrt(Discriminant)) / (2 * A);
        Root2 := (-B - Sqrt(Discriminant)) / (2 * A);
        Result := True;
        End
     Else Begin
        Root1 := 0;
        Root2 := 0;
        Result := False;
        End;
End;

{$endregion}

{$region '*************************************** Hermite Functions ***************************************'}

Procedure Hermite(Const T : Double; Out Components : THermiteComponents);
Var
   T2, T3 : Double;
Begin
     T2 := T * T;
     T3 := T2 * T;

     Components.H0 := 1 - 3 * T2 + 2 * T3;
     Components.H1 := 3 * T2 - 2 * T3;
     Components.H2 := T - 2 * T2 + T3;
     Components.H3 := -T2 + T3;
End;

Procedure HermiteDerivatives(Const T : Double; Out ComponentDerivatives : THermiteComponentDerivatives);
Var
   T2, T3 : Double;
Begin
     T2 := T * T;
     T3 := T2 * T;

     ComponentDerivatives.H0 := 1 - 3 * T2 + 2 * T3;
     ComponentDerivatives.H0d := -6 * T + 6 * T2;
     ComponentDerivatives.H0dd := -6 + 12 * T;

     ComponentDerivatives.H1 := 3 * T2 - 2 * T3;
     ComponentDerivatives.H1d := 6 * T - 6 * T2;
     ComponentDerivatives.H1dd := 6 - 12 * T;

     ComponentDerivatives.H2 := T - 2 * T2 + T3;
     ComponentDerivatives.H2d := 1 - 4 * T + 3 * T2;
     ComponentDerivatives.H2dd := -4 + 6 * T;

     ComponentDerivatives.H3 := -T2 + T3;
     ComponentDerivatives.H3d := -2 * T + 3 * T2;
     ComponentDerivatives.H3dd := -2 + 6 * T;
End;

Function HermiteCurve(Const P0, T0, P1, T1 : TMVector; Const T : Double) : TMVector;
Var
   Components : THermiteComponents;
Begin
     Hermite(T, Components);

     With Components Do Begin
          Result.X := H0 * P0.X + H1 * P1.X + H2 * T0.X + H3 * T1.X;
          Result.Y := H0 * P0.Y + H1 * P1.Y + H2 * T0.Y + H3 * T1.Y;
          Result.Z := H0 * P0.Z + H1 * P1.Z + H2 * T0.Z + H3 * T1.Z;
          End;
End;

{$endregion}


end.
