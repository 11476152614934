unit Model.Systems;

interface

Uses Classes, SysUtils, WEBLib.JSON, EndPoints.Types;

Type
                     TSystem = Class(TObject)
                               Private
                               FUserId : String;
                               FSystemId : String;
                               FDiskId : String;
                               FBoardId : String;
                               FPolyCADId : String;
                               FIssued : TDateTime;
                               FExpires : TDateTime;
                               FReIssued : TDateTime;
                               FValidated : TDateTime;
                               Procedure fromJSON(AJSONObject : TJSONObject);
                               Public
                               Property UserId : String Read FUserId;
                               Property SystemId : String Read FSystemId;
                               Property DiskId : String Read FDiskId;
                               Property BoardId : String Read FBoardId;
                               Property PolyCADId : String Read FPolyCADId;
                               Property Issued : TDateTime Read FIssued;
                               Property Expires : TDateTime Read FExpires;
                               Property ReIssued : TDateTime Read FReIssued;
                               Property Validated : TDateTime Read FValidated;
                               End;

                    TSystems = Class(TObject)
                               Private
                               FList : TList;
                               Function GetCount : Integer;
                               Function GetSystem(Index : Integer) : TSystem;
                               Protected
                               Procedure Add(ASystem : TSystem);
                               Procedure Delete(ASystem : TSystem); overload;
                               Public
                               Constructor Create;
                               Destructor Destroy; override;
                               Procedure fromJSON(AJSONArray : TJSONArray);
                               Procedure Clear;
                               Function Delete(Index : Integer; AConnection : IHTTPConnection) : Boolean; overload; virtual; abstract; async;
                               Function Delete(Const SystemId : String; AConnection : IHTTPConnection) : Boolean; overload; async;
                               Function Delete(ASystem : TSystem; AConnection : IHTTPConnection) : Boolean; overload; async;
                               Function First : TSystem;
                               Function Last : TSystem;
                               Function Contains(ASystemId : String) : Boolean; overload;
                               Function Contains(ASystem : TSystem) : Boolean; overload;
                               Function IndexOf(ASystemId : String) : Integer; overload;
                               Function IndexOf(ASystem : TSystem) : Integer; overload;
                               Function Update(AConnection : IHTTPConnection) : Boolean; overload; virtual; abstract; async;
                               Procedure Enumerate(AStrings : TStrings);
                               Property Count : Integer Read GetCount;
                               Property Systems[Index : Integer] : TSystem Read GetSystem; default;
                               End;

implementation

Uses Js, LxWeb.JSON, WEBLib.REST;

{$region '************************************************* TSystem *******************************************************'}

Procedure TSystem.fromJSON(AJSONObject : TJSONObject);
Var
   jIter : TJSONIterator;
   DateStr : String;
Begin
     For jIter in AJSONObject Do
         If jIter.KeyIs('UserId', jdString) Then
            FUserId := jIter.AsString
         Else If jIter.KeyIs('SystemId', jdString) Then
            FSystemId := jIter.AsString
         Else If jIter.KeyIs('DiskId', jdString) Then
            FDiskId := jIter.AsString
         Else If jIter.KeyIs('BoardId', jdString) Then
            FBoardId := jIter.AsString
         Else If jIter.KeyIs('PolyCADId', jdString) Then
            FPolyCADId := jIter.AsString
         Else If jIter.KeyIs('Issued', jdString) Then Begin
            DateStr := jIter.AsString;
            If Not(DateStr = 'null') Then
               FIssued := TRESTClient.ISOToDateTime(DateStr)
            Else
               FIssued := 0;
            End
         Else If jIter.KeyIs('Expires', jdString) Then Begin
            DateStr := jIter.AsString;
            If Not(DateStr = 'null') Then
               FExpires := TRESTClient.ISOToDateTime(DateStr)
            Else
               FExpires := 0;
            End
         Else If jIter.KeyIs('Reissued', jdString) Then Begin
            DateStr := jIter.AsString;
            If Not(DateStr = 'null') Then
               FReIssued := TRESTClient.ISOToDateTime(DateStr)
            Else
               FReIssued := 0;
            End
         Else If jIter.KeyIs('Validated', jdString) Then Begin
            DateStr := jIter.AsString;
            If Not(DateStr = 'null') Then
               FValidated := TRESTClient.ISOToDateTime(DateStr)
            Else
               FValidated := 0;
            End;
End;

{$endregion}

{$region '************************************************* TSystems ******************************************************'}

Constructor TSystems.Create;
Begin
     Inherited Create;
     FList := TList.Create;
End;

Destructor TSystems.Destroy;
Begin
     Clear;
     FList.Free;
     Inherited Destroy;
End;

Function TSystems.GetCount : Integer;
Begin
     Result := FList.Count;
End;

Function TSystems.GetSystem(Index : Integer) : TSystem;
Begin
     Result := TSystem(FList[Index]);
End;

Procedure TSystems.Add(ASystem : TSystem);
Begin
     FList.Add(ASystem);
End;

Procedure TSystems.Delete(ASystem : TSystem);
Begin
     If Contains(ASystem) Then Begin
        FList.Remove(ASystem);
        ASystem.Free;
        End;
End;

Procedure TSystems.Clear;
Var
   I : Integer;
   Sys : TSystem;
Begin
     For I := 0 to Count - 1 Do Begin
         Sys := Systems[I];
         Sys.Free;
         End;

     FList.Clear;
End;

Function TSystems.First : TSystem;
Begin
     Result := TSystem(FList.First);
End;

Function TSystems.Last : TSystem;
Begin
     Result := TSystem(FList.Last);
End;

Function TSystems.Contains(ASystemId : String) : Boolean;
Begin
     Result := IndexOf(ASystemId) > -1;
End;

Function TSystems.Contains(ASystem : TSystem) : Boolean;
Begin
     Result := IndexOf(ASystem) > -1;
End;

Function TSystems.IndexOf(ASystemId : String) : Integer;
Var
   I : Integer;
   UCase : String;
Begin
     Result := -1;
     UCase := UpperCase(ASystemId);

     For I := 0 to Count - 1 Do
         If UCase = UpperCase(Systems[I].SystemId) Then Begin
            Result := I;
            Break;
            End;
End;

Function TSystems.IndexOf(ASystem : TSystem) : Integer;
Begin
     Result := FList.IndexOf(ASystem);
End;

Procedure TSystems.fromJSON(AJSONArray : TJSONArray);
Var
   jIter : TJSONIterator;
   ASystem : TSystem;
Begin
     Clear;

     For jIter in AJSONArray Do
         If jIter.DataType = jdObject Then Begin
            ASystem := TSystem.Create;
            ASystem.fromJSON(jIter.AsObject);
            FList.Add(ASystem);
            End;
End;

Function TSystems.Delete(Const SystemId : String; AConnection : IHTTPConnection) : Boolean;
Var
   Index : Integer;
Begin
     Index := IndexOf(SystemId);
     If Index > -1 Then
        Result := await(boolean, Delete(Index, AConnection))
     Else
        Result := False;
End;

Function TSystems.Delete(ASystem : TSystem; AConnection : IHTTPConnection) : Boolean;
Var
   Index : Integer;
Begin
     Index := IndexOf(ASystem);
     If Index > -1 Then
        Result := await(boolean, Delete(Index, AConnection))
     Else
        Result := False;
End;

Procedure TSystems.Enumerate(AStrings : TStrings);
Var
   I : Integer;
Begin
     AStrings.BeginUpdate;
     Try
        AStrings.Clear;
        For I := 0 to Count - 1 Do
        AStrings.AddObject(Systems[I].SystemId, Systems[I]);
     Finally
        AStrings.EndUpdate;
     End;
End;

{$endregion}


end.
