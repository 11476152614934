unit MessageUnit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  WEBLib.WebCtrls;

type
  TMessageForm = class(TForm)
    panelButtons: THTMLDiv;
    lbMessage: TLabel;
    lbHeading: TLabel;
  private
    { Private declarations }
    FHeading, FMessage : String;
    Procedure SetHeading(Val : String);
    Procedure SetMessage(Val : String);
  public
    { Public declarations }
    Property Heading : String Read FHeading Write SetHeading;
    Property Message : string Read FMessage Write SetMessage;
    Class Procedure ShowMessage(AMessage : String); overload;
    Class Procedure ShowMessage(AHeading, AMessage : String); overload;
    Class Procedure ShowMessage(AMessage : String; AButtons : array of string); overload;
    Class Procedure ShowMessage(AHeading, AMessage : String; AButtons : array of string); overload;
  protected procedure LoadDFMValues; override; end;

var
  MessageForm: TMessageForm;

implementation

{$R *.dfm}

Procedure TMessageForm.SetHeading(Val : String);
Begin
     If Not(FHeading = Val) Then Begin
        FHeading := Val;
        lbHeading.Caption := FHeading;
        End;

     If Length(FHeading) > 0 Then
        TJSHTMLElement(lbHeading.ElementHandle.parentNode).style.removeProperty('Display')
     Else
        TJSHTMLElement(lbHeading.ElementHandle.parentNode).style.SetProperty('Display', 'none');
End;

Procedure TMessageForm.SetMessage(Val : String);
Begin
     FMessage := Val;
     lbMessage.HTML := FMessage;
End;

Class Procedure TMessageForm.ShowMessage(AMessage : String);
Var
   AForm : TMessageForm;
Begin
     Application.CreateForm(TMessageForm, Application.AppContainer, AForm,
     procedure(AObject : TObject)
     begin
          TMessageForm(AObject).Heading := '';
          TMessageForm(AObject).Message := AMessage;
     end);
End;

Class Procedure TMessageForm.ShowMessage(AHeading, AMessage : String);
Var
   AForm : TMessageForm;
Begin
     Application.CreateForm(TMessageForm, Application.AppContainer, AForm,
     procedure(AObject : TObject)
     begin
          TMessageForm(AObject).Heading := AHeading;
          TMessageForm(AObject).Message := AMessage;
     end);
End;

Class Procedure TMessageForm.ShowMessage(AMessage : String; AButtons : array of string);
Var
   AForm : TMessageForm;
   ButtonCaption : String;
   AButton : TJSHTMLElement;
Begin
     Application.CreateForm(TMessageForm, Application.AppContainer, AForm,
     procedure(AObject : TObject)
     begin
          TMessageForm(AObject).Heading := '';
          TMessageForm(AObject).Message := AMessage;

          For ButtonCaption in AButtons Do Begin
              AButton := TJSHTMLInputElement(document.createElement('input'));
              AButton['Type'] := 'BUTTON';
              AButton['value'] := ButtonCaption;
              TMessageForm(AObject).panelButtons.ElementHandle.appendChild(AButton);
              End;
     end);
End;

Class Procedure TMessageForm.ShowMessage(AHeading, AMessage : String; AButtons : array of string);
Var
   AForm : TMessageForm;
   ButtonCaption : String;
   AButton : TJSHTMLElement;
Begin
     Application.CreateForm(TMessageForm, Application.AppContainer, AForm,
     procedure(AObject : TObject)
     begin
          TMessageForm(AObject).Heading := AHeading;
          TMessageForm(AObject).Message := AMessage;

          For ButtonCaption in AButtons Do Begin
              AButton := TJSHTMLInputElement(document.createElement('input'));
              AButton['Type'] := 'BUTTON';
              AButton['value'] := ButtonCaption;
              TMessageForm(AObject).panelButtons.ElementHandle.appendChild(AButton);
              End;

     end);
End;

procedure TMessageForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbMessage := TLabel.Create('lbMessage');
  lbHeading := TLabel.Create('lbHeading');
  panelButtons := THTMLDiv.Create('button_options');

  lbMessage.BeforeLoadDFMValues;
  lbHeading.BeforeLoadDFMValues;
  panelButtons.BeforeLoadDFMValues;
  try
    Name := 'MessageForm';
    Width := 640;
    Height := 480;
    ElementFont := efCSS;
    ElementPosition := epRelative;
    FormContainer := 'application';
    lbMessage.SetParentComponent(Self);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 19;
    lbMessage.Top := 44;
    lbMessage.Width := 50;
    lbMessage.Height := 13;
    lbMessage.Caption := 'lbMessage';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.HTMLType := tDIV;
    lbMessage.WidthPercent := 100.000000000000000000;
    lbHeading.SetParentComponent(Self);
    lbHeading.Name := 'lbHeading';
    lbHeading.Left := 19;
    lbHeading.Top := 21;
    lbHeading.Width := 47;
    lbHeading.Height := 13;
    lbHeading.Caption := 'lbHeading';
    lbHeading.HeightPercent := 100.000000000000000000;
    lbHeading.HTMLType := tH1;
    lbHeading.WidthPercent := 100.000000000000000000;
    panelButtons.SetParentComponent(Self);
    panelButtons.Name := 'panelButtons';
    panelButtons.Left := 19;
    panelButtons.Top := 63;
    panelButtons.Width := 242;
    panelButtons.Height := 102;
    panelButtons.HeightStyle := ssAuto;
    panelButtons.WidthStyle := ssAuto;
    panelButtons.ChildOrder := 8;
    panelButtons.ElementPosition := epIgnore;
    panelButtons.ElementFont := efCSS;
    panelButtons.Role := '';
  finally
    lbMessage.AfterLoadDFMValues;
    lbHeading.AfterLoadDFMValues;
    panelButtons.AfterLoadDFMValues;
  end;
end;

end.
