unit LicenseUnit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TLicenseForm = class(TForm)
    btnContinue: TButton;
    procedure btnContinueClick(Sender: TObject);
  private
    { Private declarations }
    Procedure DoContinue;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  LicenseForm: TLicenseForm;

implementation

{$R *.dfm}

Uses LxWeb.Router;

Procedure TLicenseForm.DoContinue;
Begin
     btnContinue.Cursor := crHourGlass;
     Router.Route('/feedback');
End;

procedure TLicenseForm.btnContinueClick(Sender: TObject);
begin
     DoContinue;
end;

procedure TLicenseForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  btnContinue := TButton.Create('btnContinue');

  btnContinue.BeforeLoadDFMValues;
  try
    Name := 'LicenseForm';
    Width := 640;
    Height := 480;
    ElementClassName := 'application';
    ElementFont := efCSS;
    ElementPosition := epRelative;
    btnContinue.SetParentComponent(Self);
    btnContinue.Name := 'btnContinue';
    btnContinue.Left := 8;
    btnContinue.Top := 8;
    btnContinue.Width := 96;
    btnContinue.Height := 25;
    btnContinue.Caption := 'btnContinue';
    btnContinue.ElementFont := efCSS;
    btnContinue.ElementPosition := epIgnore;
    btnContinue.HeightStyle := ssAuto;
    btnContinue.HeightPercent := 100.000000000000000000;
    btnContinue.WidthStyle := ssAuto;
    btnContinue.WidthPercent := 100.000000000000000000;
    SetEvent(btnContinue, Self, 'OnClick', 'btnContinueClick');
  finally
    btnContinue.AfterLoadDFMValues;
  end;
end;

end.