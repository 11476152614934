program app;

{$R *.dres}

uses
  Vcl.Forms,
  WEBLib.Forms,
  Web,
  Js,
  ProfileUnit in 'profile\ProfileUnit.pas'{*.html},
  SignupUnit in 'signup\SignupUnit.pas'{*.html},
  ForgotPasswordUnit in 'forgotpassword\ForgotPasswordUnit.pas'{*.html},
  LoginUnit in 'login\LoginUnit.pas'{*.html},
  ChangePasswordUnit in 'changepassword\ChangePasswordUnit.pas'{*.html},
  LicenseUnit in 'license\LicenseUnit.pas'{*.html},
  ValidateUnit in 'authenticate\ValidateUnit.pas'{*.html},
  Admin.Systems in '..\..\MyPolyCAD\Model\Admin.Systems.pas',
  Admin.Users in '..\..\MyPolyCAD\Model\Admin.Users.pas',
  EndPoints.Connection in '..\..\MyPolyCAD\Model\EndPoints.Connection.pas',
  EndPoints.Types in '..\..\MyPolyCAD\Model\EndPoints.Types.pas',
  Model.Systems in '..\..\MyPolyCAD\Model\Model.Systems.pas',
  Model.Systems.Presentation in '..\..\MyPolyCAD\Model\Model.Systems.Presentation.pas',
  Model.Users in '..\..\MyPolyCAD\Model\Model.Users.pas',
  MyPolyCAD.App in '..\..\MyPolyCAD\Model\MyPolyCAD.App.pas',
  MyPolyCAD.Model.Feedback in '..\..\MyPolyCAD\Model\MyPolyCAD.Model.Feedback.pas',
  MyPolyCAD.Model in '..\..\MyPolyCAD\Model\MyPolyCAD.Model.pas',
  MyPolyCAD.Model.Systems in '..\..\MyPolyCAD\Model\MyPolyCAD.Model.Systems.pas',
  MyPolyCAD.Model.User in '..\..\MyPolyCAD\Model\MyPolyCAD.Model.User.pas',
  FeedbackUnit in 'feedback\FeedbackUnit.pas',
  MyPolyCAD.Model.Mail in '..\..\MyPolyCAD\Model\MyPolyCAD.Model.Mail.pas',
  MessageUnit in 'message\MessageUnit.pas'{*.html},
  LxWeb.Router in '..\..\Library\Core\LxWeb.Router.pas',
  MessageLoginUnit in 'messagelogin\MessageLoginUnit.pas'{*.html},
  MyPolyCAD.Configuration in '..\..\MyPolyCAD\Model\MyPolyCAD.Configuration.pas';

{$R *.res}

begin
     Application.Initialize;
     Application.AppContainer := 'application';
     Application.MainFormOnTaskbar := True;

     Router.AddRoute('/SIGNUP', TSignupForm);
     Router.AddRoute('/LICENSE', TLicenseForm, @appMyPolyCAD.IsSigningUp, '/SIGNUP'{, false});
     Router.AddRoute('/FEEDBACK', TFeedbackForm, @appMyPolyCAD.IsSigningUp, '/SIGNUP' {, false});
     Router.AddRoute('/VALIDATE', TValidateForm);

     Router.AddRoute('/LOGIN', TLoginForm{, false});
     Router.AddRoute('/PROFILE', TProfileForm, @appMyPolyCAD.IsLoggedIn, '/LOGIN'{, false});

     Router.AddRoute('/FORGOTPASSWORD', TForgotPasswordForm{, false});
     Router.AddRoute('/CHANGEPASSWORD', TChangePasswordForm{, false});

     Router.AddDefaultRoute(TLoginForm);

     Router.Route(window.location.hash);

     Application.CreateForm(TMessageLoginForm, MessageLoginForm);
  Application.Run;
end.
