unit LxWebReCaptcha;

interface

Uses Classes, SysUtils, WEBLib.Controls;

Type
    TLxWebRecaptcha = Class(TComponent)
                      Private
                      FSiteKey : String;
                      FValidationUrl : String;
                      Procedure AddControlScriptLink(const link: string);
                      Public
                      Procedure AfterLoadDFMValues; overload;
                      Function Execute(Out Token : String) : Boolean; overload; async;
                      Function Execute(Out Token : String; Const Action : string) : Boolean; overload; async;
                      Function Validate(Const Token : String) : Boolean; overload; async;
                      Function Validate(Const Token, Action : String) : Boolean; overload; async;
                      Property SiteKey : String Read FSiteKey Write FSiteKey;
                      Property ValidationUrl : String Read FValidationUrl Write FValidationUrl;
                      End;

implementation

Uses Web, Js, WEBLib.JSON, WEBLib.REST;

Function HTTPResult(AResponse : String) : Boolean;
Begin
     Result := False;

     asm
        const res_json = JSON.parse(AResponse);

        if (typeof res_json.Success == "boolean" )
        {
           Result = res_json.Success;
        }
        else if (typeof res_json.success == "boolean" )
        {
           Result = res_json.success;
        }
        else if (typeof res_json.Result == "boolean" )
        {
           Result = res_json.Result;
        }
        else if (typeof res_json.result == "boolean" )
        {
           Result = res_json.result;
        }
        else
        {
           Result = false;
        }
     end;
End;



{$HINTS OFF}

Procedure TLxWebRecaptcha.AddControlScriptLink(const link: string);
Var
   id: string;
   script: TJSHTMLScriptElement;
Begin
     id := ClassName;

     If not Assigned(document.getElementById(id)) Then Begin
        script := TJSHTMLScriptElement(document.createElement('script'));
        script.id := id;
        script.src := link;
        script.type_ := 'text/javascript';
        document.head.appendChild(script);
        End;
End;

Procedure TLxWebRecaptcha.AfterLoadDFMValues;
Begin
     Inherited;

     if Not(FSiteKey = '') then
        AddControlScriptLink('https://www.google.com/recaptcha/api.js?render=' + FSiteKey);
End;

Function TLxWebRecaptcha.Execute(Out Token : String) : Boolean;
Var
   Key, returned_token : String;
Begin
     Key := FSiteKey;
     Token := '';
     Result := False;

     if Not(FSiteKey = '') Then Begin
        asm
           returned_token = await grecaptcha.execute(Key);
           Result = (returned_token !== null);
        end;

        if Result Then
           Token := returned_token;
        End;
End;

Function TLxWebRecaptcha.Execute(Out Token : String; Const Action : string = 'noAction') : Boolean;
Var
   Key, returned_token, internalAction : String;
Begin
     Key := FSiteKey;
     Token := '';
     internalAction := Action;
     Result := False;

     if Not(FSiteKey = '') Then Begin
        asm
           returned_token = await grecaptcha.execute(Key, {action: internalAction});
           Result = (returned_token !== null);
        end;

        if Result Then
           Token := returned_token;
        End;
End;

Function TLxWebRecaptcha.Validate(Const Token : String) : Boolean;
Var
   AConnection : TWebHTTPRequest;
   LResponse : TJSXMLHttpRequest;
   AResponse : String;
Begin
     If Not(FValidationUrl = '') Then Begin
        AConnection := TWebHTTPRequest.Create(nil);
        Try
           AConnection.Url := FValidationUrl + Token;
           AConnection.Command := httpGET;

           LResponse := await(TJSXMLHttpRequest, AConnection.Perform);
           AResponse := LResponse.ResponseText;

           Result := HTTPResult(AResponse);

        Finally
           AConnection.Free;
        End;
        End
     Else
        Result := False;
End;

Function TLxWebRecaptcha.Validate(Const Token, Action : String) : Boolean;
Var
   AConnection : TWebHTTPRequest;
   LResponse : TJSXMLHttpRequest;
   AResponse : String;
Begin
     If Not(FValidationUrl = '') Then Begin
        AConnection := TWebHTTPRequest.Create(nil);
        Try
           AConnection.Url := FValidationUrl + Token + '?action=' + Action;
           AConnection.Command := httpGET;

           LResponse := await(TJSXMLHttpRequest, AConnection.Perform);
           AResponse := LResponse.ResponseText;

           Result := HTTPResult(AResponse);

        Finally
           AConnection.Free;
        End;
        End
     Else
        Result := False;
End;


end.
