unit MyPolyCAD.Configuration;

interface

Const
     ConfigurationSupportEmail = 'my@polycad.co.uk';

{$IFDEF CONFIG_LOCALHOST}
       ConfigurationConnection = 'http://localhost:4000/';
     ConfigurationRecaptchaKey = '6Lfn17MlAAAAAF4ZdEdLLWstMX5dfqN-pHDurWo1';
{$ENDIF}

{$IFDEF CONFIG_POLYCAD_LOCAL}
       ConfigurationConnection = 'https://my.polycad.local/';
     ConfigurationRecaptchaKey = '6Lfn17MlAAAAAF4ZdEdLLWstMX5dfqN-pHDurWo1';
{$ENDIF}

{$IFDEF CONFIG_POLYCAD_CO_UK}
       ConfigurationConnection = 'https://my.polycad.co.uk/';
     ConfigurationRecaptchaKey = '6Lf4xLIlAAAAAMk1ayxeR89AnnQYnOqSgAbQe0-i';
{$ENDIF}


implementation

end.
