unit MessageLoginUnit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TMessageLoginForm = class(TForm)
    btnLogin: TButton;
    lbMessage: TLabel;
    lbHeading: TLabel;
    procedure btnLoginClick(Sender: TObject);
  private
    { Private declarations }
    FHeading, FMessage : String;
    Procedure SetHeading(Val : String);
    Procedure SetMessage(Val : String);
  public
    { Public declarations }
    Class Procedure ShowMessage(Const AHeading, AMessage : String);
    Property Heading : String Read FHeading Write SetHeading;
    Property Message : string Read FMessage Write SetMessage;
  protected procedure LoadDFMValues; override; end;

var
  MessageLoginForm: TMessageLoginForm;

implementation

{$R *.dfm}

Uses MyPolyCAD.App, LxWeb.Router;

Class Procedure TMessageLoginForm.ShowMessage(Const AHeading, AMessage : String);
Var
   AForm : TMessageLoginForm;
Begin
     Application.CreateForm(TMessageLoginForm, Application.AppContainer, AForm,
     procedure(AObject : TObject)
     begin
          TMessageLoginForm(AObject).Heading := AHeading;
          TMessageLoginForm(AObject).Message := AMessage;
     end);
End;


Procedure TMessageLoginForm.SetHeading(Val : String);
Begin
     If Not(FHeading = Val) Then Begin
        FHeading := Val;
        lbHeading.Caption := FHeading;
        End;

     If Length(FHeading) > 0 Then
        TJSHTMLElement(lbHeading.ElementHandle.parentNode).style.removeProperty('Display')
     Else
        TJSHTMLElement(lbHeading.ElementHandle.parentNode).style.SetProperty('Display', 'none');
End;

Procedure TMessageLoginForm.SetMessage(Val : String);
Begin
     FMessage := Val;
     lbMessage.HTML := FMessage;
End;

procedure TMessageLoginForm.btnLoginClick(Sender: TObject);
begin
     Router.DefaultRoute;
end;



procedure TMessageLoginForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbMessage := TLabel.Create('lbMessage');
  lbHeading := TLabel.Create('lbHeading');
  btnLogin := TButton.Create('btnLogin');

  lbMessage.BeforeLoadDFMValues;
  lbHeading.BeforeLoadDFMValues;
  btnLogin.BeforeLoadDFMValues;
  try
    Name := 'MessageLoginForm';
    Width := 640;
    Height := 480;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    FormContainer := 'application';
    lbMessage.SetParentComponent(Self);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 24;
    lbMessage.Top := 42;
    lbMessage.Width := 50;
    lbMessage.Height := 13;
    lbMessage.Caption := 'lbMessage';
    lbMessage.ElementFont := efCSS;
    lbMessage.ElementPosition := epIgnore;
    lbMessage.HeightStyle := ssAuto;
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.HTMLType := tDIV;
    lbMessage.WidthStyle := ssAuto;
    lbMessage.WidthPercent := 100.000000000000000000;
    lbHeading.SetParentComponent(Self);
    lbHeading.Name := 'lbHeading';
    lbHeading.Left := 24;
    lbHeading.Top := 23;
    lbHeading.Width := 47;
    lbHeading.Height := 13;
    lbHeading.Caption := 'lbHeading';
    lbHeading.ElementFont := efCSS;
    lbHeading.ElementPosition := epIgnore;
    lbHeading.HeightStyle := ssAuto;
    lbHeading.HeightPercent := 100.000000000000000000;
    lbHeading.HTMLType := tH1;
    lbHeading.WidthStyle := ssAuto;
    lbHeading.WidthPercent := 100.000000000000000000;
    btnLogin.SetParentComponent(Self);
    btnLogin.Name := 'btnLogin';
    btnLogin.Left := 24;
    btnLogin.Top := 67;
    btnLogin.Width := 96;
    btnLogin.Height := 25;
    btnLogin.Caption := 'btnLogin';
    btnLogin.ElementFont := efCSS;
    btnLogin.ElementPosition := epIgnore;
    btnLogin.HeightStyle := ssAuto;
    btnLogin.HeightPercent := 100.000000000000000000;
    btnLogin.WidthStyle := ssAuto;
    btnLogin.WidthPercent := 100.000000000000000000;
    SetEvent(btnLogin, Self, 'OnClick', 'btnLoginClick');
  finally
    lbMessage.AfterLoadDFMValues;
    lbHeading.AfterLoadDFMValues;
    btnLogin.AfterLoadDFMValues;
  end;
end;

end.