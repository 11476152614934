unit ValidateUnit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TValidateForm = class(TForm)
    lbEmail: TLabel;
    btnValidate: TButton;
    btnResend: TButton;
    edValidateCode2: TEdit;
    edValidateCode1: TEdit;
    procedure btnResendClick(Sender: TObject);
    procedure edValidateCodeChange(Sender: TObject);
    procedure btnValidateClick(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    FHasSwitchedEdit : Boolean;
    { Private declarations }
    Function HasKey : Boolean;
    [async]
    Procedure EvaluateResend; async;
    [async]
    Procedure ResendValidation; async;
    [async]
    Procedure ValidateKey; async;
    [async]
    Procedure ValidateShortCode; async;
    Procedure ProcessValidationOutcome(ASuccess : Boolean; AMessage : String);
  public
    { Public declarations }

  protected procedure LoadDFMValues; override; end;

var
  ValidateForm: TValidateForm;

implementation

{$R *.dfm}

Uses MyPolyCAD.App, MyPolyCAD.Configuration, WEBLib.WebTools, LxWeb.Tools, LxWeb.Router, MessageLoginUnit, MessageUnit;

Procedure TValidateForm.EvaluateResend;
Var
   Validated, ResendValidation : Boolean;
Begin
     If not await(boolean, appMyPolyCAD.IsValidating) Then
        Router.DefaultRoute;

     Validated := await(boolean, appMyPolyCAD.IsValidated(ResendValidation));
     btnResend.Enabled := Not Validated and ResendValidation;

     If Validated Then
        Router.DefaultRoute;
End;

Procedure TValidateForm.ResendValidation;
Var
   AMessage, PageMessage : String;
begin
     if await(boolean, appMyPolyCAD.RequestValidationEmail(AMessage)) Then Begin
        btnResend.Enabled := False;
        EvaluateResend;
        End
     Else Begin
        PageMessage := '<p>The email confirmation process used for account validation experienced an error and wasn&apos;t able to complete the full process. This process needs to complete before you can register PolyCAD. Please inform support at <a href="mailto:' + ConfigurationSupportEmail + '">' + ConfigurationSupportEMail + '</a> of the error message below.</p>' +
                       '<p>Reason: ' + AMessage + '</p>';
        TMessageForm.ShowMessage('Validation Failed', PageMessage);
        End;
End;

Function TValidateForm.HasKey : Boolean;
Var
   Location : String;
begin
     Location := window.location.href;
     Result := Location.Contains('?key=');
End;

procedure TValidateForm.ValidateKey;
Var
   Location, Key : String;
   AMessage : String;
   Success : Boolean;
begin
     Location := window.location.href;
     if Location.Contains('?key=') Then Begin
        Key := StringAfter('?key=', Location);

        Success := await(boolean, appMyPolyCAD.ValidateKey(Key, AMessage));
        ProcessValidationOutcome(Success, AMessage);
        End;
end;

procedure TValidateForm.ValidateShortCode;
Var
   ShortCode, Code1, Code2 : String;
   AMessage : String;
   Validated, Success : Boolean;
begin
     Validated := false;
     Code1 := edValidateCode1.Text;
     Code2 := edValidateCode2.Text;
     asm
        Validated = Code1.match(/\d{4}/) && Code2.match(/\d{4}/);
     end;

     If Validated Then Begin
        ShortCode := Code1 + ' ' + Code2;
        Success := await(boolean, appMyPolyCAD.ValidateShortCode(ShortCode, AMessage));
        ProcessValidationOutcome(Success, AMessage);
        End;
end;

Procedure TValidateForm.ProcessValidationOutcome(ASuccess : Boolean; AMessage : String);
Var
   APageMessage : String;
Begin
     If ASuccess Then Begin
        APageMessage := '<p>Thank you for validating your <strong>my.polycad.co.uk</strong> account. If you have not yet downloaded PolyCAD, <a href="https://www.polycad.co.uk/pages/downloads/downloads.html" target="_blank">download</a>, install and register using your <strong>my.polycad.co.uk</strong> account details.</p>' +
                        '<p>If you have any questions or comments, you can reach out to support at <a href="mailto:my@polycad.co.uk">my@polycad.co.uk</a></p>';
        TMessageLoginForm.ShowMessage('Validation Successful', APageMessage);
        End
     Else Begin
        If UpperCase(AMessage) = 'NO VALIDATION PENDING' Then Begin
           APageMessage := '<p>The validation link is invalid or has already been used. If you are still trying to validate your account please login to resend the email link.</p>' +
                           '<p>If you continue to have issues validating your account, please contact support at <a href="mailto:' + ConfigurationSupportEmail + '">' + ConfigurationSupportEmail + '</a></p>';
           End
        Else If UpperCase(AMessage) = 'VALIDATION EXPIRED' Then Begin
           APageMessage := '<p>The validation link has expired. Please login to resend the email link.</p>' +
                           '<p>If you continue to have issues validating your account, please contact support at <a href="mailto:' + ConfigurationSupportEmail + '">' + ConfigurationSupportEmail + '</a></p>';
           End
        Else If UpperCase(AMessage) = 'UNABLE TO VALIDATE, SESSION HAS EXPIRED' Then Begin
           APageMessage := '<p>The session has expired. Please login to complete the validation process.</p>' +
                           '<p>If you continue to have issues validating your account, please contact support at <a href="mailto:' + ConfigurationSupportEmail + '">' + ConfigurationSupportEmail + '</a></p>';
           End
        Else Begin
           APageMessage := '<p>The validation link failed to validate your account.</p>' +
                           '<p>Reason: ' + AMessage + '</p>' +
                           '<p>If you continue to have issues validating your account, please contact support at <a href="mailto:' + ConfigurationSupportEmail + '">' + ConfigurationSupportEmail + '</a></p>';
           End;

        TMessageLoginForm.ShowMessage('Validation Failed', APageMessage);
        End;
End;

procedure TValidateForm.WebFormShow(Sender: TObject);
begin
     FHasSwitchedEdit := False;
     If HasKey Then
        ValidateKey
     Else Begin
        lbEmail.Caption := appMyPolyCAD.User.Email;
        EvaluateResend;
        End;
end;

{$HINTS OFF}

procedure TValidateForm.edValidateCodeChange(Sender: TObject);
Var
   Code1, Code2 : String;
   Validated : Boolean;
begin
     Validated := false;
     Code1 := edValidateCode1.Text;
     Code2 := edValidateCode2.Text;
     If (Length(Code1) = 4) and (Length(Code2) = 0) and Not FHasSwitchedEdit Then Begin
        edValidateCode2.SetFocus;
        FHasSwitchedEdit := True;
        End;

     asm
        Validated = Code1.match(/\d{4}/) && Code2.match(/\d{4}/);
     end;

     btnValidate.Enabled := Validated;
end;

{$HINTS ON}

procedure TValidateForm.btnResendClick(Sender: TObject);
begin
     ResendValidation;
end;

procedure TValidateForm.btnValidateClick(Sender: TObject);
begin
     ValidateShortCode;
end;







procedure TValidateForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbEmail := TLabel.Create('lbEMail');
  btnValidate := TButton.Create('btnValidate');
  btnResend := TButton.Create('btnResend');
  edValidateCode2 := TEdit.Create('edValidateCode2');
  edValidateCode1 := TEdit.Create('edValidateCode1');

  lbEmail.BeforeLoadDFMValues;
  btnValidate.BeforeLoadDFMValues;
  btnResend.BeforeLoadDFMValues;
  edValidateCode2.BeforeLoadDFMValues;
  edValidateCode1.BeforeLoadDFMValues;
  try
    Name := 'ValidateForm';
    Width := 761;
    Height := 567;
    ElementFont := efCSS;
    FormContainer := 'application';
    SetEvent(Self, 'OnShow', 'WebFormShow');
    lbEmail.SetParentComponent(Self);
    lbEmail.Name := 'lbEmail';
    lbEmail.Left := 8;
    lbEmail.Top := 16;
    lbEmail.Width := 54;
    lbEmail.Height := 13;
    lbEmail.Caption := 'email.name';
    lbEmail.HeightPercent := 100.000000000000000000;
    lbEmail.WidthPercent := 100.000000000000000000;
    btnValidate.SetParentComponent(Self);
    btnValidate.Name := 'btnValidate';
    btnValidate.Left := 0;
    btnValidate.Top := 44;
    btnValidate.Width := 96;
    btnValidate.Height := 25;
    btnValidate.Caption := 'btnValidate';
    btnValidate.ChildOrder := 1;
    btnValidate.ElementFont := efCSS;
    btnValidate.ElementPosition := epIgnore;
    btnValidate.Enabled := False;
    btnValidate.HeightStyle := ssAuto;
    btnValidate.HeightPercent := 100.000000000000000000;
    btnValidate.WidthStyle := ssAuto;
    btnValidate.WidthPercent := 100.000000000000000000;
    SetEvent(btnValidate, Self, 'OnClick', 'btnValidateClick');
    btnResend.SetParentComponent(Self);
    btnResend.Name := 'btnResend';
    btnResend.Left := 0;
    btnResend.Top := 75;
    btnResend.Width := 96;
    btnResend.Height := 25;
    btnResend.Caption := 'btnResend';
    btnResend.ChildOrder := 4;
    btnResend.ElementFont := efCSS;
    btnResend.ElementPosition := epIgnore;
    btnResend.Enabled := False;
    btnResend.HeightStyle := ssAuto;
    btnResend.HeightPercent := 100.000000000000000000;
    btnResend.WidthStyle := ssAuto;
    btnResend.WidthPercent := 100.000000000000000000;
    SetEvent(btnResend, Self, 'OnClick', 'btnResendClick');
    edValidateCode2.SetParentComponent(Self);
    edValidateCode2.Name := 'edValidateCode2';
    edValidateCode2.Left := 0;
    edValidateCode2.Top := 137;
    edValidateCode2.Width := 96;
    edValidateCode2.Height := 25;
    edValidateCode2.ChildOrder := 4;
    edValidateCode2.EditType := weNumeric;
    edValidateCode2.ElementFont := efCSS;
    edValidateCode2.ElementPosition := epIgnore;
    edValidateCode2.HeightStyle := ssAuto;
    edValidateCode2.HeightPercent := 100.000000000000000000;
    edValidateCode2.MaxLength := 6;
    edValidateCode2.Pattern := '\d{4}';
    edValidateCode2.Required := True;
    edValidateCode2.WidthStyle := ssAuto;
    edValidateCode2.WidthPercent := 100.000000000000000000;
    SetEvent(edValidateCode2, Self, 'OnChange', 'edValidateCodeChange');
    edValidateCode1.SetParentComponent(Self);
    edValidateCode1.Name := 'edValidateCode1';
    edValidateCode1.Left := 0;
    edValidateCode1.Top := 106;
    edValidateCode1.Width := 96;
    edValidateCode1.Height := 25;
    edValidateCode1.ChildOrder := 5;
    edValidateCode1.ElementFont := efCSS;
    edValidateCode1.ElementPosition := epIgnore;
    edValidateCode1.HeightStyle := ssAuto;
    edValidateCode1.HeightPercent := 100.000000000000000000;
    edValidateCode1.MaxLength := 6;
    edValidateCode1.Pattern := '\d{4}';
    edValidateCode1.Required := True;
    edValidateCode1.WidthStyle := ssAuto;
    edValidateCode1.WidthPercent := 100.000000000000000000;
    SetEvent(edValidateCode1, Self, 'OnChange', 'edValidateCodeChange');
  finally
    lbEmail.AfterLoadDFMValues;
    btnValidate.AfterLoadDFMValues;
    btnResend.AfterLoadDFMValues;
    edValidateCode2.AfterLoadDFMValues;
    edValidateCode1.AfterLoadDFMValues;
  end;
end;

end.