unit Model.Users;

interface

Uses Classes, SysUtils, EndPoints.Types, WEBLib.JSON;

Type
     TMyMailPreference = (mpNewRelease, mpNewArticle);
    TMyMailPreferences = set of TMyMailPreference;

                 TUser = Class(TObject)
                         Protected
                         FId : String;
                         FName : String;
                         FEmail : String;
                         FOrganisation : String;
                         FLocation : String;
                         FStatus : Integer;
                         FMailPreferences : Integer;
                         FInvalidated : Boolean;

                         Function GetIsValidated : Boolean;
                         Function GetIsUnvalidated : Boolean;
                         Function GetIsAdministrator : Boolean;
                         Function GetIsChangePassword : Boolean;
                         Function GetIsDisabled : Boolean;
                         Function GetMailPreferences : TMyMailPreferences;
                         //Procedure SetName(Val : String);
                         //Procedure SetEmail(Val : String);
                         //Procedure SetOrganisation(Val : String);
                         //Procedure SetStatus(Val : Integer);
                         Procedure Invalidate;
                         Public
                         Constructor Create; overload;
                         Function Invalidated : Boolean;
                         Procedure fromJSON(AJSONObject : TJSONObject);
                         Procedure toJSON(AJSONObject : TJSONObject);
                         Property Id : String Read FId;
                         Property Name : String Read FName;
                         Property Email : String Read FEmail;
                         Property Organisation : String Read FOrganisation;
                         Property Location : String Read FLocation;
                         Property Status : Integer Read FStatus;
                         Property MailPreferences : TMyMailPreferences Read GetMailPreferences;

                         Property IsValidated : Boolean Read GetIsValidated;
                         Property IsUnvalidated : Boolean Read GetIsUnvalidated;
                         Property IsAdministrator : Boolean Read GetIsAdministrator;
                         Property IsChangePassword : Boolean Read GetIsChangePassword;
                         Property IsDisabled : Boolean Read GetIsDisabled;
                         End;

                TUsers = Class(TObject)
                         Private
                         FList : TList;
                         Function GetCount : Integer;
                         Function GetUser(Index : Integer) : TUser;
                         Protected
                         Function CreateUser : TUser; virtual;
                         Procedure fromJSON(AJSONArray : TJSONArray);
                         Procedure Add(AUser : TUser); overload;
                         Procedure Delete(AUser : TUser); overload;
                         Public
                         Constructor Create;
                         Destructor Destroy; override;
                         Procedure Clear;
                         Function First : TUser;
                         Function Last : TUser;
                         Function Contains(AUserId : String) : Boolean; overload;
                         Function Contains(AUser : TUser) : Boolean;  overload;
                         Function IndexOf(AUserId : String) : Integer; overload;
                         Function IndexOf(AUser : TUser) : Integer; overload;

                         Function Update(AConnection : IHttpConnection) : Boolean; virtual; abstract; async;
                         Procedure Enumerate(AStrings : TStrings);




                         Property Count : Integer Read GetCount;
                         Property Users[Index : Integer] : TUser Read GetUser; default;

                         End;

Const
     statusValidated = $0020;        // 0000 0000 0010 0000
     statusUnvalidated = $0004;      // 0000 0000 0000 0100
     statusAdmin = $2918;            // 0010 1001 0001 1000
     statusChangePassword = $0481;   // 0000 0100 1000 0001
     statusDisabled = $4242;         // 0100 0010 0100 0010

implementation

Uses Js, Web, LxWeb.Json;

{$region '************************************************* TUser *********************************************************'}

Constructor TUser.Create;
Begin
     Inherited Create;
     FInvalidated := False;
     FId := '';
End;

Function TUser.GetIsValidated : Boolean;
Begin
     Result := (FStatus and statusValidated) = statusValidated;
End;

Function TUser.GetIsUnvalidated : Boolean;
Begin
     Result := (FStatus and statusUnvalidated) = statusUnvalidated;
End;

Function TUser.GetIsAdministrator : Boolean;
Begin
     Result := (FStatus and statusAdmin) = statusAdmin;
End;

Function TUser.GetIsChangePassword : Boolean;
Begin
     Result := (FStatus and statusChangePassword) = statusChangePassword;
End;

Function TUser.GetIsDisabled : Boolean;
Begin
     Result := (FStatus and statusDisabled) = statusDisabled;
End;

Function TUser.GetMailPreferences : TMyMailPreferences;
Begin
     Result := [];
     If (FMailPreferences and $1) = $1 Then Include(Result, mpNewRelease);
     If (FMailPreferences and $2) = $2 Then Include(Result, mpNewArticle);
End;

Procedure TUser.Invalidate;
Begin
     FInvalidated := True;
End;

Function TUser.Invalidated : Boolean;
Begin
     Result := FInvalidated;
End;

Procedure TUser.fromJSON(AJSONObject : TJSONObject);
Var
   jIter : TJSONIterator;
Begin
     For jIter in AJSONObject Do
         If jIter.KeyIs('Id', jdString) Then
            FId := jIter.AsString
         Else If jIter.KeyIs('Name', jdString) Then
            FName := jIter.AsString
         Else If jIter.KeyIs('EMail', jdString) Then
            FEMail := jIter.AsString
         Else If jIter.KeyIs('Organisation', jdString) Then
            FOrganisation := jIter.AsString
         Else If jIter.KeyIs('Status', jdInteger) Then
            FStatus := jIter.AsInteger
         Else If jIter.KeyIs('Location', jdString) Then
            FLocation := jIter.AsString
         Else If jIter.KeyIs('MailPreferences', jdInteger) Then
            FMailPreferences := jIter.AsInteger;

     FInvalidated := False;
End;

Procedure TUser.toJSON(AJSONObject : TJSONObject);
Begin
     AJSONObject.AddPair('Id', FId);
     AJSONObject.AddPair('Name', FName);
     AJSONObject.AddPair('EMail', FEMail);
     AJSONObject.AddPair('Organisation', FOrganisation);
     AJSONObject.AddPair('Status', FStatus);
End;

{$endregion}

{$region '************************************************* TUsers ********************************************************'}

Constructor TUsers.Create;
Begin
     Inherited Create;
     FList := TList.Create;
End;

Destructor TUsers.Destroy;
Begin
     Clear;
     FList.Free;
     Inherited Destroy;
End;

Function TUsers.GetCount : Integer;
Begin
     Result := FList.Count;
End;

Function TUsers.GetUser(Index : Integer) : TUser;
Begin
     Result := TUser(FList[Index]);
End;

Procedure TUsers.Add(AUser : TUser);
Begin
     FList.Add(AUser);
End;

Procedure TUsers.Delete(AUser : TUser);
Begin
     If Contains(AUser) Then Begin
        FList.Remove(AUser);
        AUser.Free;
        End;
End;

Procedure TUsers.Clear;
Var
   I : Integer;
   Usr : TUser;
Begin
     For I := 0 to Count - 1 Do Begin
         Usr := Users[I];
         Usr.Free;
         End;

     FList.Clear;
End;

Function TUsers.CreateUser : TUser;
Begin
     Result := TUser.Create;
End;

Procedure TUsers.fromJSON(AJSONArray : TJSONArray);
Var
   jIter : TJSONIterator;
   AUser : TUser;
Begin
     Clear;

     For jIter in AJSONArray Do
         If jIter.DataType = jdObject Then Begin
            AUser := CreateUser;
            AUser.fromJSON(jIter.AsObject);
            FList.Add(AUser);
            End;
End;

Function TUsers.First : TUser;
Begin
     Result := TUser(FList.First);
End;

Function TUsers.Last : TUser;
Begin
     Result := TUser(FList.Last);
End;

Function TUsers.Contains(AUserId : String) : Boolean;
Begin
     Result := IndexOf(AUserId) > -1;
End;

Function TUsers.Contains(AUser : TUser) : Boolean;
Begin
     Result := IndexOf(AUser) > -1;
End;

Function TUsers.IndexOf(AUserId : String) : Integer;
Var
   I : Integer;
   UCase : String;
Begin
     Result := -1;
     UCase := UpperCase(AUserId);

     For I := 0 to Count - 1 Do
         If UCase = UpperCase(Users[I].Id) Then Begin
            Result := I;
            Break;
            End;
End;

Function TUsers.IndexOf(AUser : TUser) : Integer;
Begin
     Result := FList.IndexOf(AUser);
End;

Procedure TUsers.Enumerate(AStrings : TStrings);
Var
   I : Integer;
Begin
     AStrings.BeginUpdate;
     Try
        AStrings.Clear;

        For I := 0 to Count - 1 DO
            AStrings.AddObject(Users[I].Name + ' (' + Users[I].Id + ')', Users[I]);

     Finally
        AStrings.EndUpdate;
     End;
End;

{$endregion}


end.
