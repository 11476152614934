unit ProfileUnit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  WEBLib.REST, Admin.Systems, EndPoints.Connection, DB, WEBLib.Grids,
  WEBLib.FlexControls;

type
  TProfileForm = class(TForm)
    edOrganisation: TEdit;
    chkMailRelease: TCheckBox;
    edFullName: TEdit;
    chkMailArticle: TCheckBox;
    WebHttpRequest1: THttpRequest;
    btnSubmit: TButton;
    lbEMail: TLabel;
    edLocation: TEdit;
    WebStretchPanel1: TStretchPanel;
    WebTableControl1: TTableControl;
    btnLogout: TButton;
    lblNoSystemsRegistered: TLabel;
    btnAdministration: TButton;
    procedure btnSubmitClick(Sender: TObject);
    procedure btnLogoutClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure pageChange(Sender: TObject);
  private
    { Private declarations }
    [async]
    Procedure UpdateApp; async;
    Procedure UpdateUser;
    [async]
    Procedure UpdateSystems; async;
    [async]
    Procedure SubmitFeedback; async;
    [async]
    Procedure Logout; async;
    [async]
    Procedure DeleteSystem(ASystemId : String); async;
    Function imgClick(aEvent : TJSMouseEvent) : Boolean;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  ProfileForm: TProfileForm;

implementation

{$R *.dfm}

Uses LxWeb.Router, MyPolyCAD.App, Model.Systems.Presentation, MyPolyCAD.Model.User;

procedure TProfileForm.WebFormCreate(Sender: TObject);
begin
     UpdateApp;

     edFullName.MaxLength := appMyPolyCAD.UserName_MAXLENGTH;
     edOrganisation.MaxLength := appMyPolyCAD.Organisation_MAXLENGTH;
     edLocation.MaxLength := appMyPolyCAD.Location_MAXLENGTH;
end;

Procedure TProfileForm.UpdateApp;
Begin
     if await(boolean, appMyPolyCAD.Update) Then Begin
        UpdateUser;
        UpdateSystems;
        End;
End;


Procedure TProfileForm.UpdateUser;
begin
     lbEMail.Caption := appMyPolyCAD.User.EMail;
     edFullName.Text := appMyPolyCAD.User.Name;
     edOrganisation.Text := appMyPolyCAD.User.Organisation;
     edLocation.Text := appMyPolyCAD.User.Location;
     chkMailRelease.Checked := mpNewRelease in appMyPolyCAD.User.MailPreferences;
     chkMailArticle.Checked := mpNewArticle in appMyPolyCAD.User.MailPreferences;

     btnAdministration.Visible := appMyPolyCAD.User.IsAdministrator;
     btnAdministration.Enabled := appMyPolyCAD.User.IsAdministrator;
end;

Procedure TProfileForm.UpdateSystems;
Var
   I : Integer;
   aImage : TJSHTMLImageElement;
Begin
     If appMyPolyCAD.Systems.Count > 0 Then Begin
        SystemsToTable(appMyPolyCAD.Systems, WebTableControl1);

        WebTableControl1.ColCount := 7;
        For I := 1 to WebTableControl1.RowCount - 1 Do Begin
            aImage := TJSHTMLImageElement(document.CreateElement('IMG'));

            if Odd(I) Then
               aImage.src := 'images/delete_white.svg'
            else
               aImage.src := 'images/delete_skyblue.svg';

            aImage.Title := 'Delete';
            aImage.Id := appMyPolyCAD.Systems[I-1].SystemId;
            aImage.OnClick := imgClick;
            WebTableControl1.CellElements[6, I].appendChild(aImage);
            End;
        End
     Else Begin
        WebStretchPanel1.Visible := False;
        lblNoSystemsRegistered.Visible := True;
        End;

End;

Procedure TProfileForm.SubmitFeedback;
Begin
     appMyPolyCAD.User.Name := edFullName.Text;
     appMyPolyCAD.User.Organisation := edOrganisation.Text;
     appMyPolyCAD.User.Location := edLocation.Text;

     If chkMailRelease.Checked Then
        appMyPolyCAD.User.MailPreferences := appMyPolyCAD.User.MailPreferences + [mpNewRelease]
     Else
        appMyPolyCAD.User.MailPreferences := appMyPolyCAD.User.MailPreferences - [mpNewRelease];

     If chkMailArticle.Checked Then
        appMyPolyCAD.User.MailPreferences := appMyPolyCAD.User.MailPreferences + [mpNewArticle]
     Else
        appMyPolyCAD.User.MailPreferences := appMyPolyCAD.User.MailPreferences - [mpNewArticle];


     await(boolean, appMyPolyCAD.Submit);
End;

Procedure TProfileForm.Logout;
Begin
     await(boolean, appMyPolyCAD.Logout);
     Router.Route('/login');
End;


Procedure TProfileForm.DeleteSystem(ASystemId : String);
Begin
     If await(boolean, appMyPolyCAD.DeleteSystem(ASystemId)) Then
        UpdateSystems;
End;

Function TProfileForm.imgClick(aEvent : TJSMouseEvent) : Boolean;
Begin
     DeleteSystem(aEvent.TargetElement.Id);
     Result := True;
End;

procedure TProfileForm.pageChange(Sender: TObject);
begin
     btnSubmit.Enabled := True;
end;

procedure TProfileForm.btnSubmitClick(Sender: TObject);
begin
     SubmitFeedback;
     btnSubmit.Enabled := False;
end;

procedure TProfileForm.btnLogoutClick(Sender: TObject);
begin
     Logout;
end;





procedure TProfileForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbEMail := TLabel.Create('lbEMail');
  lblNoSystemsRegistered := TLabel.Create('lblNoSystemsRegistered');
  edOrganisation := TEdit.Create('edOrganisation');
  chkMailRelease := TCheckBox.Create('chkMailRelease');
  edFullName := TEdit.Create('edFullName');
  chkMailArticle := TCheckBox.Create('chkMailArticle');
  btnSubmit := TButton.Create('btnSubmit');
  edLocation := TEdit.Create('edLocation');
  WebStretchPanel1 := TStretchPanel.Create('tableContainer');
  WebTableControl1 := TTableControl.Create(Self);
  btnLogout := TButton.Create('btnLogout');
  btnAdministration := TButton.Create('btnAdministration');
  WebHttpRequest1 := THttpRequest.Create(Self);

  lbEMail.BeforeLoadDFMValues;
  lblNoSystemsRegistered.BeforeLoadDFMValues;
  edOrganisation.BeforeLoadDFMValues;
  chkMailRelease.BeforeLoadDFMValues;
  edFullName.BeforeLoadDFMValues;
  chkMailArticle.BeforeLoadDFMValues;
  btnSubmit.BeforeLoadDFMValues;
  edLocation.BeforeLoadDFMValues;
  WebStretchPanel1.BeforeLoadDFMValues;
  WebTableControl1.BeforeLoadDFMValues;
  btnLogout.BeforeLoadDFMValues;
  btnAdministration.BeforeLoadDFMValues;
  WebHttpRequest1.BeforeLoadDFMValues;
  try
    Name := 'ProfileForm';
    Width := 838;
    Height := 614;
    ElementFont := efCSS;
    ElementPosition := epRelative;
    FormContainer := 'application';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbEMail.SetParentComponent(Self);
    lbEMail.Name := 'lbEMail';
    lbEMail.Left := 8;
    lbEMail.Top := 19;
    lbEMail.Width := 23;
    lbEMail.Height := 13;
    lbEMail.Caption := 'Hello';
    lbEMail.ElementFont := efCSS;
    lbEMail.ElementPosition := epIgnore;
    lbEMail.HeightStyle := ssAuto;
    lbEMail.HeightPercent := 100.000000000000000000;
    lbEMail.WidthStyle := ssAuto;
    lbEMail.WidthPercent := 100.000000000000000000;
    lblNoSystemsRegistered.SetParentComponent(Self);
    lblNoSystemsRegistered.Name := 'lblNoSystemsRegistered';
    lblNoSystemsRegistered.Left := 8;
    lblNoSystemsRegistered.Top := 400;
    lblNoSystemsRegistered.Width := 111;
    lblNoSystemsRegistered.Height := 13;
    lblNoSystemsRegistered.Caption := 'No systems registered.';
    lblNoSystemsRegistered.HeightPercent := 100.000000000000000000;
    lblNoSystemsRegistered.HTMLType := tDIV;
    lblNoSystemsRegistered.Visible := False;
    lblNoSystemsRegistered.WidthPercent := 100.000000000000000000;
    edOrganisation.SetParentComponent(Self);
    edOrganisation.Name := 'edOrganisation';
    edOrganisation.Left := 8;
    edOrganisation.Top := 69;
    edOrganisation.Width := 209;
    edOrganisation.Height := 25;
    edOrganisation.ChildOrder := 3;
    edOrganisation.ElementFont := efCSS;
    edOrganisation.ElementPosition := epIgnore;
    edOrganisation.HeightStyle := ssAuto;
    edOrganisation.HeightPercent := 100.000000000000000000;
    edOrganisation.WidthStyle := ssAuto;
    edOrganisation.WidthPercent := 100.000000000000000000;
    SetEvent(edOrganisation, Self, 'OnChange', 'pageChange');
    chkMailRelease.SetParentComponent(Self);
    chkMailRelease.Name := 'chkMailRelease';
    chkMailRelease.Left := 8;
    chkMailRelease.Top := 504;
    chkMailRelease.Width := 96;
    chkMailRelease.Height := 25;
    chkMailRelease.Caption := 'chkMailRelease';
    chkMailRelease.ChildOrder := 17;
    chkMailRelease.Color := clNone;
    chkMailRelease.ElementFont := efCSS;
    chkMailRelease.ElementPosition := epIgnore;
    chkMailRelease.HeightStyle := ssAuto;
    chkMailRelease.HeightPercent := 100.000000000000000000;
    chkMailRelease.State := cbUnchecked;
    chkMailRelease.WidthStyle := ssAuto;
    chkMailRelease.WidthPercent := 100.000000000000000000;
    SetEvent(chkMailRelease, Self, 'OnClick', 'pageChange');
    edFullName.SetParentComponent(Self);
    edFullName.Name := 'edFullName';
    edFullName.Left := 8;
    edFullName.Top := 38;
    edFullName.Width := 209;
    edFullName.Height := 25;
    edFullName.ChildOrder := 27;
    edFullName.ElementFont := efCSS;
    edFullName.ElementPosition := epIgnore;
    edFullName.HeightStyle := ssAuto;
    edFullName.HeightPercent := 100.000000000000000000;
    edFullName.WidthStyle := ssAuto;
    edFullName.WidthPercent := 100.000000000000000000;
    SetEvent(edFullName, Self, 'OnChange', 'pageChange');
    chkMailArticle.SetParentComponent(Self);
    chkMailArticle.Name := 'chkMailArticle';
    chkMailArticle.Left := 8;
    chkMailArticle.Top := 528;
    chkMailArticle.Width := 96;
    chkMailArticle.Height := 25;
    chkMailArticle.Caption := 'chkMailArticle';
    chkMailArticle.ChildOrder := 28;
    chkMailArticle.Color := clNone;
    chkMailArticle.ElementFont := efCSS;
    chkMailArticle.ElementPosition := epIgnore;
    chkMailArticle.HeightStyle := ssAuto;
    chkMailArticle.HeightPercent := 100.000000000000000000;
    chkMailArticle.State := cbUnchecked;
    chkMailArticle.WidthStyle := ssAuto;
    chkMailArticle.WidthPercent := 100.000000000000000000;
    SetEvent(chkMailArticle, Self, 'OnClick', 'pageChange');
    btnSubmit.SetParentComponent(Self);
    btnSubmit.Name := 'btnSubmit';
    btnSubmit.Left := 8;
    btnSubmit.Top := 559;
    btnSubmit.Width := 96;
    btnSubmit.Height := 25;
    btnSubmit.Caption := 'Submit';
    btnSubmit.ChildOrder := 25;
    btnSubmit.ElementFont := efCSS;
    btnSubmit.ElementPosition := epIgnore;
    btnSubmit.Enabled := False;
    btnSubmit.HeightStyle := ssAuto;
    btnSubmit.HeightPercent := 100.000000000000000000;
    btnSubmit.WidthStyle := ssAuto;
    btnSubmit.WidthPercent := 100.000000000000000000;
    SetEvent(btnSubmit, Self, 'OnClick', 'btnSubmitClick');
    edLocation.SetParentComponent(Self);
    edLocation.Name := 'edLocation';
    edLocation.Left := 8;
    edLocation.Top := 99;
    edLocation.Width := 209;
    edLocation.Height := 25;
    edLocation.ChildOrder := 3;
    edLocation.ElementFont := efCSS;
    edLocation.ElementPosition := epIgnore;
    edLocation.HeightStyle := ssAuto;
    edLocation.HeightPercent := 100.000000000000000000;
    edLocation.WidthStyle := ssAuto;
    edLocation.WidthPercent := 100.000000000000000000;
    SetEvent(edLocation, Self, 'OnChange', 'pageChange');
    WebStretchPanel1.SetParentComponent(Self);
    WebStretchPanel1.Name := 'WebStretchPanel1';
    WebStretchPanel1.Left := 8;
    WebStretchPanel1.Top := 136;
    WebStretchPanel1.Width := 709;
    WebStretchPanel1.Height := 250;
    WebStretchPanel1.ElementClassName := 'tblContainer';
    WebStretchPanel1.HeightStyle := ssAuto;
    WebStretchPanel1.WidthStyle := ssAuto;
    WebStretchPanel1.BorderColor := clSilver;
    WebStretchPanel1.BorderStyle := bsSingle;
    WebStretchPanel1.ChildOrder := 8;
    WebStretchPanel1.Color := clWhite;
    WebStretchPanel1.ElementFont := efCSS;
    WebStretchPanel1.ElementPosition := epIgnore;
    WebStretchPanel1.FixedHeight := 5;
    WebTableControl1.SetParentComponent(WebStretchPanel1);
    WebTableControl1.Name := 'WebTableControl1';
    WebTableControl1.Left := 60;
    WebTableControl1.Top := 16;
    WebTableControl1.Width := 553;
    WebTableControl1.Height := 200;
    WebTableControl1.HeightStyle := ssAuto;
    WebTableControl1.WidthStyle := ssPercent;
    WebTableControl1.BorderColor := clSilver;
    WebTableControl1.ElementFont := efCSS;
    WebTableControl1.ElementPosition := epIgnore;
    WebTableControl1.ElementRowSelectClassName := 'hello';
    WebTableControl1.ElementSelectionClassName := 'hello';
    btnLogout.SetParentComponent(Self);
    btnLogout.Name := 'btnLogout';
    btnLogout.Left := 110;
    btnLogout.Top := 559;
    btnLogout.Width := 96;
    btnLogout.Height := 25;
    btnLogout.Caption := 'Logout';
    btnLogout.ChildOrder := 9;
    btnLogout.ElementFont := efCSS;
    btnLogout.ElementPosition := epIgnore;
    btnLogout.HeightStyle := ssAuto;
    btnLogout.HeightPercent := 100.000000000000000000;
    btnLogout.WidthStyle := ssAuto;
    btnLogout.WidthPercent := 100.000000000000000000;
    SetEvent(btnLogout, Self, 'OnClick', 'btnLogoutClick');
    btnAdministration.SetParentComponent(Self);
    btnAdministration.Name := 'btnAdministration';
    btnAdministration.Left := 212;
    btnAdministration.Top := 559;
    btnAdministration.Width := 96;
    btnAdministration.Height := 25;
    btnAdministration.Caption := 'Administration';
    btnAdministration.ChildOrder := 11;
    btnAdministration.ElementFont := efCSS;
    btnAdministration.ElementPosition := epIgnore;
    btnAdministration.HeightStyle := ssAuto;
    btnAdministration.HeightPercent := 100.000000000000000000;
    btnAdministration.Visible := False;
    btnAdministration.WidthStyle := ssAuto;
    btnAdministration.WidthPercent := 100.000000000000000000;
    WebHttpRequest1.SetParentComponent(Self);
    WebHttpRequest1.Name := 'WebHttpRequest1';
    WebHttpRequest1.Command := httpPUT;
    WebHttpRequest1.Headers.BeginUpdate;
    try
      WebHttpRequest1.Headers.Clear;
      WebHttpRequest1.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
      WebHttpRequest1.Headers.Add('Content-Type=application/json');
      WebHttpRequest1.Headers.Add('Accept=application/json');
    finally
      WebHttpRequest1.Headers.EndUpdate;
    end;
    WebHttpRequest1.ResponseType := rtText;
    WebHttpRequest1.URL := 'http://localhost:4000/feedback';
    WebHttpRequest1.Left := 152;
    WebHttpRequest1.Top := 568;
  finally
    lbEMail.AfterLoadDFMValues;
    lblNoSystemsRegistered.AfterLoadDFMValues;
    edOrganisation.AfterLoadDFMValues;
    chkMailRelease.AfterLoadDFMValues;
    edFullName.AfterLoadDFMValues;
    chkMailArticle.AfterLoadDFMValues;
    btnSubmit.AfterLoadDFMValues;
    edLocation.AfterLoadDFMValues;
    WebStretchPanel1.AfterLoadDFMValues;
    WebTableControl1.AfterLoadDFMValues;
    btnLogout.AfterLoadDFMValues;
    btnAdministration.AfterLoadDFMValues;
    WebHttpRequest1.AfterLoadDFMValues;
  end;
end;

end.
