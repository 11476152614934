unit SignupUnit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  WEBLib.REST, MyPolyCAD.Model, LxWebPasswordStrength, WEBLib.WebCtrls;

type
  TSignupForm = class(TForm)
    edEmail: TEdit;
    edPassword: TEdit;
    btnSubmit: TButton;
    LxWebPasswordStrength1: TLxWebPasswordStrength;
    divMessage: THTMLDiv;
    lbMessage: TLabel;
    procedure edEmailChange(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure btnSubmitClick(Sender: TObject);
    procedure edPasswordChange(Sender: TObject);
  private
    { Private declarations }
    [async]
    Procedure Submit; async;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  SignupForm: TSignupForm;

implementation

{$R *.dfm}

Uses LxWeb.Router, LxWeb.Tools, myPolyCAD.App;

Procedure TSignupForm.Submit;
Var
   AMessage : String;
   Success : Boolean;
Begin
     btnSubmit.Cursor := crHourGlass;
     Success := await(boolean, appMyPolyCAD.preSignup(edEmail.Text, edPassword.Text, AMessage));

     If Success Then
        Router.Route('/license')
     Else If UpperCase(AMessage).Contains('REQUIRES VALIDATION') Then
        Router.Route('/validate')
     Else Begin
        btnSubmit.Cursor := crDefault;
        lbMessage.Caption := 'Error: ' + AMessage;
        divMessage.Visible := True;
        End;
End;

procedure TSignupForm.WebFormCreate(Sender: TObject);
begin
     btnSubmit.Enabled := false;
     edEMail.MaxLength := appMyPolyCAD.EMail_MAXLENGTH;
  edPassword.MaxLength := appMyPolyCAD.Password_MAXLENGTH;
end;

procedure TSignupForm.edEmailChange(Sender: TObject);
begin
     divMessage.Visible := false;
     btnSubmit.Enabled := ValidateEmail(edEmail.Text) and CheckPasswordStrength(edPassword.Text);

end;

procedure TSignupForm.edPasswordChange(Sender: TObject);
Var
   AStrength : Integer;
   ATips : String;
begin
     divMessage.Visible := false;
     AStrength := CheckPasswordStrength(edPassword.Text, ATips);
     btnSubmit.Enabled := (AStrength >= 4) and ValidateEmail(edEmail.Text);
     LxWebPasswordStrength1.Strength := AStrength;
     LxWebPasswordStrength1.Hint := ATips;
     edPassword.Hint := ATips;

end;

procedure TSignupForm.btnSubmitClick(Sender: TObject);
begin
     Submit;
end;


procedure TSignupForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  edEmail := TEdit.Create('edEmail');
  edPassword := TEdit.Create('edPassword');
  btnSubmit := TButton.Create('btnSubmit');
  LxWebPasswordStrength1 := TLxWebPasswordStrength.Create('lbPasswordStrength');
  divMessage := THTMLDiv.Create('divMessage');
  lbMessage := TLabel.Create('lbMessage');

  edEmail.BeforeLoadDFMValues;
  edPassword.BeforeLoadDFMValues;
  btnSubmit.BeforeLoadDFMValues;
  LxWebPasswordStrength1.BeforeLoadDFMValues;
  divMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  try
    Name := 'SignupForm';
    Width := 640;
    Height := 480;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    FormContainer := 'application';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    edEmail.SetParentComponent(Self);
    edEmail.Name := 'edEmail';
    edEmail.Left := 0;
    edEmail.Top := 0;
    edEmail.Width := 96;
    edEmail.Height := 25;
    edEmail.ElementFont := efCSS;
    edEmail.ElementPosition := epIgnore;
    edEmail.HeightStyle := ssAuto;
    edEmail.HeightPercent := 100.000000000000000000;
    edEmail.WidthStyle := ssAuto;
    edEmail.WidthPercent := 100.000000000000000000;
    SetEvent(edEmail, Self, 'OnChange', 'edEmailChange');
    edPassword.SetParentComponent(Self);
    edPassword.Name := 'edPassword';
    edPassword.Left := 0;
    edPassword.Top := 31;
    edPassword.Width := 96;
    edPassword.Height := 25;
    edPassword.ChildOrder := 1;
    edPassword.ElementFont := efCSS;
    edPassword.ElementPosition := epIgnore;
    edPassword.HeightStyle := ssAuto;
    edPassword.HeightPercent := 100.000000000000000000;
    edPassword.ShowHint := True;
    edPassword.WidthStyle := ssAuto;
    edPassword.WidthPercent := 100.000000000000000000;
    SetEvent(edPassword, Self, 'OnChange', 'edPasswordChange');
    btnSubmit.SetParentComponent(Self);
    btnSubmit.Name := 'btnSubmit';
    btnSubmit.Left := 0;
    btnSubmit.Top := 62;
    btnSubmit.Width := 96;
    btnSubmit.Height := 25;
    btnSubmit.Caption := 'Submit';
    btnSubmit.ChildOrder := 2;
    btnSubmit.ElementFont := efCSS;
    btnSubmit.ElementPosition := epIgnore;
    btnSubmit.HeightStyle := ssAuto;
    btnSubmit.HeightPercent := 100.000000000000000000;
    btnSubmit.WidthStyle := ssAuto;
    btnSubmit.WidthPercent := 100.000000000000000000;
    SetEvent(btnSubmit, Self, 'OnClick', 'btnSubmitClick');
    LxWebPasswordStrength1.SetParentComponent(Self);
    LxWebPasswordStrength1.Name := 'LxWebPasswordStrength1';
    LxWebPasswordStrength1.Left := 102;
    LxWebPasswordStrength1.Top := 19;
    LxWebPasswordStrength1.Width := 261;
    LxWebPasswordStrength1.Height := 45;
    LxWebPasswordStrength1.HeightStyle := ssAuto;
    LxWebPasswordStrength1.WidthStyle := ssAuto;
    LxWebPasswordStrength1.ShapeCount := 5;
    LxWebPasswordStrength1.ShapeWidth := 16;
    LxWebPasswordStrength1.ShapeHeight := 16;
    LxWebPasswordStrength1.ShapeRadius := 12;
    LxWebPasswordStrength1.ShapeThickness := 2;
    LxWebPasswordStrength1.Strength := 0;
    LxWebPasswordStrength1.AcceptableStrength := 4;
    LxWebPasswordStrength1.ShapeColor := clSilver;
    LxWebPasswordStrength1.PoorColor := clRed;
    LxWebPasswordStrength1.AcceptableColor := 42495;
    LxWebPasswordStrength1.GoodColor := clLime;
    LxWebPasswordStrength1.ShowHint := True;
    LxWebPasswordStrength1.BorderStyle := bsSingle;
    LxWebPasswordStrength1.BorderColor := clSilver;
    LxWebPasswordStrength1.Color := clWhite;
    LxWebPasswordStrength1.ElementFont := efCSS;
    LxWebPasswordStrength1.ElementPosition := epIgnore;
    divMessage.SetParentComponent(Self);
    divMessage.Name := 'divMessage';
    divMessage.Left := 116;
    divMessage.Top := 88;
    divMessage.Width := 221;
    divMessage.Height := 97;
    divMessage.HeightStyle := ssAuto;
    divMessage.WidthStyle := ssAuto;
    divMessage.ChildOrder := 5;
    divMessage.ElementPosition := epIgnore;
    divMessage.ElementFont := efCSS;
    divMessage.Role := '';
    divMessage.Visible := False;
    lbMessage.SetParentComponent(divMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 24;
    lbMessage.Top := 28;
    lbMessage.Width := 50;
    lbMessage.Height := 13;
    lbMessage.Caption := 'lbMessage';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
  finally
    edEmail.AfterLoadDFMValues;
    edPassword.AfterLoadDFMValues;
    btnSubmit.AfterLoadDFMValues;
    LxWebPasswordStrength1.AfterLoadDFMValues;
    divMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
  end;
end;

end.