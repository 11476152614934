unit MyPolyCAD.Model.Mail;

interface

Uses Classes, SysUtils, WEBLib.JSON;

Type
    TMyMailPreferences = Class(TObject)
                         Private
                         FNewRelease : Boolean;
                         FNewArticle : Boolean;
                         FInvalidated : Boolean;
                         Procedure SetNewRelease(Val : Boolean);
                         Procedure SetNewArticle(Val : Boolean);
                         Procedure Invalidate;
                         Public
                         Constructor Create;
                         Function Invalidated : Boolean;
                         Procedure fromJSON(AJSONObject : TJSONObject);
                         Procedure toJSON(AJSONObject : TJSONObject);
                         Property NewRelease : Boolean Read FNewRelease Write SetNewRelease;
                         Property NewArticle : Boolean Read FNewArticle Write SetNewArticle;
                         End;

implementation

Uses JS, LxWeb.JSON;

{$region '************************************************* TMyMailPreferences ********************************************'}

Constructor TMyMailPreferences.Create;
Begin
     Inherited Create;
     FNewRelease := True;
     FNewArticle := True;
End;

Procedure TMyMailPreferences.SetNewRelease(Val : Boolean);
Begin
     If Not(FNewRelease = Val) Then Begin
        FNewRelease := Val;
        Invalidate;
        End;
End;

Procedure TMyMailPreferences.SetNewArticle(Val : Boolean);
Begin
     If Not(FNewArticle = Val) Then Begin
        FNewArticle := Val;
        Invalidate;
        End;
End;

Procedure TMyMailPreferences.Invalidate;
Begin
     FInvalidated := True;
End;

Function TMyMailPreferences.Invalidated : Boolean;
Begin
     Result := FInvalidated;
End;

Procedure TMyMailPreferences.fromJSON(AJSONObject : TJSONObject);
Var
   jIter : TJSONIterator;
Begin
     For jIter in AJSONObject Do
         If jIter.KeyIs('NewRelease', jdBoolean) Then
            FNewRelease := jIter.AsBoolean
         Else If jIter.KeyIs('NewArticle', jdBoolean) Then
            FNewArticle := jIter.AsBoolean;

     FInvalidated := False;
End;

Procedure TMyMailPreferences.toJSON(AJSONObject : TJSONObject);
Begin
     AJSONObject.AddPair('NewRelease', FNewRelease);
     AJSONObject.AddPair('NewArticle', FNewArticle);
End;

{$endregion}


end.
