unit EndPoints.Connection;

interface

Uses Classes, SysUtils, WEBLib.JSON, WEBLib.REST, EndPoints.Types, LxWeb.Tools;

Type
        THttpConnection = Class(TDummyInterfacedObject, IHttpConnection)
                          Private
                          FMyConnection : THTTPRequest;
                          FConnection : THTTPRequest;
                          FUrl : String;
                          FWithCredentials : Boolean;
                          FLastError : THTTPConnectionError;
                          Public
                          Procedure BasicAuthentication(AUserName, APassword : String);
                          Procedure ClearAuthentication;


                          Constructor Create(AUrl : String; AConnection : THTTPRequest); overload;
                          Constructor Create(AUrl : String); overload;
                          Destructor Destroy; override;

                          Function Request(ACommand : THttpCommand; Const AEndPoint, ARequest : String; Out AResponse : String) : Boolean; overload; async;
                          Function Request(ACommand : THttpCommand; Const AEndPoint : String; ARequest, AResponse : TJSONObject) : Boolean; overload; async;

                          Function Get(Const AEndPoint : String) : Boolean; overload; async;
                          Function Get(Const AEndPoint : String; Out AResponse : String) : Boolean; overload; async;
                          Function Put(Const AEndPoint, ARequest : String; Out AResponse : String) : Boolean; overload; async;
                          Function Post(Const AEndPoint, ARequest : String; Out AResponse : String) : Boolean; overload; async;
                          Function Delete(Const AEndPoint : String; Out AResponse : String) : Boolean; overload; async;


                          Property Connection : THTTPRequest Read FConnection;
                          Property Url : String Read FUrl;
                          Property WithCredentials : Boolean Read FWithCredentials Write FWithCredentials;
                          Property LastError : THTTPConnectionError Read FLastError;
                          End;

implementation

Uses Js, Web;


Function HTTPResult(AResponse : String; Out AMessage : String) : Boolean;
Var
   ResMessage : String;
Begin
     ResMessage := '';
     Result := False;

     asm
        try
        {
            const res_json = JSON.parse(AResponse);
            if (typeof res_json.message == "string")
            {
               ResMessage = res_json.message;
            }
            else if (typeof res_json.Message == "string")
            {
               ResMessage = res_json.Message;
            }
            else
            {
               ResMessage = AResponse;
            }

            if (typeof res_json.Success == "boolean" )
            {
               Result = res_json.Success;
            }
            else if (typeof res_json.success == "boolean" )
            {
               Result = res_json.success;
            }
            else if (typeof res_json.Result == "boolean" )
            {
               Result = res_json.Result;
            }
            else if (typeof res_json.result == "boolean" )
            {
               Result = res_json.result;
            }
            else
            {
               Result = true;
            }
        }
        catch
        {
             Result = true;
        }
     end;

     AMessage := ResMessage;
End;

{$region '************************************************* THttpConnection ***********************************************'}

Constructor THttpConnection.Create(AUrl : String; AConnection : THTTPRequest);
Begin
     Inherited Create;
     FUrl := IncludeTrailingPathDelimiter(AUrl);
     FConnection := AConnection;
     FLastError := THTTPConnectionError.Create;
     FMyConnection := nil;
End;

Constructor THttpConnection.Create(AUrl : String);
Begin
     FMyConnection := THTTPRequest.Create(nil);
     Create(AUrl, FMyConnection);
     FMyConnection := FConnection;

     FConnection.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
     FConnection.Headers.Add('Content-Type=application/json');
     FConnection.Headers.Add('Accept=application/json');
End;

Destructor THttpConnection.Destroy;
Begin
     FConnection := nil;
     FLastError.Free;
     If Assigned(FMyConnection) Then FMyConnection.Free;
     Inherited Destroy;
End;

Procedure THttpConnection.BasicAuthentication(AUserName, APassword : String);
Begin
     FConnection.User := AUserName;
     FConnection.Password := APassword;
End;

Procedure THttpConnection.ClearAuthentication;
Begin
     FConnection.User := '';
     FConnection.Password := '';
End;

Function THttpConnection.Request(ACommand : THttpCommand; Const AEndPoint, ARequest : String; Out AResponse : String) : Boolean;
Var
   RestCommand : WEBLib.REST.THttpCommand;
   LResponse : TJSXMLHttpRequest;
   AMessage : String;
Begin
     Case ACommand of
            cmdPOST : RestCommand := httpPOST;
             cmdPUT : RestCommand := httpPUT;
          cmdDELETE : RestCommand := httpDELETE;
                 Else RestCommand := httpGET;
          End;

     FConnection.Url := FUrl + LowerCase(AEndPoint);
     FConnection.Command := RestCommand;
     FConnection.PostData := ARequest;
     FConnection.WithCredentials := FWithCredentials;

     Try
        LResponse := await(TJSXMLHttpRequest, FConnection.Perform);
        AResponse := LResponse.ResponseText;
        Result := (200 <= LResponse.Status) and (LResponse.Status < 300);

        If Not HTTPResult(AResponse, AMessage) Then Begin
           AResponse := AMessage;
           Result := False;
           End;

        //If (500 <= LResponse.Status) and (LResponse.Status < 600) Then DisplayError(AResponse);
        //If (400 <= LResponse.Status) and (LResponse.Status < 500) Then DisplayError(AResponse);

        If Result Then
           FLastError.Clear
        Else Begin
           FLastError.Assign(AEndPoint, ACommand, LResponse.Status, ARequest, AResponse);
           FLastError.Log;
           End;

     Except
        On E : Exception Do Begin
           Result := False;
           AResponse := E.Message;
           End;
        On E : TJSError Do Begin
           Result := False;
           AResponse := E.Message;
           End;

     End;
End;

Function THttpConnection.Request(ACommand : THttpCommand; Const AEndPoint : String; ARequest, AResponse : TJSONObject) : Boolean;
Begin
     Result := False;
End;

Function THttpConnection.Get(Const AEndPoint : String) : Boolean;
Var
   ARequest, AResponse : String;
Begin
     Result := await(Boolean, Request(cmdGET, AEndPoint, ARequest, AResponse));
End;

Function THttpConnection.Get(Const AEndPoint : String; Out AResponse : String) : Boolean;
Var
   ARequest : String;
Begin
     Result := await(Boolean, Request(cmdGET, AEndPoint, ARequest, AResponse));
End;

Function THttpConnection.Put(Const AEndPoint, ARequest : String; Out AResponse : String) : Boolean;
Begin
     Result := await(Boolean, Request(cmdPUT, AEndPoint, ARequest, AResponse));
End;

Function THttpConnection.Post(Const AEndPoint, ARequest : String; Out AResponse : String) : Boolean;
Begin
     Result := await(Boolean, Request(cmdPOST, AEndPoint, ARequest, AResponse));
End;

Function THttpConnection.Delete(Const AEndPoint : String; Out AResponse : String) : Boolean;
Var
   ARequest : String;
Begin
     Result := await(Boolean, Request(cmdDELETE, AEndPoint, ARequest, AResponse));
End;

{$endregion}

end.
