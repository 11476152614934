unit LoginUnit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls, MyPolyCAD.Model,
  WEBLib.WebCtrls;

type
  TLoginForm = class(TForm)
    edEmail: TEdit;
    edPassword: TEdit;
    btnLogin: TButton;
    btnSignup: TButton;
    divMessage: THTMLDiv;
    lbMessage: TLabel;
    procedure WebFormCreate(Sender: TObject);
    procedure edEmailChange(Sender: TObject);
    procedure btnLoginClick(Sender: TObject);
    procedure btnSignupClick(Sender: TObject);
  private
    { Private declarations }
    [async]
    Procedure Login; async;
    Procedure Register;


  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  LoginForm: TLoginForm;

implementation

{$R *.dfm}

Uses LxWeb.Tools, LxWeb.Router, MyPolyCAD.App;


Procedure TLoginForm.Login;
Var
   AMessage : String;
Begin
     btnLogin.Cursor := crHourGlass;
     If await(boolean, appMyPolyCAD.Login(edEmail.Text, edPassword.Text, AMessage)) Then
        Router.Route('/profile')
     Else If UpperCase(AMessage).Contains('REQUIRES VALIDATION') Then
        Router.Route('/validate')
     Else Begin
        btnLogin.Cursor := crDefault;
        lbMessage.Caption := 'Error: ' + AMessage;
        divMessage.Visible := True;
        End;
End;

Procedure TLoginForm.Register;
Begin
     btnSignup.Cursor := crHourGlass;
     Router.Route('/signup');
End;

procedure TLoginForm.WebFormCreate(Sender: TObject);
begin
     btnLogin.Enabled := false;

    edEMail.MaxLength := appMyPolyCAD.EMail_MAXLENGTH;
 edPassword.MaxLength := appMyPolyCAD.Password_MAXLENGTH;
end;

procedure TLoginForm.btnLoginClick(Sender: TObject);
begin
     Login;
end;

procedure TLoginForm.btnSignupClick(Sender: TObject);
begin
     Register;
end;

procedure TLoginForm.edEmailChange(Sender: TObject);
begin
     divMessage.Visible := false;
     btnLogin.Enabled := ValidateEmail(edEmail.Text) and (Length(edPassword.Text) > 4);
end;


procedure TLoginForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  edEmail := TEdit.Create('edEmail');
  edPassword := TEdit.Create('edPassword');
  btnLogin := TButton.Create('btnLogin');
  btnSignup := TButton.Create('btnRegister');
  divMessage := THTMLDiv.Create('divMessage');
  lbMessage := TLabel.Create('lbMessage');

  edEmail.BeforeLoadDFMValues;
  edPassword.BeforeLoadDFMValues;
  btnLogin.BeforeLoadDFMValues;
  btnSignup.BeforeLoadDFMValues;
  divMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  try
    Name := 'LoginForm';
    Width := 640;
    Height := 480;
    ElementClassName := 'application';
    ElementFont := efCSS;
    ElementPosition := epRelative;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    edEmail.SetParentComponent(Self);
    edEmail.Name := 'edEmail';
    edEmail.Left := 0;
    edEmail.Top := 0;
    edEmail.Width := 96;
    edEmail.Height := 25;
    edEmail.ElementFont := efCSS;
    edEmail.ElementPosition := epIgnore;
    edEmail.HeightStyle := ssAuto;
    edEmail.HeightPercent := 100.000000000000000000;
    edEmail.WidthStyle := ssAuto;
    edEmail.WidthPercent := 100.000000000000000000;
    SetEvent(edEmail, Self, 'OnChange', 'edEmailChange');
    edPassword.SetParentComponent(Self);
    edPassword.Name := 'edPassword';
    edPassword.Left := 0;
    edPassword.Top := 31;
    edPassword.Width := 96;
    edPassword.Height := 25;
    edPassword.ChildOrder := 1;
    edPassword.ElementFont := efCSS;
    edPassword.ElementPosition := epIgnore;
    edPassword.HeightStyle := ssAuto;
    edPassword.HeightPercent := 100.000000000000000000;
    edPassword.WidthStyle := ssAuto;
    edPassword.WidthPercent := 100.000000000000000000;
    SetEvent(edPassword, Self, 'OnChange', 'edEmailChange');
    btnLogin.SetParentComponent(Self);
    btnLogin.Name := 'btnLogin';
    btnLogin.Left := 0;
    btnLogin.Top := 62;
    btnLogin.Width := 96;
    btnLogin.Height := 25;
    btnLogin.Caption := 'btnLogin';
    btnLogin.ChildOrder := 2;
    btnLogin.ElementFont := efCSS;
    btnLogin.ElementPosition := epIgnore;
    btnLogin.Enabled := False;
    btnLogin.HeightStyle := ssAuto;
    btnLogin.HeightPercent := 100.000000000000000000;
    btnLogin.WidthStyle := ssAuto;
    btnLogin.WidthPercent := 100.000000000000000000;
    SetEvent(btnLogin, Self, 'OnClick', 'btnLoginClick');
    btnSignup.SetParentComponent(Self);
    btnSignup.Name := 'btnSignup';
    btnSignup.Left := 0;
    btnSignup.Top := 116;
    btnSignup.Width := 96;
    btnSignup.Height := 25;
    btnSignup.Caption := 'btnRegister';
    btnSignup.ChildOrder := 3;
    btnSignup.ElementFont := efCSS;
    btnSignup.ElementPosition := epIgnore;
    btnSignup.HeightStyle := ssAuto;
    btnSignup.HeightPercent := 100.000000000000000000;
    btnSignup.WidthStyle := ssAuto;
    btnSignup.WidthPercent := 100.000000000000000000;
    SetEvent(btnSignup, Self, 'OnClick', 'btnSignupClick');
    divMessage.SetParentComponent(Self);
    divMessage.Name := 'divMessage';
    divMessage.Left := 184;
    divMessage.Top := 56;
    divMessage.Width := 85;
    divMessage.Height := 31;
    divMessage.HeightStyle := ssAuto;
    divMessage.WidthStyle := ssAuto;
    divMessage.ChildOrder := 4;
    divMessage.ElementPosition := epIgnore;
    divMessage.ElementFont := efCSS;
    divMessage.Role := '';
    divMessage.Visible := False;
    lbMessage.SetParentComponent(divMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 12;
    lbMessage.Top := 10;
    lbMessage.Width := 50;
    lbMessage.Height := 13;
    lbMessage.Caption := 'lbMessage';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
  finally
    edEmail.AfterLoadDFMValues;
    edPassword.AfterLoadDFMValues;
    btnLogin.AfterLoadDFMValues;
    btnSignup.AfterLoadDFMValues;
    divMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
  end;
end;

end.