unit MyPolyCAD.Model.User;

interface

Uses Classes, SysUtils, WEBLib.JSON, EndPoints.Types;

Type
     TMyMailPreference = (mpNewRelease, mpNewArticle);
    TMyMailPreferences = set of TMyMailPreference;

               TMyUser = Class(TObject)
                         Private
                         FId : String;
                         FName : String;
                         FEmail : String;
                         //FSignupPassword : String;
                         FOrganisation : String;
                         FLocation : String;
                         FMailPreferences : Integer;
                         FStatus : Integer;
                         FInvalidated : Boolean;
                         Function GetMailPreferences : TMyMailPreferences;
                         Function GetIsAdministrator : Boolean;
                         Procedure SetName(Val : String);
                         Procedure SetOrganisation(Val : String);
                         Procedure SetLocation(Val : String);
                         Procedure SetMailPreferences(Val : TMyMailPreferences);
                         Procedure Invalidate;
                         Public
                         Constructor Create;
                         Function Invalidated : Boolean;
                         Procedure Clear;
                         Procedure fromJSON(AJSONObject : TJSONObject);
                         Procedure toJSON(AJSONObject : TJSONObject);
                         Function Update(AConnection : IHTTPConnection) : Boolean; async;
                         Function Submit(AConnection : IHTTPConnection) : Boolean; async;
                         Property Id : String Read FId;
                         Property Name : String Read FName Write SetName;
                         Property Email : String Read FEmail;
                         //Property SignupPassword : String Read FSignupPassword;
                         Property Organisation : String Read FOrganisation Write SetOrganisation;
                         Property Location : String Read FLocation Write SetLocation;
                         Property MailPreferences : TMyMailPreferences Read GetMailPreferences Write SetMailPreferences;
                         Property Status : Integer Read FStatus;
                         Property IsAdministrator : Boolean Read GetIsAdministrator;
                         End;

Const
     statusValidated = $0020;        // 0000 0000 0010 0000
     statusUnvalidated = $0004;      // 0000 0000 0000 0100
     statusAdmin = $2918;            // 0010 1001 0001 1000
     statusChangePassword = $0481;   // 0000 0100 1000 0001
     statusDisabled = $4242;         // 0100 0010 0100 0010

implementation

Uses JS, LxWeb.JSON;

{$region '************************************************* TMyUser *******************************************************'}

Constructor TMyUser.Create;
Begin
     Inherited Create;
     Clear;
End;

Function TMyUser.GetMailPreferences : TMyMailPreferences;
Begin
     Result := [];
     If (FMailPreferences and $1) = $1 Then Include(Result, mpNewRelease);
     If (FMailPreferences and $2) = $2 Then Include(Result, mpNewArticle);
End;

Function TMyUser.GetIsAdministrator : Boolean;
Begin
     Result := (FStatus and statusAdmin) = statusAdmin;
End;

Procedure TMyUser.SetName(Val : String);
Begin
     If Not(FName = Val) Then Begin
        FName := Val;
        Invalidate;
        End;
End;

Procedure TMyUser.SetOrganisation(Val : String);
Begin
     If Not(FOrganisation = Val) Then Begin
        FOrganisation := Val;
        Invalidate;
        End;
End;

Procedure TMyUser.SetLocation(Val : String);
Begin
     If Not(FLocation = Val) Then Begin
        FLocation := Val;
        Invalidate;
        End;
End;

Procedure TMyUser.SetMailPreferences(Val : TMyMailPreferences);
Var
   IntVal : Integer;
Begin
     IntVal := 0;
     If mpNewRelease in Val Then IntVal := IntVal or $1;
     If mpNewArticle in Val Then IntVal := IntVal or $2;

     If Not(FMailPreferences = IntVal) Then Begin
        FMailPreferences := IntVal;
        Invalidate;
        End;
End;

Procedure TMyUser.Invalidate;
Begin
     FInvalidated := True;
End;

Function TMyUser.Invalidated : Boolean;
Begin
     Result := FInvalidated;
End;

Procedure TMyUser.fromJSON(AJSONObject : TJSONObject);
Var
   jIter : TJSONIterator;
Begin
     For jIter in AJSONObject Do
         If jIter.KeyIs('Id', jdString) Then
            FId := jIter.AsString
         Else If jIter.KeyIs('Name', jdString) Then
            FName := jIter.AsString
         Else If jIter.KeyIs('EMail', jdString) Then
            FEMail := jIter.AsString
         Else If jIter.KeyIs('Organisation', jdString) Then
            FOrganisation := jIter.AsString
         Else If jIter.KeyIs('Location', jdString) Then
            FLocation := jIter.AsString
         Else If jIter.KeyIs('Status', jdInteger) Then
            FStatus := jIter.AsInteger
         Else If jIter.KeyIs('MailPreferences', jdInteger) Then
            FMailPreferences := jIter.AsInteger;

     FInvalidated := False;
End;

Procedure TMyUser.toJSON(AJSONObject : TJSONObject);
Begin
     AJSONObject.AddPair('Id', FId);
     AJSONObject.AddPair('Name', FName);
     AJSONObject.AddPair('EMail', FEMail);
     AJSONObject.AddPair('Organisation', FOrganisation);
     AJSONObject.AddPair('Location', FLocation);
     AJSONObject.AddPair('MailPreferences', FMailPreferences);
End;

Procedure TMyUser.Clear;
Begin
     FId := '';
     FName := '';
     FEmail := '';
     FOrganisation := '';
     FLocation := '';
     FMailPreferences := $3;
     FStatus := 0;
     FInvalidated := False;
     FMailPreferences := $3;
     FStatus := 0;
End;

Function TMyUser.Update(AConnection : IHTTPConnection) : Boolean;
Var
   AResponse : String;
Begin
     If await(Boolean, AConnection.Get('user', AResponse)) Then
        Result := StrToJSON(AResponse, @fromJSON)
     Else
        Result := false;
End;

Function TMyUser.Submit(AConnection : IHTTPConnection) : Boolean; async;
Var
   ARequest, AResponse : String;
Begin
     ARequest := JSONtoStr(@toJSON);

     Result := await(Boolean, AConnection.Put('user', ARequest, AResponse));
End;


{$endregion}


end.
