unit LxWeb.Tools;

interface

Uses Classes, SysUtils, Types, JS, TypInfo, jsDelphiSystem;

Type
                                    TFile = Class(TObject)
                                            Private
                                            FStrings : TStrings;
                                            FIndex : Integer;
                                            Function GetText : String;
                                            Procedure SetText(Val : String);
                                            Public
                                            Constructor Create;
                                            Destructor Destroy; override;
                                            {$IFDEF PAS2JS}
                                            [async]
                                            Function LoadFromUrl(AUrl : String) : TJSPromise; overload;
                                            procedure LoadFromURL(const aURL: String; Async: Boolean; OnLoaded: TNotifyEventRef; OnError: TStringNotifyEventRef); overload;
                                            procedure LoadFromFile(const aFileName: String; const OnLoaded: TProc; const AError: TProcString);
                                            {$ENDIF}
                                            Function Readln : String;
                                            Function Eof : Boolean;
                                            Property Text : String Read GetText Write SetText;
                                            End;

                   TDummyInterfacedObject = Class(TObject, IInterface)
                                            Protected
                                            Function _AddRef: Integer; {$IFNDEF PAS2JS}stdcall;{$ENDIF}
                                            Function _Release: Integer; {$IFNDEF PAS2JS}stdcall;{$ENDIF}
                                            public
                                            Function QueryInterface(const IID: TGUID; out Obj): HRESULT; virtual;{$IFNDEF PAS2JS} stdcall;{$ENDIF}
                                            End;

                       TFloatFormatHelper = Record Helper For TFloatFormat
                                            Class Function FromString(AStr : String) : TFloatFormat; static;
                                            Function ToString : String;
                                            Function Convert(AValue : Double; ADecimalPlaces : Integer) : String; overload;
                                            Function Convert(AValue : JSValue; ADecimalPlaces : Integer) : String; overload;
                                            End;

                   TNumericLocaleSettings = Record
                                            DecimalSeparator : string;
                                            ListSeparator : String;
                                            Class Function Create : TNumericLocaleSettings; overload; static;
                                            Class Function Create_US : TNumericLocaleSettings; overload; static;
                                            Class Function Create(AFormatSettings : TFormatSettings) : TNumericLocaleSettings; overload; static;
                                            Class Function Create(ADecimalSeparator, AListSeparator : String) : TNumericLocaleSettings; overload; static;
                                            End;

                   {$IFDEF PAS2JS}

                       TStringArrayHelper = record helper for TStringArray
                                            Function Count : Integer;
                                            End;

                            JSValueHelper = record helper for JSValue
                                            Function IsNumber : Boolean;
                                            Function IsInteger : Boolean;
                                            Function IsBoolean : Boolean;
                                            Function IsString : Boolean;
                                            Function isEqual(AValue : JSValue) : Boolean;
                                            Function ToNumber : Double;
                                            Function ToInteger : Integer;
                                            Function ToBoolean : Boolean;
                                            Function ToString : String;
                                            Function ValueType : TJSValueType;
                                            End;

                   {$ENDIF}

Const
         {$IFDEF PAS2JS}
         CharDigits : array[0..9] of char = ('0', '1', '2', '3', '4', '5', '6', '7', '8', '9');
         {$ELSE}
         CharDigits : set of AnsiChar = ['0'..'9'];
         {$ENDIF}

                                     CRLF = #10;
                                    Space = #32;
                                      Tab = #9;
                                    Comma = #44;
                                   Period = #46;
                              SingleQuote = #39;
                              DoubleQuote = #34;
                              SquaredChar : char = '²';
                                CubedChar : char = '³';
                                 QuarChar : char = #2074;
                              DegreesChar : char = '°';
                                 nbspChar = #160;

         {Ordinary key codes produced by OnKeydown, OnKeyUp and OnKeypress events}
                                     VK_0 = $30;
                                     VK_1 = $31;
                                     VK_2 = $32;
                                     VK_3 = $33;
                                     VK_4 = $34;
                                     VK_5 = $35;
                                     VK_6 = $36;
                                     VK_7 = $37;
                                     VK_8 = $38;
                                     VK_9 = $39;
                                     VK_A = $41;
                                     VK_B = $42;
                                     VK_C = $43;
                                     VK_D = $44;
                                     VK_E = $45;
                                     VK_F = $46;
                                     VK_G = $47;
                                     VK_H = $48;
                                     VK_I = $49;
                                     VK_J = $4A;
                                     VK_K = $4B;
                                     VK_L = $4C;
                                     VK_M = $4D;
                                     VK_N = $4E;
                                     VK_O = $4F;
                                     VK_P = $50;
                                     VK_Q = $51;
                                     VK_R = $52;
                                     VK_S = $53;
                                     VK_T = $54;
                                     VK_U = $55;
                                     VK_V = $56;
                                     VK_W = $57;
                                     VK_X = $58;
                                     VK_Y = $59;
                                     VK_Z = $5A;

Function FloatToInt(Const X : Single) : Integer;
Function StrToFloat(S : String) : Double;
Function IsDigit(C : Char) : Boolean;
Function IsUpper(C : Char) : Boolean;
Function IsLower(C : Char) : Boolean;
Function StringBefore(Token, Source : String) : String;
Function StringAfter(Token, Source : String) : String;
Function StringBetween(Const AfterToken, BeforeToken, Source : String) : String; overload;
Function StringBetween(Const Token, Source : String) : String; overload;
Function StringWithinBrackets(AString : String) : String;
Function AlphaNumericTextCompare(Text1, Text2 : String) : Integer;
Function IncreaseName(Name : String; MaintainLength : Boolean = False) : String;
Function CorrectPathDelimiter(APath : String) : String;
Function JustFileName(FileName : TFileName) : TFileName;
Function UniqueID(ALength : Integer) : String;
Function ValidateEmail(Const AEmail : String) : Boolean;
Function CheckPasswordStrength(Const APassword : String; Var ATips : String) : Integer; overload;
Function CheckPasswordStrength(Const APassword : String) : Boolean; overload;

{$ifdef PAS2JS}
Function SetToString(Const TypeInfo : TTypeInfo; Const Value; Brackets: Boolean = True): string;
Procedure EnumerateEnumeration(Const TypeInfo : TTypeInfo; AStrings : TStrings; AStripPrefix : Boolean = false);
Function EnumerationToString(Const TypeInfo : TTypeInfo; Const Enumeration; AStripPrefix : Boolean = false) : String;
Function StringToEnumeration(Const TypeInfo : TTypeInfo; Const AEnumeration : String) : Integer; overload;
Procedure StringToEnumeration(Const AString : String; Const TypeInfo : TTypeInfo; Var AEnumeration); overload;
Procedure StringToSet(Const AString : String; Const TypeInfo : TTypeInfo; Const Value);
{$ELSE}
Function EnumerationToString(AEnumTypeInfo : PTypeInfo; Const Enumeration; AStripPrefix : Boolean = false) : String;
Procedure StringToEnumeration(Const AString : String; AEnumTypeInfo : PTypeInfo; Var AEnumeration);
Procedure EnumerateEnumeration(TypeInfo : PTypeInfo; AStrings : TStrings; AStripPrefix : Boolean = false);
Function SetToString(TypeInfo: PTypeInfo; Const Value; Brackets: Boolean = True): string;
Procedure StringToSet(Const AString : String; Const TypeInfo : PTypeInfo; Var Value);
{$ENDIF}

Function IsInteger(Val : Double) : Boolean; overload;
Function IsInteger(Val : Double; Out Value : Integer) : Boolean; overload;
Function IsInteger(Val : String; Out Value : Integer) : Boolean; overload;

Function IsNumber(S : String) : Boolean; overload;
Function IsNumber(S : String; Out Value : Double) : Boolean; overload;

Function IsBoolean(Const Val : String) : Boolean;

{$IFDEF PAS2JS}
Function IsNumber(V : JSValue) : Boolean; overload;
Function IsInteger(V : JSValue) : Boolean; overload;
{$ENDIF}

implementation

Const
     IsIntegerTolerance = 10e-10;

{$region '************************************** Functions and Procedures *************************'}

Function FloatToInt(const X : Single): Integer;
Begin
     Result := Trunc(X);
End;

Function StrToFloat(S : String) : Double;
Var
   D : Double;
   E : Integer;
Begin
     While Pos(',', S) > 0 Do
           S[Pos(',', S)] := '.';

     Val(Trim(S), D, E);
     If E > 0 Then
        Raise Exception.Create('Unable to convert string to number (' + S + ')');
     Result := D;
End;

Function IsDigit(C : Char) : Boolean;
Begin
     Result := CharInSet(C, CharDigits);
End;

{$HINTS OFF}

Function IsLower(C : Char) : Boolean;
Begin
     {$IFDEF PAS2JS}
     asm
        Result = (C == C.toLowerCase());
     end;
     {$ELSE}
        Result := (C = LowerCase(C));
     {$ENDIF}
End;

Function IsUpper(C : Char) : Boolean;
Begin
     {$IFDEF PAS2JS}
     asm
        Result = (C == C.toUpperCase());
     end;
     {$ELSE}
        Result := (C = UpperCase(C));
     {$ENDIF}
End;

{$HINTS ON}

Function StringBefore(Token, Source : String) : String;
Var
   Position : Integer;
Begin
     Position := Pos(UpperCase(Token), UpperCase(Source));
     If Position > 0  Then
        Result := Trim(Copy(Source, 1, Position-1))
     Else
        Result := Source;
End;

Function StringAfter(Token, Source : String) : String;
Var
   Position : Integer;
Begin
     Position := Pos(UpperCase(Token), UpperCase(Source));
     If Position > 0  Then
        Result := Trim(Copy(Source, Position+Length(Token), Length(Source)))
     Else
        Result := Source;
End;

Function StringBetween(Const AfterToken, BeforeToken, Source : String) : String;
Var
   AfterPosition, BeforePosition : Integer;
Begin
     AfterPosition := Pos(UpperCase(AfterToken), UpperCase(Source));
     If AfterPosition > 0 Then AfterPosition := AfterPosition + Length(AfterToken);

     If AfterPosition > 0 Then
        Result := Trim(Copy(Source, AfterPosition, Length(Source)))
     Else
        Result := Source;

     BeforePosition := Pos(UpperCase(BeforeToken), UpperCase(Result));
     If BeforePosition > 0 Then
        Result := Trim(Copy(Result, 1, BeforePosition - 1));
End;

Function StringBetween(Const Token, Source : String) : String; overload;
Var
   AfterPosition, BeforePosition : Integer;
Begin
     AfterPosition := Pos(UpperCase(Token), UpperCase(Source));
     If AfterPosition > 0 Then AfterPosition := AfterPosition + Length(Token);

     If AfterPosition > 0 Then
        Result := Trim(Copy(Source, AfterPosition, Length(Source)))
     Else
        Result := Source;

     BeforePosition := Pos(UpperCase(Token), UpperCase(Result));
     If BeforePosition > 0 Then
        Result := Trim(Copy(Result, 1, BeforePosition - 1));
End;

Function StringWithinBrackets(AString : String) : String;
Const
     {$IFDEF PAS2JS}
     OpenBrackets : array[0..2] of char = ('(', '[', '{');
     {$ELSE}
     OpenBrackets : set of ansichar = ['(', '[', '{'];
     {$ENDIF}
Var
   I, OpenBracketIndex : Integer;
   CloseBracketChar : Char;
Begin
     Result := AString;
     OpenBracketIndex := 0;
     CloseBracketChar := ')';

     For I := 1 to Length(AString) Do
         If CharInSet(AString[I], OpenBrackets) Then Begin
            OpenBracketIndex := I;
            If AString[I] = '[' Then
               CloseBracketChar := ']'
            Else If AString[I] = '{' Then
               CloseBracketChar := '}'
            Else
               CloseBracketChar := ')';
            End;

     If OpenBracketIndex > 0 Then
        For I := Length(AString) Downto OpenBracketIndex + 1 Do
            If AString[I] = CloseBracketChar Then Begin
               Result := Copy(AString, OpenBracketIndex + 1, I - OpenBracketIndex - 1);
               Break;
               End;
End;

Function AlphaNumericTextCompare(Text1, Text2 : String) : Integer;
Var
   MinLen : Integer;

   Function BothNumeric(Index : Integer) : Boolean;
   Begin
        Result := IsDigit(Text1[Index]) and IsDigit(Text2[Index]);
   End;

   Function Compare(Index : Integer) : Integer;
   Begin
        If Not(IsDigit(Text1[Index]) xor IsDigit(Text2[Index])) Then
           Result := Ord(UpCase(Text1[Index])) - Ord(UpCase(Text2[Index]))
        Else If IsDigit(Text2[Index]) Then
           Result := -1
        Else
           Result := 1;
   End;

   Function ShortestNumber(Index : Integer) : Integer;
   Var
      I, Len1, Len2 : Integer;
   Begin
        Len1 := 0;
        Len2 := 0;
        For I := Index to Length(Text1) Do
            If IsDigit(Text1[I]) Then
               Inc(Len1)
            Else
               Break;

        For I := Index to Length(Text2) Do
            If IsDigit(Text2[I]) Then
               Inc(Len2)
            Else
               Break;

        Result := Len1 - Len2;
   End;

Var
   I, L1, L2 : Integer;
Begin
     L1 := Length(Text1);
     L2 := Length(Text2);
     If L1 < L2 Then
        MinLen := L1
     Else
        MinLen := L2;

     I := 1;
     While (I <= MinLen) and (Upcase(Text1[I]) = Upcase(Text2[I])) Do Inc(I);

     If I <= MinLen Then Begin
        If BothNumeric(I) Then Begin
           Result := ShortestNumber(I);
           If Result = 0 Then Result := Compare(I);
           End
        Else
           Result := Compare(I);
        End
     Else
        Result := Length(Text1) - Length(Text2);
End;

Function IncreaseName(Name : String; MaintainLength : Boolean = False) : String;
Var
   S1, S2, TryString, NumString : String;
   Num, I, Err, OriginalLen, FixedLen : Integer;
Begin
     OriginalLen := Length(Name);

     If OriginalLen > 0 Then Begin
        I := OriginalLen;
        While IsDigit(Name[I]) Do Begin
              Dec(I);
              If I = 0 Then Break;
              End;

        If I > 0 Then S1 := Copy(Name, 1, I) Else S1 := '';
        S2 := Copy(Name, I + 1, 255);
        Val(S2, Num, Err);
        If Err > 0 Then Num := 0;
        Inc(Num);

        NumString := IntToStr(Num);
        TryString := s1 + NumString;
        If MaintainLength and (Length(TryString) > OriginalLen) Then Begin
           FixedLen := OriginalLen - Length(NumString);
           If FixedLen < 1 Then FixedLen := 1;
           s1 := Copy(Name, 1, FixedLen);
           Result := s1 + NumString;
           End
        Else
           Result := TryString;
        End
     Else
        Result := 'Name1';
End;

Function CorrectPathDelimiter(APath : String) : String;
Var
   WrongPathDelim : Char;
Begin
     If PathDelim = '\' Then
        WrongPathDelim := '/'
     Else
        WrongPathDelim := '\';

     Result := APath.Replace(WrongPathDelim, PathDelim);
End;

Function JustFileName(FileName : TFileName) : TFileName;
Var
   S : String;
   I : Integer;
Begin
     S := ExtractFileName(FileName);
     If Pos('.', S) > 0 Then Begin
        I := Length(S);
        While S[I] <> '.' Do Dec(I);
        S := Copy(S, 1, I-1);
        End;
     Result := S;
End;

Function UniqueID(ALength : Integer) : String;
Const
     base62Chars : string = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
Var
   I : Integer;
Begin
     For I := 0 to ALength - 1 Do
         Result := Result + base62Chars[Random(62) + 1];
End;

Function ValidateEmail(Const AEmail : String) : Boolean;
{$IFDEF PAS2JS}
Var
   Success : Boolean;
{$ENDIF}
Begin
     {$IFDEF PAS2JS}
     asm
        const emailFormat = /^[a-zA-Z0-9_.\-+]+(?<!^[0-9]*)@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        Success = AEmail !== '' && AEmail.match(emailFormat);
     end;

     Result := Success;
     {$ELSE}
     Result := False;
     {$ENDIF}
End;

Function CheckPasswordStrength(Const APassword : String; Var ATips : String) : Integer; overload;
{$IFDEF PAS2JS}
Var
   AStrength : Integer;
   CheckTips : String;
{$ENDIF}
Begin
     {$IFDEF PAS2JS}
     asm
       // Initialize variables
       AStrength = 0;
       CheckTips = "";

       // Check password length
       if (APassword.length < 8)
       {
         CheckTips += "Make the password longer. ";
         AStrength += 1;
       }
       else
       {
         AStrength += 2;
       }

       // Check for mixed case
       if (APassword.match(/[a-z]/) && APassword.match(/[A-Z]/))
       {
         AStrength += 1;
       }
       else
       {
         CheckTips += "Use both lowercase and uppercase letters. ";
       }

       // Check for numbers
       if (APassword.match(/\d/))
       {
         AStrength += 1;
       }
       else
       {
         CheckTips += "Include at least one number. ";
       }

       // Check for special characters
       if (APassword.match(/[^a-zA-Z\d]/))
       {
         AStrength += 1;
       }
       else
       {
         CheckTips += "Include at least one special character. ";
       }

       // Return results
       if (AStrength < 3)
       {
         CheckTips = "Easy to guess. " + CheckTips;
       }
       else if (AStrength === 3)
       {
         CheckTips = "Medium difficulty. " + CheckTips;
       }
       else if (AStrength === 4)
       {
         CheckTips = "Difficult. " + CheckTips;
       }
       else
       {
         CheckTips = "Extremely difficult. " + CheckTips;
       }
     End;

     Result := AStrength;
     ATips := CheckTips;

     {$ELSE}

     Result := 0;

     {$ENDIF}
End;

Function CheckPasswordStrength(Const APassword : String) : Boolean; overload;
Var
   S : String;
Begin
     Result := CheckPasswordStrength(APassword, S) >= 3;
End;

{$ifdef PAS2JS}

{$HINTS OFF}

Function EnumerationToString(Const TypeInfo : TTypeInfo; Const Enumeration; AStripPrefix : Boolean = false) : String;
Var
   TIEnum: TTypeInfoEnum;
Begin
     If TypeInfo.Kind <> tkEnumeration Then
        Raise Exception.Create('EnumerationToString - Type Info does not represent an Enumeration')
     Else Begin
        TIEnum := TTypeInfoEnum(TypeInfo);
        asm
           Result = TIEnum.enumtype[Enumeration];
        end;

        If AStripPrefix Then
           While (Result.Length > 0) and IsLower(Result[1]) Do
                 Delete(Result, 1, 1);
        End;
End;

Procedure EnumerateEnumeration(Const TypeInfo : TTypeInfo; AStrings : TStrings; AStripPrefix : Boolean = false);
Var
   I : Integer;
   AName : String;
   TIEnum: TTypeInfoEnum;
Begin
     AStrings.BeginUpdate;
     Try
        AStrings.Clear;
        If TypeInfo.Kind = tkEnumeration Then Begin
           TIEnum := TTypeInfoEnum(TypeInfo);
           For I := TIEnum.minvalue to TIEnum.maxvalue Do Begin
               asm
                  AName = TIEnum.enumtype[I];
               end;

               If AStripPrefix Then
                  While (AName.Length > 0) and IsLower(AName[1]) Do
                        Delete(AName, 1, 1);
               AStrings.Add(AName);
               End;
           End;
     Finally
        AStrings.EndUpdate;
     End;
End;

Function StringToEnumeration(Const TypeInfo : TTypeInfo; Const AEnumeration : String) : Integer;
Var
   I, MinVal, MaxVal : Integer;
   TIEnum: TTypeInfoEnum;
   FullName, StrippedName, UpEnumeration : String;
Begin
     Result := -1;
     If TypeInfo.Kind = tkEnumeration Then Begin
        UpEnumeration := UpperCase(AEnumeration);
        TIEnum := TTypeInfoEnum(TypeInfo);
        For I := TIEnum.minvalue to TIEnum.maxvalue Do Begin
            asm
               FullName = TIEnum.enumtype[I];
            end;

            StrippedName := FullName;
            While (StrippedName.Length > 0) and IsLower(StrippedName[1]) Do
                  Delete(StrippedName, 1, 1);

            FullName := UpperCase(FullName);
            StrippedName := UpperCase(StrippedName);

            If (FullName = UpEnumeration) or (StrippedName = UpEnumeration) Then Begin
               Result := I;
               Break;
               End;
            End;
        End
     Else
        Raise Exception.Create('EnumerationToString - Type Info does not represent an Enumeration')
End;

Procedure StringToEnumeration(Const AString : String; Const TypeInfo : TTypeInfo; Var AEnumeration);
Var
   Found : Boolean;
   I, MinVal, MaxVal : Integer;
   TIEnum: TTypeInfoEnum;
   FullName, StrippedName, UpEnumeration : String;
Begin
     If TypeInfo.Kind = tkEnumeration Then Begin
        Found := False;
        UpEnumeration := UpperCase(AString);
        TIEnum := TTypeInfoEnum(TypeInfo);
        For I := TIEnum.minvalue to TIEnum.maxvalue Do Begin
            asm
               FullName = TIEnum.enumtype[I];
            end;

            StrippedName := FullName;
            While (StrippedName.Length > 0) and IsLower(StrippedName[1]) Do
                  Delete(StrippedName, 1, 1);

            FullName := UpperCase(FullName);
            StrippedName := UpperCase(StrippedName);

            If (FullName = UpEnumeration) or (StrippedName = UpEnumeration) Then Begin
               AEnumeration := I;
               Found := True;
               Break;
               End;
            End;

        If Not Found Then
           AEnumeration := TIEnum.minvalue;
        End
     Else
        Raise Exception.Create('EnumerationToString - Type Info does not represent an Enumeration')
End;

Function SetToString(Const TypeInfo : TTypeInfo; Const Value; Brackets: Boolean) : string;
Var
   TIEnum: TTypeInfoEnum;
   TISet: TTypeInfoSet;
   ToString : String;
Begin
     TISet := TTypeInfoSet(TypeInfo);

     if TISet.CompType is TTypeInfoEnum Then Begin
        TIEnum := TTypeInfoEnum(TISet.CompType);

        asm
           Result = '';
           for(var i = TIEnum.minvalue; i <= TIEnum.maxvalue; i++)
           {
            if (Value[i])
            {
               if (Result.length > 0) Result = Result + ', ';
               Result = Result + TIEnum.enumtype[i];
            }
           }

           if (Brackets)
           {
            Result = '[' + Result + ']';
           }
        end;
        End
     Else
        Result := 'not a set';
End;

Procedure StringToSet(Const AString : String; Const TypeInfo : TTypeInfo; Const Value);
Var
   I : Integer;
   TIEnum: TTypeInfoEnum;
   TISet: TTypeInfoSet;
   S : String;
   SArray : TStringArray;
   AEnumeration, UpEnumeration, FullName, StrippedName : String;
Begin
     If TypeInfo.Kind = tkSet Then Begin
        TISet := TTypeInfoSet(TypeInfo);

        If TISet.CompType is TTypeInfoEnum Then Begin
           S := AString;
           If (Length(S) > 0) and (S[1] = '[') Then Delete(S, 1, 1);
           If (Length(S) > 0) and (S[Length(S)] = ']') Then Delete(S, Length(S), 1);

           TIEnum := TTypeInfoEnum(TISet.CompType);
           For I := TIEnum.minvalue to TIEnum.maxvalue Do
               asm
                  if (Value[I])
                  {
                     delete Value[I];
                  }
               end;


           SArray := S.Split(',');
           For AEnumeration in SArray Do Begin
               UpEnumeration := UpperCase(Trim(AEnumeration));

               For I := TIEnum.minvalue to TIEnum.maxvalue Do Begin
                   asm
                      FullName = TIEnum.enumtype[I];
                   end;

                   StrippedName := FullName;
                   While (StrippedName.Length > 0) and IsLower(StrippedName[1]) Do
                         Delete(StrippedName, 1, 1);

                   FullName := UpperCase(FullName);
                   StrippedName := UpperCase(StrippedName);

                   If (FullName = UpEnumeration) or (StrippedName = UpEnumeration) Then Begin
                      asm
                         Value[I] = true;
                      end;
                      Break;
                      End;
                   End;
               End;
           End;
        End;
End;


{$HINTS ON}

{$ELSE}

Function EnumerationToString(AEnumTypeInfo : PTypeInfo; Const Enumeration; AStripPrefix : Boolean = false) : String;
Var
   OrdTypeInfo: PTypeInfo;
Begin
     If AEnumTypeInfo.Kind <> tkEnumeration Then
        Raise Exception.Create('EnumerationToString - Type Info does not represent an Enumeration')
     Else Begin
        OrdTypeInfo := AEnumTypeInfo;
        Result := GetEnumName(OrdTypeInfo, byte(Enumeration));

        If AStripPrefix Then
           While (Result.Length > 0) and IsLower(Result[1]) Do
                 Delete(Result, 1, 1);
        End;
End;

Procedure StringToEnumeration(Const AString : String; AEnumTypeInfo : PTypeInfo; Var AEnumeration);
Var
   Found : Boolean;
   I, MinVal, MaxVal : Integer;
   OrdTypeInfo: PTypeInfo;
   OrdTypeData: PTypeData;
   FullName, StrippedName, UpEnumeration : String;
Begin
     Found := False;
     UpEnumeration := UpperCase(AString);

     OrdTypeInfo := AEnumTypeInfo;
     OrdTypeData := GetTypeData(OrdTypeInfo);

     MinVal := OrdTypeData^.MinValue;
     MaxVal := OrdTypeData^.MaxValue;

     For I := MinVal to MaxVal Do Begin
         FullName := GetEnumName(OrdTypeInfo, I);

         StrippedName := FullName;
         While (StrippedName.Length > 0) and IsLower(StrippedName[1]) Do
               Delete(StrippedName, 1, 1);

         FullName := UpperCase(FullName);
         StrippedName := UpperCase(StrippedName);

         If (FullName = UpEnumeration) or (StrippedName = UpEnumeration) Then Begin
            Integer(AEnumeration) := I;
            Found := True;
            Break;
            End;
         End;

     If Not Found Then
        Integer(AEnumeration) := MinVal;
End;

Procedure EnumerateEnumeration(TypeInfo : PTypeInfo; AStrings : TStrings; AStripPrefix : Boolean = false);
Var
   I : Integer;
   OrdTypeInfo: PTypeInfo;
   OrdTypeData: PTypeData;
   MinVal, MaxVal: Integer;
   S : String;
Begin
     AStrings.BeginUpdate;
     Try
        AStrings.Clear;

        OrdTypeInfo := TypeInfo;
        OrdTypeData := GetTypeData(OrdTypeInfo);

        MinVal := OrdTypeData^.MinValue;
        MaxVal := OrdTypeData^.MaxValue;

        For I := MinVal to MaxVal Do Begin
            S := GetEnumName(OrdTypeInfo, I);

            If AStripPrefix Then
               While (S.Length > 0) and IsLower(S[1]) Do
                     Delete(S, 1, 1);

            AStrings.Add(S);
            End;
     Finally
        AStrings.EndUpdate;
     End;
End;

Function SetToString(TypeInfo: PTypeInfo; Const Value; Brackets: Boolean): string;
Var
   I : Integer;
   Data : PTypeData;
   EnumInfo : PTypeInfo;
   EnumData : PTypeData;
Begin
     If TypeInfo.Kind <> tkSet Then
        Result := ''
     Else Begin
        Data := GetTypeData(TypeInfo);
        EnumInfo := Data^.CompType^;
        EnumData := GetTypeData(EnumInfo);

        Result := '';
        If Brackets Then Result := '[';

        For I := EnumData.MinValue to EnumData.MaxValue Do
            If I in TIntegerSet(Value) Then Begin
               If (Brackets and (Length(Result) > 1)) or (Not Brackets and (Length(Result) > 0)) Then
                  Result := Result + ',';

               Result := Result + GetEnumName(EnumInfo, I);
               End;

        If Brackets Then Result := Result + ']';
        End;
End;

Procedure StringToSet(Const AString : String; Const TypeInfo : PTypeInfo; Var Value);
Begin
End;

{$ENDIF}

Function IsInteger(Val : Double) : Boolean;
Begin
     Result := Abs(FloatToInt(Val) - Val) < IsIntegerTolerance;
End;

Function IsInteger(Val : Double; Out Value : Integer) : Boolean;
Begin
     Value := FloatToInt(Val);
     Result := Abs(Value - Val) < IsIntegerTolerance;

     If Not Result Then Value := 0;
End;

Function IsInteger(Val : String) : Boolean; overload;
Var
   F : Double;
Begin
     If IsNumber(Val) Then Begin
        F := StrToFloat(Val);
        Result := IsInteger(F);
        End
     Else
        Result := False;
End;

Function IsInteger(Val : String; Out Value : Integer) : Boolean;
Var
   F : Double;
Begin
     If IsNumber(Val) Then Begin
        F := StrToFloat(Val);
        Result := IsInteger(F, Value);
        End
     Else
        Result := False;

     If Not Result Then Value := 0;
End;

Function IsBoolean(Const Val : String) : Boolean;
Var
   UVal : String;
Begin
     UVal := UpperCase(Val);
     Result := (UVal = 'TRUE') or (UVal = 'FALSE');
End;

Function Local_IsBoolean(Const Val : String) : Boolean;
Var
   UVal : String;
Begin
     UVal := UpperCase(Val);
     Result := (UVal = 'TRUE') or (UVal = 'FALSE');
End;

{$HINTS OFF}

Procedure ForceDecimalSeparatorToPeriod(Var S : String);
Begin
     If Not (FormatSettings.DecimalSeparator = '.') Then
        While (Pos(FormatSettings.DecimalSeparator, S) > 0) Do
              S[Pos(FormatSettings.DecimalSeparator, S)] := '.';
End;

Function IsNumber(S : String) : Boolean; overload;
Var
   D : Double;
   E : Integer;
Begin
     If S = '' Then
        Result := false
     Else Begin
        ForceDecimalSeparatorToPeriod(S);
        Val(S, D, E);
        Result := (E = 0);
        End;
End;

Function IsNumber(S : String; Out Value : Double) : Boolean; overload;
Var
   D : Double;
   E : Integer;
Begin
     If S = '' Then Begin
        Result := False;
        D := 0;
        End
     Else Begin
       ForceDecimalSeparatorToPeriod(S);
       Val(S, D, E);
       Result := (E = 0);
       End;

     If Result Then
        Value := D
     Else
        Value := 0;
End;


{$HINTS ON}


{$IFDEF PAS2JS}

Function IsInteger(V : JSValue) : Boolean; overload;
Var
   Vtype : TJSValueType;
Begin
     vType := GetValueType(V);
     Case vType of
                    jvtString : Result := LxWeb.Tool.IsInteger(String(V));
         jvtInteger, jvtFloat : Result := Js.IsInteger(V);
                           Else Result := False;
          End;
End;

Function IsNumber(V : JSValue) : Boolean; overload;
Var
   Vtype : TJSValueType;
Begin
     vType := GetValueType(V);
     Case vType of
          jvtString : Result := LxWeb.Tool.IsNumber(String(V));
         jvtInteger : Result := Js.IsInteger(V);
           jvtFloat : Result := jsIsFinite(V);
         Else Result := False;
          End;
End;

{$ENDIF}

{$endregion}

{$region '************************************** TFile ********************************************'}

Constructor TFile.Create;
Begin
     Inherited Create;
     FStrings := TStringList.Create;
     FIndex := 0;
End;

Destructor TFile.Destroy;
Begin
     FStrings.Free;
End;

Function TFile.GetText : String;
Begin
     Result := FStrings.Text;
End;

Procedure TFile.SetText(Val : String);
Begin
     FStrings.Text := Val;
     FIndex := 0;
End;

{$IFDEF PAS2JS}

Procedure TFile.LoadFromURL(const aURL: String; Async: Boolean; OnLoaded: TNotifyEventRef; OnError: TStringNotifyEventRef);
Begin
     FStrings.LoadFromURL(aUrl, Async, OnLoaded, OnError);
End;

Procedure TFile.LoadFromFile(const aFileName: String; const OnLoaded: TProc; const AError: TProcString);
Begin
     FStrings.LoadFromFile(aFileName, OnLoaded, AError);
End;

Function TFile.LoadFromUrl(AUrl : String) : TJSPromise;
Begin
     Result := TJSPromise.new(
               procedure(ASuccess, AFailed: TJSPromiseResolver)
               Var
                  AStringList  : TStringList;
               begin
                    FStrings.LoadFromUrl(AUrl, True, Procedure(Sender : TObject)
                    Begin
                         FIndex := 0;
                         ASuccess(True);
                    End,
                    Procedure(Sender : TObject; Const aString : String)
                    Begin
                         FIndex := 0;
                         AFailed(False);
                    End);
               End);
End;

{$ENDIF}

Function TFile.Readln : String;
Begin
     If FIndex < FStrings.Count Then Begin
        Result := FStrings[FIndex];
        Inc(FIndex);
        End;
End;

Function TFile.Eof : Boolean;
Begin
     Result := (FIndex >= FStrings.Count);
End;

{$endregion}

{$region '************************************** TDummyInterfacedObject ****************************}

Function TDummyInterfacedObject.QueryInterface(const IID: TGUID; out Obj): HResult;
Begin
     if GetInterface(IID, Obj) then
        Result := 0
     else
        Result := E_NOINTERFACE;

     //Integer(Obj) := 0;
     //Result := E_NOINTERFACE;
End;

Function TDummyInterfacedObject._AddRef: Integer;
Begin
     Result := 0;
End;

Function TDummyInterfacedObject._Release: Integer;
Begin
     Result := 0;
End;

{$endregion}

{$region '************************************** TStringArrayHelper ********************************}

{$IFDEF PAS2JS}

Function TStringArrayHelper.Count : Integer;
Begin
     Result := High(Self) - Low(Self) + 1;
End;

{$ENDIF}

{$endregion}

{$region '************************************** JSValueHelper ************************************'}

{$IFDEF PAS2JS}

Function JSValueHelper.IsNumber : Boolean;
Var
   Vtype : TJSValueType;
Begin
     vType := GetValueType(Self);
     Case vType of
          jvtString : Result := LxWeb.Tools.IsNumber(String(Self));
         jvtInteger : Result := Js.IsInteger(Self);
           jvtFloat : Result := jsIsFinite(Self);
         Else Result := False;
          End;
End;

Function JSValueHelper.IsInteger : Boolean;
Var
   Vtype : TJSValueType;
Begin
     vType := GetValueType(Self);
     Case vType of
                    jvtString : Result := LxWeb.Tools.IsInteger(String(Self));
         jvtInteger, jvtFloat : Result := Js.IsInteger(Self);
                           Else Result := False;
          End;
End;

Function JSValueHelper.IsBoolean : Boolean;
Var
   Vtype : TJSValueType;
   S : String;
Begin
     vType := GetValueType(Self);
     Case vType of
                    jvtString : Begin
                                S := String(Self);
                                Result := Local_IsBoolean(S);
                                End;
                   jvtBoolean : Result := Js.IsBoolean(Self);
                           Else Result := False;
          End;
End;

Function JSValueHelper.IsString : Boolean;
Begin
     Result := Js.IsString(Self);
End;

Function JSValueHelper.isEqual(AValue : JSValue) : Boolean;
Begin
     Result := (jsTypeOf(self) = jsTypeOf(AValue)) and (self = AValue)
End;

Function JSValueHelper.ToNumber : Double;
Var
   Vtype : TJSValueType;
Begin
     vType := GetValueType(Self);
     Case vType of
          jvtString : if LxWeb.Tools.IsNumber(String(Self)) Then
                         Result := StrToFloat(String(Self))
                      else
                         Raise Exception.Create('JSValueHelper.ToNumber - Unable to convert "' + String(Self) + '" to number');
         jvtInteger : Result := js.ToInteger(Self);
           jvtFloat : Result := Double(Self);
                 Else Raise Exception.Create('JSValueHelper.ToNumber - Unable to convert JSValue to number');
          End;
End;

Function JSValueHelper.ToInteger : Integer;
Var
   Vtype : TJSValueType;
Begin
     vType := GetValueType(Self);
     Case vType of
                    jvtString : if LxWeb.Tools.IsInteger(String(Self)) Then
                                   Result := Round(StrToFloat(String(Self)))
                                else
                                   Raise Exception.Create('JSValueHelper.ToInteger - Unable to convert "' + String(Self) + '" to Integer');

         jvtInteger, jvtFloat : Result := js.ToInteger(Self);
                           Else Raise Exception.Create('JSValueHelper.ToInteger - Unable to convert JSValue to Integer');
          End;
End;

Function JSValueHelper.ToBoolean : Boolean;
Var
   Vtype : TJSValueType;
Begin
     vType := GetValueType(Self);
     Case vType of
                    jvtString : if LxWeb.Tools.IsBoolean(String(Self)) Then
                                   Result := StrToBool(String(Self))
                                else
                                   Raise Exception.Create('JSValueHelper.ToBoolean - Unable to convert "' + String(Self) + '" to Boolean');

                   jvtBoolean : Result := js.ToBoolean(Self);
                           Else Raise Exception.Create('JSValueHelper.ToInteger - Unable to convert JSValue to Boolean');
          End;
End;

Function JSValueHelper.ToString : String;
Begin
     Result := String(Self);
End;

Function JSValueHelper.ValueType : TJSValueType;
Begin
     Result := GetValueType(Self);
End;

{$ENDIF}

{$endregion}

{$region '************************************** TFloatFormatHelper *******************************'}

Class Function TFloatFormatHelper.FromString(AStr : String) : TFloatFormat;
Begin
     StringToEnumeration(AStr, TypeInfo(TFloatFormat), Result);
End;

Function TFloatFormatHelper.ToString : String;
Begin
     Result := EnumerationToString(TypeInfo(TFloatFormat), Self);

End;

Function TFloatFormatHelper.Convert(AValue : Double; ADecimalPlaces : Integer) : String;
Begin
     If Self = ffExponent Then
        Result := FloatToStrF(AValue, Self, ADecimalPlaces, ADecimalPlaces)
     Else
        Result := FloatToStrF(AValue, Self, 10, ADecimalPlaces);
End;

Function TFloatFormatHelper.Convert(AValue : JSValue; ADecimalPlaces : Integer) : String;
Begin
     If IsNumber(AValue) Then
        Result := Convert(Double(AValue), ADecimalPlaces)
     Else
        Result := '';
End;

{$endregion}

{$region '************************************************* TNumericLocaleSettings ****************************************'}

Class Function TNumericLocaleSettings.Create : TNumericLocaleSettings;
Begin
     Result.DecimalSeparator := FormatSettings.DecimalSeparator;

     If Result.DecimalSeparator = ',' Then
        Result.ListSeparator := ';'
     Else
        Result.ListSeparator := ',';
End;

Class Function TNumericLocaleSettings.Create_US : TNumericLocaleSettings;
Begin
     Result.DecimalSeparator := '.';
     Result.ListSeparator := ',';
End;

Class Function TNumericLocaleSettings.Create(AFormatSettings : TFormatSettings) : TNumericLocaleSettings;
Begin
     Result.DecimalSeparator := AFormatSettings.DecimalSeparator;
     If Result.DecimalSeparator = ',' Then
        Result.ListSeparator := ';'
     Else
        Result.ListSeparator := ',';
End;

Class Function TNumericLocaleSettings.Create(ADecimalSeparator, AListSeparator : String) : TNumericLocaleSettings;
Begin
     Result.DecimalSeparator := ADecimalSeparator;
     Result.ListSeparator := AListSeparator;
End;

{$endregion}

End.