unit LxWebPasswordStrength;

interface

Uses Classes, SysUtils, Types, WEBLib.Graphics, WEBLib.Controls, LxWebControls, JS, Web;

Type
    TLxWebPasswordStrength = Class(TCustomControl)
                             Private
                             FShapeCount : Integer;
                             FShapes : Array of TJSHTMLELement;
                             FShapeWidth : Integer;
                             FShapeHeight : Integer;
                             FShapeRadius : Integer;
                             FShapeThickness : Integer;
                             FStrength : Integer;
                             FAcceptableStrength : Integer;
                             FShapeColor : TColor;
                             FPoorColor : TColor;
                             FAcceptableColor : TColor;
                             FGoodColor : TColor;

                             FShapeClassName : String;
                             FPoorClassName : String;
                             FAcceptableClassName : String;
                             FGoodClassName : String;



                             Procedure SetShapeCount(Val : Integer);
                             Procedure SetShapeWidth(Val : Integer);
                             Procedure SetShapeHeight(Val : Integer);
                             Procedure SetShapeRadius(Val : Integer);
                             Procedure SetShapeThickness(Val : Integer);

                             Procedure SetStrength(Val : Integer);
                             Procedure SetAcceptableStrength(Val : Integer);

                             Procedure SetShapeColor(Val : TColor);
                             Procedure SetPoorColor(Val : TColor);
                             Procedure SetAcceptableColor(Val : TColor);
                             Procedure SetGoodColor(Val : TColor);

                             Procedure SetShapeClassName(Val : String);
                             Procedure SetPoorClassName(Val : String);
                             Procedure SetAcceptableClassName(Val : String);
                             Procedure SetGoodClassName(Val : String);



                             Procedure InitialiseShapes(AContainer: TJSElement);
                             Procedure RemoveShapes(AContainer: TJSElement);
                             Procedure RecreateShapes;


                             Procedure UpdateShapesStyle;
                             Procedure UpdateStrengthStyle;

                             Protected
                             Procedure CreateInitialize; override;
                             Function CreateElement: TJSElement; override;
                             Procedure CreateChildElements(AContainer: TJSElement); override;
                             Procedure UpdateElementSize; override;


                             Published
                             Property ShapeCount : Integer Read FShapeCount Write SetShapeCount;
                             Property ShapeWidth : Integer Read FShapeWidth Write SetShapeWidth;
                             Property ShapeHeight : Integer Read FShapeHeight Write SetShapeHeight;
                             Property ShapeRadius : Integer Read FShapeRadius Write SetShapeRadius;
                             Property ShapeThickness : Integer Read FShapeThickness Write SetShapeThickness;
                             Property Strength : Integer Read FStrength Write SetStrength;
                             Property AcceptableStrength : Integer Read FAcceptableStrength Write SetAcceptableStrength;
                             Property ShapeColor : TColor Read FShapeColor Write SetShapeColor;
                             Property PoorColor : TColor Read FPoorColor Write SetPoorColor;
                             Property AcceptableColor : TColor Read FAcceptableColor Write SetAcceptableColor;
                             Property GoodColor : TColor Read FGoodColor Write SetGoodColor;

                             Property ShapeClassName : String Read FShapeClassName Write SetShapeClassName;
                             Property PoorClassName : String Read FPoorClassName Write SetPoorClassName;
                             Property AcceptableClassName : String Read FAcceptableClassName Write SetAcceptableClassName;
                             Property GoodClassName : String Read FGoodClassName Write SetGoodClassName;

                             Property Align;
                             Property BorderStyle;
                             Property BorderColor;
                             Property Color;
                             property AlignWithMargins;
                             property Anchors;
                             property DragMode;
                             property ElementID;
                             property ElementFont;
                             property ElementPosition;
                             property Enabled;
                             property HeightPercent;
                             property HeightStyle;
                             property Margins;
                             property WidthPercent;
                             property WidthStyle;
                             property OnClick;
                             property OnDblClick;
                             property OnMouseWheel;
                             property OnTouchStart;
                             property OnTouchMove;
                             property OnTouchEnd;
                             property OnDragDrop;
                             property OnDragOver;
                             property OnEndDrag;
                             property OnStartDrag;
                             End;


implementation

{$region '************************************************* TLxWebPasswordStrength ****************************************'}

Procedure TLxWebPasswordStrength.CreateInitialize;
Begin
     FShapeWidth := 20;
     FShapeHeight := 20;
     FShapeRadius := 12;
     FShapeThickness := 2;
     FStrength := 2;
     FAcceptableStrength := 3;
     FShapeColor := clSilver;
     FPoorColor := clRed;
     FAcceptableColor := clWebOrange;
     FGoodColor := clLime;

     FShapeClassName := '';
     FPoorClassName := '';
     FAcceptableClassName := '';
     FGoodClassName := '';
     Inherited CreateInitialize;
End;

Procedure TLxWebPasswordStrength.SetShapeCount(Val : Integer);
Begin
     If Not(FShapeCount = Val) Then Begin
        FShapeCount := Val;
        If FStrength > FShapeCount Then FStrength := FShapeCount;
        If FAcceptableStrength > FShapeCount Then FAcceptableStrength := FShapeCount;
        RecreateShapes;
        End;
End;

Procedure TLxWebPasswordStrength.SetShapeWidth(Val : Integer);
Begin
     If Not(FShapeWidth = Val) Then Begin
        FShapeWidth := Val;
        UpdateShapesStyle;
        End;
End;

Procedure TLxWebPasswordStrength.SetShapeHeight(Val : Integer);
Begin
     If Not(FShapeHeight = Val) Then Begin
        FShapeHeight := Val;
        UpdateShapesStyle;
        End;
End;

Procedure TLxWebPasswordStrength.SetShapeRadius(Val : Integer);
Begin
     If Not(FShapeRadius = Val) Then Begin
        FShapeRadius := Val;
        UpdateShapesStyle;
        End;
End;

Procedure TLxWebPasswordStrength.SetShapeThickness(Val : Integer);
Begin
     If Not(FShapeThickness = Val) Then Begin
        FShapeThickness := Val;
        UpdateShapesStyle;
        End;
End;

Procedure TLxWebPasswordStrength.SetStrength(Val : Integer);
Begin
     If Val < 0 Then Val := 0;
     If Val > FShapeCount Then Val := FShapeCount;

     If Not(FStrength = Val) Then Begin
        FStrength := Val;
        UpdateStrengthStyle;
        End;
End;

Procedure TLxWebPasswordStrength.SetAcceptableStrength(Val : Integer);
Begin
     If Val < 0 Then Val := 0;
     If Val > FShapeCount Then Val := FShapeCount;

     If Not(FAcceptableStrength = Val) Then Begin
        FAcceptableStrength := Val;
        UpdateStrengthStyle;
        End;
End;

Procedure TLxWebPasswordStrength.SetShapeColor(Val : TColor);
Begin
     If Not(FShapeColor = Val) Then Begin
        FShapeColor := Val;
        UpdateShapesStyle;
        End;
End;

Procedure TLxWebPasswordStrength.SetPoorColor(Val : TColor);
Begin
     If Not(FPoorColor = Val) Then Begin
        FPoorColor := Val;
        UpdateStrengthStyle;
        End;
End;

Procedure TLxWebPasswordStrength.SetAcceptableColor(Val : TColor);
Begin
     If Not(FAcceptableColor = Val) Then Begin
        FAcceptableColor := Val;
        UpdateStrengthStyle;
        End;
End;

Procedure TLxWebPasswordStrength.SetGoodColor(Val : TColor);
Begin
     If Not(FGoodColor = Val) Then Begin
        FGoodColor := Val;
        UpdateStrengthStyle;
        End;
End;

Procedure TLxWebPasswordStrength.SetShapeClassName(Val : String);
Begin
     If Not(FShapeClassName = Val) Then Begin
        FShapeClassName := Val;
        UpdateShapesStyle;
        End;
End;

Procedure TLxWebPasswordStrength.SetPoorClassName(Val : String);
Begin
     If Not(FPoorClassName = Val) Then Begin
        FPoorClassName := Val;
        UpdateStrengthStyle;
        End;
End;

Procedure TLxWebPasswordStrength.SetAcceptableClassName(Val : String);
Begin
     If Not(FAcceptableClassName = Val) Then Begin
        FAcceptableClassName := Val;
        UpdateStrengthStyle;
        End;
End;

Procedure TLxWebPasswordStrength.SetGoodClassName(Val : String);
Begin
     If Not(FGoodClassName = Val) Then Begin
        FGoodClassName := Val;
        UpdateStrengthStyle;
        End;
End;

{Procedure TLxWebPasswordStrength.InitialiseStyles;
Begin
End;}

Function TLxWebPasswordStrength.CreateElement: TJSElement;
Begin
     Result := document.createElement('SPAN');
End;

Procedure TLxWebPasswordStrength.InitialiseShapes(AContainer: TJSElement);
Var
   I : Integer;
Begin
     SetLength(FShapes, FShapeCount);

     For I := 0 to FShapeCount - 1 Do Begin
         FShapes[I] := TJSHTMLElement(document.CreateElement('SPAN'));
         AContainer.appendChild(FShapes[I]);
         End;
End;

Procedure TLxWebPasswordStrength.RemoveShapes(AContainer: TJSElement);
Begin
     While AContainer.hasChildNodes Do
           AContainer.removeChild(AContainer.firstChild);
End;

Procedure TLxWebPasswordStrength.RecreateShapes;
Begin
     RemoveShapes(ElementHandle);
     InitialiseShapes(ElementHandle);
     UpdateShapesStyle;
     UpdateStrengthStyle;
End;

Procedure TLxWebPasswordStrength.UpdateShapesStyle;
Var
   I : Integer;
Begin
     If Length(FShapes) > 0 Then
        For I := 0 to FShapeCount - 1 Do Begin
            FShapes[I].style.SetProperty('display', 'inline-block');

            If Length(FShapeClassname) = 0 Then Begin
               FShapes[I].style.SetProperty('border', ColorToHtml(FShapeColor) + ' ' + IntToStr(FShapeThickness) + 'px solid');
               FShapes[I].style.SetProperty('width', IntToStr(FShapeWidth) + 'px');
               FShapes[I].style.SetProperty('height', IntToStr(FShapeHeight) + 'px');
               FShapes[I].style.SetProperty('border-radius', IntToStr(FShapeRadius) + 'px');
               FShapes[I].style.SetProperty('margin-right', '1px');
               End
            Else
               FShapes[I].classList.add(FShapeClassName);
            End;
End;

Procedure TLxWebPasswordStrength.UpdateStrengthStyle;
Var
   I : Integer;
Begin
     If Length(FShapes) > 0 Then
        For I := 0 to FShapeCount - 1 Do Begin
            FShapes[I].style.RemoveProperty('background-color');
            if Length(FPoorClassName) > 0 Then
               FShapes[I].classList.Remove(FPoorClassName);
            if Length(FAcceptableClassName) > 0 Then
               FShapes[I].classList.Remove(FAcceptableClassName);
            if Length(FPoorClassName) > 0 Then
               FShapes[I].classList.Remove(FAcceptableClassName);
            End;

     If (FStrength > 0) and (Length(FShapes) > 0) Then Begin
        If FStrength < FAcceptableStrength Then Begin
           If Length(FPoorClassName) > 0 Then Begin
              For I := 0 to FStrength - 1 Do
                  FShapes[I].classList.Add(FPoorClassName);
              End
           Else
              For I := 0 to FStrength - 1 Do
                  FShapes[I].style.setProperty('background-color', ColorToHTML(FPoorColor));
           End
        Else If FStrength = FAcceptableStrength Then Begin
           If Length(FAcceptableClassName) > 0 Then Begin
              For I := 0 to FStrength - 1 Do
                  FShapes[I].classList.Add(FAcceptableClassName);
              End
           Else
              For I := 0 to FStrength - 1 Do
                  FShapes[I].style.setProperty('background-color', ColorToHTML(FAcceptableColor));
           End
        Else If FStrength > FAcceptableStrength Then Begin
           If Length(FGoodClassName) > 0 Then Begin
              For I := 0 to FStrength - 1 Do
                  FShapes[I].classList.Add(FGoodClassName);
              End
           Else
              For I := 0 to FStrength - 1 Do
                  FShapes[I].style.setProperty('background-color', ColorToHTML(FGoodColor));
           End
        End;
End;


Procedure TLxWebPasswordStrength.CreateChildElements(AContainer: TJSElement);
Begin
     InitialiseShapes(AContainer);
End;

Procedure TLxWebPasswordStrength.UpdateElementSize;
begin
     Inherited;

     If Assigned(ElementHandle) and Visible Then Begin
        ElementHandle.style.setProperty('vertical-align', 'middle');
        UpdateShapesStyle;
        UpdateStrengthStyle;
        End;
End;



{$endregion}

end.
