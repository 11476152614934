unit Admin.Systems;

interface

Uses Classes, Js, SysUtils, Model.Systems, EndPoints.Types;

Type
    TAdminSystems = Class(TSystems)
                    Function Delete(Index : Integer; AConnection : IHTTPConnection) : Boolean; overload; override; async;
                    Function Update(AConnection : IHTTPConnection) : Boolean; override; async;
                    End;

implementation

Uses LxWeb.JSON;

Function TAdminSystems.Delete(Index : Integer; AConnection : IHTTPConnection) : Boolean;
Var
   ASystem : TSystem;
   AResponse : String;
Begin
     Result := False;

     ASystem := Systems[Index];
     If Assigned(ASystem) and await(boolean, AConnection.Delete('systems/' + ASystem.SystemId, AResponse)) Then Begin
        Delete(ASystem);
        Result := True;
        End;
End;

Function TAdminSystems.Update(AConnection : IHTTPConnection) : Boolean;
Var
   AResponse : String;
begin
     Result := False;

     If await(boolean, AConnection.Get('debug/systems', AResponse)) Then
        Result := StrToJson(AResponse, @fromJSON);
End;

end.
