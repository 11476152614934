unit Model.Systems.Presentation;

interface

Uses Classes, SysUtils, Model.Systems, WEBLib.Grids;

Procedure SystemsToTable(ASystems : TSystems; ATable : TTableControl);

implementation

Procedure SystemsToTable(ASystems : TSystems; ATable : TTableControl);
Var
   I : Integer;
Begin
     ATable.ColCount := 6;
     ATable.RowCount := ASystems.Count + 1;
     ATable.Cells[0,0] := 'System';
     ATable.Cells[1,0] := 'PolyCAD';
     ATable.Cells[2,0] := 'Issued';
     ATable.Cells[3,0] := 'ReIssued';
     ATable.Cells[4,0] := 'Expires';
     ATable.Cells[5,0] := 'Validated';

     For I := 0 to ASystems.Count - 1 Do Begin
         ATable.Cells[0, I + 1] := ASystems[I].SystemId;
         ATable.Cells[1, I + 1] := ASystems[I].PolyCADId;

         If ASystems[I].Issued > 1 Then
            ATable.Cells[2, I + 1] := FormatDateTime('dd-mmm-yy', ASystems[I].Issued)
         Else
            ATable.Cells[2, I + 1] := '';

         If ASystems[I].ReIssued > 1 Then
            ATable.Cells[3, I + 1] := FormatDateTime('dd-mmm-yy', ASystems[I].ReIssued)
         Else
            ATable.Cells[3, I + 1] := '';

         If ASystems[I].Expires > 1 Then
            ATable.Cells[4, I + 1] := FormatDateTime('dd-mmm-yy', ASystems[I].Expires)
         Else
            ATable.Cells[4, I + 1] := '';

         If ASystems[I].Validated > 1 Then
            ATable.Cells[5, I + 1] := FormatDateTime('dd-mmm-yy', ASystems[I].Validated)
         Else
            ATable.Cells[5, I + 1] := '';
         End;
End;


end.
