unit LxWebControls;

interface

uses SysUtils, Classes, Types, WEBLib.Graphics, WEBLib.Controls, Web, Generics.Collections;

type
               TLxWebScrollControl = class;
                TLxWebImageService = class;
                         TImageUrl = String;

          TLxWebControlScrollState = (wcssInactive, wcssScrolling, wcssUpdating);
           TLxWebCaptionFontOption = (foControl, foCaptionFont, foCSS);

                       TLxWebPopup = Class(TCustomControl)
                                     Private
                                     FOnPopup : TNotifyEvent;
                                     FOnHide : TNotifyEvent;
                                     Protected
                                     Procedure DoPopup(Const X, Y : Integer); virtual;
                                     Procedure DoHidePopup; virtual;
                                     Function HandleDoContextMenu(Event: TJSMouseEvent): Boolean; override;
                                     Property OnPopup : TNotifyEvent Read FOnPopup Write FOnPopup;
                                     Property OnHide : TNotifyEvent Read FOnHide Write FOnHide;
                                     Public
                                     Procedure Popup(Const X, Y : Integer);
                                     Procedure HidePopup;
                                     End;

                TLxWebPopupManager = Class(TObject)
                                     Class Procedure AssignParent(AOwner : TComponent; APopupControl : TControl);
                                     Class Procedure RemoveParent(AOwner : TComponent; APopupControl : TControl);
                                     Class Function PopupPosition(Const X, Y : Integer; Const APopupRect : TRect; APopupControl : TControl; AOwner : TComponent) : TPoint; overload;
                                     Class Function PopupPosition(Const X, Y : Integer; Const APopupRect, AControlRect : TRect; APopupControl : TControl; AOwner : TComponent) : TPoint; overload;
                                     Class Function PopupPosition(Const X, Y : Integer; APopupControl : TControl; AOwner : TComponent) : TPoint; overload;

                                     Class Function PopupPosition(Const APositionRect : TRect; APopupControl : TControl; AOwner : TComponent) : TPoint; overload;
                                     Class Function PopupPosition(Const APositionRect, APopupRect : TRect; APopupControl : TControl; AOwner : TComponent) : TPoint; overload;

{                                     Class Function PopupPosition(Const X, Y : Integer; AOwner : TComponent; APopupControl : TControl) : TPoint; overload;
                                     Class Function PopupPosition(Const APoint : TPoint; AOwner : TComponent; APopupControl : TControl) : TPoint; overload;
                                     Class Function PopupPosition(Const ARect : TRect; AOwner : TComponent; APopupControl : TControl) : TPoint; overload;}
                                     End;

                      TLxWebCursor = Class(TCollectionItem)
                                     Private
                                     FImageUrl : TImageUrl;
                                     FOffsetX, FOffsetY : Integer;
                                     FCursorId : Integer;
                                     Procedure SetImageUrl(Val : String);
                                     Protected
                                     Function GetDisplayName: String; override;
                                     Public
                                     Constructor Create(ACollection : TCollection); override;
                                     Function CSSDefinition : String;
                                     Property CursorId : Integer Read FCursorId Write FCursorId;
                                     Property ImageUrl : TImageUrl Read FImageUrl Write SetImageUrl;
                                     Property OffsetX : Integer Read FOffsetX Write FOffsetX;
                                     Property OffsetY : Integer Read FOffsetY Write FOffsetY;
                                     End;

                     TLxWebCursors = Class(TOwnedCollection)
                                     Private
                                     Function GetItem(Index: integer): TLxWebCursor; reintroduce;
                                     Procedure SetItem(Index: integer; const Value: TLxWebCursor); reintroduce;
                                     Public
                                     Constructor Create(AOwner : TComponent); reintroduce;
                                     Function Add: TLxWebCursor; reintroduce; overload;
                                     Function Insert(Index: integer): TLxWebCursor; reintroduce; overload;
                                     Procedure AddCursor(ACursorId : Integer; AImageUrl : TImageUrl); overload;
                                     Procedure AddCursor(ACursorId : Integer; AImageUrl : TImageUrl; AOffsetX, AOffsetY : Integer); overload;
                                     Procedure DeleteCursor(ACursorId : Integer);
                                     Function ContainsCursor(ACursorId : Integer) : Boolean;
                                     Function IndexOfCursor(ACursorId : Integer) : Integer;
                                     Procedure SetElementPointer(AElement: TJSHTMLElement; ACursor: TCursor);
                                     Property Items[Index: integer] : TLxWebCursor read GetItem write SetItem; default;
                                     End;

               TLxWebCustomControl = Class(TCustomControl)
                                     Private
                                     FVisibilityChangePtr : Pointer;
                                     Protected
                                     procedure BindEvents; override;
                                     procedure UnBindEvents; override;
                                     procedure ClearMethodPointers; override;
                                     procedure GetMethodPointers; override;
                                     Function HandleDoVisibilityChanged(Event: TEventListenerEvent): Boolean;
                                     Procedure DoVisibilityChanged; virtual;
                                     End;

               TLxWebCursorControl = Class(TLxWebCustomControl)
                                     Private
                                     Class Var FCursors : TLxWebCursors;
                                     Procedure SetElementPointer(AElement: TJSHTMLElement; ACursor: TCursor); override;
                                     Public
                                     Class Constructor Create;
                                     End;

               TLxWebBorderControl = Class(TLxWebCursorControl)
                                     Private
                                     FContextPopup : TLxWebPopup;
                                     Protected
                                     Procedure UpdateElementVisual; override;
                                     Function HandleDoContextMenu(Event: TJSMouseEvent): Boolean; override;
                                     Property ContextPopup : TLxWebPopup Read FContextPopup Write FContextPopup;
                                     Public
                                     Procedure Notification(AComponent: TComponent; Operation: TOperation); override;
                                     End;

         TLxWebScrollBarController = Class(TPersistent)
                                     Private
                                     FParentScrollerControl : TLxWebScrollControl;
                                     FKind : TScrollBarKind;
                                     FClientRange, FScrollPosition, FScrollPageSize : Integer;
                                     FPageSize, FMin, FMax, FPosition : Integer;
                                     FHiddenIfUnnecessary : Boolean;
                                     Function GetControl : TLxWebScrollControl;
                                     Function GetScrollVisible : Boolean;
                                     Function GetScrollPosition : Integer;
                                     Function GetScrollPageSize : Integer;
                                     Procedure SetScrollPosition(Val : Integer);
                                     Procedure SetScrollPageSize(Val : Integer);
                                     Procedure SetPosition(Val : Integer);
                                     Procedure SetMin(Val : Integer);
                                     Procedure SetMax(Val : Integer);
                                     Procedure SetPageSize(Val : Integer);
                                     Procedure SetHiddenIfUnnecessary(Val : Boolean);
                                     Function GetEnabled : Boolean;
                                     Protected
                                     Procedure Invalidate;
                                     Procedure Update;
                                     Function HandleScroll(Event: TJSUIEvent) : Boolean;
                                     Property Control : TLxWebScrollControl Read GetControl;
                                     Property ScrollPosition : Integer Read GetScrollPosition Write SetScrollPosition;
                                     Property ScrollPageSize : Integer Read GetScrollPageSize Write SetScrollPageSize;
                                     Property ScrollVisible : Boolean Read GetScrollVisible;
                                     Public
                                     Constructor Create(Owner : TLxWebScrollControl; Kind : TScrollBarKind);
                                     Procedure Assign(Source : TPersistent); override;
                                     Property Enabled : Boolean Read GetEnabled;
                                     Published
                                     Property Position : Integer Read FPosition Write SetPosition;
                                     Property Min : Integer Read FMin Write SetMin;
                                     Property Max : Integer Read FMax Write SetMax;
                                     Property PageSize : Integer Read FPageSize Write SetPageSize;
                                     Property HiddenIfUnnecessary : Boolean Read FHiddenIfUnnecessary Write SetHiddenIfUnnecessary;
                                     End;


          TLxWebScrollControlEvent = Procedure(Sender : TObject; Const HorizontalPosition, VerticalPosition : Integer);

               TLxWebScrollControl = class(TLxWebBorderControl)
                                      private
                                        FScrollState : TLxWebControlScrollState;
                                        FScrollingElement : TJSHTMLElement;
                                        FScrollModel : TJSHTMLElement;
                                        FScrollPtr: pointer;
                                        FVScrollBar, FHScrollBar : TLxWebScrollBarController;
                                        FScrollBars : TScrollStyle;
                                        FOnScroll : TLxWebScrollControlEvent;
                                        FZIndex : Integer;
                                        Procedure SetZIndex(Val : integer);
                                      protected
                                        function GetClientRect: TRect; override;
                                        Function CreateElement : TJSElement; override;
                                        Procedure UpdateElementVisual; override;
                                        Procedure UpdateElementOverflow;
                                        procedure UpdateElement; override;
                                        procedure Invalidate; override;
                                        procedure Resize; override;

                                        Function HandleScroll(Event: TJSUIEvent): Boolean;
                                        Procedure DoScroll(Const HorizontalPosition, VerticalPosition : Integer); virtual;
                                        Procedure DoUpdateScrolling(HorizontalScrollBar, VerticalScrollBar : TLxWebScrollBarController); virtual;
                                        Procedure UpdateScrolling;

                                        procedure BindEvents; override;
                                        procedure UnBindEvents; override;
                                        procedure ClearMethodPointers; override;
                                        procedure GetMethodPointers; override;
                                        Property ZIndex : Integer Read FZIndex Write SetZIndex;
                                        Property ScrollingElement : TJSHTMLElement Read FScrollingElement;
                                        Property ScrollModel : TJSHTMLElement Read FScrollModel;
                                        Property ScrollBars : TScrollStyle Read FScrollBars Write FScrollBars;
                                        Property VScrollBar : TLxWebScrollBarController Read FVScrollBar;
                                        Property HScrollBar : TLxWebScrollBarController Read FHScrollBar;
                                        Property OnScroll : TLxWebScrollControlEvent Read FOnScroll Write FOnScroll;
                                      public
                                        Procedure CreateInitialize; override;
                                        Destructor Destroy; override;
                                      published
                                      end;

                     TLxFloatFormat = Class(TPersistent)
                                      Private
                                      FFormat : TFloatFormat;
                                      FDecimalPlaces : Integer;
                                      FOnChange : TNotifyEvent;
                                      Procedure SetFormat(Val : TFloatFormat);
                                      Procedure SetDecimalPlaces(Val : Integer);
                                      Protected
                                      Procedure Change;
                                      Public
                                      Constructor Create;
                                      procedure Assign(Source: TPersistent); overload; override;
                                      Procedure Assign(AFloatFormat : TLxFloatFormat; Quiet : Boolean = false); reintroduce; overload;
                                      Function Convert(AValue : Double) : String; overload;
                                      Function Convert(AValue : JSValue) : String; overload;
                                      Property OnChange : TNotifyEvent Read FOnChange Write FOnChange;
                                      Published
                                      Property Format : TFloatFormat Read FFormat Write SetFormat default ffGeneral;
                                      Property DecimalPlaces : Integer Read FDecimalPlaces Write SetDecimalPlaces default 3;
                                      End;

        TLxWebImageServiceLoadEvent = Procedure(AImageService : TLxWebImageService; AImageUrl : TImageUrl) of Object;

             TLxWebImageServiceLink = Class(TObject)
                                      Private
                                      FClient : TObject;
                                      FImageService : TLxWebImageService;
                                      FOnLoad : TLxWebImageServiceLoadEvent;
                                      Protected
                                      Procedure SetImageService(Val : TLxWebImageService); virtual;
                                      Procedure ImageLoad(AImageService : TLxWebImageService; AImageUrl : TImageUrl); virtual;
                                      Public
                                      Constructor Create(AClient : TObject);
                                      Destructor Destroy; override;
                                      Property Client : TObject Read FClient;
                                      Property ImageService : TLxWebImageService Read FImageService Write SetImageService;
                                      Property OnLoad : TLxWebImageServiceLoadEvent Read FOnLoad Write FOnLoad;
                                      End;

                 TLxWebImageService = Class(TComponent)
                                      Protected
                                      FClients : TList<TLxWebImageServiceLink>;
                                      Function GetCount : Integer; virtual; abstract;
                                      Function GetWidth : Integer; virtual; abstract;
                                      Function GetHeight : Integer; virtual; abstract;
                                      Function GetUrl(Index : Integer) : TImageUrl; virtual; abstract;
                                      Procedure NotifyLoad(AImageUrl : TImageUrl);
                                      Procedure Attach(ALink : TLxWebImageServiceLink);
                                      Procedure Detach(ALink : TLxWebImageServiceLink);
                                      Procedure DetachAll;
                                      Public
                                      Constructor Create(AOwner : TComponent); override;
                                      Destructor Destroy; override;
                                      Function Contains(AImageUrl : TImageUrl) : Boolean; virtual; abstract;
                                      Procedure Draw(Const ImageIndex, X, Y : Integer; ACanvas : TCanvas); overload;
                                      Procedure Draw(Const ImageUrl : TImageUrl; Const X, Y : Integer; ACanvas : TCanvas); overload;
                                      Procedure Draw(Const ImageIndex, X, Y : Integer; AEnabled : Boolean; ACanvas : TCanvas); overload; virtual; abstract;
                                      Procedure Draw(Const ImageUrl : TImageUrl; Const X, Y : Integer; AEnabled : Boolean; ACanvas : TCanvas); overload; virtual; abstract;
                                      Procedure Draw(Const ImageIndex, X, Y, AWidth, AHeight : Integer; ACanvas : TCanvas); overload;
                                      Procedure Draw(Const ImageUrl : TImageUrl; Const X, Y, AWidth, AHeight : Integer; ACanvas : TCanvas); overload;
                                      Procedure Draw(Const ImageIndex, X, Y, AWidth, AHeight : Integer; AEnabled : Boolean; ACanvas : TCanvas); overload; virtual; abstract;
                                      Procedure Draw(Const ImageUrl : TImageUrl; Const X, Y, AWidth, AHeight : Integer; AEnabled : Boolean; ACanvas : TCanvas); overload; virtual; abstract;
                                      Property Count : Integer Read GetCount;
                                      Property Width : Integer Read GetWidth;
                                      Property Height : Integer Read GetHeight;
                                      Property Urls[Index : Integer] : TImageUrl Read GetUrl;
                                      End;

                TLxWebControlAction = class;
                 TLxWebActionChange = (acEnabled, acVisible, acChecked, acCaption, acCategory, acHint, acGroupIndex, acImageUrl, acExecuteEvent);
            TLxWebActionChangeEvent = Procedure(AAction : TLxWebControlAction; AChange : TLxWebActionChange) of Object;

            TLxWebControlActionLink = Class(TObject)
                                      Private
                                      FClient : TObject;
                                      FAction : TLxWebControlAction;
                                      FOnChange : TLxWebActionChangeEvent;
                                      Protected
                                      Function GetIsEnabled : Boolean;
                                      Function GetIsLinked : Boolean;
                                      Function GetEnabled : Boolean;
                                      Function GetVisible : Boolean;
                                      Function GetChecked : Boolean;
                                      Function GetCaption : String;
                                      Function GetCategory : String;
                                      Function GetHint : String;
                                      Function GetGroupIndex : Integer;
                                      Function GetImageUrl : TImageUrl;
                                      Function GetImages : TLxWebImageService;

                                      {Procedure SetEnabled(Val : Boolean);
                                      Procedure SetVisible(Val : Boolean);
                                      Procedure SetChecked(Val : Boolean);
                                      Procedure SetCaption(Val : String);
                                      Procedure SetCategory(Val : String);
                                      Procedure SetHint(Val : String);
                                      Procedure SetGroupIndex(Val : Integer);
                                      Procedure SetImageUrl(Val : TImageUrl);}
                                      Procedure SetAction(Val : TLxWebControlAction); virtual;
                                      Procedure Change(AAction : TLxWebControlAction; AChange : TLxWebActionChange); virtual;

                                      Property Client : TObject Read FClient;
                                      Public
                                      Constructor Create(AClient : TObject); virtual;
                                      Destructor Destroy; override;
                                      Procedure Execute(Sender : TObject);
                                      Property Action : TLxWebControlAction Read FAction Write SetAction;
                                      Property IsEnabled : Boolean Read GetIsEnabled;
                                      Property IsLinked : Boolean Read GetIsLinked;
                                      Property Enabled : Boolean Read GetEnabled;
                                      Property Visible : Boolean Read GetVisible;
                                      Property Checked : Boolean Read GetChecked;
                                      Property Caption : String Read GetCaption;
                                      Property Category : String Read GetCategory;
                                      Property Hint : String Read GetHint;
                                      Property GroupIndex : Integer Read GetGroupIndex;
                                      Property ImageUrl : TImageUrl Read GetImageUrl;
                                      Property Images : TLxWebImageService Read GetImages;
                                      Property OnChange : TLxWebActionChangeEvent Read FOnChange Write FOnChange;
                                      End;

                TLxWebControlAction = Class(TComponent)
                                      Private
                                      FClients : TList<TLxWebControlActionLink>;
                                      Protected
                                      Function GetIsEnabled : Boolean; virtual; abstract;
                                      Function GetEnabled : Boolean; virtual; abstract;
                                      Function GetVisible : Boolean; virtual; abstract;
                                      Function GetChecked : Boolean; virtual; abstract;
                                      Function GetAutoCheck : Boolean; virtual; abstract;
                                      Function GetCaption : String; virtual; abstract;
                                      Function GetCategory : String; virtual; abstract;
                                      Function GetHint : String; virtual; abstract;
                                      Function GetGroupIndex : Integer; virtual; abstract;
                                      Function GetImageUrl : TImageUrl; virtual; abstract;
                                      Function GetImages : TLxWebImageService; virtual;
                                      Function GetExecuteEvent : TNotifyEvent; virtual;
                                      Function GetUpdateEvent : TNotifyEvent; virtual;
                                      Procedure Attach(ALink : TLxWebControlActionLink);
                                      Procedure Detach(ALink : TLxWebControlActionLink);
                                      Procedure DetachAll;
                                      Procedure DoBeforeExecute; virtual;
                                      Procedure DoAfterExecute; virtual;
                                      Procedure Change(ActionChange : TLxWebActionChange); virtual;
                                      Public
                                      Constructor Create(AOwner : TComponent); override;
                                      Destructor Destroy; override;
                                      Procedure Execute(Sender : TObject);
                                      Procedure Update(Sender : TObject);
                                      Property IsEnabled : Boolean Read GetIsEnabled;
                                      Property Enabled : Boolean Read GetEnabled;
                                      Property Visible : Boolean Read GetVisible;
                                      Property Checked : Boolean Read GetChecked;
                                      Property AutoCheck : Boolean Read GetAutoCheck;
                                      Property Caption : string Read GetCaption;
                                      Property Category : String Read GetCategory;
                                      Property Hint : String Read GetHint;
                                      Property GroupIndex : Integer Read GetGroupIndex;
                                      Property ImageUrl : TImageUrl Read GetImageUrl;
                                      Property Images : TLxWebImageService Read GetImages;
                                      Property OnExecute : TNotifyEvent Read GetExecuteEvent;
                                      Property OnUpdate : TNotifyEvent Read GetUpdateEvent;
                                      End;


Procedure RegisterCursors(ACursors : TLxWebCursors);

implementation

Uses Math, WEBLib.Forms, LxWeb.Graphics, LxWeb.Tools;

{$region '************************************** TLxWebPopup *****************************************'}

Procedure TLxWebPopup.DoPopup(Const X, Y : Integer);
Begin
End;

Procedure TLxWebPopup.Popup(Const X, Y : Integer);
Begin
     If Assigned(FOnPopup) Then FOnPopup(Self);
     DoPopup(X, Y);
End;

Procedure TLxWebPopup.DoHidePopup;
Begin
End;

Procedure TLxWebPopup.HidePopup;
Begin
     DoHidePopup;
     If Assigned(FOnHide) Then FOnHide(Self);
End;

Function TLxWebPopup.HandleDoContextMenu(Event: TJSMouseEvent): Boolean;
Begin
     StopPropagation;
     Event.preventDefault;
     Result := False;
End;

{$endregion}

{$region '************************************** TLxWebPopupManager **********************************'}

Class Procedure TLxWebPopupManager.AssignParent(AOwner : TComponent; APopupControl : TControl);
Var
   OwnerControl : TControl;
   ParentForm : TCustomForm;
Begin
     OwnerControl := AOwner as TControl;

     If Assigned(OwnerControl) Then Begin
        If Not Assigned(APopupControl.Parent) Then Begin
           ParentForm := GetParentForm(OwnerControl);
           If Assigned(ParentForm) then
              APopupControl.Parent := ParentForm
           Else
              APopupControl.Parent := OwnerControl;
           End;
        End
     Else
        Raise Exception.Create('TLxWebPopupManager.AssignParent - Owner is not assigned or not a control');
End;

Class Procedure TLxWebPopupManager.RemoveParent(AOwner : TComponent; APopupControl : TControl);
Var
   OwnerControl : TControl;
Begin
     OwnerControl := AOwner as TControl;

     If Assigned(OwnerControl) Then Begin
        If Assigned(APopupControl.Parent) Then
           APopupControl.Parent := nil;
        End
     Else
        Raise Exception.Create('TLxWebPopupManager.RemoveParent - Owner is not assigned or not a control');
End;

Class Function TLxWebPopupManager.PopupPosition(Const X, Y : Integer; Const APopupRect : TRect; APopupControl : TControl; AOwner : TComponent) : TPoint; overload;
Var
   TheParent : TControl;
   DropDownX, DropDownY, DropDownLeft, DropDownTop : Double;
   OwnerControl : TControl;
Begin
     OwnerControl := AOwner as TControl;

     If Assigned(OwnerControl) Then Begin
        If Assigned(APopupControl.Parent) Then Begin
           TheParent := APopupControl.Parent;
           If TheParent is TCustomForm Then Begin
              If TheParent = Application.MainForm Then Begin
                 Result := OwnerControl.ClientToScreen(Point(X, Y));

                 If Result.X + APopupRect.Width > TheParent.Width Then
                    Result.X := Result.X - APopupRect.Width;// + OwnerControl.Width;

                 If Result.Y + APopupRect.Height > TheParent.Height Then
                    Result.Y := Result.Y - APopupRect.Height;//{ - OwnerControl.Height};
                 End
              Else Begin
                 OwnerControl.ClientToXY(X, Y, DropDownX, DropDownY);
                 TheParent.XYToClient(DropDownX, DropDownY, DropDownLeft, DropDownTop);

                 Result.X := Round(DropDownLeft);
                 Result.Y := Round(DropDownTop);
                 End;
              End
           Else Begin
              OwnerControl.ClientToXY(X, Y, DropDownX, DropDownY);
              TheParent.XYToClient(DropDownX, DropDownY, DropDownLeft, DropDownTop);

              Result.X := Round(DropDownX);
              Result.Y := Round(DropDownY);
              End;
           End
        Else
           Raise Exception.Create('TLxWebPopupManager.PopupPosition - Popup ' + APopupControl.ClassName + ' in ' + OwnerControl.Name + ' does not have an assigned parent');
        End
     Else
        Raise Exception.Create('TLxWebPopupManager.PopupPosition - Owner is not assigned or not a control');
End;

Class Function TLxWebPopupManager.PopupPosition(Const X, Y : Integer; Const APopupRect, AControlRect : TRect; APopupControl : TControl; AOwner : TComponent) : TPoint; overload;
Var
   TheParent : TControl;
   DropDownX, DropDownY, DropDownLeft, DropDownTop : Double;
   OwnerControl : TControl;
Begin
     OwnerControl := AOwner as TControl;

     If Assigned(OwnerControl) Then Begin
        If Assigned(APopupControl.Parent) Then Begin
           TheParent := APopupControl.Parent;
           If TheParent is TCustomForm Then Begin
              If TheParent = Application.MainForm Then Begin
                 Result := OwnerControl.ClientToScreen(Point(X, Y));

                 If Result.X + APopupRect.Width > TheParent.Width Then
                    Result.X := Result.X - APopupRect.Width + AControlRect.Width;

                 If Result.Y + APopupRect.Height > TheParent.Height Then
                    Result.Y := Result.Y - APopupRect.Height - AControlRect.Height;
                 End
              Else Begin
                 OwnerControl.ClientToXY(X, Y, DropDownX, DropDownY);
                 TheParent.XYToClient(DropDownX, DropDownY, DropDownLeft, DropDownTop);

                 Result.X := Round(DropDownLeft);
                 Result.Y := Round(DropDownTop);
                 End;
              End
           Else
              Result := Point(X, Y);
           End
        Else
           Raise Exception.Create('TLxWebPopupManager.PopupPosition - Popup ' + APopupControl.ClassName + ' in ' + OwnerControl.Name + ' does not have an assigned parent');
        End
     Else
        Raise Exception.Create('TLxWebPopupManager.PopupPosition - Owner is not assigned or not a control');
End;


Class Function TLxWebPopupManager.PopupPosition(Const X, Y : Integer; APopupControl : TControl; AOwner : TComponent) : TPoint;
Var
   ARect : TRect;
Begin
     ARect := APopupControl.ClientRect;
     Result := PopupPosition(X, Y, ARect, APopupControl, AOwner);
End;

Class Function TLxWebPopupManager.PopupPosition(Const APositionRect : TRect; APopupControl : TControl; AOwner : TComponent) : TPoint;
Var
   APopupRect : TRect;
Begin
     APopupRect := APopupControl.ClientRect;
     Result := PopupPosition(APositionRect, APopupRect, APopupControl, AOwner);
End;

Class Function TLxWebPopupManager.PopupPosition(Const APositionRect, APopupRect : TRect; APopupControl : TControl; AOwner : TComponent) : TPoint;
Var
   PopupPosition : TPoint;
   TheParent : TControl;
   OwnerControl : TControl;
Begin
     PopupPosition.X := APositionRect.Right - APopupRect.Width;
     PopupPosition.Y := APositionRect.Bottom;

     OwnerControl := AOwner as TControl;

     If Assigned(OwnerControl) Then Begin
        If Assigned(APopupControl.Parent) Then Begin
           TheParent := APopupControl.Parent;
           If TheParent is TCustomForm Then Begin
              If TheParent = Application.MainForm Then Begin
                 Result := OwnerControl.ClientToScreen(PopupPosition);

                 If Result.X < 0 Then
                    Result.X := Result.X + (APopupRect.Width - APositionRect.Width)
                 Else If Result.X + APopupRect.Width > TheParent.Width Then
                    Result.X := Result.X - APositionRect.Width;

                 If Result.Y + APopupRect.Height > TheParent.Height Then
                    Result.Y := Result.Y - APopupRect.Height - APositionRect.Height - 3;
                 End
              Else Begin
{                 OwnerControl.ClientToXY(X, Y, DropDownX, DropDownY);
                 TheParent.XYToClient(DropDownX, DropDownY, DropDownLeft, DropDownTop);

                 Result.X := Round(DropDownLeft);
                 Result.Y := Round(DropDownTop);}
                 End;
              End;
           End
        Else
           Raise Exception.Create('TLxWebPopupManager.PopupPosition - Popup ' + APopupControl.ClassName + ' in ' + OwnerControl.Name + ' does not have an assigned parent');
        End
     Else
        Raise Exception.Create('TLxWebPopupManager.PopupPosition - Owner is not assigned or not a control');
End;



(*


Class Function TLxWebPopupManager.PopupPosition(Const X, Y : Integer; AOwner : TComponent; APopupControl : TControl) : TPoint;
Begin
     Result := PopupPosition(Point(X, Y), AOwner, APopupControl);
End;

Class Function TLxWebPopupManager.PopupPosition(Const APoint : TPoint; AOwner : TComponent; APopupControl : TControl) : TPoint;
Var
   TheParent : TControl;
   DropDownX, DropDownY, DropDownLeft, DropDownTop : Double;
   OwnerControl : TControl;
Begin
     OwnerControl := AOwner as TControl;

     If Assigned(OwnerControl) Then Begin
        If Assigned(APopupControl.Parent) Then Begin
           TheParent := APopupControl.Parent;
           If TheParent is TCustomForm Then Begin
              If TheParent = Application.MainForm Then Begin
                 Result := OwnerControl.ClientToScreen(APoint);

                 If Result.X + APopupControl.Width > TheParent.Width Then
                    Result.X := Result.X - APopupControl.Width + OwnerControl.Width;

                 If Result.Y + APopupControl.Height > TheParent.Height Then
                    Result.Y := Result.Y - APopupControl.Height{ - OwnerControl.Height};
                 End
              Else Begin
                 OwnerControl.ClientToXY(APoint.X, APoint.Y, DropDownX, DropDownY);
                 TheParent.XYToClient(DropDownX, DropDownY, DropDownLeft, DropDownTop);

                 Result.X := Round(DropDownLeft);
                 Result.Y := Round(DropDownTop);
                 End;
              End
           Else
              Result := APoint;
           End
        Else
           Raise Exception.Create('TLxWebPopupManager.PopupPosition - Popup ' + APopupControl.ClassName + ' in ' + OwnerControl.Name + ' does not have an assigned parent');
        End
     Else
        Raise Exception.Create('TLxWebPopupManager.PopupPosition - Owner is not assigned or not a control');
End;

Class Function TLxWebPopupManager.PopupPosition(Const ARect : TRect; AOwner : TComponent; APopupControl : TControl) : TPoint;
Begin
     Result := PopupPosition(Point(ARect.Left, ARect.Bottom), AOwner, APopupControl);
End;

*)


{$endregion}

{$region '************************************** TLxWebCursor ****************************************'}

Constructor TLxWebCursor.Create(ACollection : TCollection);
Begin
     Inherited Create(ACollection);
     FCursorId := -1;
     FOffsetX := 0;
     FOffsetY := 0;
     FImageUrl := '';
End;

Function TLxWebCursor.GetDisplayName: String;
Begin
     If Length(FImageUrl) > 0 Then
        Result := Format('%d - %s', [FCursorId, FImageUrl])
     Else
        Result := Format('%d', [FCursorId]);
End;

Procedure TLxWebCursor.SetImageUrl(Val : String);
Begin
     FImageUrl := CorrectPathDelimiter(Val);
End;

Function TLxWebCursor.CSSDefinition : String;
Begin
     if Not((FOffsetX = 0) and (FOffsetY = 0)) Then
        Result := Format('url(%s) %d %d, auto', [FImageUrl, FOffsetX, FOffsetY])
     Else
        Result := Format('url(%s), auto', [FImageUrl, FOffsetX, FOffsetY]);
End;

{$endregion}

{$region '************************************** TLxWebCursors ***************************************'}

Constructor TLxWebCursors.Create(AOwner : TComponent);
Begin
     Inherited Create(AOwner, TLxWebCursor);
End;

Function TLxWebCursors.GetItem(Index: integer): TLxWebCursor;
Begin
     Result := TLxWebCursor(inherited Items[Index]);
End;

Procedure TLxWebCursors.SetItem(Index: integer; const Value: TLxWebCursor);
Begin
     inherited Items[Index] := Value;
End;


Function TLxWebCursors.Add : TLxWebCursor;
Begin
     Result := TLxWebCursor(inherited Add);
End;

Function TLxWebCursors.Insert(Index: integer): TLxWebCursor;
Begin
     Result := TLxWebCursor(inherited Insert(Index));
End;

Procedure TLxWebCursors.AddCursor(ACursorId : Integer; AImageUrl : TImageUrl);
Var
   ACursor : TLxWebCursor;
Begin
     ACursor := Add;
     ACursor.FCursorId := ACursorId;
     ACursor.FImageUrl := AImageUrl;
End;

Procedure TLxWebCursors.AddCursor(ACursorId : Integer; AImageUrl : TImageUrl; AOffsetX, AOffsetY : Integer);
Var
   ACursor : TLxWebCursor;
Begin
     ACursor := Add;
     ACursor.FCursorId := ACursorId;
     ACursor.FImageUrl := AImageUrl;
     ACursor.FOffsetX := AOffsetX;
     ACursor.FOffsetY := AOffsetY;
End;

Procedure TLxWebCursors.DeleteCursor(ACursorId : Integer);
Var
   Index : Integer;
Begin
     Index := IndexOfCursor(ACursorId);
     If Index > -1 Then
        Delete(Index);
End;

Function TLxWebCursors.ContainsCursor(ACursorId : Integer) : Boolean;
Begin
     Result := IndexOfCursor(ACursorId) > -1;
End;

Function TLxWebCursors.IndexOfCursor(ACursorId : Integer) : Integer;
Var
   I : Integer;
Begin
     Result := -1;
     For I := 0 to Count - 1 Do
         If Items[I].CursorId = ACursorId Then Begin
            Result := I;
            Break;
            End;
End;

Procedure TLxWebCursors.SetElementPointer(AElement: TJSHTMLElement; ACursor: TCursor);
Var
   Index : Integer;
   ACursorDefinition : TLxWebCursor;
Begin
     Index := IndexOfCursor(ACursor);
     If Index > -1 Then Begin
        ACursorDefinition := Items[Index];
        AElement.Style.setProperty('cursor', ACursorDefinition.CSSDefinition);
        End;
End;


{$endregion}

{$region '************************************** TLxWebCustomControl *********************************'}

procedure TLxWebCustomControl.GetMethodPointers;
begin
     inherited;
     FVisibilityChangePtr := @HandleDoVisibilityChanged;
end;

procedure TLxWebCustomControl.ClearMethodPointers;
begin
     inherited;
     FVisibilityChangePtr := nil;
end;

procedure TLxWebCustomControl.BindEvents;
begin
     inherited;
     if Assigned(ElementBindHandle) then
        document.addEventListener('visibilitychange', FVisibilityChangePtr);
end;

procedure TLxWebCustomControl.UnBindEvents;
begin
     inherited;
     if Assigned(ElementBindHandle) then
        document.removeEventListener('visibilitychange', FVisibilityChangePtr);
end;

Function TLxWebCustomControl.HandleDoVisibilityChanged(Event: TEventListenerEvent): Boolean;
Begin
     If Visible Then DoVisibilityChanged;
     Result := False;
End;

Procedure TLxWebCustomControl.DoVisibilityChanged;
Begin
     Invalidate;
End;

{$endregion}

{$region '************************************** TLxWebCursorControl *********************************'}

Class Constructor TLxWebCursorControl.Create;
Begin
     FCursors := nil;
End;

Procedure TLxWebCursorControl.SetElementPointer(AElement: TJSHTMLElement; ACursor: TCursor);
Begin
     If Assigned(FCursors) and FCursors.ContainsCursor(ACursor) Then
        FCursors.SetElementPointer(AElement, ACursor)
     Else
        Inherited;
End;

{$endregion}

{$region '************************************** TLxWebBorderControl *********************************'}

Procedure TLxWebBorderControl.UpdateElementVisual;
Begin
     Inherited;

     If Assigned(ElementHandle) Then Begin
        If BorderStyle = bsSingle Then Begin
           GetElementStyle.setProperty('border-style', 'solid');
           GetElementStyle.setProperty('border-width', '1px');
           GetElementStyle.setProperty('border-color', ColorToHTML(BorderColor));
           End
        Else
           GetElementStyle.setProperty('border-style', 'none');
        End;
End;

Function TLxWebBorderControl.HandleDoContextMenu(Event: TJSMouseEvent): Boolean;
Var
   pt: TPoint;
Begin
     inherited;

     if Assigned(FContextPopup) Then Begin
        Event.stopPropagation;
        Event.preventDefault;
        pt := Point(Round(Event.clientX), Round(Event.clientY));
        FContextPopup.Popup(pt.X, pt.Y);
        Result := true;
        End;
end;

Procedure TLxWebBorderControl.Notification(AComponent: TComponent; Operation: TOperation);
Begin
     inherited Notification(AComponent, Operation);

     If (Operation = opRemove) Then Begin
        If AComponent = FContextPopup Then
           FContextPopup := nil;
        End;
End;

{$endregion}

{$region '************************************** TLxWebScrollBarController ***************************'}

Constructor TLxWebScrollBarController.Create(Owner : TLxWebScrollControl; Kind : TScrollBarKind);
Begin
     Inherited Create;
     FKind := Kind;
     FParentScrollerControl := Owner;
     FHiddenIfUnnecessary := False;
     FClientRange := 0;
     FScrollPosition := -1;
     FScrollPageSize := -1;
End;

Procedure TLxWebScrollBarController.Assign(Source : TPersistent);
Var
   AController : TLxWebScrollBarController;
Begin
     If Source is TLxWebScrollBarController Then Begin
        AController := Source as TLxWebScrollBarController;
        FKind := AController.FKind;
        FPageSize := AController.FPageSize;
        FMin := AController.FMin;
        FMax := AController.FMax;
        FPosition := AController.FPosition;
        FHiddenIfUnnecessary := AController.FHiddenIfUnnecessary;
        Invalidate;
        End
     Else
        Inherited Assign(Source);
End;

Function TLxWebScrollBarController.GetEnabled : Boolean;
Begin
     Result := ((FKind = sbHorizontal) and (Control.ScrollBars = ssHorizontal)) or
               ((FKind = sbVertical) and (Control.ScrollBars = ssVertical)) or
               (Control.ScrollBars = ssBoth);
End;

Function TLxWebScrollBarController.GetControl : TLxWebScrollControl;
Begin
     Result := FParentScrollerControl;
End;

Function TLxWebScrollBarController.GetScrollVisible : Boolean;
Begin
     Result := ((FKind = sbHorizontal) and (Control.ScrollBars = ssHorizontal)) or
               ((FKind = sbVertical) and (Control.ScrollBars = ssVertical)) or
               (Control.ScrollBars = ssBoth);

     Result := Result and (((FMax - FMin) >= FPageSize) or Not FHiddenIfUnnecessary);
End;

Function TLxWebScrollBarController.GetScrollPosition : Integer;
Begin
     If Assigned(Control) Then Begin
        If FKind = sbHorizontal Then
           FScrollPosition := Control.ScrollingElement.scrollLeft
        Else
           FScrollPosition := Control.ScrollingElement.scrollTop;
        End;

     Result := FScrollPosition;
End;

Function TLxWebScrollBarController.GetScrollPageSize : Integer;
Var
   Value : String;
   PxIndex : Integer;
Begin
     if Assigned(Control) Then Begin
        If FKind = sbHorizontal Then
           Value := UpperCase(Control.ScrollModel.style.GetPropertyValue('width'))
        Else
           Value := UpperCase(Control.ScrollModel.style.GetPropertyValue('height'));

        PxIndex := Pos('PX', Value);
        if PxIndex > 0 Then Begin
           Delete(Value, PxIndex,2);
           Val(Value, FScrollPageSize, PxIndex);
           End;
        End;

     Result := FScrollPageSize;
End;

Procedure TLxWebScrollBarController.SetScrollPosition(Val : Integer);
Var
   ChangeState : Boolean;
Begin
     If Not(FScrollPosition = Val) and Assigned(Control) and Assigned(Control.ScrollingElement) Then Begin
        FScrollPosition := Val;

        if (FParentScrollerControl.FScrollState in [wcssInactive, wcssUpdating]) Then Begin
           ChangeState := FParentScrollerControl.FScrollState = wcssInactive;
           if ChangeState Then FParentScrollerControl.FScrollState := wcssUpdating;
           Try
              //Console.Log('SetScrollPostion ' + IntToStr(FScrollPosition));
              If FKind = sbHorizontal Then
                 Control.ScrollingElement.scrollLeft := FScrollPosition
              Else
                 Control.ScrollingElement.scrollTop := FScrollPosition;
           Finally
              If ChangeState Then FParentScrollerControl.FScrollState := wcssInactive;
           End;
           End;
        End;
End;

Procedure TLxWebScrollBarController.SetScrollPageSize(Val : Integer);
Begin
     If Not(FScrollPageSize = Val) and Assigned(Control) and Assigned(Control.ScrollModel) Then Begin
        FScrollPageSize := Val;
        //Console.Log('SetScrollPageSize ' + IntToStr(FScrollPageSize));

        If FKind = sbHorizontal Then Begin
           With Control.ScrollModel.Style Do Begin
                setProperty('left', '0px');
                setProperty('width', IntToStr(FScrollPageSize) + 'px');
                End;
           Control.UpdateElementOverflow;
           End
        Else Begin
           With Control.ScrollModel.Style Do Begin
                setProperty('top', '0px');
                setProperty('height', IntToStr(FScrollPageSize) + 'px');
                End;
           Control.UpdateElementOverflow;
           End;
        End;
End;

Procedure TLxWebScrollBarController.SetPosition(Val : Integer);
Begin
     If Enabled Then Begin
        FPosition := Val;
        //Console.Log('SetPosition - ' + IntToStr(FPosition));

        Invalidate;
        End;
End;

Procedure TLxWebScrollBarController.SetMin(Val : Integer);
Begin
     If Not(FMin = Val) Then Begin
        FMin := Val;
        Invalidate;
        End;
End;

Procedure TLxWebScrollBarController.SetMax(Val : Integer);
Begin
     If Not(FMax = Val) Then Begin
        FMax := Val;
        Invalidate;
        End;
End;

Procedure TLxWebScrollBarController.SetPageSize(Val : Integer);
Begin
     If Val < 0 Then Val := 0;

     If Not(FPageSize = Val) Then Begin
        FPageSize := Val;
        Invalidate;
        End;
End;

Procedure TLxWebScrollBarController.SetHiddenIfUnnecessary(Val : Boolean);
Begin
     If Not(FHiddenIfUnnecessary = Val) Then Begin
        FHiddenIfUnnecessary := Val;
        Invalidate;
        End;
End;

Procedure TLxWebScrollBarController.Invalidate;
Begin
     If Enabled and (FParentScrollerControl.FScrollState = wcssInactive) Then
        FParentScrollerControl.Invalidate;
End;

Procedure TLxWebScrollBarController.Update;
Var
   RectClient : TRect;
   Resolution : Double;
//   VirtualPosition : Integer;
Begin
     If Enabled Then Begin

        RectClient := FParentScrollerControl.ClientRect;
        If FKind = sbHorizontal Then
           FClientRange := RectClient.Right - RectClient.Left
        Else
           FClientRange := RectClient.Bottom - RectClient.Top;

        If FClientRange > 0 Then Begin
           If PageSize > 0 Then Begin
              Resolution := FClientRange / PageSize;

              //Console.Log('Update - Min ' + FloatToStr(Min));
              //Console.Log('Update - Max ' + FloatToStr(Max));
              //Console.Log('Update - Range ' + IntToStr(FClientRange));
              //Console.Log('Update - Resolution ' + FloatToStr(Resolution));
              //Console.Log('Update - PageSize ' + IntToStr(PageSize));
              //Console.Log('Update - Position ' + IntToStr(Position));
              ScrollPageSize := Ceil(Resolution * (FMax - FMin + 1));
              ScrollPosition := Round(Position * Resolution);
              //Console.Log('Update - ScrollPostion ' + IntToStr(FScrollPosition));

//              VirtualPosition := Round(ScrollPosition / Resolution);
              //Console.Log('Update - VirtualPostion ' + IntToStr(VirtualPosition));



              End
           Else Begin
              ScrollPageSize := FClientRange + (FMax - FMin) - (FPageSize - 1);
              ScrollPosition := Position;
              End;
           End;
        End;
End;

Function TLxWebScrollBarController.HandleScroll(Event: TJSUIEvent) : Boolean;
Var
   Resolution : Double;
   NewPosition : Integer;
Begin
     Event.stopPropagation;

     If (FParentScrollerControl.FScrollState = wcssScrolling) and (FClientRange > 0) and Assigned(Control) Then Begin

        if PageSize > 0 Then Begin
           Resolution := FClientRange / PageSize;
           //Console.Log('HandleScroll - Position ' + IntToStr(Position));

           If FKind = sbHorizontal Then
              FScrollPosition := Control.ScrollingElement.scrollLeft
           Else
              FScrollPosition := Control.ScrollingElement.scrollTop;

           //Console.Log('HandleScroll - ScrollPosition ' + IntToStr(FScrollPosition));


           NewPosition := Round(FScrollPosition / Resolution);
           //Console.Log('HandleScroll - NewPosition ' + IntToStr(NewPosition));
           Result := Not(NewPosition = FPosition);

           FScrollPosition := Round(NewPosition * Resolution);
           //Console.Log('HandleScroll - ScrollPosition Update' + IntToStr(FScrollPosition));
           FPosition := NewPosition;
           End
        Else Begin
           NewPosition := ScrollPosition;
           Result := Not(NewPosition = FPosition);
           FPosition := ScrollPosition;
           End;
        End
     Else
        Result := False;
End;


{$endregion}

{$region '************************************** TLxWebScrollControl *********************************'}

Procedure TLxWebScrollControl.CreateInitialize;
Begin
     Inherited;
     FVScrollBar := TLxWebScrollBarController.Create(Self, sbVertical);
     FHScrollBar := TLxWebScrollBarController.Create(Self, sbHorizontal);
     FScrollBars := ssBoth;
     FZIndex := 0;
     FScrollState := wcssInActive;
End;

Destructor TLxWebScrollControl.Destroy;
Begin
     FHScrollBar.Free;
     FVScrollBar.Free;
     Inherited Destroy;
End;

Procedure TLxWebScrollControl.SetZIndex(Val : integer);
Begin
     If Not(FZIndex = Val) Then Begin
        FZIndex := Val;
        //If Not IsUpdating Then Invalidate;
        End;
End;

function TLxWebScrollControl.GetClientRect: TRect;
var
  dw, dh: integer;
begin
     If Assigned(ElementHandle) Then Begin
        dw := 0;
        dh := 0;

        if VScrollBar.ScrollVisible  then
           dw := 16; //GetScrollBarWidth;

        if HScrollBar.ScrollVisible then
           dh := 16; //GetScrollBarHeight;

        Result := Rect(0,0, TJSHTMLElement(ElementHandle).clientWidth - dw, TJSHTMLElement(ElementHandle).clientHeight - dh);
        End
     Else
        Result := TRect.Empty;
end;

Function TLxWebScrollControl.CreateElement : TJSElement;
Begin
     ControlStyle := ControlStyle + [csAcceptsControls];
     Try
        Result := Inherited CreateElement;

        FScrollingElement := TJSHTMLElement(document.createElement('DIV'));
        FScrollingElement['id'] := ElementId + '_Viewport';

        FScrollModel := TJSHTMLElement(document.createElement('DIV'));
        FScrollModel['id'] := ElementId + '_ScrollModel';
        FScrollingElement.AppendChild(FScrollModel);
        Result.AppendChild(FScrollingElement);
     Finally
        ControlStyle := ControlStyle - [csAcceptsControls];
     End;
End;

procedure TLxWebScrollControl.UpdateElementVisual;
begin
     inherited;

     if (csDesigning in ComponentState) then
     begin
       ElementHandle.style.setProperty('border', '1px dotted gray');
     end;

     If Not IsUpdating Then Begin

        If Assigned(ElementHandle) Then
           With ElementHandle.Style Do Begin
                SetProperty('overflow', 'hidden');
                SetProperty('z-index', InttoStr(FZIndex));
                End;

        If Assigned(FScrollingElement) Then
           With FScrollingElement.style Do Begin
                setProperty('height', '100%');
                setProperty('width', '100%');
                SetProperty('z-index', InttoStr(10));

                UpdateScrolling;
                UpdateElementOverflow;
                End;

        If Assigned(FScrollModel) Then
           With FScrollModel.style Do Begin
                setProperty('width',  IntToStr(FHScrollBar.FScrollPageSize) + 'px');
                setProperty('height', IntToStr(FVScrollBar.FScrollPageSize) + 'px');

                SetProperty('z-index', InttoStr(-10));
                End;

        If Assigned(ElementCanvas) Then Begin
           //ElementCanvas['zindex'] := InttoStr(FZIndex-10);
           With ElementCanvas.style Do Begin
                SetProperty('z-index', InttoStr(-10));
                End;
           End;

        End;
end;

Procedure TLxWebScrollControl.UpdateElementOverflow;
Var
   HScrollVisible, VScrollVisible : Boolean;
Begin
     If Not IsUpdating and Assigned(FScrollingElement) Then
        With FScrollingElement.style Do Begin
             HScrollVisible := HScrollBar.ScrollVisible;
             VScrollVisible := VScrollBar.ScrollVisible;

             If HScrollVisible and VScrollVisible Then
                setProperty('overflow', 'scroll')
             Else If VScrollVisible Then Begin
                removeProperty('overflow');
                setProperty('overflow-x', 'hidden');
                setProperty('overflow-y', 'scroll');
                End
             Else If HScrollVisible Then Begin
                removeProperty('overflow');
                setProperty('overflow-x', 'scroll');
                setProperty('overflow-y', 'hidden');
                End
             Else
                setProperty('overflow', 'hidden');
             End;
End;

procedure TLxWebScrollControl.UpdateElement;
Begin
     Inherited;

  {   if Assigned(FScrollingElement) and not IsUpdating then begin
        case ScrollBars of
             ssNone: FScrollingElement.style.setProperty('overflow', 'hidden');

         ssVertical: Begin
                     FScrollingElement.style.removeProperty('overflow');
                     FScrollingElement.style.setProperty('overflow-x', 'hidden');
                     FScrollingElement.style.setProperty('overflow-y', 'auto');
                     End;

       ssHorizontal: Begin
                     FScrollingElement.style.removeProperty('overflow');
                     FScrollingElement.style.setProperty('overflow-x', 'auto');
                     FScrollingElement.style.setProperty('overflow-y', 'hidden');
                     End;

             ssBoth: FScrollingElement.style.setProperty('overflow', 'auto');
             End;
       End;}
End;

procedure TLxWebScrollControl.GetMethodPointers;
begin
     inherited;
     FScrollPtr := @HandleScroll;
end;

procedure TLxWebScrollControl.ClearMethodPointers;
begin
     inherited;
     FScrollPtr := nil;
end;

procedure TLxWebScrollControl.BindEvents;
begin
     inherited;
     if Assigned(FScrollingElement) then
        FScrollingElement.addEventListener('scroll', FScrollPtr);
end;

procedure TLxWebScrollControl.UnBindEvents;
begin
     inherited;
     if Assigned(FScrollingElement) then
        FScrollingElement.removeEventListener('scroll', FScrollPtr);
end;


function TLxWebScrollControl.HandleScroll(Event: TJSUIEvent): Boolean;
Var
   SendScrollEvent : Boolean;
begin
     SendScrollEvent := False;

     If Assigned(FHScrollBar) and Assigned(FVScrollBar) and (FScrollState = wcssInactive) Then Begin
        FScrollState := wcssScrolling;
        Try
           SendScrollEvent := FVScrollBar.HandleScroll(Event) or FHScrollBar.HandleScroll(Event);

           If SendScrollEvent Then DoScroll(FHScrollBar.Position, FVScrollBar.Position);
        Finally
           FScrollState := wcssInactive;
        End;
        End;

     Result := True;
end;

Procedure TLxWebScrollControl.DoScroll(Const HorizontalPosition, VerticalPosition : Integer);
Begin
     If Assigned(FOnScroll) Then FOnScroll(Self, HorizontalPosition, VerticalPosition);
End;

Procedure TLxWebScrollControl.DoUpdateScrolling(HorizontalScrollBar, VerticalScrollBar : TLxWebScrollBarController);
Begin
End;

Procedure TLxWebScrollControl.UpdateScrolling;
Begin
     If Assigned(FHScrollBar) and Assigned(FVScrollBar) and (FScrollState = wcssInactive) Then Begin
        FScrollState := wcssUpdating;
        Try
           DoUpdateScrolling(FHScrollBar, FVScrollBar);
           FHScrollBar.Update;
           FVScrollBar.Update;
        Finally
           FScrollState := wcssInactive;
        End;
        End;
End;

Procedure TLxWebScrollControl.Invalidate;
Begin
     Inherited;

     If Assigned(ElementHandle) and not IsUpdating Then
        UpdateScrolling;
End;

procedure TLxWebScrollControl.Resize;
Begin
     Inherited;

     If Assigned(ElementHandle) and not IsUpdating Then
        UpdateScrolling;
End;

{$endregion}

{$region '************************************** TLxFloatFormat **************************************'}

Constructor TLxFloatFormat.Create;
Begin
     Inherited Create;
     FFormat := ffGeneral;
     FDecimalPlaces := 3;
     FOnChange := nil;
End;

Procedure TLxFloatFormat.Assign(Source: TPersistent);
Begin
     If Source is TLxFloatFormat Then
        Assign(Source as TLxFloatFormat);
End;

Procedure TLxFloatFormat.Assign(AFloatFormat : TLxFloatFormat; Quiet : Boolean = false);
Begin
     FFormat := AFloatFormat.Format;
     FDecimalPlaces := AFloatFormat.DecimalPlaces;
     If Not Quiet Then Change;
End;

Procedure TLxFloatFormat.SetFormat(Val : TFloatFormat);
Begin
     If Not(FFormat = Val) Then Begin
        FFormat := Val;
        Change;
        End;
End;

Procedure TLxFloatFormat.SetDecimalPlaces(Val : Integer);
Begin
     If Not(FDecimalPlaces = Val) Then Begin
        FDecimalPlaces := Val;
        Change;
        End
End;

Procedure TLxFloatFormat.Change;
Begin
     If Assigned(FOnChange) Then FOnChange(Self);
End;

Function TLxFloatFormat.Convert(AValue : Double) : String;
Begin
     If FFormat = ffExponent Then
        Result := FloatToStrF(AValue, Format, DecimalPlaces, DecimalPlaces)
     Else
        Result := FloatToStrF(AValue, FFormat, 10, FDecimalPlaces);
End;

Function TLxFloatFormat.Convert(AValue : JSValue) : String;
Begin
     Result := Convert(Double(AValue));
End;

{$endregion}

{$region '************************************** TLxWebImageServiceLink ******************************'}

Constructor TLxWebImageServiceLink.Create(AClient : TObject);
Begin
     Inherited Create;
     FClient := AClient;
     FOnLoad := nil;
     FImageService := nil;
End;

Destructor TLxWebImageServiceLink.Destroy;
Begin
     If Assigned(FImageService) Then
        FImageService.Detach(Self);

     Inherited;
End;

Procedure TLxWebImageServiceLink.SetImageService(Val : TLxWebImageService);
Begin
     If Not(FImageService = Val) Then Begin
        If Assigned(FImageService) Then FImageService.Detach(Self);
        FImageService := Val;
        If Assigned(FImageService) Then FImageService.Attach(Self);
        End;
End;

Procedure TLxWebImageServiceLink.ImageLoad(AImageService : TLxWebImageService; AImageUrl : TImageUrl);
Begin
     If Assigned(FOnLoad) Then
        FOnLoad(AImageService, AImageUrl);
End;

{$endregion}

{$region '************************************** TLxWebImageService **********************************'}

Constructor TLxWebImageService.Create(AOwner : TComponent);
Begin
     Inherited Create(AOwner);
     FClients := nil;
End;

Destructor TLxWebImageService.Destroy;
Begin
     If Assigned(FClients) Then Begin
        DetachAll;
        FClients.Free;
        End;

     Inherited Destroy;
End;

Procedure TLxWebImageService.Attach(ALink : TLxWebImageServiceLink);
Begin
     If Not Assigned(FClients) Then FClients := TList<TLxWebImageServiceLink>.Create;
     FClients.Add(ALink);
End;

Procedure TLxWebImageService.Detach(ALink : TLxWebImageServiceLink);
Begin
     If Assigned(FClients) Then Begin
        FClients.Remove(ALink);
        If FClients.Count = 0 Then FreeAndNil(FClients);
        End;
End;

Procedure TLxWebImageService.DetachAll;
Var
   ALink : TLxWebImageServiceLink;
Begin
     If Assigned(FClients) Then Begin
        For ALink in FClients Do
            ALink.FImageService := nil;

        FClients.Clear;
        End;
End;

Procedure TLxWebImageService.NotifyLoad(AImageUrl : TImageUrl);
Var
   ALink : TLxWebImageServiceLink;
Begin
     If Assigned(FClients) Then
        For ALink in FClients Do
            ALink.ImageLoad(Self, AImageUrl);
End;

Procedure TLxWebImageService.Draw(Const ImageIndex, X, Y : Integer; ACanvas : TCanvas);
Begin
     Draw(ImageIndex, X, Y, True, ACanvas);
End;

Procedure TLxWebImageService.Draw(Const ImageUrl : TImageUrl; Const X, Y : Integer; ACanvas : TCanvas);
Begin
     Draw(ImageUrl, X, Y, True, ACanvas);
End;

Procedure TLxWebImageService.Draw(Const ImageIndex, X, Y, AWidth, AHeight : Integer; ACanvas : TCanvas);
Begin
     Draw(ImageIndex, X, Y, AWidth, AHeight, True, ACanvas);
End;

Procedure TLxWebImageService.Draw(Const ImageUrl : TImageUrl; Const X, Y, AWidth, AHeight : Integer; ACanvas : TCanvas);
Begin
     Draw(ImageUrl, X, Y, AWidth, AHeight, True, ACanvas);
End;

{$endregion}

{$region '************************************** TLxWebControlActionLink *****************************'}

Constructor TLxWebControlActionLink.Create(AClient : TObject);
Begin
     Inherited Create;
     FClient := AClient;
     FAction := nil;
     FOnChange := nil;
End;

Destructor TLxWebControlActionLink.Destroy;
Begin
     If Assigned(FAction) Then FAction.Detach(Self);
     Inherited Destroy;
End;

Function TLxWebControlActionLink.GetIsEnabled : Boolean;
Begin
     If Assigned(FAction) Then
        Result := FAction.IsEnabled
     Else
        Result := False;
End;

Function TLxWebControlActionLink.GetIsLinked : Boolean;
Begin
     Result := Assigned(FAction);
End;

Function TLxWebControlActionLink.GetEnabled : Boolean;
Begin
     If Assigned(FAction) Then
        Result := FAction.Enabled
     Else
        Result := False;
End;

Function TLxWebControlActionLink.GetVisible : Boolean;
Begin
     If Assigned(FAction) Then
        Result := FAction.Visible
     Else
        Result := False;
End;

Function TLxWebControlActionLink.GetChecked : Boolean;
Begin
     If Assigned(FAction) Then
        Result := FAction.Checked
     Else
        Result := False;
End;

Function TLxWebControlActionLink.GetCaption : String;
Begin
     If Assigned(FAction) Then
        Result := FAction.Caption
     Else
        Result := '';
End;

Function TLxWebControlActionLink.GetCategory : String;
Begin
     If Assigned(FAction) Then
        Result := FAction.Category
     Else
        Result := '';
End;

Function TLxWebControlActionLink.GetHint : String;
Begin
     If Assigned(FAction) Then
        Result := FAction.Hint
     Else
        Result := '';
End;

Function TLxWebControlActionLink.GetGroupIndex : Integer;
Begin
     If Assigned(FAction) Then
        Result := FAction.GroupIndex
     Else
        Result := 0;
End;

Function TLxWebControlActionLink.GetImageUrl : TImageUrl;
Begin
     If Assigned(FAction) Then
        Result := FAction.ImageUrl
     Else
        Result := '';
End;

Function TLxWebControlActionLink.GetImages : TLxWebImageService;
Begin
     If Assigned(FAction) Then
        Result := FAction.Images
     Else
        Result := nil;
End;

(*

Procedure TLxWebControlActionLink.SetEnabled(Val : Boolean);
Begin
     Change(FAction, acEnabled);
End;

Procedure TLxWebControlActionLink.SetVisible(Val : Boolean);
Begin
     Change(FAction, acVisible);
End;

Procedure TLxWebControlActionLink.SetChecked(Val : Boolean);
Begin
     Change(FAction, acChecked);
End;

Procedure TLxWebControlActionLink.SetCaption(Val : String);
Begin
     Change(FAction, acCaption);
End;

Procedure TLxWebControlActionLink.SetCategory(Val : String);
Begin
     Change(FAction, acCategory);
End;

Procedure TLxWebControlActionLink.SetHint(Val : String);
Begin
     Change(FAction, acHint);
End;

Procedure TLxWebControlActionLink.SetGroupIndex(Val : Integer);
Begin
     Change(FAction, acGroupIndex);
End;

Procedure TLxWebControlActionLink.SetImageUrl(Val : TImageUrl);
Begin
     Change(FAction, acImageUrl);
End;

*)

Procedure TLxWebControlActionLink.SetAction(Val : TLxWebControlAction);
Begin
     If Not(Val = FAction) Then Begin
        If Assigned(FAction) Then FAction.Detach(Self);
        FAction := Val;
        If Assigned(FAction) Then FAction.Attach(Self);
        End;
End;

Procedure TLxWebControlActionLink.Change(AAction : TLxWebControlAction; AChange : TLxWebActionChange);
Begin
     If Assigned(FOnChange) Then FOnChange(AAction, AChange);
End;

Procedure TLxWebControlActionLink.Execute(Sender : TObject);
Begin
     If Assigned(FAction) Then FAction.Execute(Sender);
End;

{$endregion}

{$region '************************************** TLxWebControlAction *********************************'}

Constructor TLxWebControlAction.Create(AOwner : TComponent);
Begin
     Inherited Create(AOwner);
     FClients := nil;
End;

Destructor TLxWebControlAction.Destroy;
Begin
     If Assigned(FClients) Then Begin
        DetachAll;
        FClients.Free;
        End;

     Inherited Destroy;
End;

Function TLxWebControlAction.GetImages : TLxWebImageService;
Begin
     Result := nil;
End;

Function TLxWebControlAction.GetExecuteEvent : TNotifyEvent;
Begin
     Result := nil;
End;

Function TLxWebControlAction.GetUpdateEvent : TNotifyEvent;
Begin
     Result := nil;
End;

Procedure TLxWebControlAction.Attach(ALink : TLxWebControlActionLink);
Begin
     If Not Assigned(FClients) Then FClients := TList<TLxWebControlActionLink>.Create;
     FClients.Add(ALink);
End;

Procedure TLxWebControlAction.Detach(ALink : TLxWebControlActionLink);
Begin
     If Assigned(FClients) Then Begin
        FClients.Remove(ALink);
        If FClients.Count = 0 Then FreeAndNil(FClients);
        End;
End;

Procedure TLxWebControlAction.DetachAll;
Var
   ALink : TLxWebControlActionLink;
Begin
     If Assigned(FClients) Then Begin
        For ALink in FClients Do
            ALink.FAction := nil;

        FClients.Clear;
        End;
End;

Procedure TLxWebControlAction.Change(ActionChange : TLxWebActionChange);
Var
   ALink : TLxWebControlActionLink;
Begin
     If Assigned(FClients) Then
        For ALink in FClients Do
            ALink.Change(Self, ActionChange);
End;

Procedure TLxWebControlAction.DoBeforeExecute;
Begin
End;

Procedure TLxWebControlAction.DoAfterExecute;
Begin
End;

Procedure TLxWebControlAction.Execute(Sender : TObject);
Begin
     DoBeforeExecute;
     If Assigned(OnExecute) Then OnExecute(Self);
     DoAfterExecute;
End;

Procedure TLxWebControlAction.Update(Sender : TObject);
Begin
     If Assigned(OnUpdate) Then OnUpdate(Self);
End;


{$endregion}

Procedure RegisterCursors(ACursors : TLxWebCursors);
Begin
     TLxWebCursorControl.FCursors := ACursors;
End;

end.
