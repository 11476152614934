unit LxWeb.Graphics;

interface

Uses Classes, SysUtils, Types, Math, LxWeb.Maths, Vcl.Graphics, WEBLib.Graphics, Web, JS;

Const
         vMatrixSize = 3;
        HandleRadius = 3;


        cl3DDkShadow = $696969;
         clBtnShadow = $a0a0a0;

Type
{$IFNDEF PAS2JS}

                            TCanvasPointF = TPointF;
                             TCanvasRectF = TRectF;
                             TCanvasSizeF = TSizeF;
                                   TvRect = TRectF;

{$ELSE}

                                  TPointF = TCanvasPointF;
                                   TRectF = TCanvasRectF;

{$ENDIF}

                       TCanvasSizeFHelper = record helper for TCanvasSizeF
                                            Private
                                            Function GetWidth : Integer;
                                            Function GetHeight : Integer;
                                            Procedure SetWidth(Val : Integer);
                                            Procedure SetHeight(Val : Integer);
                                            Public
                                            Class Function Create(Const AWidth, AHeight : Double) : TCanvasSizeF; overload; static;
                                            Class Function Create(Const AWidth, AHeight : Integer) : TCanvasSizeF; overload; static;
                                            Property Width : Integer Read GetWidth Write SetWidth;
                                            Property Height : Integer Read GetHeight Write SetHeight;
                                            End;

                             TPointHelper = record helper for TPoint
                                            Class Function Create(Const X, Y : Integer) : TPoint; static;
                                            Function Add(Const P : TPoint) : TPoint;
                                            Function Subtract(Const P : TPoint) : TPoint;
                                            Function DistanceTo(Const P : TPoint) : Integer;
                                            End;

                      TCanvasPointFHelper = record helper for TCanvasPointF
                                            Class Function Create(Const X, Y : Double) : TCanvasPointF; overload; static;
                                            Class Function Create(Const APoint : TPoint) : TCanvasPointF; overload; static;
                                            Class Function Ratio(Const ARatio : Double; Const Point1, Point2 : TCanvasPointF) :  TCanvasPointF; static;
                                            Class Function MidPoint(Const Point1, Point2 : TCanvasPointF) : TCanvasPointF; static;
                                            Class Function Zero : TCanvasPointF; static;
                                            Class Function Origin : TCanvasPointF; static;
                                            Function Add(Const P : TCanvasPointF) : TCanvasPointF;
                                            Function Subtract(Const P : TCanvasPointF) : TCanvasPointF;
                                            Function DistanceTo(Const P : TCanvasPointF) : Double;
                                            Function AngleFrom(Const APoint : TCanvasPointF) : Double;
                                            Function CrossProduct(Const APoint : TCanvasPointF) : Double;
                                            Function Length : Double;
                                            Function HasLength : Boolean;
                                            Function Perpendicular : TCanvasPointF;
                                            Procedure Normalise;
                                            Function Normalised : TCanvasPointF;
                                            Function VectorAngle : Double;
                                            End;

                              TSizeHelper = record helper for TSize
                                            Private
                                            Function GetWidth : Integer;
                                            Function GetHeight : Integer;
                                            Procedure SetWidth(Val : Integer);
                                            Procedure SetHeight(Val : Integer);
                                            Public
                                            Class Function Create(Const AWidth, AHeight : Integer) : TSize; overload; static;
                                            Class Function Create(Const ASize : TCanvasSizeF) : TSize; overload; static;
                                            Procedure Clear;
                                            Property Width : Integer Read GetWidth Write SetHeight;
                                            Property Height : Integer Read GetHeight Write SetWidth;
                                            End;

                              TRectHelper = record helper for TRect
                                            Class Function Create : TRect; overload; static;
                                            Class Function Create(Const Left, Top, Right, Bottom : Integer) : TRect; overload; static;
                                            Function Contains(Const X, Y : Integer) : Boolean; overload;
                                            Function Contains(Const APoint : TPoint) : Boolean; overload;
                                            Function Contains(Const APoint : TCanvasPointF) : Boolean; overload;
                                            Function Contains(Const ARect : TRect) : Boolean; overload;
                                            Function Contains(Const ARect : TCanvasRectF) : Boolean; overload;
                                            Function Intersects(Const ARect : TRect) : Boolean; overload;
                                            Function Intersects(Const ARect : TCanvasRectF) : Boolean; overload;
                                            Procedure Inflate(Const dX, dY : Integer); overload;
                                            Procedure Inflate(Const dLeft, dTop, dRight, dBottom : Integer); overload;
                                            Procedure Offset(Const dX, dY : Integer);
                                            Function CenterPoint : TPoint;
                                            Function Width : Integer;
                                            Function Height : Integer;
                                            Procedure Clear;
                                            Function IsEmpty : Boolean;
                                            Class Function Empty : TRect; static;
                                            End;

                                  TMatrix = Record
                                            Private
                                            FArray : array[1..vMatrixSize, 1..vMatrixSize] of double;
                                            Function GetElement(Const I, J : Integer) : Double;
                                            Procedure SetElement(Const I, J : Integer; Const Val : Double);
                                            Public
                                            Class Function Translate(Const aX, aY : Double) : TMatrix; overload; static;
                                            Class Function Translate(Const ATranslation : TCanvasPointF) : TMatrix; overload; static;
                                            Class Function Scale(Const S : Double) : TMatrix; overload; static;
                                            Class Function Scale(Const sX, sY : Double) : TMatrix; overload; static;
                                            Class Function Rotate(Const Angle : Double) : TMatrix; overload; static;
                                            Class Function Rotate(Const X, Y, Angle : Double) : TMatrix; overload; static;
                                            Class Function OMatrix : TMatrix; overload; static;
                                            Class Function IMatrix : TMatrix; overload; static;
                                            Class Function XMirror : TMatrix; overload; static;
                                            Class Function YMirror : TMatrix; overload; static;
                                            class Function Negative(AMatrix : TMatrix) : TMatrix; static;
                                            class Function Add(AMatrix, BMatrix : TMatrix): TMatrix; static;
                                            class Function Subtract(Const AMatrix, BMatrix: TMatrix): TMatrix; static;
                                            class Function Multiply(Const AScalar : Double; Const AMatrix : TMatrix): TMatrix; overload; static;
                                            class Function Multiply(Const AMatrix : TMatrix; Const AScalar : Double): TMatrix; overload; static;
                                            class Function Multiply(Const AMatrix, BMatrix : TMatrix): TMatrix; overload; static;
                                            class Function Multiply(Const AMatrix : TMatrix; Const APoint : TCanvasPointF): TCanvasPointF; overload; static;
                                            Constructor Create(Const AOffset : TCanvasPointF; Const ARotate : Double); overload;
                                            Constructor Create(Const AOffset : TCanvasPointF; Const AScaleX, AScaleY, ARotate : Double); overload;
                                            Constructor Create(Const AColumn1, AColumn2, AColumn3 : TCanvasPointF); overload;
                                            Function Determinant : Double;
                                            Function Adjoint : TMatrix;
                                            Function Inverse : TMatrix;
                                            Procedure Transpose;
                                            Function Transposed : TMatrix;
                                            Function Multiply(Const APoint : TCanvasPointF): TCanvasPointF; overload;
                                            Class Function Map(Const Source, Destination : TRectF; MaintainAspectRatio : Boolean) : TMatrix; static;
                                            Property Elements[Const I, J : Integer] : Double Read GetElement Write SetElement; default;
                                            End;

                       TCanvasLineSegment = Record
                                            P1, P2 : TCanvasPointF;
                                            Constructor Create(Const X1, Y1, X2, Y2 : Double); overload;
                                            Constructor Create(Const AP1, AP2 : TCanvasPointF); overload;
                                            Function MidPoint : TCanvasPointF;
                                            Function PointClosestToPoint(Const APoint : TCanvasPointF) : TCanvasPointF;
                                            End;

                              TCanvasLine = Record
                                            Base, Direction : TCanvasPointF;
                                            Constructor Create(Const X1, Y1, X2, Y2 : Double); overload;
                                            Constructor Create(Const AP1, AP2 : TCanvasPointF); overload;
                                            Constructor Create(Const ALine : TCanvasLineSegment); overload;
                                            Function F(Const T : Double) : TCanvasPointF; overload;
                                            Function PointClosestToPoint(Const APoint : TCanvasPointF) : TCanvasPointF; overload;
                                            Function PointClosestToPoint(Const APoint : TCanvasPointF; Out T : Double) : TCanvasPointF; overload;
                                            Function DistanceTo(Const APoint : TCanvasPointF) : Double;
                                            //Function Intersection(Const ALine : TCanvasLine) : Boolean; overload;
                                            //Function Intersection(Const ALine : TCanvasLine; Out APoint : TCanvasPointF) : Boolean; overload;
                                            End;

           TCanvasRectVerticalOrientation = (rvTopPositive, rvBottomPositive);
                       TCanvasRectFHelper = Record Helper for TCanvasRectF
                                            Private
                                            //FInitialised : Boolean;
                                            Function GetOrientation : TCanvasRectVerticalOrientation;
                                            Procedure SetOrientation(Val : TCanvasRectVerticalOrientation);


                                            Function GetBottomLeft : TCanvasPointF;
                                            Function GetTopRight : TCanvasPointF;
                                            Function GetTopLeft : TCanvasPointF;
                                            Function GetBottomRight : TCanvasPointF;


                                            Function GetLeftMiddle : TCanvasPointF;
                                            Function GetRightMiddle : TCanvasPointF;
                                            Function GetTopMiddle : TCanvasPointF;
                                            Function GetBottomMiddle : TCanvasPointF;

                                            Procedure SetTopLeft(Val : TCanvasPointF);
                                            Procedure SetBottomRight(Val : TCanvasPointF);
                                            Procedure SetBottomLeft(Val : TCanvasPointF);
                                            Procedure SetTopRight(Val : TCanvasPointF);

                                            Public
                                            Class Function Create : TCanvasRectF; overload; static;
                                            Class Function Create(Const Left, Top, Right, Bottom : Double) : TCanvasRectF; overload; static;
                                            Class Function Create(Const ARect : TRect) : TCanvasRectF; overload; static;
                                            Class Function Create(Const AP1, AP2 : TCanvasPointF) : TCanvasRectF; overload; static;
                                            Class function ViewMap(Const Source, Destination : TCanvasRectF) : TMatrix; overload; static;
                                            Class function Map(Const Source, Destination : TCanvasRectF; MaintainAspectRatio : Boolean) : TMatrix; overload; static;
                                            Class Function Empty : TCanvasRectF; static;

                                            Function Contains(Const APoint : TCanvasPointF) : Boolean;
                                            Function Intersection(Const ARect : TCanvasRectF) : TCanvasRectF;
                                            Function Intersects(Const ARect : TCanvasRectF) : Boolean; overload;
                                            Function Intersects(Const ASegment : TCanvasLineSegment) : Boolean; overload;
                                            Procedure Include(Const X, Y : Double); overload;
                                            Procedure Include(Const APoint : TCanvasPointF); overload;
                                            Procedure Include(ARect : TCanvasRectF); overload;
                                            Procedure Inflate(Const Amount : Double); overload;
                                            Procedure Inflate(Const WidthWards, HeightWards : Double); overload;
                                            Procedure Offset(Const dx, dy : Double); overload;
                                            Procedure Offset(Const APoint : TCanvasPointF); overload;
                                            Function PointClosestToPoint(Const APoint : TCanvasPointF) : TCanvasPointF;
                                            Procedure Clear;
                                            Function IsEmpty : Boolean;
                                            Function IsEqualTo(ARect : TCanvasRectF) : Boolean;
                                            Function Width : Double;
                                            Function Height : Double;
                                            Function TopPositive : TCanvasRectF;
                                            Function BottomPositive : TCanvasRectF;
                                            Function Centre : TCanvasPointF;
                                            Function CenterPoint : TCanvasPointF;
                                            Function Area : Double;
                                            Procedure TransformBounds(Const AMatrix : TMatrix);
                                            Property Orientation : TCanvasRectVerticalOrientation Read GetOrientation Write SetOrientation;

                                            Property BottomLeft : TCanvasPointF Read GetBottomLeft Write SetBottomLeft;
                                            Property TopRight : TCanvasPointF Read GetTopRight Write SetTopRight;
                                            Property TopLeft : TCanvasPointF Read GetTopLeft Write SetTopLeft;
                                            Property BottomRight : TCanvasPointF Read GetBottomRight Write SetBottomRight;

                                            Property LeftMiddle : TCanvasPointF Read GetLeftMiddle;
                                            Property RightMiddle : TCanvasPointF Read GetRightMiddle;
                                            Property TopMiddle : TCanvasPointF Read GetTopMiddle;
                                            Property BottomMiddle : TCanvasPointF Read GetBottomMiddle;
                                            End;

                          TPenStyleHelper = Record Helper for TPenStyle
                                            Class Function FromString(AStr : String) : TPenStyle; static;
                                            Function ToString : String;
                                            Function ToCSS : String;
                                            End;

                        TFontStylesHelper = Record Helper for TFontStyles
                                            Class Function FromString(AStr : String) : TFontStyles; static;
                                            Function ToString : String;
                                            Function ToCSS : String;
                                            Procedure AssignTo(AStyles : TJSCSSStyleDeclaration);
                                            End;

                              TFontHelper = Class Helper for TFont
                                            Function ToCSS : String;
                                            Procedure AssignTo(AStyles : TJSCSSStyleDeclaration); overload;
                                            {$IFDEF PAS2JS}
                                            Procedure FromCSS(AElement : TJSHTMLElement); overload;
                                            Procedure FromCSS(AStyles : TJSCSSStyleDeclaration); overload;
                                            {$ENDIF}
                                            End;

Function Point(Const X, Y : Integer) : TPoint;
Function CanvasPointF(Const X, Y : Double) : TCanvasPointF; overload;
Function CanvasPointF(Const APoint : TPoint) : TCanvasPointF; overload;

Function vEqual(Const APoint, BPoint : TCanvasPointF) : Boolean;
Function vNegative(Const APoint : TCanvasPointF) : TCanvasPointF; overload;
Function vAdd(Const APoint, BPoint : TCanvasPointF): TCanvasPointF; overload;
Function vSubtract(Const APoint, BPoint: TCanvasPointF): TCanvasPointF; overload;
Function vMultiply(Const AScalar : Double; Const APoint: TCanvasPointF): TCanvasPointF; overload;
Function vMultiply(Const APoint: TCanvasPointF; Const AScalar : Double): TCanvasPointF; overload;
Function vMultiply(Const APoint1, APoint2: TCanvasPointF): Double; overload;
Function vDotProduct(Const APoint1, APoint2 : TCanvasPointF) : Double;
Function vDivide(Const APoint: TCanvasPointF; Const AScalar : Double): TCanvasPointF;

Function vAdd(Const ARect : TCanvasRectF; Const APoint : TCanvasPointF): TCanvasRectF; overload;
Function vAdd(Const ARect, BRect : TCanvasRectF): TCanvasRectF; overload;

Function PointInPolygon(Const APoint : TPoint; Const APoints : array of TPoint) : Boolean; overload;
Function PointInPolygon(Const APoint : TPoint; Const APoints : array of TCanvasPointF) : Boolean; overload;
Function PointInPolygon(Const APoint : TCanvasPointF; Const APoints : array of TCanvasPointF) : Boolean; overload;

Const
     vTolerance : Double = 0.0001;

implementation

Uses TypInfo, LxWeb.Tools;

Const
     Epsilon: Single = 1E-40;

{$region '************************************************* TPenStyleHelper ***********************************************'}

Class Function TPenStyleHelper.FromString(AStr : String) : TPenStyle;
Begin
     StringToEnumeration(AStr, TypeInfo(TPenStyle), Result);
End;

Function TPenStyleHelper.ToString : String;
Begin
     Result := EnumerationToString(TypeInfo(TPenStyle), Self);
End;

Function TPenStyleHelper.ToCSS : String;
Begin
     Case Self of
          psDash : Result := 'dashed';
           psDot : Result := 'dotted';
         psClear : Result := 'none';
          else Result := 'solid';
          End;
End;

{$endregion}

{$region '************************************************* TFontStylesHelper *********************************************'}

{$HINTS OFF}

Class Function TFontStylesHelper.FromString(AStr : String) : TFontStyles;
Begin
     StringToSet(AStr, TypeInfo(TFontStyles), Result);
End;

{$HINTS ON}

Function TFontStylesHelper.ToString : String;
Begin
     Result := SetToString(TypeInfo(TFontStyles), Self);
End;

Procedure TFontStylesHelper.AssignTo(AStyles : TJSCSSStyleDeclaration);
Var
   S : String;
Begin
     If fsItalic in Self Then
        AStyles.SetProperty('font-style', 'italic')
     else
        AStyles.SetProperty('font-style', 'normal');

     If fsBold in Self Then
        AStyles.SetProperty('font-weight', 'bold')
     else
        AStyles.SetProperty('font-weight', 'normal');

     If (fsUnderline in Self) or (fsStrikeOut in Self) Then Begin
        if fsUnderline in Self then
           s := 'underline';

        if fsStrikeOut in Self Then Begin
           if s <> ''  then s := s + ' ';
           s := s + 'line-through';
           End;

        AStyles.SetProperty('text-decoration', s);
        End;
End;

Function TFontStylesHelper.ToCSS : String;
Var
   s : String;
Begin
     Result := '';

     If fsItalic in Self Then
        Result := Result + 'font-style: italic;'
     Else
        Result := Result + 'font-style: normal;';

     If fsBold in Self Then
        Result := Result + 'font-weight: bold;'
     Else
        Result := Result + 'font-Weight: normal;';

     If (fsUnderline in Self) or (fsStrikeOut in Self) Then Begin
        if fsUnderline in Self then
           s := 'underline';

        if fsStrikeOut in Self Then Begin
           if s <> ''  then s := s + ' ';
           s := s + 'line-through';
           End;

        Result := Result + Format('text-decoration: %s;', [s]);
        End;
End;

{$endregion}

{$region '************************************************* TFontHelper ***************************************************'}

Procedure TFontHelper.AssignTo(AStyles : TJSCSSStyleDeclaration);
Begin
     AStyles.SetProperty('font-family', Self.Name);
     Self.Style.AssignTo(AStyles);

     AStyles.SetProperty('font-size', IntToStr(Self.Size) + 'pt');
     AStyles.SetProperty('color', ColorToHtml(Self.Color));
End;

Function TFontHelper.ToCSS : String;
Begin
     Result := '';

     Result := Result + Format('font-family: %s;', [Self.Name]);
     Result := Result + Self.Style.ToCss;
     Result := Result + Format('font-size: %dpt;', [Self.Size]);
     Result := Result + Format('color: %s;', [ColorToHtml(Self.Color)]);
End;

{$IFDEF PAS2JS}

Procedure TFontHelper.FromCSS(AElement : TJSHTMLElement);
Var
   AStyles : TJSCSSStyleDeclaration;
Begin
     AStyles := window.getComputedStyle(AElement);
     FromCSS(AStyles);
End;

Procedure TFontHelper.FromCSS(AStyles : TJSCSSStyleDeclaration);
Var
   AFontStyle : TFontStyles;
   cssFontWeight : String;
   cssFontStyle : String;
   cssTextDecoration : String;
   cssColor : String;
begin
     Name := AStyles.getPropertyValue('font-family');
     Size := Round(parseFloat(AStyles.getPropertyValue('font-size')) * 72 / 96);

     AFontStyle := [];

     cssFontWeight := AStyles.getPropertyValue('font-weight');
     if cssFontWeight.Contains('bold') or (isNumber(cssFontWeight) and (parseFloat(cssFontWeight) > 650)) Then
        Include(AFontStyle, fsBold);

     cssFontStyle := AStyles.getPropertyValue('font-style');
     If cssFontStyle.Contains('italic') or cssFontStyle.Contains('oblique') Then
        Include(AFontStyle, fsItalic);

     cssTextDecoration := AStyles.getPropertyValue('text-decoration');
     If cssTextDecoration.Contains('underline') Then
        Include(AFontStyle, fsUnderline);

     If cssTextDecoration.Contains('line-through') Then
        Include(AFontStyle, fsStrikeOut);

     Style := AFontStyle;

     cssColor := AStyles.getPropertyValue('color');
     Color := RGBToColor(cssColor);
End;

{$ENDIF}

{$endregion}

{$region '************************************************* TCanvasSizeFHelper ********************************************'}

Class Function TCanvasSizeFHelper.Create(Const AWidth, AHeight : Double) : TCanvasSizeF;
Begin
     Result.cx := AWidth;
     Result.cy := AHeight
End;

Class Function TCanvasSizeFHelper.Create(Const AWidth, AHeight : Integer) : TCanvasSizeF;
Begin
     Result.cx := AWidth;
     Result.cy := AHeight
End;

Function TCanvasSizeFHelper.GetWidth : Integer;
Begin
     Result := Trunc(cx);
End;

Function TCanvasSizeFHelper.GetHeight : Integer;
Begin
     Result := Trunc(cy);
End;

Procedure TCanvasSizeFHelper.SetWidth(Val : Integer);
Begin
     cx := Val;
End;

Procedure TCanvasSizeFHelper.SetHeight(Val : Integer);
Begin
     cy := Val;
End;

{$endregion}

{$region '************************************************* TPointHelper **************************************************'}

Function Point(Const X, Y : Integer) : TPoint;
Begin
     Result.X := X;
     Result.Y := Y;
End;

Class Function TPointHelper.Create(Const X, Y : Integer) : TPoint;
Begin
     Result.X := X;
     Result.Y := Y;
End;

Function TPointHelper.Add(Const P : TPoint) : TPoint;
Begin
     Result.X := X + P.X;
     Result.Y := Y + P.Y;
End;

Function TPointHelper.Subtract(Const P : TPoint) : TPoint;
Begin
     Result.X := X - P.X;
     Result.Y := Y - P.Y;
End;

Function TPointHelper.DistanceTo(Const P : TPoint) : Integer;
Begin
     Result := Trunc(Sqrt(Sqr(X - P.X) + Sqr(Y - P.Y)));
End;

{$endregion}

{$region '************************************************* TCanvasPointFHelper *******************************************'}

Function CanvasPointF(Const X, Y : Double) : TCanvasPointF;
Begin
     Result.X := X;
     Result.Y := Y;
End;

Function CanvasPointF(Const APoint : TPoint) : TCanvasPointF;
Begin
     Result.X := APoint.X;
     Result.Y := APoint.Y;
End;

Class Function TCanvasPointFHelper.Create(Const X, Y : Double) : TCanvasPointF;
Begin
     Result.X := X;
     Result.Y := Y;
End;

Class Function TCanvasPointFHelper.Create(Const APoint : TPoint) : TCanvasPointF;
Begin
     Result.X := APoint.X;
     Result.Y := APoint.Y;
End;

Function TCanvasPointFHelper.Add(Const P : TCanvasPointF) : TCanvasPointF;
Begin
     Result.X := X + P.X;
     Result.Y := Y + P.Y;
End;

Function TCanvasPointFHelper.Subtract(Const P : TCanvasPointF) : TCanvasPointF;
Begin
     Result.X := X - P.X;
     Result.Y := Y - P.Y;
End;

Function TCanvasPointFHelper.DistanceTo(Const P : TCanvasPointF) : Double;
Begin
     Result := Sqrt(Sqr(X - P.X) + Sqr(Y - P.Y));
End;

Class Function TCanvasPointFHelper.Zero : TCanvasPointF;
Begin
     Result.X := 0;
     Result.Y := 0;
End;

Class Function TCanvasPointFHelper.Origin : TCanvasPointF;
Begin
     Result.X := 0;
     Result.Y := 0;
End;

Class Function TCanvasPointFHelper.Ratio(Const ARatio : Double; Const Point1, Point2 : TCanvasPointF) :  TCanvasPointF;
Begin
     Result.X := (1 - ARatio) * Point1.X + ARatio * Point2.X;
     Result.Y := (1 - ARatio) * Point1.Y + ARatio * Point2.Y;
End;

Class Function TCanvasPointFHelper.MidPoint(Const Point1, Point2 : TCanvasPointF) : TCanvasPointF;
Begin
     Result.X := (Point1.X + Point2.X) / 2;
     Result.Y := (Point1.Y + Point2.Y) / 2;
End;

Function TCanvasPointFHelper.AngleFrom(Const APoint : TCanvasPointF) : Double;
Var
   Dp, Cp : Double;

Begin
     Dp := vMultiply(Self.Normalised, APoint.Normalised);
     Cp := CrossProduct(APoint);

     If Dp > 1 Then
        Dp := 1
     Else If Dp < -1 Then
        Dp := -1;

     If Cp = 0 Then
        Result := ArcCos(Dp)
     Else
        Result := ArcCos(Dp) * Sgn(-Cp);
End;

Function TCanvasPointFHelper.CrossProduct(Const APoint : TCanvasPointF) : Double;
Begin
     Result := X * APoint.Y - APoint.X * Y;
End;

Function TCanvasPointFHelper.Length : Double;
Begin
     Result := Sqrt(Sqr(X) + Sqr(Y));
End;

Function TCanvasPointFHelper.HasLength : Boolean;
Begin
     Result := (Abs(X) = 0) and (Abs(Y) > 0);
End;

Function TCanvasPointFHelper.Perpendicular : TCanvasPointF;
Begin
     Result := TCanvasPointF.Create(-Y, X);
End;

Procedure TCanvasPointFHelper.Normalise;
Var
   L : Double;
Begin
     L := Length;
     If L > 0 Then Begin
        X := X / L;
        Y := Y / L;
        End;
End;

Function TCanvasPointFHelper.Normalised : TCanvasPointF;
Var
   L : Double;
Begin
     L := Length;
     If L > 0 Then Begin
        Result.X := X / L;
        Result.Y := Y / L;
        End
     Else
        Result := Self;
End;

Function TCanvasPointFHelper.VectorAngle : Double;
Begin
     Result := ArcTanExt(Y, X);
End;

Function vEqual(Const APoint, BPoint : TCanvasPointF) : Boolean;
Begin
     Result := (APoint.X = BPoint.X) and (APoint.Y = BPoint.Y);
End;

Function vNegative(Const APoint : TCanvasPointF) : TCanvasPointF; overload;
Begin
     Result.X := -APoint.X;
     Result.Y := -APoint.Y;
End;

Function vAdd(Const APoint, BPoint : TCanvasPointF): TCanvasPointF; overload;
Begin
     Result.X := APoint.X + BPoint.X;
     Result.Y := APoint.Y + BPoint.Y;
End;

Function vSubtract(Const APoint, BPoint: TCanvasPointF): TCanvasPointF; overload;
Begin
     Result.X := APoint.X - BPoint.X;
     Result.Y := APoint.Y - BPoint.Y;
End;

Function vMultiply(Const AScalar : Double; Const APoint: TCanvasPointF): TCanvasPointF; overload;
Begin
     Result.X := AScalar * APoint.X;
     Result.Y := AScalar * APoint.Y;
End;

Function vMultiply(Const APoint: TCanvasPointF; Const AScalar : Double): TCanvasPointF; overload;
Begin
     Result.X := AScalar * APoint.X;
     Result.Y := AScalar * APoint.Y;
End;

Function vMultiply(Const APoint1, APoint2: TCanvasPointF): Double; overload;
Begin
     Result := (APoint1.X * APoint2.X) + (APoint1.Y * APoint2.Y);
End;

Function vDotProduct(Const APoint1, APoint2 : TCanvasPointF) : Double;
Begin
     Result := vMultiply(APoint1, APoint2);
End;

Function vDivide(Const APoint: TCanvasPointF; Const AScalar : Double): TCanvasPointF;
Begin
     Result.X := APoint.X / AScalar;
     Result.Y := APoint.Y / AScalar;
End;

{$endregion}

{$region '************************************************* TSizeHelper ***************************************************'}

Class Function TSizeHelper.Create(Const AWidth, AHeight : Integer) : TSize;
Begin
     Result.cx := AWidth;
     Result.cy := AHeight
End;

Class Function TSizeHelper.Create(Const ASize : TCanvasSizeF) : TSize;
Begin
     Result.cx := Ceil(ASize.cx);
     Result.cy := Ceil(ASize.cy);
End;

Function TSizeHelper.GetWidth : Integer;
Begin
     Result := cx;
End;

Function TSizeHelper.GetHeight : Integer;
Begin
     Result := cy;
End;

Procedure TSizeHelper.SetWidth(Val : Integer);
Begin
     cx := Val;
End;

Procedure TSizeHelper.SetHeight(Val : Integer);
Begin
     cy := Val;
End;

Procedure TSizeHelper.Clear;
Begin
     cx := 0;
     cy := 0;
End;

{$endregion}

{$region '************************************************* TRectHelper ***************************************************'}

Class Function TRectHelper.Create : TRect;
Begin
     Result.Left := 0;
     Result.Top := 0;
     Result.Right := 0;
     Result.Bottom := 0;
End;

Class Function TRectHelper.Create(Const Left, Top, Right, Bottom : Integer) : TRect;
Begin
     Result.Left := Left;
     Result.Top := Top;
     Result.Right := Right;
     Result.Bottom := Bottom;
End;

Function TRectHelper.Contains(Const X, Y : Integer) : Boolean;
Begin
     Result := PtInRect(Self, Point(X, Y));
End;

Function TRectHelper.Contains(Const APoint : TPoint) : Boolean;
Begin
     Result := PtInRect(Self, APoint);
End;

Function TRectHelper.Contains(Const APoint : TCanvasPointF) : Boolean;
Begin
     Result := Not((APoint.X < Left) or (APoint.X > Right) or (APoint.Y < Top) or (APoint.Y > Bottom))
End;

Function TRectHelper.Contains(Const ARect : TRect) : Boolean;
Begin
     Result := (Left <= ARect.Left) and (Right >= ARect.Right) and (Top <= ARect.Top) and (Bottom >= ARect.Bottom);
End;

Function TRectHelper.Contains(Const ARect : TCanvasRectF) : Boolean;
Begin
     Result := (Left <= ARect.Left) and (Right >= ARect.Right) and (Top <= ARect.Top) and (Bottom >= ARect.Bottom);
End;

Function TRectHelper.Intersects(Const ARect : TRect) : Boolean;
Begin
     Result := Not((Right < ARect.Left) or (ARect.Right < Left) or (ARect.Bottom < Top) or (ARect.Top < Bottom));
End;

Function TRectHelper.Intersects(Const ARect : TCanvasRectF) : Boolean;
Begin
     Result := Not((Right < ARect.Left) or (ARect.Right < Left) or (ARect.Bottom < Top) or (ARect.Top < Bottom));
End;

Procedure TRectHelper.Inflate(Const dX, dY : Integer);
Begin
     InflateRect(self, dX, dY);
End;

Procedure TRectHelper.Inflate(Const dLeft, dTop, dRight, dBottom : Integer);
Begin
     Dec(Self.Left, dLeft);
     Dec(Self.Top, dTop);
     Inc(Self.Right, dRight);
     Inc(Self.Bottom, dBottom);
End;


Procedure TRectHelper.Offset(Const dX, dY : Integer);
Begin
     OffsetRect(self, dX, dY);
End;

Function TRectHelper.CenterPoint : TPoint;
Begin
     Result.X := (Left + Right) DIV 2;
     Result.Y := (Top + Bottom) DIV 2;
End;

Function TRectHelper.Width : Integer;
Begin
     Result := Abs(Right - Left);
End;

Function TRectHelper.Height : Integer;
Begin
     Result := Abs(Top - Bottom);
End;

Procedure TRectHelper.Clear;
Begin
     Left := 0;
     Top := 0;
     Right := 0;
     Bottom := 0;
End;

Class Function TRectHelper.Empty : TRect;
Begin
     Result := TRect.Create;
End;

Function TRectHelper.IsEmpty : Boolean;
begin
     Result := (Right <= Left) or (Bottom <= Top);
end;

{$endregion}

{$region '************************************************* TCanvasRectFHelper ********************************************'}

Class Function TCanvasRectFHelper.Create : TCanvasRectF;
Begin
     Result.Left := 0;
     Result.Top := 0;
     Result.Right := 0;
     Result.Bottom := 0;
End;

Class Function TCanvasRectFHelper.Create(Const Left, Top, Right, Bottom : Double) : TCanvasRectF;
Begin
     Result.Left := Left;
     Result.Top := Top;
     Result.Right := Right;
     Result.Bottom := Bottom;
End;

Class Function TCanvasRectFHelper.Create(Const ARect : TRect) : TCanvasRectF;
Begin
     Result.Left := Min(ARect.Left, ARect.Right);
     Result.Top := Min(ARect.Top, ARect.Bottom);
     Result.Right := Max(ARect.Left, ARect.Right);
     Result.Bottom := Max(ARect.Top, ARect.Bottom);
End;

Class Function TCanvasRectFHelper.Create(Const AP1, AP2 : TCanvasPointF) : TCanvasRectF;
Begin
     Result.Left := Min(AP1.X, AP2.X);
     Result.Top := Min(AP1.Y, AP2.Y);
     Result.Right := Max(AP1.X, AP2.X);
     Result.Bottom := Max(AP1.Y, AP2.Y);
End;

Class Function TCanvasRectFHelper.Empty : TCanvasRectF;
Begin
     Result := TCanvasRectF.Create;
End;

Class Function TCanvasRectFHelper.ViewMap(Const Source, Destination : TCanvasRectF) : TMatrix;
Var
   SourceMtx, DestMtx : TMatrix;
   ViewportRect : TCanvasRectF;
   vSourceWidth, vSourceHeight : Double;
Begin
     DestMtx := TMatrix.IMatrix;
     DestMtx[1, 1] := (Destination.Right - Destination.Left) / 2;
     DestMtx[2, 2] := (Destination.Top - Destination.Bottom) / 2;

     DestMtx[1, 3] := (Destination.Right + Destination.Left) / 2;
     DestMtx[2, 3] := (Destination.Top + Destination.Bottom) / 2;


     vSourceHeight := 1.02 * Abs(Source.Height);
     vSourceWidth := 1.02 * vSourceHeight * Destination.Width / Destination.Height;

     If Abs(vSourceWidth) < Abs(Source.Width) Then BEgin
        vSourceWidth := 1.02 * Abs(Source.Width);
        vSourceHeight := 1.02 * vSourceWidth *  Destination.Height / Destination.Width;
        End;

     ViewportRect.Left := Source.Centre.X - vSourceWidth / 2;
     ViewportRect.Right := Source.Centre.X + vSourceWidth / 2;
     ViewportRect.Top := Source.Centre.Y - vSourceHeight / 2;
     ViewportRect.Bottom := Source.Centre.Y + vSourceHeight / 2;
     ViewportRect.Orientation := Source.Orientation;


     SourceMtx := TMatrix.IMatrix;
     SourceMtx[1, 1] := 2 / (ViewportRect.Right - ViewportRect.Left);
     SourceMtx[2, 2] := 2 / (ViewportRect.Top - ViewportRect.Bottom);

     SourceMtx[1, 3] := -(ViewportRect.Left + ViewportRect.Right) / (ViewportRect.Right - ViewportRect.Left);
     SourceMtx[2, 3] := -(ViewportRect.Top + ViewportRect.Bottom) / (ViewportRect.Top - ViewportRect.Bottom);

     Result := TMatrix.Multiply(DestMtx, SourceMtx);
End;

class function TCanvasRectFHelper.Map(Const Source, Destination : TCanvasRectF; MaintainAspectRatio : Boolean) : TMatrix;
Var
   SourceMtx, DestMtx : TMatrix;
   ViewportRect : TCanvasRectF;
   vSourceWidth, vSourceHeight : Double;
Begin
     DestMtx := TMatrix.IMatrix;
     DestMtx[1, 1] := (Destination.Right - Destination.Left) / 2;
     DestMtx[2, 2] := (Destination.Top - Destination.Bottom) / 2;

     DestMtx[1, 3] := (Destination.Right + Destination.Left) / 2;
     DestMtx[2, 3] := (Destination.Top + Destination.Bottom) / 2;

     If MaintainAspectRatio Then Begin
        vSourceHeight := Abs(Source.Height);
        vSourceWidth := vSourceHeight * Destination.Width / Destination.Height;

        If Abs(vSourceWidth) < Abs(Source.Width) Then BEgin
           vSourceWidth := Abs(Source.Width);
           vSourceHeight := vSourceWidth *  Destination.Height / Destination.Width;
           End;
        End
     Else Begin
        vSourceHeight := Abs(Source.Height);
        vSourceWidth := Abs(Source.Width);
        End;

     ViewportRect.Left := Source.Centre.X - vSourceWidth / 2;
     ViewportRect.Right := Source.Centre.X + vSourceWidth / 2;
     ViewportRect.Top := Source.Centre.Y - vSourceHeight / 2;
     ViewportRect.Bottom := Source.Centre.Y + vSourceHeight / 2;
     ViewportRect.Orientation := Source.Orientation;


     SourceMtx := TMatrix.IMatrix;
     SourceMtx[1, 1] := 2 / (ViewportRect.Right - ViewportRect.Left);
     SourceMtx[2, 2] := 2 / (ViewportRect.Top - ViewportRect.Bottom);

     SourceMtx[1, 3] := -(ViewportRect.Left + ViewportRect.Right) / (ViewportRect.Right - ViewportRect.Left);
     SourceMtx[2, 3] := -(ViewportRect.Top + ViewportRect.Bottom) / (ViewportRect.Top - ViewportRect.Bottom);

     Result := TMatrix.Multiply(DestMtx, SourceMtx);
End;

Function TCanvasRectFHelper.GetOrientation : TCanvasRectVerticalOrientation;
Begin
     If (Top = ABigNumber) and (Bottom = -ABigNumber) Then
        Result := rvBottomPositive
     Else If Bottom < Top Then
        Result := rvTopPositive
     Else
        Result := rvBottomPositive;
End;

Procedure TCanvasRectFHelper.SetOrientation(Val : TCanvasRectVerticalOrientation);
Var
   Temp : Single;
Begin
     If Not(Val = Orientation) Then Begin
        Temp := Bottom;
        Bottom := Top;
        Top := Temp;
        End;
End;

Function TCanvasRectFHelper.GetBottomLeft : TCanvasPointF;
Begin
     Result := TCanvasPointF.Create(Left, Bottom);
End;

Function TCanvasRectFHelper.GetTopRight : TCanvasPointF;
Begin
     Result := TCanvasPointF.Create(Right, Top);
End;

Function TCanvasRectFHelper.GetTopLeft : TCanvasPointF;
Begin
     Result := TCanvasPointF.Create(Left, Top);
End;

Function TCanvasRectFHelper.GetBottomRight : TCanvasPointF;
Begin
     Result := TCanvasPointF.Create(Right, Bottom);
End;

Function TCanvasRectFHelper.GetLeftMiddle : TCanvasPointF;
Begin
     Result := TCanvasPointF.Create(Left, (Top + Bottom) / 2);
End;

Function TCanvasRectFHelper.GetRightMiddle : TCanvasPointF;
Begin
     Result := TCanvasPointF.Create(Right, (Top + Bottom) / 2);
End;

Function TCanvasRectFHelper.GetTopMiddle : TCanvasPointF;
Begin
     Result := TCanvasPointF.Create((Left + Right) / 2, Top);
End;

Function TCanvasRectFHelper.GetBottomMiddle : TCanvasPointF;
Begin
     Result := TCanvasPointF.Create((Left + Right) / 2, Bottom);
End;

Procedure TCanvasRectFHelper.SetBottomLeft(Val : TCanvasPointF);
Begin
     Left := Val.X;
     Bottom := Val.Y;
End;

Procedure TCanvasRectFHelper.SetTopRight(Val : TCanvasPointF);
Begin
     Right := Val.X;
     Top := Val.Y;
End;

Procedure TCanvasRectFHelper.SetTopLeft(Val : TCanvasPointF);
Begin
     Left := Val.X;
     Top := Val.Y;
End;

Procedure TCanvasRectFHelper.SetBottomRight(Val : TCanvasPointF);
Begin
     Right := Val.X;
     Bottom := Val.Y;
End;


Function TCanvasRectFHelper.Contains(Const APoint : TCanvasPointF) : Boolean;
Begin
     If Top > Bottom Then
        Result := Not((APoint.X < Left) or (APoint.X > Right) or (APoint.Y > Top) or (APoint.Y < Bottom))
     Else
        Result := Not((APoint.X < Left) or (APoint.X > Right) or (APoint.Y < Top) or (APoint.Y > Bottom));
End;

Function TCanvasRectFHelper.Intersection(Const ARect : TCanvasRectF) : TCanvasRectF;
Var
   ARectSorted : TCanvasRectF;
Begin
     If Orientation = rvTopPositive Then Begin
        ARectSorted := ARect.TopPositive;
        Result.Left := Max(Left, ARectSorted.Left);
        Result.Right := Min(Right, ARectSorted.Right);
        Result.Top := Min(Top, ARectSorted.Top);
        Result.Bottom := Max(Bottom, ARectSorted.Bottom);
        End
     Else Begin
        ARectSorted := ARect.BottomPositive;
        Result.Left := Max(Left, ARectSorted.Left);
        Result.Right := Min(Right, ARectSorted.Right);
        Result.Top := Max(Top, ARectSorted.Top);
        Result.Bottom := Min(Bottom, ARectSorted.Bottom);
        End;
End;

Function TCanvasRectFHelper.Intersects(Const ARect : TCanvasRectF) : Boolean;
Begin
     Result := (Right < ARect.Left) or (Top > ARect.Bottom) or (Left > ARect.Right) or (Bottom < ARect.Top);
End;

Function TCanvasRectFHelper.Intersects(Const ASegment : TCanvasLineSegment) : Boolean;
Var
   tNear, tFar : Double;
   d, p : TCanvasPointF;

   Function CheckComponent(dn, pn, minn, maxn : Double) : Boolean;
   Var
      t0, t1, tmp : Double;
   Begin
        // Check for Parallelism
        If (Abs(dn) < ASmallNumber) Then
           Result := ((minn <= pn) and (pn <= maxn))
        Else Begin
           // Ray not parallel to planes, so find parameters of intersection
           t0 := (minn  - pn) / dn;
           t1 := (maxn  - pn) / dn;

          // Check Ordering
          if (t0 > t1) Then Begin
             // Swap them
             tmp := t0;
             t0 := t1;
             t1 := tmp;
             End;

          // Compare current values
          If (t0 > tNear) then tNear := t0;
          If (t1 < tFar) then tFar := t1;

          // Check if Ray missed entirely
          Result := tNear < tFar;
          End;
   End;

Begin
     Result := True;
     tNear := -ABigNumber;
     tFar := ABigNumber;

     d := vSubtract(ASegment.P2, ASegment.P1);
     p := ASegment.P1;

     If Not CheckComponent(d.x, p.x, Left, Right) Then Result := False;
     If Result and Not CheckComponent(d.y, p.y, Top, Bottom) Then Result := False;

     Result := Result and Not((tFar < 0) or (tNear > 1));
End;

Procedure TCanvasRectFHelper.Include(Const APoint : TCanvasPointF);
Begin
     With APoint Do
          Include(X, Y);
End;

Procedure TCanvasRectFHelper.Include(Const X, Y : Double);
Begin
     If X < Left Then Left := X;
     If Right < X Then Right := X;

     If Orientation = rvTopPositive Then Begin
        If Y > Top Then Top := Y;
        If Y < Bottom Then Bottom := Y;
        End
     Else Begin
        If Y < Top Then Top := Y;
        If Bottom < Y Then Bottom := Y;
        End;
End;

Procedure TCanvasRectFHelper.Include(ARect : TCanvasRectF);
Begin
     If Not ARect.IsEmpty Then Begin
        If ARect.Left < Left Then Left := ARect.Left;
        If Right < ARect.Right Then Right := ARect.Right;

        If Orientation = rvTopPositive Then Begin
           ARect.Orientation := rvTopPositive;
           If ARect.Top > Top Then Top := ARect.Top;
           If ARect.Bottom < Bottom Then Bottom := ARect.Bottom;
           End
        Else Begin
           ARect.Orientation := rvBottomPositive;
           If ARect.Top < Top Then Top := ARect.Top;
           If ARect.Bottom > Bottom Then Bottom := ARect.Bottom;
           End;
        End;
End;

Procedure TCanvasRectFHelper.Inflate(Const Amount : Double);
Begin
     Left := Left - Amount;
     Right := Right + Amount;

     If Orientation = rvBottomPositive Then Begin
        Top := Top - Amount;
        Bottom := Bottom + Amount;
        End
     Else Begin
        Top := Top + Amount;
        Bottom := Bottom - Amount;
        End;
End;

Procedure TCanvasRectFHelper.Inflate(Const WidthWards, HeightWards : Double);
Begin
     Left := Left - WidthWards;
     Right := Right + WidthWards;

     If Orientation = rvBottomPositive Then Begin
        Top := Top - HeightWards;
        Bottom := Bottom + HeightWards;
        End
     Else Begin
        Top := Top + HeightWards;
        Bottom := Bottom - HeightWards;
        End;
End;

Procedure TCanvasRectFHelper.Offset(Const dx, dy : Double);
Begin
     Left := Left + dx;
     Right := Right + dx;
     Top := Top + dy;
     Bottom := Bottom + dy;
End;

Procedure TCanvasRectFHelper.Offset(Const APoint : TCanvasPointF);
Begin
     Left := Left + APoint.X;
     Right := Right + APoint.X;
     Top := Top + APoint.Y;
     Bottom := Bottom + APoint.Y;
End;

Function TCanvasRectFHelper.PointClosestToPoint(Const APoint : TCanvasPointF) : TCanvasPointF;
Var
   P1, P2, P3, P4, P : TCanvasPointF;
   Dist, LDist : Double;
   ALine : TCanvasLineSegment;

Begin
     Dist := ABigNumber;

     P1 := CanvasPointF(Left, Bottom);
     P2 := CanvasPointF(Right, Bottom);
     P3 := CanvasPointF(Right, Top);
     P4 := CanvasPointF(Left, Top);

     ALine := TCanvasLineSegment.Create(P1, P2);
     P := ALine.PointClosestToPoint(APoint);
     LDist := P.DistanceTo(APoint);
     If LDist < Dist Then Begin
        Result := P;
        Dist := LDist;
        End;

     ALine := TCanvasLineSegment.Create(P2, P3);
     P := ALine.PointClosestToPoint(APoint);
     LDist := P.DistanceTo(APoint);
     If LDist < Dist Then Begin
        Result := P;
        Dist := LDist;
        End;

     ALine := TCanvasLineSegment.Create(P3, P4);
     P := ALine.PointClosestToPoint(APoint);
     LDist := P.DistanceTo(APoint);
     If LDist < Dist Then Begin
        Result := P;
        Dist := LDist;
        End;

     ALine := TCanvasLineSegment.Create(P4, P1);
     P := ALine.PointClosestToPoint(APoint);
     LDist := P.DistanceTo(APoint);
     If LDist < Dist Then
        Result := P;
End;

Procedure TCanvasRectFHelper.Clear;
Begin
     Left := ABigNumber;
     Right := -ABigNumber;
     Top := ABigNumber;
     Bottom := -ABigNumber;
End;

Function TCanvasRectFHelper.IsEmpty : Boolean;
Begin
     If Orientation = rvBottomPositive Then
        Result := (Right <= Left) or (Bottom <= Top)
     Else
        Result := (Right <= Left) or (Bottom >= Top);
End;

Function TCanvasRectFHelper.IsEqualTo(ARect : TCanvasRectF) : Boolean;
Begin
     Result := (Left = ARect.Left) and (Top = ARect.Top) and (Right = ARect.Right) and (Bottom = ARect.Bottom);
End;

Function TCanvasRectFHelper.Width : Double;
Begin
     Result := Abs(Right - Left);
End;

Function TCanvasRectFHelper.Height : Double;
Begin
     Result := Abs(Top - Bottom);
End;

Function TCanvasRectFHelper.TopPositive : TCanvasRectF;
Begin
     Result.Left := Left;
     Result.Right := Right;
     Result.Top := Max(Top, Bottom);
     Result.Bottom := Min(Top, Bottom);
End;

Function TCanvasRectFHelper.BottomPositive : TCanvasRectF;
Begin
     Result.Left := Left;
     Result.Right := Right;
     Result.Top := Min(Top, Bottom);
     Result.Bottom := Max(Top, Bottom);
End;

Function TCanvasRectFHelper.Centre : TCanvasPointF;
Begin
     Result.X := (Left + Right) / 2;
     Result.Y := (Top + Bottom) / 2;
End;

Function TCanvasRectFHelper.CenterPoint : TCanvasPointF;
Begin
     Result.X := (Left + Right) / 2;
     Result.Y := (Top + Bottom) / 2;
End;

Function TCanvasRectFHelper.Area : Double;
Begin
     Result := Width * Height;
End;

Procedure TCanvasRectFHelper.TransformBounds(Const AMatrix : TMatrix);
Var
   P1, P2, P3, P4 : TCanvasPointF;
Begin
     P1 := TCanvasPointF.Create(Left, Bottom);
     P2 := TCanvasPointF.Create(Right, Bottom);
     P3 := TCanvasPointF.Create(Right, Top);
     P4 := TCanvasPointF.Create(Left, Top);

     Clear;
     Include(AMatrix.Multiply(P1));
     Include(AMatrix.Multiply(P2));
     Include(AMatrix.Multiply(P3));
     Include(AMatrix.Multiply(P4));
End;

Function vAdd(Const ARect : TCanvasRectF; Const APoint : TCanvasPointF): TCanvasRectF; overload;
Begin
     Result.Left := ARect.Left + APoint.X;
     Result.Right := ARect.Right + APoint.X;
     Result.Top := ARect.Top + APoint.Y;
     Result.Bottom := ARect.Bottom + APoint.Y;
End;

Function vAdd(Const ARect, BRect : TCanvasRectF): TCanvasRectF; overload;
Begin
     If ARect.Left < BRect.Left Then
        Result.Left := ARect.Left
     Else
        Result.Left := BRect.Left;

     If ARect.Top < BRect.Top Then
        Result.Top := ARect.Top
     Else
        Result.Top := BRect.Top;

     If ARect.Right > BRect.Right Then
        Result.Right := ARect.Right
     Else
        Result.Right := BRect.Right;

     If ARect.Bottom > BRect.Bottom Then
        Result.Bottom := ARect.Bottom
     Else
        Result.Bottom := BRect.Bottom;
End;

{$endregion}

{$region '************************************************* TMatrix *******************************************************'}

Constructor TMatrix.Create(Const AOffset : TCanvasPointF; Const ARotate : Double);
Begin
     Self := TMatrix.Multiply(TMatrix.Translate(AOffset), TMatrix.Rotate(ARotate));
End;

Constructor TMatrix.Create(Const AOffset : TCanvasPointF; Const AScaleX, AScaleY, ARotate : Double);
Begin
     Self := TMatrix.Multiply(TMatrix.Translate(AOffset), TMatrix.Multiply(TMatrix.Rotate(ARotate), TMatrix.Scale(AScaleX, AScaleY)));
End;

Constructor TMatrix.Create(Const AColumn1, AColumn2, AColumn3 : TCanvasPointF);
Begin
     Elements[1, 1] := AColumn1.X;
     Elements[2, 1] := AColumn1.Y;
     Elements[3, 1] := 0;

     Elements[1, 2] := AColumn2.X;
     Elements[2, 2] := AColumn2.Y;
     Elements[3, 2] := 0;

     Elements[1, 3] := AColumn3.X;
     Elements[2, 3] := AColumn3.Y;
     Elements[3, 3] := 1;
End;

Function TMatrix.GetElement(Const I, J : Integer) : Double;
Begin
     Result := FArray[I, J];
End;

Procedure TMatrix.SetElement(Const I, J : Integer; Const Val : Double);
Begin
     FArray[I, J] := Val;
End;

Class Function TMatrix.Negative(AMatrix : TMatrix) : TMatrix;
Var
   I, J : Integer;
Begin
     For I := 1 to vMatrixSize Do
         For J :=  1 to vMatrixSize Do
         Result.FArray[I, J] := -AMatrix.FArray[I, J];
End;

class Function TMatrix.Add(AMatrix, BMatrix : TMatrix): TMatrix;
Var
   I, J : Integer;
Begin
     For I := 1 to vMatrixSize Do
         For J :=  1 to vMatrixSize Do
         Result.FArray[I, J] := AMatrix.FArray[I, J] + BMatrix.FArray[I, J];
End;

class Function TMatrix.Subtract(Const AMatrix, BMatrix: TMatrix): TMatrix;
Var
   I, J : Integer;
Begin
     For I := 1 to vMatrixSize Do
         For J :=  1 to vMatrixSize Do
         Result.FArray[I, J] := AMatrix.FArray[I, J] - BMatrix.FArray[I, J];
End;

class Function TMatrix.Multiply(Const AScalar : Double; Const AMatrix : TMatrix): TMatrix;
Var
   I, J : Integer;
Begin
     For I := 1 to vMatrixSize Do
         For J :=  1 to vMatrixSize Do
         Result.FArray[I, J] := AScalar * AMatrix.FArray[I, J];
End;

class Function TMatrix.Multiply(Const AMatrix : TMatrix; Const AScalar : Double): TMatrix;
Var
   I, J : Integer;
Begin
     For I := 1 to vMatrixSize Do
         For J :=  1 to vMatrixSize Do
         Result.FArray[I, J] := AScalar * AMatrix.FArray[I, J];
End;

class Function TMatrix.Multiply(Const AMatrix, BMatrix : TMatrix): TMatrix;
Var
   I, J, K : Integer;
   TempSum : Double;
Begin
     For I := 1 to vMatrixSize Do
         For J := 1 to vMatrixSize Do Begin
             TempSum := 0;
             For K := 1 to vMatrixSize Do
                 TempSum := TempSum + AMatrix.FArray[I, K] * BMatrix.FArray[K, J];
             Result.FArray[I, J] := TempSum;
             End;
End;

class Function TMatrix.Multiply(Const AMatrix : TMatrix; Const APoint : TCanvasPointF) : TCanvasPointF;
Begin
     Result.X := AMatrix.FArray[1, 1] * APoint.X + AMatrix.FArray[1, 2] * APoint.Y + AMatrix.FArray[1, 3];
     Result.Y := AMatrix.FArray[2, 1] * APoint.X + AMatrix.FArray[2, 2] * APoint.Y + AMatrix.FArray[2, 3];
End;

Function TMatrix.Multiply(Const APoint : TCanvasPointF): TCanvasPointF;
Begin
     Result.X := FArray[1, 1] * APoint.X + FArray[1, 2] * APoint.Y + FArray[1, 3];
     Result.Y := FArray[2, 1] * APoint.X + FArray[2, 2] * APoint.Y + FArray[2, 3];
End;


Class Function TMatrix.OMatrix : TMatrix;
Begin
     Result[1, 1] := 0;
     Result[1, 2] := 0;
     Result[1, 3] := 0;

     Result[2, 1] := 0;
     Result[2, 2] := 0;
     Result[2, 3] := 0;

     Result[3, 1] := 0;
     Result[3, 2] := 0;
     Result[3, 3] := 0;
End;

Class Function TMatrix.IMatrix : TMatrix;
Begin
     Result[1, 1] := 1;
     Result[1, 2] := 0;
     Result[1, 3] := 0;

     Result[2, 1] := 0;
     Result[2, 2] := 1;
     Result[2, 3] := 0;

     Result[3, 1] := 0;
     Result[3, 2] := 0;
     Result[3, 3] := 1;
End;

Class Function TMatrix.XMirror : TMatrix;
Begin
     Result[1, 1] := -1;
     Result[1, 2] := 0;
     Result[1, 3] := 0;

     Result[2, 1] := 0;
     Result[2, 2] := 1;
     Result[2, 3] := 0;

     Result[3, 1] := 0;
     Result[3, 2] := 0;
     Result[3, 3] := 1;
End;

Class Function TMatrix.YMirror : TMatrix;
Begin
     Result[1, 1] := 1;
     Result[1, 2] := 0;
     Result[1, 3] := 0;

     Result[2, 1] := 0;
     Result[2, 2] := -1;
     Result[2, 3] := 0;

     Result[3, 1] := 0;
     Result[3, 2] := 0;
     Result[3, 3] := 1;
End;

Class Function TMatrix.Scale(Const S : Double) : TMatrix;
Begin
     Result := IMatrix;
     Result[1, 1] := S;
     Result[2, 2] := S;
End;

Class Function TMatrix.Scale(Const sX, sY : Double) : TMatrix;
Begin
     Result := IMatrix;
     Result[1, 1] := sX;
     Result[2, 2] := sY;
End;

Class Function TMatrix.Translate(Const aX, aY : Double) : TMatrix;
Begin
     Result := IMatrix;
     Result[1, 3] := aX;
     Result[2, 3] := aY;
End;

Class Function TMatrix.Translate(Const ATranslation : TCanvasPointF) : TMatrix;
Begin
     Result := IMatrix;
     Result[1, 3] := ATranslation.X;
     Result[2, 3] := ATranslation.Y;
End;

Class Function TMatrix.Rotate(Const Angle : Double) : TMatrix;
Begin
     Result := IMatrix;
     Result[1, 1] := Cos(Angle);
     Result[1, 2] := -Sin(Angle);
     Result[2, 1] := Sin(Angle);
     Result[2, 2] := Cos(Angle);
End;

Class Function TMatrix.Rotate(Const X, Y, Angle : Double) : TMatrix;
Begin
     Result := TMatrix.Multiply(TMatrix.Translate(X, Y), TMatrix.Multiply(TMatrix.Rotate(Angle), TMatrix.Translate(-X, -Y)));
End;

Function TMatrix.Determinant : Double;
Begin
     Result := Elements[1, 1] * (Elements[2, 2] * Elements[3, 3] - Elements[2, 3] * Elements[3, 2]) -
               Elements[1, 2] * (Elements[2, 1] * Elements[3, 3] - Elements[2, 3] * Elements[3, 1]) +
               Elements[1, 3] * (Elements[2, 1] * Elements[3, 2] - Elements[3, 1] * Elements[2, 2]);
End;

Function TMatrix.Adjoint : TMatrix;
Begin
     Result[1, 1] :=  (Elements[2, 2] * Elements[3, 3] - Elements[3, 2] * Elements[2, 3]);
     Result[1, 2] := -(Elements[1, 2] * Elements[3, 3] - Elements[3, 2] * Elements[1, 3]);
     Result[1, 3] :=  (Elements[1, 2] * Elements[2, 3] - Elements[2, 2] * Elements[1, 3]);

     Result[2, 1] := -(Elements[2, 1] * Elements[3, 3] - Elements[3, 1] * Elements[2, 3]);
     Result[2, 2] :=  (Elements[1, 1] * Elements[3, 3] - Elements[3, 1] * Elements[1, 3]);
     Result[2, 3] := -(Elements[1, 1] * Elements[2, 3] - Elements[2, 1] * Elements[1, 3]);

     Result[3, 1] :=  (Elements[2, 1] * Elements[3, 2] - Elements[3, 1] * Elements[2, 2]);
     Result[3, 2] := -(Elements[1, 1] * Elements[3, 2] - Elements[3, 1] * Elements[1, 2]);
     Result[3, 3] :=  (Elements[1, 1] * Elements[2, 2] - Elements[2, 1] * Elements[1, 2]);
End;

Function TMatrix.Inverse : TMatrix;
var
  Det: Single;
begin
     Det := Determinant;
     if Abs(Det) < Epsilon then
        Result := IMatrix
     Else
         Result:= TMatrix.Multiply(Adjoint, (1 / Det));
End;

Procedure TMatrix.Transpose;
Begin
     Swap(FArray[1, 2], FArray[2, 1]);
     Swap(FArray[1, 3], FArray[3, 1]);
     Swap(FArray[2, 3], FArray[3, 2]);
End;

Function TMatrix.Transposed : TMatrix;
Begin
     Result.FArray[1, 1] := FArray[1, 1];
     Result.FArray[1, 2] := FArray[2, 1];
     Result.FArray[1, 3] := FArray[3, 1];

     Result.FArray[2, 1] := FArray[1, 2];
     Result.FArray[2, 2] := FArray[2, 2];
     Result.FArray[2, 3] := FArray[3, 2];

     Result.FArray[3, 1] := FArray[1, 3];
     Result.FArray[3, 2] := FArray[2, 3];
     Result.FArray[3, 3] := FArray[3, 3];
End;

Class Function TMatrix.Map(Const Source, Destination : TRectF; MaintainAspectRatio : Boolean) : TMatrix;
Var
   SourceMtx, DestMtx : TMatrix;
   ViewportRect : TRectF;
   Swap, vSourceWidth, vSourceHeight : Double;
Begin
     DestMtx := TMatrix.IMatrix;
     DestMtx[1, 1] := (Destination.Right - Destination.Left) / 2;
     DestMtx[2, 2] := (Destination.Top - Destination.Bottom) / 2;

     DestMtx[1, 3] := (Destination.Right + Destination.Left) / 2;
     DestMtx[2, 3] := (Destination.Top + Destination.Bottom) / 2;


     If MaintainAspectRatio Then Begin
        vSourceHeight := Abs(Source.Height);
        vSourceWidth := vSourceHeight * Destination.Width / Destination.Height;

        If Abs(vSourceWidth) < Abs(Source.Width) Then BEgin
           vSourceWidth := Abs(Source.Width);
           vSourceHeight := vSourceWidth *  Destination.Height / Destination.Width;
           End;
        End
     Else Begin
        vSourceHeight := Abs(Source.Height);
        vSourceWidth := Abs(Source.Width);
        End;

     ViewportRect.Left := Source.CenterPoint.X - vSourceWidth / 2;
     ViewportRect.Right := Source.CenterPoint.X + vSourceWidth / 2;
     ViewportRect.Top := Source.CenterPoint.Y - vSourceHeight / 2;
     ViewportRect.Bottom := Source.CenterPoint.Y + vSourceHeight / 2;

     If ((Source.Top < Source.Bottom) and (ViewportRect.Top > ViewportRect.Bottom)) or ((Source.Top > Source.Bottom) and (ViewportRect.Top < ViewportRect.Bottom)) Then Begin
        Swap := ViewportRect.Top;
        ViewportRect.Top := ViewportRect.Bottom;
        ViewportRect.Bottom := Swap;
        End;

     SourceMtx := TMatrix.IMatrix;
     SourceMtx[1, 1] := 2 / (ViewportRect.Right - ViewportRect.Left);
     SourceMtx[2, 2] := 2 / (ViewportRect.Top - ViewportRect.Bottom);

     SourceMtx[1, 3] := -(ViewportRect.Left + ViewportRect.Right) / (ViewportRect.Right - ViewportRect.Left);
     SourceMtx[2, 3] := -(ViewportRect.Top + ViewportRect.Bottom) / (ViewportRect.Top - ViewportRect.Bottom);

     Result := TMatrix.Multiply(DestMtx, SourceMtx);
End;

{$endregion}

{$region '************************************************* TvLineSegment *************************************************'}

Constructor TCanvasLineSegment.Create(Const X1, Y1, X2, Y2 : Double);
Begin
     P1 := TCanvasPointF.Create(X1, Y1);
     P2 := TCanvasPointF.Create(X2, Y2);
End;

Constructor TCanvasLineSegment.Create(Const AP1, AP2 : TCanvasPointF);
Begin
     P1 := AP1;
     P2 := AP2;
End;

Function TCanvasLineSegment.MidPoint : TCanvasPointF;
Begin
     Result.X := (P1.X + P2.X) / 2;
     Result.Y := (P1.Y + P2.Y) / 2;
End;

Function TCanvasLineSegment.PointClosestToPoint(Const APoint : TCanvasPointF) : TCanvasPointF;
Var
   Delta, YmP0 : TCanvasPointF;
   t, DeltaDotDelta : Double;
Begin
     Delta := vSubtract(P2, P1);
     YmP0 := vSubtract(APoint, P1);
     t := vDotProduct(Delta, YmP0);

     if t <= 0 Then
        Result := P1
     Else Begin
        DeltaDotDelta := vDotProduct(Delta, Delta);

        If t >= DeltaDotDelta Then
           Result := P2
        Else Begin
           t := t / DeltaDotDelta;
           Result := TCanvasPointF.Ratio(t, P1, P2);
           End;
        End;
End;

{$endregion}

{$region '************************************************* TvLine ********************************************************'}

Constructor TCanvasLine.Create(Const X1, Y1, X2, Y2 : Double);
Begin
     Base := CanvasPointF(X1, Y1);
     Direction := vSubtract(CanvasPointF(X2, Y2), Base);
     Direction.Normalise;
End;

Constructor TCanvasLine.Create(Const AP1, AP2 : TCanvasPointF);
Begin
     Base := AP1;
     Direction := vSubtract(AP2, Base);
     Direction.Normalise;
End;

Constructor TCanvasLine.Create(Const ALine : TCanvasLineSegment);
Begin
     Base := ALine.P1;
     Direction := vSubtract(ALine.P2, Base);
     Direction.Normalise;
End;

Function TCanvasLine.F(Const T : Double) : TCanvasPointF;
Begin
     Result := vAdd(Base, vMultiply(t, Direction));
End;

Function TCanvasLine.PointClosestToPoint(Const APoint : TCanvasPointF) : TCanvasPointF;
Var
   t  : Double;
Begin
     Result := PointClosestToPoint(APoint, T);
End;

Function TCanvasLine.PointClosestToPoint(Const APoint : TCanvasPointF; Out T : Double) : TCanvasPointF;
Var
   YmP0 : TCanvasPointF;
Begin
     YmP0 := vSubtract(APoint, Base);
     t := vMultiply(Direction, YmP0);
     Result := F(t);
End;

Function TCanvasLine.DistanceTo(Const APoint : TCanvasPointF) : Double;
Var
   Normal : TCanvasPointF;
Begin
     Normal := Direction.Perpendicular.Normalised;
     Result := Abs(vDotProduct(Normal, Base) - vDotProduct(Normal, APoint));
End;

{$endregion}

{$region '************************************************* PointInPolygon ************************************************'}

Function PointInPolygon(Const APoint : TPoint; Const APoints : array of TPoint) : Boolean; overload;
// cn_PnPoly(): crossing number test for a point in a polygon
//      Input:   P = a point,
//               V[] = vertex points of a polygon V[n+1] with V[n]=V[0]
//      Return:  0 = outside, 1 = inside
// This code is patterned after [Franklin, 2000]
Var
   Hgh, Crossing, I : Integer;
   Vt : Double;

   Procedure TestSegment(Const Point1, Point2 : TPoint);
   Begin
        If ((Point1.Y <= APoint.Y) and (Point2.Y > APoint.Y)) or    // an upward crossing
            ((Point1.Y > APoint.Y) and (Point2.Y <= APoint.Y)) Then Begin // a downward crossing

            // Point in Polygon Winding Number Inclusion Page 7 of 7
            Vt := (APoint.Y - Point1.Y) / (Point2.Y - Point1.Y);

            If (APoint.X < Point1.X + vt * (Point2.X - Point1.x)) Then // P.x < intersect
               Inc(Crossing); // a valid crossing of Y=P.Y right of P.x
            End;
   End;

Begin
     Hgh := High(APoints);
     Crossing := 0;
     For I := Low(APoints) + 1 to Hgh Do
         TestSegment(APoints[I-1], APoints[I]);

     If Not ((APoints[0].X = APoints[Hgh].X) and (APoints[0].Y = APoints[Hgh].Y)) Then
        TestSegment(APoints[Hgh], APoints[0]);

     Result := Odd(Crossing);
End;

Function PointInPolygon(Const APoint : TPoint; Const APoints : array of TCanvasPointF) : Boolean; overload;
// cn_PnPoly(): crossing number test for a point in a polygon
//      Input:   P = a point,
//               V[] = vertex points of a polygon V[n+1] with V[n]=V[0]
//      Return:  0 = outside, 1 = inside
// This code is patterned after [Franklin, 2000]
Var
   Hgh, Crossing, I : Integer;
   Vt : Double;

   Procedure TestSegment(Const Point1, Point2 : TCanvasPointF);
   Begin
        If ((Point1.Y <= APoint.Y) and (Point2.Y > APoint.Y)) or    // an upward crossing
            ((Point1.Y > APoint.Y) and (Point2.Y <= APoint.Y)) Then Begin // a downward crossing

            // Point in Polygon Winding Number Inclusion Page 7 of 7
            Vt := (APoint.Y - Point1.Y) / (Point2.Y - Point1.Y);

            If (APoint.X < Point1.X + vt * (Point2.X - Point1.x)) Then // P.x < intersect
               Inc(Crossing); // a valid crossing of Y=P.Y right of P.x
            End;
   End;

Begin
     Hgh := High(APoints);
     Crossing := 0;
     For I := Low(APoints) + 1 to Hgh Do
         TestSegment(APoints[I-1], APoints[I]);

     If Not ((APoints[0].X = APoints[Hgh].X) and (APoints[0].Y = APoints[Hgh].Y)) Then
        TestSegment(APoints[Hgh], APoints[0]);

     Result := Odd(Crossing);
End;

Function PointInPolygon(Const APoint : TCanvasPointF; Const APoints : array of TCanvasPointF) : Boolean; overload;
// cn_PnPoly(): crossing number test for a point in a polygon
//      Input:   P = a point,
//               V[] = vertex points of a polygon V[n+1] with V[n]=V[0]
//      Return:  0 = outside, 1 = inside
// This code is patterned after [Franklin, 2000]
Var
   Hgh, Crossing, I : Integer;
   Vt : Double;

   Procedure TestSegment(Const Point1, Point2 : TCanvasPointF);
   Begin
        If ((Point1.Y <= APoint.Y) and (Point2.Y > APoint.Y)) or    // an upward crossing
            ((Point1.Y > APoint.Y) and (Point2.Y <= APoint.Y)) Then Begin // a downward crossing

            // Point in Polygon Winding Number Inclusion Page 7 of 7
            Vt := (APoint.Y - Point1.Y) / (Point2.Y - Point1.Y);

            If (APoint.X < Point1.X + vt * (Point2.X - Point1.x)) Then // P.x < intersect
               Inc(Crossing); // a valid crossing of Y=P.Y right of P.x
            End;
   End;

Begin
     Hgh := High(APoints);
     Crossing := 0;
     For I := Low(APoints) + 1 to Hgh Do
         TestSegment(APoints[I-1], APoints[I]);

     If Not ((APoints[0].X = APoints[Hgh].X) and (APoints[0].Y = APoints[Hgh].Y)) Then
        TestSegment(APoints[Hgh], APoints[0]);

     Result := Odd(Crossing);
End;



{$endregion}

end.
