unit MyPolyCAD.App;

interface

Uses MyPolyCAD.Model;

Var
   appMyPolyCAD : TMyPolyCAD = nil;

implementation

Uses EndPoints.Connection, MyPolyCAD.Configuration;

Var
    AConnection : THTTPConnection;

Initialization
    AConnection := THTTPConnection.Create(ConfigurationConnection);
    AConnection.WithCredentials := True;

    appMyPolyCAD := TMyPolyCAD.Create(AConnection);
end.
