unit FeedbackUnit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  WEBLib.REST;

type
  TFeedbackForm = class(TForm)
    radEngineer: TRadioButton;
    edOrganisation: TEdit;
    chkXTopology: TCheckBox;
    radOther: TRadioButton;
    chkStability: TCheckBox;
    chkFileTransfer: TCheckBox;
    chkCurveDesign: TCheckBox;
    chkWeightEstimation: TCheckBox;
    chkGeometricAnalysis: TCheckBox;
    chkHullGeneration: TCheckBox;
    radAcademic: TRadioButton;
    chkImages: TCheckBox;
    chkHydrostatics: TCheckBox;
    chkHullTransformation: TCheckBox;
    chkMailRelease: TCheckBox;
    radAdmin: TRadioButton;
    txtHearOf: TMemo;
    chkLinesPlanGeneration: TCheckBox;
    chkSurfaceDesign: TCheckBox;
    radStudent: TRadioButton;
    chkPointClouds: TCheckBox;
    radAmateur: TRadioButton;
    chkArrangements: TCheckBox;
    edFullName: TEdit;
    chkMailArticle: TCheckBox;
    btnContinue: TButton;
    edLocation: TEdit;
    procedure btnContinueClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    [async]
    Procedure SubmitFeedback; async;
    Procedure UpdateForm;
    Procedure UpdateApp;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FeedbackForm: TFeedbackForm;

implementation

{$R *.dfm}

Uses LxWeb.Router, MyPolyCAD.App, MyPolyCAD.Configuration, MyPolyCAD.Model.Feedback, MyPolyCAD.Model.User, MessageUnit;

procedure TFeedbackForm.WebFormCreate(Sender: TObject);
begin
     UpdateForm;
end;

Procedure TFeedbackForm.UpdateForm;
Begin
     edFullName.Text := appMyPolyCAD.User.Name;
     edOrganisation.Text := appMyPolyCAD.User.Organisation;
     edLocation.Text := appMyPolyCAD.User.Location;

     txtHearOf.Text := appMyPolyCAD.Feedback.HearOfPolyCAD;

     With appMyPolyCAD.Feedback Do Begin
          radStudent.Checked := UserDescription = udStudent;
          radAmateur.Checked := UserDescription = udAmateur;
          radAcademic.Checked := UserDescription = udAcademic;
          radEngineer.Checked := UserDescription = udProfessional;
          radAdmin.Checked := UserDescription = udITAdministrator;
          radOther.Checked := UserDescription = udOther;
          End;

     With appMyPolyCAD.Feedback Do Begin
          chkSurfaceDesign.Checked := sfHullSurfaceDesign in SoftwareFeatures;
          chkCurveDesign.Checked := sfCurveDesign in SoftwareFeatures;
          chkXTopology.Checked := sfXTopology in SoftwareFeatures;
          chkHullGeneration.Checked := sfParametricHullGeneration in SoftwareFeatures;
          chkHullTransformation.Checked := sfHullTransformation in SoftwareFeatures;
          chkWeightEstimation.Checked := sfWeightEstimation in SoftwareFeatures;
          chkGeometricAnalysis.Checked := sfGeometricAnalysis in SoftwareFeatures;
          chkHydrostatics.Checked := sfHydrostaticAnalysis in SoftwareFeatures;
          chkStability.Checked := sfIntactStability in SoftwareFeatures;
          chkFileTransfer.Checked := sfFormatExchange in SoftwareFeatures;
          chkPointClouds.Checked := sfPointClouds in  SoftwareFeatures;
          chkImages.Checked := sfImages in SoftwareFeatures;
          chkArrangements.Checked := sfCompartmentation in SoftwareFeatures;
          chkLinesPlanGeneration.Checked := sfLinesPlanGeneration in SoftwareFeatures;
          End;

     chkMailRelease.Checked := mpNewRelease in appMyPolyCAD.User.MailPreferences;
     chkMailArticle.Checked := mpNewArticle in appMyPolyCAD.User.MailPreferences;
End;

Procedure TFeedbackForm.UpdateApp;
begin
     appMyPolyCAD.User.Name := edFullName.Text;
     appMyPolyCAD.User.Organisation := edOrganisation.Text;
     appMyPolyCAD.User.Location := edLocation.Text;

     With appMyPolyCAD.Feedback Do Begin
          HearOfPolyCAD := txtHearOf.Lines.Text;

          If radStudent.Checked Then UserDescription := udStudent;
          If radAmateur.Checked Then UserDescription := udAmateur;
          If radAcademic.Checked Then UserDescription := udAcademic;
          If radEngineer.Checked Then UserDescription := udProfessional;
          If radAdmin.Checked Then UserDescription := udITAdministrator;
          If radOther.Checked Then UserDescription := udOther;

          SoftwareFeatures := [];
          If chkSurfaceDesign.Checked Then Include(SoftwareFeatures, sfHullSurfaceDesign);
          If chkCurveDesign.Checked Then Include(SoftwareFeatures, sfCurveDesign);
          If chkXTopology.Checked Then Include(SoftwareFeatures, sfXTopology);
          If chkHullGeneration.Checked Then Include(SoftwareFeatures, sfParametricHullGeneration);
          If chkHullTransformation.Checked Then Include(SoftwareFeatures, sfHullTransformation);
          If chkWeightEstimation.Checked Then Include(SoftwareFeatures, sfWeightEstimation);
          If chkGeometricAnalysis.Checked Then Include(SoftwareFeatures, sfGeometricAnalysis);
          If chkHydrostatics.Checked Then Include(SoftwareFeatures, sfHydrostaticAnalysis);
          If chkStability.Checked Then Include(SoftwareFeatures, sfIntactStability);
          If chkFileTransfer.Checked Then Include(SoftwareFeatures, sfFormatExchange);
          If chkPointClouds.Checked Then Include(SoftwareFeatures, sfPointClouds);
          If chkImages.Checked Then Include(SoftwareFeatures, sfImages);
          If chkArrangements.Checked Then Include(SoftwareFeatures, sfCompartmentation);
          If chkLinesPlanGeneration.Checked Then Include(SoftwareFeatures, sfLinesPlanGeneration);
          End;

     If chkMailRelease.Checked Then
        appMyPolyCAD.User.MailPreferences := appMyPolyCAD.User.MailPreferences + [mpNewRelease]
     Else
        appMyPolyCAD.User.MailPreferences := appMyPolyCAD.User.MailPreferences - [mpNewRelease];

     If chkMailArticle.Checked Then
        appMyPolyCAD.User.MailPreferences := appMyPolyCAD.User.MailPreferences + [mpNewArticle]
     Else
        appMyPolyCAD.User.MailPreferences := appMyPolyCAD.User.MailPreferences - [mpNewArticle];
End;

Procedure TFeedbackForm.SubmitFeedback;
Var
   AMessage, PageMessage : String;
Begin
     btnContinue.Cursor := crHourGlass;
     UpdateApp;
     if await(boolean, appMyPolyCAD.SignUp(AMessage)) Then
        Router.Route('/validate')
     Else Begin
          btnContinue.Cursor := crDefault;
        PageMessage := '<p>The email confirmation process used for account validation experienced an error and wasn&apos;t able to complete the full process. This process needs to complete before you can register PolyCAD. Please inform support at <a href="mailto:' + ConfigurationSupportEmail + '">' + ConfigurationSupportEMail + '</a> of the error message below.</p>' +
                       '<p>Reason: ' + AMessage + '</p>';
        TMessageForm.ShowMessage('Validation Failed', PageMessage);
        End;
End;

procedure TFeedbackForm.btnContinueClick(Sender: TObject);
begin
     SubmitFeedback;
end;



procedure TFeedbackForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  radEngineer := TRadioButton.Create('radEngineer');
  edOrganisation := TEdit.Create('edOrganisation');
  chkXTopology := TCheckBox.Create('chkXTopology');
  radOther := TRadioButton.Create('radOther');
  chkStability := TCheckBox.Create('chkStability');
  chkFileTransfer := TCheckBox.Create('chkFileTransfer');
  chkCurveDesign := TCheckBox.Create('chkCurveDesign');
  chkWeightEstimation := TCheckBox.Create('chkWeightEstimation');
  chkGeometricAnalysis := TCheckBox.Create('chkGeometricAnalysis');
  chkHullGeneration := TCheckBox.Create('chkHullGeneration');
  radAcademic := TRadioButton.Create('radAcademic');
  chkImages := TCheckBox.Create('chkImages');
  chkHydrostatics := TCheckBox.Create('chkHydrostatics');
  chkHullTransformation := TCheckBox.Create('chkHullTransformation');
  chkMailRelease := TCheckBox.Create('chkMailRelease');
  radAdmin := TRadioButton.Create('radAdmin');
  txtHearOf := TMemo.Create('txtHearOf');
  chkLinesPlanGeneration := TCheckBox.Create('chkLinesPlanGeneration');
  chkSurfaceDesign := TCheckBox.Create('chkSurfaceDesign');
  radStudent := TRadioButton.Create('radStudent');
  chkPointClouds := TCheckBox.Create('chkPointClouds');
  radAmateur := TRadioButton.Create('radAmateur');
  chkArrangements := TCheckBox.Create('chkArrangements');
  edFullName := TEdit.Create('edFullName');
  chkMailArticle := TCheckBox.Create('chkMailArticle');
  btnContinue := TButton.Create('btnContinue');
  edLocation := TEdit.Create('edLocation');

  radEngineer.BeforeLoadDFMValues;
  edOrganisation.BeforeLoadDFMValues;
  chkXTopology.BeforeLoadDFMValues;
  radOther.BeforeLoadDFMValues;
  chkStability.BeforeLoadDFMValues;
  chkFileTransfer.BeforeLoadDFMValues;
  chkCurveDesign.BeforeLoadDFMValues;
  chkWeightEstimation.BeforeLoadDFMValues;
  chkGeometricAnalysis.BeforeLoadDFMValues;
  chkHullGeneration.BeforeLoadDFMValues;
  radAcademic.BeforeLoadDFMValues;
  chkImages.BeforeLoadDFMValues;
  chkHydrostatics.BeforeLoadDFMValues;
  chkHullTransformation.BeforeLoadDFMValues;
  chkMailRelease.BeforeLoadDFMValues;
  radAdmin.BeforeLoadDFMValues;
  txtHearOf.BeforeLoadDFMValues;
  chkLinesPlanGeneration.BeforeLoadDFMValues;
  chkSurfaceDesign.BeforeLoadDFMValues;
  radStudent.BeforeLoadDFMValues;
  chkPointClouds.BeforeLoadDFMValues;
  radAmateur.BeforeLoadDFMValues;
  chkArrangements.BeforeLoadDFMValues;
  edFullName.BeforeLoadDFMValues;
  chkMailArticle.BeforeLoadDFMValues;
  btnContinue.BeforeLoadDFMValues;
  edLocation.BeforeLoadDFMValues;
  try
    Name := 'FeedbackForm';
    Width := 838;
    Height := 614;
    ElementFont := efCSS;
    ElementPosition := epRelative;
    FormContainer := 'application';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    radEngineer.SetParentComponent(Self);
    radEngineer.Name := 'radEngineer';
    radEngineer.Left := 135;
    radEngineer.Top := 180;
    radEngineer.Width := 96;
    radEngineer.Height := 25;
    radEngineer.Caption := 'radEngineer';
    radEngineer.Checked := False;
    radEngineer.Color := clNone;
    radEngineer.ElementFont := efCSS;
    radEngineer.ElementPosition := epIgnore;
    radEngineer.GroupName := 'DescribeYourselfGroup';
    radEngineer.HeightStyle := ssAuto;
    radEngineer.HeightPercent := 100.000000000000000000;
    radEngineer.WidthStyle := ssAuto;
    radEngineer.WidthPercent := 100.000000000000000000;
    edOrganisation.SetParentComponent(Self);
    edOrganisation.Name := 'edOrganisation';
    edOrganisation.Left := 0;
    edOrganisation.Top := 31;
    edOrganisation.Width := 209;
    edOrganisation.Height := 25;
    edOrganisation.ChildOrder := 3;
    edOrganisation.ElementFont := efCSS;
    edOrganisation.ElementPosition := epIgnore;
    edOrganisation.HeightStyle := ssAuto;
    edOrganisation.HeightPercent := 100.000000000000000000;
    edOrganisation.WidthStyle := ssAuto;
    edOrganisation.WidthPercent := 100.000000000000000000;
    chkXTopology.SetParentComponent(Self);
    chkXTopology.Name := 'chkXTopology';
    chkXTopology.Left := 0;
    chkXTopology.Top := 310;
    chkXTopology.Width := 141;
    chkXTopology.Height := 25;
    chkXTopology.Caption := 'chkXTopology';
    chkXTopology.ChildOrder := 4;
    chkXTopology.Color := clNone;
    chkXTopology.ElementFont := efCSS;
    chkXTopology.ElementPosition := epIgnore;
    chkXTopology.HeightStyle := ssAuto;
    chkXTopology.HeightPercent := 100.000000000000000000;
    chkXTopology.State := cbUnchecked;
    chkXTopology.WidthStyle := ssAuto;
    chkXTopology.WidthPercent := 100.000000000000000000;
    radOther.SetParentComponent(Self);
    radOther.Name := 'radOther';
    radOther.Left := 135;
    radOther.Top := 237;
    radOther.Width := 96;
    radOther.Height := 25;
    radOther.Caption := 'radOther';
    radOther.Checked := False;
    radOther.ChildOrder := 5;
    radOther.Color := clNone;
    radOther.ElementFont := efCSS;
    radOther.ElementPosition := epIgnore;
    radOther.GroupName := 'DescribeYourselfGroup';
    radOther.HeightStyle := ssAuto;
    radOther.HeightPercent := 100.000000000000000000;
    radOther.WidthStyle := ssAuto;
    radOther.WidthPercent := 100.000000000000000000;
    chkStability.SetParentComponent(Self);
    chkStability.Name := 'chkStability';
    chkStability.Left := 0;
    chkStability.Top := 386;
    chkStability.Width := 96;
    chkStability.Height := 25;
    chkStability.Caption := 'chkStability';
    chkStability.ChildOrder := 7;
    chkStability.Color := clNone;
    chkStability.ElementFont := efCSS;
    chkStability.ElementPosition := epIgnore;
    chkStability.HeightStyle := ssAuto;
    chkStability.HeightPercent := 100.000000000000000000;
    chkStability.State := cbUnchecked;
    chkStability.WidthStyle := ssAuto;
    chkStability.WidthPercent := 100.000000000000000000;
    chkFileTransfer.SetParentComponent(Self);
    chkFileTransfer.Name := 'chkFileTransfer';
    chkFileTransfer.Left := 173;
    chkFileTransfer.Top := 386;
    chkFileTransfer.Width := 96;
    chkFileTransfer.Height := 25;
    chkFileTransfer.Caption := 'chkFileTransfer';
    chkFileTransfer.ChildOrder := 8;
    chkFileTransfer.Color := clNone;
    chkFileTransfer.ElementFont := efCSS;
    chkFileTransfer.ElementPosition := epIgnore;
    chkFileTransfer.HeightStyle := ssAuto;
    chkFileTransfer.HeightPercent := 100.000000000000000000;
    chkFileTransfer.State := cbUnchecked;
    chkFileTransfer.WidthStyle := ssAuto;
    chkFileTransfer.WidthPercent := 100.000000000000000000;
    chkCurveDesign.SetParentComponent(Self);
    chkCurveDesign.Name := 'chkCurveDesign';
    chkCurveDesign.Left := 173;
    chkCurveDesign.Top := 286;
    chkCurveDesign.Width := 96;
    chkCurveDesign.Height := 25;
    chkCurveDesign.Caption := 'chkCurveDesign';
    chkCurveDesign.ChildOrder := 9;
    chkCurveDesign.Color := clNone;
    chkCurveDesign.ElementFont := efCSS;
    chkCurveDesign.ElementPosition := epIgnore;
    chkCurveDesign.HeightStyle := ssAuto;
    chkCurveDesign.HeightPercent := 100.000000000000000000;
    chkCurveDesign.State := cbUnchecked;
    chkCurveDesign.WidthStyle := ssAuto;
    chkCurveDesign.WidthPercent := 100.000000000000000000;
    chkWeightEstimation.SetParentComponent(Self);
    chkWeightEstimation.Name := 'chkWeightEstimation';
    chkWeightEstimation.Left := 0;
    chkWeightEstimation.Top := 360;
    chkWeightEstimation.Width := 96;
    chkWeightEstimation.Height := 25;
    chkWeightEstimation.Caption := 'chkWeightEstimation';
    chkWeightEstimation.ChildOrder := 10;
    chkWeightEstimation.Color := clNone;
    chkWeightEstimation.ElementFont := efCSS;
    chkWeightEstimation.ElementPosition := epIgnore;
    chkWeightEstimation.HeightStyle := ssAuto;
    chkWeightEstimation.HeightPercent := 100.000000000000000000;
    chkWeightEstimation.State := cbUnchecked;
    chkWeightEstimation.WidthStyle := ssAuto;
    chkWeightEstimation.WidthPercent := 100.000000000000000000;
    chkGeometricAnalysis.SetParentComponent(Self);
    chkGeometricAnalysis.Name := 'chkGeometricAnalysis';
    chkGeometricAnalysis.Left := 173;
    chkGeometricAnalysis.Top := 334;
    chkGeometricAnalysis.Width := 96;
    chkGeometricAnalysis.Height := 25;
    chkGeometricAnalysis.Caption := 'chkGeometricAnalysis';
    chkGeometricAnalysis.ChildOrder := 11;
    chkGeometricAnalysis.Color := clNone;
    chkGeometricAnalysis.ElementFont := efCSS;
    chkGeometricAnalysis.ElementPosition := epIgnore;
    chkGeometricAnalysis.HeightStyle := ssAuto;
    chkGeometricAnalysis.HeightPercent := 100.000000000000000000;
    chkGeometricAnalysis.State := cbUnchecked;
    chkGeometricAnalysis.WidthStyle := ssAuto;
    chkGeometricAnalysis.WidthPercent := 100.000000000000000000;
    chkHullGeneration.SetParentComponent(Self);
    chkHullGeneration.Name := 'chkHullGeneration';
    chkHullGeneration.Left := 173;
    chkHullGeneration.Top := 310;
    chkHullGeneration.Width := 96;
    chkHullGeneration.Height := 25;
    chkHullGeneration.Caption := 'chkHullGeneration';
    chkHullGeneration.ChildOrder := 12;
    chkHullGeneration.Color := clNone;
    chkHullGeneration.ElementFont := efCSS;
    chkHullGeneration.ElementPosition := epIgnore;
    chkHullGeneration.HeightStyle := ssAuto;
    chkHullGeneration.HeightPercent := 100.000000000000000000;
    chkHullGeneration.State := cbUnchecked;
    chkHullGeneration.WidthStyle := ssAuto;
    chkHullGeneration.WidthPercent := 100.000000000000000000;
    radAcademic.SetParentComponent(Self);
    radAcademic.Name := 'radAcademic';
    radAcademic.Left := 0;
    radAcademic.Top := 237;
    radAcademic.Width := 96;
    radAcademic.Height := 25;
    radAcademic.Caption := 'radAcademic';
    radAcademic.Checked := False;
    radAcademic.ChildOrder := 13;
    radAcademic.Color := clNone;
    radAcademic.ElementFont := efCSS;
    radAcademic.ElementPosition := epIgnore;
    radAcademic.GroupName := 'DescribeYourselfGroup';
    radAcademic.HeightStyle := ssAuto;
    radAcademic.HeightPercent := 100.000000000000000000;
    radAcademic.WidthStyle := ssAuto;
    radAcademic.WidthPercent := 100.000000000000000000;
    chkImages.SetParentComponent(Self);
    chkImages.Name := 'chkImages';
    chkImages.Left := 173;
    chkImages.Top := 412;
    chkImages.Width := 96;
    chkImages.Height := 25;
    chkImages.Caption := 'chkImages';
    chkImages.ChildOrder := 14;
    chkImages.Color := clNone;
    chkImages.ElementFont := efCSS;
    chkImages.ElementPosition := epIgnore;
    chkImages.HeightStyle := ssAuto;
    chkImages.HeightPercent := 100.000000000000000000;
    chkImages.State := cbUnchecked;
    chkImages.WidthStyle := ssAuto;
    chkImages.WidthPercent := 100.000000000000000000;
    chkHydrostatics.SetParentComponent(Self);
    chkHydrostatics.Name := 'chkHydrostatics';
    chkHydrostatics.Left := 173;
    chkHydrostatics.Top := 360;
    chkHydrostatics.Width := 96;
    chkHydrostatics.Height := 25;
    chkHydrostatics.Caption := 'chkHydrostatics';
    chkHydrostatics.ChildOrder := 15;
    chkHydrostatics.Color := clNone;
    chkHydrostatics.ElementFont := efCSS;
    chkHydrostatics.ElementPosition := epIgnore;
    chkHydrostatics.HeightStyle := ssAuto;
    chkHydrostatics.HeightPercent := 100.000000000000000000;
    chkHydrostatics.State := cbUnchecked;
    chkHydrostatics.WidthStyle := ssAuto;
    chkHydrostatics.WidthPercent := 100.000000000000000000;
    chkHullTransformation.SetParentComponent(Self);
    chkHullTransformation.Name := 'chkHullTransformation';
    chkHullTransformation.Left := 0;
    chkHullTransformation.Top := 334;
    chkHullTransformation.Width := 96;
    chkHullTransformation.Height := 25;
    chkHullTransformation.Caption := 'chkHullTransformation';
    chkHullTransformation.ChildOrder := 16;
    chkHullTransformation.Color := clNone;
    chkHullTransformation.ElementFont := efCSS;
    chkHullTransformation.ElementPosition := epIgnore;
    chkHullTransformation.HeightStyle := ssAuto;
    chkHullTransformation.HeightPercent := 100.000000000000000000;
    chkHullTransformation.State := cbUnchecked;
    chkHullTransformation.WidthStyle := ssAuto;
    chkHullTransformation.WidthPercent := 100.000000000000000000;
    chkMailRelease.SetParentComponent(Self);
    chkMailRelease.Name := 'chkMailRelease';
    chkMailRelease.Left := 0;
    chkMailRelease.Top := 467;
    chkMailRelease.Width := 96;
    chkMailRelease.Height := 25;
    chkMailRelease.Caption := 'chkMailRelease';
    chkMailRelease.ChildOrder := 17;
    chkMailRelease.Color := clNone;
    chkMailRelease.ElementFont := efCSS;
    chkMailRelease.ElementPosition := epIgnore;
    chkMailRelease.HeightStyle := ssAuto;
    chkMailRelease.HeightPercent := 100.000000000000000000;
    chkMailRelease.State := cbUnchecked;
    chkMailRelease.WidthStyle := ssAuto;
    chkMailRelease.WidthPercent := 100.000000000000000000;
    radAdmin.SetParentComponent(Self);
    radAdmin.Name := 'radAdmin';
    radAdmin.Left := 135;
    radAdmin.Top := 206;
    radAdmin.Width := 96;
    radAdmin.Height := 25;
    radAdmin.Caption := 'radAdmin';
    radAdmin.Checked := False;
    radAdmin.ChildOrder := 18;
    radAdmin.Color := clNone;
    radAdmin.ElementFont := efCSS;
    radAdmin.ElementPosition := epIgnore;
    radAdmin.GroupName := 'DescribeYourselfGroup';
    radAdmin.HeightStyle := ssAuto;
    radAdmin.HeightPercent := 100.000000000000000000;
    radAdmin.WidthStyle := ssAuto;
    radAdmin.WidthPercent := 100.000000000000000000;
    txtHearOf.SetParentComponent(Self);
    txtHearOf.Name := 'txtHearOf';
    txtHearOf.Left := 0;
    txtHearOf.Top := 93;
    txtHearOf.Width := 430;
    txtHearOf.Height := 81;
    txtHearOf.AutoSize := False;
    txtHearOf.ElementFont := efCSS;
    txtHearOf.ElementPosition := epIgnore;
    txtHearOf.HeightStyle := ssAuto;
    txtHearOf.HeightPercent := 100.000000000000000000;
    txtHearOf.SelLength := 0;
    txtHearOf.SelStart := 0;
    txtHearOf.WidthStyle := ssAuto;
    txtHearOf.WidthPercent := 100.000000000000000000;
    chkLinesPlanGeneration.SetParentComponent(Self);
    chkLinesPlanGeneration.Name := 'chkLinesPlanGeneration';
    chkLinesPlanGeneration.Left := 173;
    chkLinesPlanGeneration.Top := 436;
    chkLinesPlanGeneration.Width := 96;
    chkLinesPlanGeneration.Height := 25;
    chkLinesPlanGeneration.Caption := 'chkLinesPlanGeneration';
    chkLinesPlanGeneration.ChildOrder := 21;
    chkLinesPlanGeneration.Color := clNone;
    chkLinesPlanGeneration.ElementFont := efCSS;
    chkLinesPlanGeneration.ElementPosition := epIgnore;
    chkLinesPlanGeneration.HeightStyle := ssAuto;
    chkLinesPlanGeneration.HeightPercent := 100.000000000000000000;
    chkLinesPlanGeneration.State := cbUnchecked;
    chkLinesPlanGeneration.WidthStyle := ssAuto;
    chkLinesPlanGeneration.WidthPercent := 100.000000000000000000;
    chkSurfaceDesign.SetParentComponent(Self);
    chkSurfaceDesign.Name := 'chkSurfaceDesign';
    chkSurfaceDesign.Left := 0;
    chkSurfaceDesign.Top := 286;
    chkSurfaceDesign.Width := 137;
    chkSurfaceDesign.Height := 25;
    chkSurfaceDesign.Caption := 'chkSurfaceDesign';
    chkSurfaceDesign.ChildOrder := 22;
    chkSurfaceDesign.Color := clNone;
    chkSurfaceDesign.ElementFont := efCSS;
    chkSurfaceDesign.ElementPosition := epIgnore;
    chkSurfaceDesign.HeightStyle := ssAuto;
    chkSurfaceDesign.HeightPercent := 100.000000000000000000;
    chkSurfaceDesign.State := cbUnchecked;
    chkSurfaceDesign.WidthStyle := ssAuto;
    chkSurfaceDesign.WidthPercent := 100.000000000000000000;
    radStudent.SetParentComponent(Self);
    radStudent.Name := 'radStudent';
    radStudent.Left := 0;
    radStudent.Top := 180;
    radStudent.Width := 96;
    radStudent.Height := 25;
    radStudent.Caption := 'radStudent';
    radStudent.Checked := False;
    radStudent.ChildOrder := 23;
    radStudent.Color := clNone;
    radStudent.ElementFont := efCSS;
    radStudent.ElementPosition := epIgnore;
    radStudent.GroupName := 'DescribeYourselfGroup';
    radStudent.HeightStyle := ssAuto;
    radStudent.HeightPercent := 100.000000000000000000;
    radStudent.WidthStyle := ssAuto;
    radStudent.WidthPercent := 100.000000000000000000;
    chkPointClouds.SetParentComponent(Self);
    chkPointClouds.Name := 'chkPointClouds';
    chkPointClouds.Left := 0;
    chkPointClouds.Top := 412;
    chkPointClouds.Width := 96;
    chkPointClouds.Height := 25;
    chkPointClouds.Caption := 'chkPointClouds';
    chkPointClouds.ChildOrder := 24;
    chkPointClouds.Color := clNone;
    chkPointClouds.ElementFont := efCSS;
    chkPointClouds.ElementPosition := epIgnore;
    chkPointClouds.HeightStyle := ssAuto;
    chkPointClouds.HeightPercent := 100.000000000000000000;
    chkPointClouds.State := cbUnchecked;
    chkPointClouds.WidthStyle := ssAuto;
    chkPointClouds.WidthPercent := 100.000000000000000000;
    radAmateur.SetParentComponent(Self);
    radAmateur.Name := 'radAmateur';
    radAmateur.Left := 0;
    radAmateur.Top := 206;
    radAmateur.Width := 96;
    radAmateur.Height := 25;
    radAmateur.Caption := 'radAmateur';
    radAmateur.Checked := False;
    radAmateur.ChildOrder := 25;
    radAmateur.Color := clNone;
    radAmateur.ElementFont := efCSS;
    radAmateur.ElementPosition := epIgnore;
    radAmateur.GroupName := 'DescribeYourselfGroup';
    radAmateur.HeightStyle := ssAuto;
    radAmateur.HeightPercent := 100.000000000000000000;
    radAmateur.WidthStyle := ssAuto;
    radAmateur.WidthPercent := 100.000000000000000000;
    chkArrangements.SetParentComponent(Self);
    chkArrangements.Name := 'chkArrangements';
    chkArrangements.Left := 0;
    chkArrangements.Top := 436;
    chkArrangements.Width := 96;
    chkArrangements.Height := 25;
    chkArrangements.Caption := 'chkArrangements';
    chkArrangements.ChildOrder := 26;
    chkArrangements.Color := clNone;
    chkArrangements.ElementFont := efCSS;
    chkArrangements.ElementPosition := epIgnore;
    chkArrangements.HeightStyle := ssAuto;
    chkArrangements.HeightPercent := 100.000000000000000000;
    chkArrangements.State := cbUnchecked;
    chkArrangements.WidthStyle := ssAuto;
    chkArrangements.WidthPercent := 100.000000000000000000;
    edFullName.SetParentComponent(Self);
    edFullName.Name := 'edFullName';
    edFullName.Left := 0;
    edFullName.Top := 0;
    edFullName.Width := 209;
    edFullName.Height := 25;
    edFullName.ChildOrder := 27;
    edFullName.ElementFont := efCSS;
    edFullName.ElementPosition := epIgnore;
    edFullName.HeightStyle := ssAuto;
    edFullName.HeightPercent := 100.000000000000000000;
    edFullName.WidthStyle := ssAuto;
    edFullName.WidthPercent := 100.000000000000000000;
    chkMailArticle.SetParentComponent(Self);
    chkMailArticle.Name := 'chkMailArticle';
    chkMailArticle.Left := 0;
    chkMailArticle.Top := 491;
    chkMailArticle.Width := 96;
    chkMailArticle.Height := 25;
    chkMailArticle.Caption := 'chkMailArticle';
    chkMailArticle.ChildOrder := 28;
    chkMailArticle.Color := clNone;
    chkMailArticle.ElementFont := efCSS;
    chkMailArticle.ElementPosition := epIgnore;
    chkMailArticle.HeightStyle := ssAuto;
    chkMailArticle.HeightPercent := 100.000000000000000000;
    chkMailArticle.State := cbUnchecked;
    chkMailArticle.WidthStyle := ssAuto;
    chkMailArticle.WidthPercent := 100.000000000000000000;
    btnContinue.SetParentComponent(Self);
    btnContinue.Name := 'btnContinue';
    btnContinue.Left := 0;
    btnContinue.Top := 522;
    btnContinue.Width := 96;
    btnContinue.Height := 25;
    btnContinue.Caption := 'Continue';
    btnContinue.ChildOrder := 25;
    btnContinue.ElementFont := efCSS;
    btnContinue.ElementPosition := epIgnore;
    btnContinue.HeightStyle := ssAuto;
    btnContinue.HeightPercent := 100.000000000000000000;
    btnContinue.WidthStyle := ssAuto;
    btnContinue.WidthPercent := 100.000000000000000000;
    SetEvent(btnContinue, Self, 'OnClick', 'btnContinueClick');
    edLocation.SetParentComponent(Self);
    edLocation.Name := 'edLocation';
    edLocation.Left := 0;
    edLocation.Top := 62;
    edLocation.Width := 209;
    edLocation.Height := 25;
    edLocation.ChildOrder := 29;
    edLocation.ElementFont := efCSS;
    edLocation.ElementPosition := epIgnore;
    edLocation.HeightStyle := ssAuto;
    edLocation.HeightPercent := 100.000000000000000000;
    edLocation.WidthStyle := ssAuto;
    edLocation.WidthPercent := 100.000000000000000000;
  finally
    radEngineer.AfterLoadDFMValues;
    edOrganisation.AfterLoadDFMValues;
    chkXTopology.AfterLoadDFMValues;
    radOther.AfterLoadDFMValues;
    chkStability.AfterLoadDFMValues;
    chkFileTransfer.AfterLoadDFMValues;
    chkCurveDesign.AfterLoadDFMValues;
    chkWeightEstimation.AfterLoadDFMValues;
    chkGeometricAnalysis.AfterLoadDFMValues;
    chkHullGeneration.AfterLoadDFMValues;
    radAcademic.AfterLoadDFMValues;
    chkImages.AfterLoadDFMValues;
    chkHydrostatics.AfterLoadDFMValues;
    chkHullTransformation.AfterLoadDFMValues;
    chkMailRelease.AfterLoadDFMValues;
    radAdmin.AfterLoadDFMValues;
    txtHearOf.AfterLoadDFMValues;
    chkLinesPlanGeneration.AfterLoadDFMValues;
    chkSurfaceDesign.AfterLoadDFMValues;
    radStudent.AfterLoadDFMValues;
    chkPointClouds.AfterLoadDFMValues;
    radAmateur.AfterLoadDFMValues;
    chkArrangements.AfterLoadDFMValues;
    edFullName.AfterLoadDFMValues;
    chkMailArticle.AfterLoadDFMValues;
    btnContinue.AfterLoadDFMValues;
    edLocation.AfterLoadDFMValues;
  end;
end;

end.
