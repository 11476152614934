unit ForgotPasswordUnit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  WEBLib.WebCtrls;

type
  TForgotPasswordForm = class(TForm)
    divMessage: THTMLDiv;
    lbMessage: TLabel;
    btnResetPassword: TButton;
    edEMail: TEdit;
    procedure edEmailChange(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure btnResetPasswordClick(Sender: TObject);
  private
    { Private declarations }
    [async]
    Procedure Submit; async;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  ForgotPasswordForm: TForgotPasswordForm;

implementation

{$R *.dfm}

Uses LxWeb.Tools, MyPolyCAD.app, MyPolyCAD.Configuration, MessageLoginUnit, MessageUnit;

Procedure TForgotPasswordForm.Submit;
Var
   AMessage, APageMessage : String;
Begin
     if await(boolean, appMyPolyCAD.requestNewPassword(edEmail.Text, AMessage)) Then Begin
        APageMessage := '<p>An email has been sent to <strong>' + edEmail.text + '</strong> to allow a password change if it associated with an account, please click on the link contained within the email to complete the process.</p>' +
                        '<p>If you experience any issues changing your password, please contact support at <a href="mailto:' + ConfigurationSupportEmail + '">' + ConfigurationSupportEmail + '</a></p>';


        TMessageLoginForm.ShowMessage('Password Change Request Sent', APageMessage);
        End
     Else Begin
        If Uppercase(AMessage) = 'CHANGE PASSWORD EMAIL ALREADY SENT' Then Begin
           APageMessage := '<p>An email has already been sent to <strong>' + edEmail.text + '</strong> to allow a password change, please check in your email and spam box.  If nothing arrives, please wait some minutes before trying again.</p>' +
                           '<p>If you experience any issues changing your password, please contact support at <a href="mailto:' + ConfigurationSupportEmail + '">' + ConfigurationSupportEmail + '</a></p>';

           TMessageLoginForm.ShowMessage('Password Change Request Sent', APageMessage);
           End
        Else If Uppercase(AMessage) = 'EMAIL NOT FOUND' Then Begin
           APageMessage := '<p>An email has been sent to <strong>' + edEmail.text + '</strong> to allow a password change if it is associated with an account, please click on the link contained within the email to complete the process.</p>' +
                           '<p>If you experience any issues changing your password, please contact support at <a href="mailto:' + ConfigurationSupportEmail + '">' + ConfigurationSupportEmail + '</a></p>';

           TMessageLoginForm.ShowMessage('Password Change Request Sent', APageMessage);
           End
        Else If Uppercase(AMessage) = 'USER ACCOUNT IS DISABLED, CONTACT SUPPORT' Then Begin
           APageMessage := '<p>The account associated with <strong>' + edEmail.text + '</strong> is currently disabled. Please contact support at <a href="mailto:' + ConfigurationSupportEmail + '">' + ConfigurationSupportEmail + '</a> to resolve the issue.</p>';
           TMessageForm.ShowMessage('User Account is Diabled', APageMessage);
           End
        Else If Uppercase(AMessage) = 'USER ACCOUNT IS LOCKED, PLEASE WAIT SOME TIME, OR CONTACT SUPPORT' Then Begin
           APageMessage := '<p>The account associated with <strong>' + edEmail.text + '</strong> is currently locked. Please wait some time for the lock to release or contact support at <a href="mailto:' + ConfigurationSupportEmail + '">' + ConfigurationSupportEmail + '</a> to resolve the issue.</p>';
           TMessageLoginForm.ShowMessage('User Account is Locked', APageMessage);
           End
        Else If Uppercase(AMessage) = 'CHANGE PASSWORD EMAIL FAILED TO SEND' Then Begin
           APageMessage := '<p>An email has been sent to <strong>' + edEmail.text + '</strong> to allow a password change if it is associated with an account, please click on the link contained within the email to complete the process.</p>' +
                           '<p>If you experience any issues changing your password, please contact support at <a href="mailto:' + ConfigurationSupportEmail + '">' + ConfigurationSupportEmail + '</a></p>';

           TMessageLoginForm.ShowMessage('Password Change Request Sent', APageMessage);
           End
        Else Begin
           lbMessage.Caption := 'Error: ' + AMessage;
           divMessage.Visible := True;
           End;
        End;
End;

procedure TForgotPasswordForm.WebFormCreate(Sender: TObject);
begin
     btnResetPassword.Enabled := False;
end;

procedure TForgotPasswordForm.edEmailChange(Sender: TObject);
begin
     divMessage.Visible := False;
     btnResetPassword.Enabled := ValidateEmail(edEmail.Text);
end;

procedure TForgotPasswordForm.btnResetPasswordClick(Sender: TObject);
begin
     Submit;
end;



procedure TForgotPasswordForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divMessage := THTMLDiv.Create('divMessage');
  lbMessage := TLabel.Create('lbMessage');
  btnResetPassword := TButton.Create('btnResetPassword');
  edEMail := TEdit.Create('edEmail');

  divMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btnResetPassword.BeforeLoadDFMValues;
  edEMail.BeforeLoadDFMValues;
  try
    Name := 'ForgotPasswordForm';
    Width := 640;
    Height := 480;
    ElementClassName := 'application';
    ElementFont := efCSS;
    ElementPosition := epRelative;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divMessage.SetParentComponent(Self);
    divMessage.Name := 'divMessage';
    divMessage.Left := 8;
    divMessage.Top := 84;
    divMessage.Width := 221;
    divMessage.Height := 97;
    divMessage.HeightStyle := ssAuto;
    divMessage.WidthStyle := ssAuto;
    divMessage.ChildOrder := 5;
    divMessage.ElementPosition := epIgnore;
    divMessage.ElementFont := efCSS;
    divMessage.Role := '';
    divMessage.Visible := False;
    lbMessage.SetParentComponent(divMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 24;
    lbMessage.Top := 28;
    lbMessage.Width := 50;
    lbMessage.Height := 13;
    lbMessage.Caption := 'lbMessage';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btnResetPassword.SetParentComponent(Self);
    btnResetPassword.Name := 'btnResetPassword';
    btnResetPassword.Left := 8;
    btnResetPassword.Top := 8;
    btnResetPassword.Width := 96;
    btnResetPassword.Height := 25;
    btnResetPassword.Caption := 'btnResetPassword';
    btnResetPassword.ChildOrder := 1;
    btnResetPassword.ElementFont := efCSS;
    btnResetPassword.ElementPosition := epIgnore;
    btnResetPassword.HeightStyle := ssAuto;
    btnResetPassword.HeightPercent := 100.000000000000000000;
    btnResetPassword.WidthStyle := ssAuto;
    btnResetPassword.WidthPercent := 100.000000000000000000;
    SetEvent(btnResetPassword, Self, 'OnClick', 'btnResetPasswordClick');
    edEMail.SetParentComponent(Self);
    edEMail.Name := 'edEMail';
    edEMail.Left := 8;
    edEMail.Top := 44;
    edEMail.Width := 96;
    edEMail.Height := 25;
    edEMail.ChildOrder := 2;
    edEMail.ElementFont := efCSS;
    edEMail.ElementPosition := epIgnore;
    edEMail.HeightStyle := ssAuto;
    edEMail.HeightPercent := 100.000000000000000000;
    edEMail.WidthStyle := ssAuto;
    edEMail.WidthPercent := 100.000000000000000000;
    SetEvent(edEMail, Self, 'OnChange', 'edEmailChange');
  finally
    divMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btnResetPassword.AfterLoadDFMValues;
    edEMail.AfterLoadDFMValues;
  end;
end;

end.